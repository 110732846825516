// prettier-ignore
import { makeStyles, Theme, Typography } from '@material-ui/core';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import classnames from 'classnames';
import * as React from 'react';
import { isMobile } from '../../responsive';
import NumberField from './NumberField';
import { numberToString } from './numberToString';

interface Props extends WithWidth {
	value?: number;
	editable?: boolean;
	numberBackgroundColor?: {
		notEditable: string;
		editable: string;
		editing: string;
	};
	onKeyPress?: (key: string) => void;
	bold?: boolean;
	borderBottom?: boolean;
	isEditing?: boolean;
	errorText?: string | null;
	disabled?: boolean;
	undefinedValuePlaceholder?: string; // default is '-'
	decimalDigits?: number;
	currentUnit?: string;
	onFinished?: (v: number) => void;
	receiveFocus?: () => void;
}

function NumberFieldWrapper(props: Props) {
	const {
		editable,
		numberBackgroundColor,
		bold,
		errorText,
		disabled,
		width,
		undefinedValuePlaceholder,
		value,
		onKeyPress,
		isEditing,
		currentUnit,
		decimalDigits,
		onFinished,
		receiveFocus,
	} = props;

	const classes = useStyles(props);

	// default backgroundColors
	let nbColor = {
		notEditable: 'transparent',
		editable: 'lightgrey',
		editing: 'lightgrey',
	};
	if (numberBackgroundColor) {
		nbColor = numberBackgroundColor;
	}

	if (isEditing && editable && !disabled) {
		return (
			<div>
				<NumberField
					value={typeof value === 'number' ? parseFloat(value.toFixed(decimalDigits)) : value}
					endAdornment={currentUnit}
					onFinished={onFinished}
					autoFocus
					style={{
						backgroundColor: nbColor.editing,
					}}
					isTooltipOpen={errorText !== null && errorText !== undefined && !isMobile(width)}
					tooltipTitle={errorText ? errorText : ''}
					onKeyPress={onKeyPress}
					decimalDigits={decimalDigits}
				/>
			</div>
		);
	} else {
		let bgColor = editable && !disabled ? nbColor.editable : nbColor.notEditable;

		return (
			<div>
				<Typography
					data-cy="calculationRowValue"
					variant="subtitle1"
					className={classnames(
						classes.number,
						bold && classes.bold,
						errorText && classes.errorText,
						disabled && classes.disabledText
					)}
					style={{
						cursor: editable && !disabled ? 'pointer' : 'default',
						backgroundColor: bgColor,
						width: editable && !disabled ? '150px' : '',
					}}
					onClick={receiveFocus}
				>
					{numberToString(value, decimalDigits, undefinedValuePlaceholder)} {' ' + currentUnit}
				</Typography>
			</div>
		);
	}
}

const useStyles = makeStyles((theme: Theme) => ({
	bold: {
		fontWeight: 'bold',
	},

	errorText: {
		color: 'red',
	},

	disabledText: {
		color: 'lightgrey',
	},

	number: {
		lineHeight: 1.3,
		textAlign: 'right',
		marginTop: 5,
		marginRight: 5,
		marginBottom: 5,
		paddingTop: 6,
		paddingBottom: 7,
		paddingRight: 5,
	},
}));

export default withWidth()(NumberFieldWrapper);
