import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useEffect } from 'react';

interface Props {
	images: string[];
	children?: React.ReactNode;
}

function MovingBackground(props: Props) {
	const { images, children } = props;
	const classes = useStyles();

	// setter for state attributes
	const [imageIndex, setImageIndex] = React.useState(0);

	// requesting images immediately at mounting of component
	const loadImages = () => {
		images.forEach((src: string) => {
			const img = document.createElement('img');
			img.src = src; // Assigning the img src immediately requests the image
		});
	};
	// runs only once right at beginning, when component is mounted
	useEffect(() => {
		loadImages();
	}, [loadImages]);

	// second useEffect, responsible for component updates
	useEffect(() => {
		let newIndex = 0;

		if (imageIndex + 1 < images.length) {
			newIndex = imageIndex + 1;
		}

		let timeout = updateStates(newIndex);
		return () => {
			clearTimeout(timeout);
		};
	});

	// setting new states
	const updateStates = (imageIndex: number) => {
		let timeout = setTimeout(() => {
			setImageIndex(imageIndex);
		}, 6900);
		return timeout;
	};

	return (
		<div className={classes.wrapper}>
			<div key={'mimageContainer' + imageIndex} className={classes.background}>
				<img key={'mimage' + imageIndex} src={images[imageIndex]} className={classes.image} />
			</div>
			<div className={classes.content}>{children}</div>
		</div>
	);
}

const useStyles = makeStyles({
	wrapper: {
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		backgroundColor: 'white',
	},

	background: {
		animation: '$bg-slide 7s ease-out',
		width: '100%',
		height: 'calc(100% + 50px)',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
	},

	content: {
		position: 'relative',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 26,
		color: 'white',
		height: '100%',
		flexDirection: 'column',
	},

	// changed minHeight and minWidth to max_... as test-pictures are big
	image: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		// maxHeight: 'calc(100% + 50px)',
		// maxWidth: '100%',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},

	'@keyframes bg-slide': {
		'0%': {
			transform: 'translateY(0)',
			opacity: 0,
		},
		'2%': {
			opacity: 0.1,
		},
		'7%': {
			transform: 'translateY(-15px)',
			opacity: 0.6,
		},
		'40%': {
			opacity: 1,
		},
		'85%': {
			opacity: 1,
		},
		'97%': {
			opacity: 0.2,
		},
		'100%': {
			transform: 'translateY(-50px)',
			opacity: 0,
		},
	},
});

export default MovingBackground;
