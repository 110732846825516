// prettier-ignore
import { makeStyles } from '@material-ui/core';
import * as React from 'react';

interface Props {
	children?: React.ReactNode | React.ReactNode[];
}

function ConfigFieldContainer(props: Props) {
	const { children } = props;
	const classes = useStyles(props);

	return <div className={classes.root}>{children}</div>;
}

const useStyles = makeStyles({
	root: {
		paddingLeft: 40,
		paddingRight: 60,
	},
});

export default ConfigFieldContainer;
