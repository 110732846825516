// prettier-ignore
import { Collapse, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import UncompletedIcon from '@material-ui/icons/PanoramaFishEye';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useValidator } from '../../middleware/fieldValidation/fieldValidatorHook';
import { Step as S, SubStep as SS, ValidationField } from '../../model/model';
import { RootState } from '../../reducers';
import StepValidationDialog from '../validation/StepValidationDialog';

interface Props {
	step: S;
}

function Step(props: Props) {
	const { step } = props;
	const classes = useStyles(props);
	const [progressVal, setProgressVal] = React.useState(0);
	React.useEffect(() => {
		setTimeout(() => setProgressVal(step.progress ? step.progress : 0), 50);
	});
	const { t } = useTranslation();

	const validationFields: ValidationField[] = useSelector((state: RootState) => state.fieldValidation);

	let setStepCompletedAfterClick = validationFields.findIndex((el) => el.stepId === step.id) === -1;

	return (
		<Root {...props} setStepCompletedAfterClick={setStepCompletedAfterClick}>
			<div className={classes.nameDescContainer}>
				<Typography color="textSecondary" variant="button">
					{t(step.name)}
				</Typography>
				<Typography variant="subtitle2" className={classes.description}>
					{t(step.description)}
				</Typography>
			</div>
			<LinearProgress
				value={progressVal}
				variant="determinate"
				classes={
					step.disabled
						? {
								root: classes.progressDisabledRight,
								barColorPrimary: classes.progressDisabledLeft,
						  }
						: {}
				}
			/>
			<div />
		</Root>
	);
}

interface RootProps extends Props {
	children?: React.ReactNode[];
	setStepCompletedAfterClick?: boolean;
}
function Root(props: RootProps) {
	const classes = useStyles(props);
	const { setStepProgressCompleted } = useValidator();
	const { history, location } = useReactRouter();
	const { step, setStepCompletedAfterClick } = props;
	const [open, setOpen] = React.useState(false);
	const [subStepsOpen, setSubStepsOpen] = React.useState(false);

	const handleClick = () => {
		if (!step.disabled) {
			history.push(step.route);
			if (setStepCompletedAfterClick) {
				setStepProgressCompleted(step.id);
			}
		} else {
			setOpen(true);
		}
	};

	// changed from equals to startsWith to support tabs with subroutes
	const active = location.pathname.startsWith(step.route);

	React.useEffect(() => {
		let timeout = setTimeout(() => setSubStepsOpen(active), 1);
		return () => {
			clearTimeout(timeout);
		};
	});

	return (
		<div>
			<div className={active ? classes.rootActive : classes.root} onClick={handleClick}>
				{props.children}
			</div>
			<SubSteps {...props} open={subStepsOpen} />
			<StepValidationDialog {...props} step={step} open={open} onClose={() => setOpen(false)} />
		</div>
	);
}

interface SubStepsProps extends Props {
	open: boolean;
}
function SubSteps(props: SubStepsProps): JSX.Element {
	const { open, step } = props;
	const classes = useStyles(props);

	if (step.subSteps) {
		return (
			<Collapse in={open}>
				<div className={classes.subStepListContainer}>
					{step.subSteps.map((s, i) => (
						<div key={i}>
							{i !== 0 && <div className={classes.divider} />}
							<SubStep {...props} subStep={s} />
						</div>
					))}
				</div>
			</Collapse>
		);
	} else {
		return <></>;
	}
}

interface SubStepProps extends Props {
	subStep: SS;
}
function SubStep(props: SubStepProps) {
	const { setSubStepCompleted } = useValidator();
	const { subStep, step } = props;
	const classes = useStyles(props);
	const { t } = useTranslation();
	const { history, location } = useReactRouter();
	const [open, setOpen] = React.useState(false);
	const validationFields: ValidationField[] = useSelector((state: RootState) => state.fieldValidation);
	const active = subStep.route === location.pathname; // location.pathname.startsWith(subStep.route);

	let setSubStepCompletedAfterClick = validationFields.findIndex((el) => el.subStepId === subStep.id) === -1;

	if (active) {
		if (setSubStepCompletedAfterClick) {
			setSubStepCompleted(subStep.id);
		}
	}

	const onClick = () => {
		if (!subStep.disabled) {
			history.push(subStep.route);
		} else {
			setOpen(true);
		}
	};

	return (
		<div className={subStep.disabled ? classes.subStepContainerDisabled : ''}>
			<div className={active ? classes.subStepContainerActive : classes.subStepContainer}>
				{!subStep.completed && (
					<UncompletedIcon
						fontSize="small"
						className={classes.icon}
						color={subStep.disabled ? 'disabled' : 'secondary'}
					/>
				)}
				{subStep.completed && <CheckIcon fontSize="small" className={classes.icon} color="secondary" />}
				<Typography
					variant="body1"
					className={active ? classes.subStepNameActive : classes.subStepName}
					onClick={onClick}
				>
					{t(subStep.name)}
				</Typography>
			</div>
			<StepValidationDialog {...props} step={step} subStep={subStep} open={open} onClose={() => setOpen(false)} />
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: (props: Props) => ({
		height: 90,
		minHeight: 90,
		padding: 10,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.default,
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
			cursor: 'pointer',
		},
	}),

	rootActive: (props: Props) => ({
		height: 90,
		minHeight: 90,
		padding: 10,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			cursor: 'pointer',
		},
	}),

	nameDescContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 5,
	},

	description: (props: Props) => ({
		fontWeight: 'bold',
		fontSize: 18,
		color: props.step.disabled ? theme.palette.text.disabled : theme.palette.text.primary,
	}),

	progressDisabledLeft: {
		backgroundColor: 'lightgrey',
	},

	progressDisabledRight: {
		backgroundColor: '#ececec',
	},

	subStepListContainer: {
		width: '95%',
		padding: 5,
		paddingLeft: 10,
		paddingBottom: 10,
	},

	subStepContainer: {
		display: 'flex',
	},
	subStepContainerActive: {
		display: 'flex',
		textDecoration: 'underline',
	},
	subStepContainerDisabled: {
		color: theme.palette.text.disabled,
	},
	icon: {
		paddingTop: 6,
		marginRight: 5,
	},

	divider: {
		height: 10,
		width: '100%',
		borderLeftColor: theme.palette.primary.main,
		borderStyle: 'solid',
		borderWidth: 0,
		borderLeftWidth: 2,
		marginLeft: 9,
	},

	subStepName: {
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
			cursor: 'pointer',
			textDecoration: 'underline',
		},
		paddingLeft: 7,
		paddingRight: 7,
		borderRadius: 12,
	},

	subStepNameActive: {
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
			cursor: 'pointer',
			textDecoration: 'underline',
			color: theme.palette.primary.dark,
		},
		fontWeight: 'bold',
		paddingLeft: 7,
		paddingRight: 7,
		borderRadius: 12,
	},
}));

export default Step;
