// prettier-ignore
import {Divider, Drawer as DrawerMui, makeStyles, Theme, Typography} from '@material-ui/core';
import * as React from 'react';
import { Step as S } from '../../model/model';
import Feedback from './Feedback';
import Price from './Price';
import Step from './Step';

interface Props {
	stepList: S[];
}

function Drawer(props: Props) {
	const { stepList } = props;
	const classes = useStyles();

	return (
		<DrawerMui
			variant="permanent"
			open
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<Header />
			<Divider />
			{stepList.map((s) => {
				if (s && s.name) {
					return [<Step step={s} key={s.name} />, <Divider key={'divider_for_' + s.name} />];
				} else {
					return <div />;
				}
			})}
			<Price />
			<Feedback />
		</DrawerMui>
	);
}

function Header() {
	const classes = useStyles();
	return (
		<img
			onClick={() => window.open('https://www.example.com')}
			src="/assets/BlaBlub.png"
			className={classes.logo}
			alt="BlaBlub Logo"
		/>
	);
}

export const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
	drawerPaper: {
		backgroundColor: theme.palette.background.default,
		width: drawerWidth,
		position: 'relative',
		height: '100%',
	},

	logo: {
		width: '100%',
		padding: 5,
		maxHeight: 112 /**change from 120 -> 112 to be aligned with tabbar in structural Elements Page */,
		objectFit: 'scale-down',
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

export default Drawer;
