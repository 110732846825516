/**
 * validator hook to registrate fields for validation, get error text for a specific valdation field and set a field as dirty
 */
import { useSelector } from 'react-redux';
import { useActions } from '../../actions';
import * as ValidatorActions from '../../actions/fieldValidator';
import * as StepActions from '../../actions/step';
//prettier-ignore
import { LengthUnit, Step, StepId, SubStep, SubStepId, ValidationField } from "../../model/model";
import { RootState } from '../../reducers';
import { validationFunctions } from './fieldValidationFunctions';

export function useValidator(): {
	registerFields: (
		fields: {
			propertyName: any;
			errorValidationFunctionNames: (keyof typeof validationFunctions)[];
			errorValidationFunctionParams?: { greaterOrEqualMinValue?: LengthUnit };
			combinedField?: boolean;
			additionalRoute?: string;
		}[],
		stepId: StepId,
		subStepId?: SubStepId
	) => void;
	getErrorText: (propertyName: string) => string;
	setFieldDirty: (propertyName: string) => void;
	setStepProgressCompleted: (stepId: StepId) => void;
	setSubStepCompleted: (subStepId: SubStepId) => void;
} {
	const state: RootState = useSelector((state: RootState) => state);
	const { fieldValidation, stepList } = state;
	const validationActions: typeof ValidatorActions = useActions(ValidatorActions);

	const stepActions: typeof StepActions = useActions(StepActions);

	/**
	 *
	 *
	 * @param {{
	 * 			propertyName: string;
	 * 			validationFunctionNames: (keyof (typeof validationFunctions))[];
	 * 		}[]} fields
	 * Array of objects with property name and validationFunction string[] of fields that should be validated
	 * The property name is used to get the value of the field in a JSON object (RoomSpec State) with dot-notation
	 * @param {StepId} stepId
	 * All registered fields will be used to calcualte the step progress of the given step
	 * @param {string} subStepId
	 * The fields registered for a subStepId are used to calcalute the completed flag of a substep
	 */
	const registerFields = (
		fields: {
			propertyName: string;
			errorValidationFunctionNames: (keyof typeof validationFunctions)[];
			errorValidationFunctionParams?: { greaterOrEqualMinValue?: LengthUnit };
			combinedField?: boolean;
			additionalRoute?: string;
		}[],
		stepId: StepId,
		subStepId?: SubStepId
	) => {
		let fieldRegistrations: ValidationField[] = [];
		fields.forEach((field) => {
			//check if field was already registered for validation
			//this check prevents endless loops, since the registration of a new field triggers rerendering
			let fieldIndex = fieldValidation.findIndex((el) => el.propertyName === field.propertyName);
			if (fieldIndex === -1) {
				let valField: ValidationField = {
					propertyName: field.propertyName,
					errorValidationFunctionNames: field.errorValidationFunctionNames,
					errorValidationFunctionParams: field.errorValidationFunctionParams,
					stepId,
					errorText: '',
					warningText: '',
					subStepId,
					combinedField: field.combinedField,
					additionalRoute: field.additionalRoute,
				};
				fieldRegistrations.push(valField);
			}
		});

		if (fieldRegistrations.length > 0) {
			validationActions.registerFieldValidations(fieldRegistrations);
		}
	};

	const getErrorText = (fieldName: string): string => {
		let field = fieldValidation.find((el) => el.propertyName === fieldName);
		//throw error if the given fieldName is not regsitered for validation
		if (!field) {
			throw Error('Field ' + fieldName + ' is not registered for validation');
		}
		return field.showValidationState ? field.errorText : '';
	};

	/**
	 * if a field was changed it can be marked as dirty using this function
	 * the validation result for that field is then shown immediately
	 */
	const setFieldDirty = (fieldName: string) => {
		let valField = fieldValidation.find((el) => el.propertyName === fieldName);
		//throw error if the given fieldName is not regsitered for validation
		if (!valField) {
			throw Error('Field ' + fieldName + ' is not registered for validation');
		}
		if (!valField.showValidationState) {
			valField.showValidationState = true;
			validationActions.updateFieldValidations([valField]);
		}
	};

	/**
	 * sets a step progress to 100%, should be used for views or pages without fields to validate
	 */
	const setStepProgressCompleted = (stepId: StepId) => {
		let step = stepList.find((el: Step) => el.id === stepId)!;
		if (step.progress !== 100) {
			stepActions.updateStep({ ...step, progress: 100 });
		}
	};

	const setSubStepCompleted = (subStepId: SubStepId) => {
		stepList.forEach((el: Step) => {
			if (el.subSteps) {
				let subStep = getSubStepById(el, subStepId);
				if (subStep && !subStep.completed) {
					stepActions.updateSubStep({ ...subStep, completed: true });
				}
			}
		});
	};

	const getSubStepById = (step: Step, subStepId: SubStepId) => {
		let subStep = step.subSteps!.find((subEl: SubStep) => {
			return subEl.id === subStepId;
		});

		return subStep;
	};
	return {
		registerFields,
		getErrorText,
		setFieldDirty,
		setStepProgressCompleted,
		setSubStepCompleted,
	};
}
