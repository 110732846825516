import { createBrowserHistory } from 'history';
import * as localforage from 'localforage';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { createMigrate, MigrationManifest, PersistConfig, persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import fieldValidator from './middleware/fieldValidation/fieldValidationMiddleWare';
import roomElementPositionCalculator from './middleware/roomDrawingCoordinates/roomElementPositionCalculatorMiddleware';
import rootReducer from './reducers';

const clearStoreOnEveryVersionUpTo100 = (): MigrationManifest => {
	let migrations: MigrationManifest = {};

	for (let i = 0; i <= 100; i++) {
		migrations[i + ''] = (state: any) => {
			return {} as any;
		};
	}

	return migrations;
};

const persistConfig: PersistConfig<any> = {
	key: 'root_rc',
	version: 9,
	storage: localforage,
	blacklist: [],
	migrate: createMigrate(clearStoreOnEveryVersionUpTo100(), { debug: false }),
};

const logger = (createLogger as any)({
  collapsed: true
});
const history = createBrowserHistory();

const dev = process.env.NODE_ENV === 'development' || window.location.origin.includes('tjiko-dev');
const isLogging = localStorage.getItem('isLogging') === 'true' || dev;

let middleware = isLogging
	? applyMiddleware(logger, thunk, fieldValidator, roomElementPositionCalculator)
	: applyMiddleware(thunk, fieldValidator, roomElementPositionCalculator);

if (dev) {
	middleware = composeWithDevTools(middleware);
}

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default () => {
	const store = createStore(persistedReducer, {}, middleware);
	const persistor = persistStore(store);
	return { store, persistor };
};

export { history };
