// prettier-ignore
import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
	title?: string;
}

function ConfigDivider(props: Props) {
	const { title } = props;
	const classes = useStyles(props);

	if (title) {
		return (
			<div className={classes.withTitle}>
				<Typography className={classes.title}>{title}</Typography>
			</div>
		);
	} else {
		return <div className={classes.withoutTitle} />;
	}
}

const useStyles = makeStyles({
	withTitle: {
		width: '100%',
		borderBottomColor: 'lightgrey',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		marginTop: 25,
		marginBottom: 15,
	},

	withoutTitle: {
		width: '100%',
		borderBottomColor: 'lightgrey',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		marginTop: 25,
		marginBottom: 15,
	},

	title: {
		fontWeight: 'bold',
		marginLeft: 10,
	},
});

export default ConfigDivider;
