//prettier-ignore
import { makeStyles, Theme } from "@material-ui/core";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import useReactRouter from 'use-react-router';
import { useActions } from '../actions';
import * as StepActions from '../actions/step';
import ConfiguratorView from '../components/configuratorView/ConfiguratorView';
import ElectricalConfigView from '../components/technicalDetailsViews/ElectricalConfig';
import HeatingConfigView from '../components/technicalDetailsViews/HeatingConfigView';
import VentilationConfigView from '../components/technicalDetailsViews/VentilationConfigView';
import { Route, Step, StepId } from '../model/model';
import { RootState } from '../reducers';

interface Props extends RouteComponentProps<void> {}

function TechnicalDetailsPage(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	// if user is not authenticated -> redirect to login page
	const { history } = useReactRouter();
	const stepList: Step[] = useSelector((state: RootState) => state.stepList);
	const stepActions: typeof StepActions = useActions(StepActions);
	const token: string = useSelector((state: RootState) => state.token);
	if (!token) {
		history.push('/');
		return <div />;
	}

	//important note!: the order tab routs in this array has to match the order of the HTML Views bellow, e.g. the first view is Dimensionsview and the first element in the tabRoutes array is the rout to the dimensions view
	const tabRoutes = [
		Route.TECHNICAL_DETAILS.toString(),
		Route.TECHNICAL_DETAILS_HEATING.toString(),
		Route.TECHNICAL_DETAILS_VENTILATION.toString(),
	];

	const getViewIndexFromRoute = (route: string) => {
		return tabRoutes.indexOf(route) === -1 ? 0 : tabRoutes.indexOf(route);
	};
	const index = getViewIndexFromRoute(props.history.location.pathname);
	const step = stepList.find((value) => StepId.TECHNICAL_DETAILS === value.id);
	if (step !== undefined && step.progress !== undefined && step.progress < 100 - 100 / tabRoutes.length) {
		stepActions.updateStep({
			...step,
			progress: 100 - 100 / tabRoutes.length,
		});
	}

	switch (index) {
		case 0:
			return <ConfiguratorView configFieldList={<ElectricalConfigView {...props} />} />;
		case 1:
			return <ConfiguratorView configFieldList={<HeatingConfigView {...props} />} />;
		case 2:
			return <ConfiguratorView configFieldList={<VentilationConfigView {...props} />} />;

		default:
			return <></>;
	}
}
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		minHeight: '100%',
	},
}));

export default TechnicalDetailsPage;
