import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { useActions } from '../actions';
import * as AppConfigActions from '../actions/appConfig';
import * as RoomSpecActions from '../actions/roomSpec';
import ProductFamily from '../components/productSelectionViews/ProductFamilies';
import { AppConfig, RoomSpec, Route } from '../model/model';
import { RootState } from '../reducers';

interface Props extends RouteComponentProps<void> {}

function ProductSelectionPage(props: Props) {
	const classes = useStyles();

	// if user is not authenticated -> redirect to login page
	const { history } = useReactRouter();
	const token: string = useSelector((state: RootState) => state.token);
	const appConfig: AppConfig = useSelector((state: RootState) => state.appConfig);
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const appConfigActions: typeof AppConfigActions = useActions(AppConfigActions);

	const { t } = useTranslation();
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);

	React.useEffect(() => {
		if (!token) {
			history.push('/');
		} else if (!appConfig || !appConfig.productCategories || appConfig.productCategories.length < 1) {
			appConfigActions.getAppConfig(token, () => {});
		}
	}, [token, appConfig]);

	React.useEffect(() => {
		if (!roomSpec.productCategoryId) {
			roomSpecActions.updateRoomSpec({
				...roomSpec,
				productCategoryId: 'functional',
			});
		}
	}, []);

	const tabRoutes = [
		// Route.PRODUCT_SELECTION.toString(),
		Route.PRODUCT_SELECTION_PRODUCT_FAMILIES.toString(),
		//Route.PRODUCT_SELECTION_PRODUCT_TYPES.toString(),
	];

	return (
		<div className={classes.viewContainer}>
			<ProductFamily {...props} />
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
	},
	viewContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
}));

export default ProductSelectionPage;
