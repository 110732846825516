// prettier-ignore
import { makeStyles } from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RoomConfiguration, RoomSpec } from '../../model/model';
import { RootState } from '../../reducers';
import { getCalculatedRoomDimensions } from '../roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';

interface Props {}

function ExpertTable(props: Props) {
	const {} = props;
	const classes = useStyles();
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);

	const roomConfig: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);

	const columns = ['Field', 'Value', 'Unit', 'Description'];

	const options: MUIDataTableOptions = {
		search: true,
		pagination: false,
		filter: false,
		print: false,
		selectableRowsHeader: false,
		selectableRows: undefined,
	};

	return (
		<div className={classes.root}>
			<MUIDataTable
				title={'Expert Table'}
				data={genertateTableRows(roomSpec, roomConfig)}
				columns={columns}
				options={options}
			/>
		</div>
	);
}
// one row: ["Field", "Value", "Unit", "Description"]
const genertateTableRows = (roomSpec: RoomSpec, roomConfig: RoomConfiguration): (number | string)[][] => {
	const dim = getCalculatedRoomDimensions(roomSpec, roomConfig);
	let rows: (number | string)[][] = [];
	rows.push(['inner room witdh', dim.moduleWidthInCM.toFixed(2), 'cm', '']);

	rows.push(['inner room length', dim.moduleLengthInCM.toFixed(2), 'cm', '']);

	rows = rows.concat(genertateTableRowsForFeatureElement(roomSpec, 'WC'));
	rows = rows.concat(genertateTableRowsForFeatureElement(roomSpec, 'SI'));
	rows = rows.concat(genertateTableRowsForFeatureElement(roomSpec, 'SH'));
	rows = rows.concat(genertateTableRowsForDoor(roomSpec));

	rows.push(['SouthShowertiles', roomSpec.wallSouthTiles.tileSquareMetersInShowerArea.toFixed(2), 'm²', '']);

	rows.push(['WestShowertiles', roomSpec.wallWestTiles.tileSquareMetersInShowerArea.toFixed(2), 'm²', '']);

	rows.push(['NorthShowertiles', roomSpec.wallNorthTiles.tileSquareMetersInShowerArea.toFixed(2), 'm²', '']);

	rows.push(['EastShowertiles', roomSpec.wallEastTiles.tileSquareMetersInShowerArea.toFixed(2), 'm²', '']);

	rows.push([
		'SouthNormaltiles',
		roomSpec.wallSouthTiles.tileSquareMetersNotInShowerArea.toFixed(2),
		'm²',
		'not in shower',
	]);

	rows.push([
		'WestNormaltiles',
		roomSpec.wallWestTiles.tileSquareMetersNotInShowerArea.toFixed(2),
		'm²',
		'not in shower',
	]);

	rows.push([
		'NorthNormaltiles',
		roomSpec.wallNorthTiles.tileSquareMetersNotInShowerArea.toFixed(2),
		'm²',
		'not in shower',
	]);

	rows.push([
		'EastNormaltiles',
		roomSpec.wallEastTiles.tileSquareMetersNotInShowerArea.toFixed(2),
		'm²',
		'not in shower',
	]);

	rows.push(['SouthBasetiles', roomSpec.wallSouthTiles.tileSquareMetersBaseTiles.toFixed(2), 'm²', '']);

	rows.push(['WestBasetiles', roomSpec.wallWestTiles.tileSquareMetersBaseTiles.toFixed(2), 'm²', '']);

	rows.push(['NorthBasetiles', roomSpec.wallNorthTiles.tileSquareMetersBaseTiles.toFixed(2), 'm²', '']);

	rows.push(['EastBasetiles', roomSpec.wallEastTiles.tileSquareMetersBaseTiles.toFixed(2), 'm²', '']);

	rows.push([
		'dimensionLength',
		roomSpec.dimensionLength.value.toFixed(2),
		roomSpec.dimensionLength.unit,
		'Outer dimension length / Room Module Length',
	]);

	rows.push([
		'dimensionWidth',
		roomSpec.dimensionWidth.value.toFixed(2),
		roomSpec.dimensionWidth.unit,
		'Outer dimension width  / Room Module Width',
	]);

	rows.push([
		'storeyHeight',
		roomSpec.storeyHeight.value.toFixed(2),
		roomSpec.storeyHeight.unit,
		'Outer dimension Height / height of room module',
	]);

	rows.push([
		'subFloorHeight',
		roomSpec.subFloorHeight.value.toFixed(2),
		roomSpec.subFloorHeight.unit,
		'Sub Floor Height / Höhe Fußbodenaufbau',
	]);

	rows.push([
		'roomHeight',
		roomSpec.roomHeight.value.toFixed(2),
		roomSpec.roomHeight.unit,
		'Inner Room Height / inside module height',
	]);

	rows.push([
		'ceilingHeight',
		roomSpec.ceilingHeight.value.toFixed(2),
		roomSpec.ceilingHeight.unit,
		'height of ceiling construction',
	]);

	return rows;
};

const genertateTableRowsForFeatureElement = (
	roomSpec: RoomSpec,
	featureElementCategory: string
): (number | string)[][] => {
	const rows: (number | string)[][] = [];

	if (!roomSpec.featureElements[featureElementCategory]) {
		return rows;
	}

	rows.push([
		featureElementCategory + ' - Height',
		roomSpec.featureElements[featureElementCategory].elementHeight.value.toFixed(2),
		roomSpec.featureElements[featureElementCategory].elementHeight.unit,
		'',
	]);

	rows.push([
		featureElementCategory + ' - Width',
		roomSpec.featureElements[featureElementCategory].elementWidth.value.toFixed(2),
		roomSpec.featureElements[featureElementCategory].elementWidth.unit,
		'',
	]);

	rows.push([
		featureElementCategory + ' - POS X',
		roomSpec.featureElements[featureElementCategory].xCoordinate.value.toFixed(2),
		roomSpec.featureElements[featureElementCategory].xCoordinate.unit,
		'',
	]);

	rows.push([
		featureElementCategory + ' - POS Y',
		roomSpec.featureElements[featureElementCategory].yCoordinate.value.toFixed(2),
		roomSpec.featureElements[featureElementCategory].yCoordinate.unit,
		'',
	]);

	return rows;
};

const genertateTableRowsForDoor = (roomSpec: RoomSpec): (number | string)[][] => {
	const rows: (number | string)[][] = [];

	if (!roomSpec.doorSpecification) {
		return rows;
	}

	rows.push([
		'Door - frameHeight',
		roomSpec.doorSpecification.frameHeight.value.toFixed(2),
		roomSpec.doorSpecification.frameHeight.unit,
		'',
	]);

	rows.push([
		'Door - frameWidth',
		roomSpec.doorSpecification.frameWidth.value.toFixed(2),
		roomSpec.doorSpecification.frameWidth.unit,
		'',
	]);

	rows.push([
		'Door - height',
		roomSpec.doorSpecification.height.value.toFixed(2),
		roomSpec.doorSpecification.height.unit,
		'',
	]);

	rows.push([
		'Door - width',
		roomSpec.doorSpecification.width.value.toFixed(2),
		roomSpec.doorSpecification.width.unit,
		'',
	]);

	rows.push([
		'Door - xCoordinate',
		roomSpec.doorSpecification.xCoordinate.value.toFixed(2),
		roomSpec.doorSpecification.xCoordinate.unit,
		'',
	]);

	rows.push([
		'Door - yCoordinate',
		roomSpec.doorSpecification.yCoordinate.value.toFixed(2),
		roomSpec.doorSpecification.yCoordinate.unit,
		'',
	]);

	return rows;
};

const useStyles = makeStyles({
	root: {},
});

export default ExpertTable;
