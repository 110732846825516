// prettier-ignore
import { Card, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
	familyId: 'hinoki' | 'suki';
	familyVariantId: 'guest' | 'common';
	onClick?: () => void;
	selected?: boolean;
	disabled?: boolean;
}

function ProductButton(props: Props) {
	const { familyId, familyVariantId, onClick, selected, disabled } = props;
	const classes = useStyles();
	const [hover, setHover] = React.useState(false);

	return (
		<div
			className={classes.root}
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onMouseOut={() => setHover(false)}
		>
			<Card
				onClick={disabled ? () => { console.log('coming soon :)')} : onClick}
				className={disabled ? classes.card_disabled : classes.card}
				elevation={hover ? 4 : selected ? 12 : 0}
				style={{
					backgroundColor: hover || selected ? 'white' : 'transparent',
					borderColor: 'lightgrey',
					borderStyle: 'solid',
					borderWidth: 1,
					borderRadius: 4,
				}}
			>
				<Typography className={classes.heading} variant="h5" color="primary">
					{familyId === 'suki' ? 'Suki' : 'Hinoki'}
				</Typography>
				{familyId === 'suki' && familyVariantId === 'common' && (
					<img
						src="/assets/floorplan_examples/suki_common.png"
						width={350}
						style={{ padding: 10, objectFit: 'contain' }}
					/>
				)}
				{familyId === 'suki' && familyVariantId === 'guest' && (
					<img
						src="/assets/floorplan_examples/suki_guest.png"
						height={220}
						style={{
							paddingBottom: 50,
							objectFit: 'contain',
						}}
					/>
				)}
				{familyId === 'hinoki' && familyVariantId === 'common' && (
					<img
						src="/assets/floorplan_examples/hinoki_common.png"
						height={260}
						style={{
							padding: 10,
							objectFit: 'contain',
						}}
					/>
				)}
				{familyId === 'hinoki' && familyVariantId === 'guest' && (
					<img
						src="/assets/floorplan_examples/hinoki_guest.png"
						height={240}
						style={{
							padding: 10,
							objectFit: 'contain',
						}}
					/>
				)}
				<Typography className={classes.subHeading} variant="h5" color="primary">
					{familyVariantId === 'guest' ? 'Gästebad' : 'Badezimmer'}
				</Typography>
			</Card>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: 350,
		width: 350,
	},

	card: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: 350,
		width: 350,
		'&:hover': {
			cursor: 'pointer',
		},
	},

  card_disabled: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 350,
    width: 350,
    opacity: .6,
  },

	heading: {
		fontWeight: 'bold',
		marginLeft: 15,
		marginTop: 15,
	},

	subHeading: {
		marginLeft: 15,
		marginBottom: 15,
	},
}));

export default ProductButton;
