import { ClickAwayListener, Drawer, Theme } from '@material-ui/core/';
import { makeStyles } from '@material-ui/styles';
import * as _ from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Unity, { UnityContent } from 'react-unity-webgl';
import useReactRouter from 'use-react-router';
import { appBarHeight } from '../components/AppBar';
import ActionPanel from '../components/unity/ActionPanel';
import SelectionListItem from '../components/unity/SelectionListItem';
import { UnityFunctions } from '../components/unity/UnityFunctions';
import { unityItemsURL, unityURL } from '../endpoints';
import { RootState } from '../reducers';

interface Props extends RouteComponentProps<void> {}

let unityContent: UnityContent;

function UnityDemoPage(props: Props) {
	const classes = useStyles();

	// if user is not authenticated -> redirect to login page
	const { history } = useReactRouter();
	const token: string = useSelector((state: RootState) => state.token);
	const [itemTypes, setItemTypes] = React.useState([]);
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const [selectedItemTypeId, setSelectedItemTypeId] = React.useState('');
	const [selectedItemId, setSelectedItemId] = React.useState(-1);
	React.useEffect(() => {
		if (!token) {
			history.push('/');
		}
	});

	if (itemTypes == undefined || itemTypes.length < 1) {
		fetchUnityItems(token, setItemTypes);
	}

	if (!unityContent) {
		unityContent = new UnityContent(unityURL + 'Gestaltungskonfigurator.json', unityURL + 'UnityLoader.js');
	}

	const unityF = new UnityFunctions(unityContent);

	unityF.onItemDoubleClick((item) => {
		setDrawerOpen(true);
		console.log(item);
		setSelectedItemTypeId(item.category);
		setSelectedItemId(item.id);
	});

	unityF.onItemList((items) => {
		console.log('onItemList', items);
	});

	unityF.onPriceDifference((data) => {
		console.log('onPriceDifference', data);
	});

	let selectableItemList: any[] = [];
	const selectedItemType: any = _.findLast(itemTypes, ['name', selectedItemTypeId]);

	if (selectedItemType) {
		selectableItemList = selectedItemType.items;
	}

	const onSelectItemInDrawer = (id: number) => {
		const item = _.findLast(selectableItemList, ['id', id]);
		unityF.changeItem(item);

		setDrawerOpen(false);
	};

	return (
		<div className={classes.root}>
			<ClickAwayListener onClickAway={(e) => setDrawerOpen(false)}>
				<div className={classes.unityContainer}>
					<Unity unityContent={unityContent} />
				</div>
			</ClickAwayListener>
			<Drawer
				variant="persistent"
				open={drawerOpen}
				anchor="right"
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				{selectableItemList.map((item: any) => (
					<SelectionListItem
						id={item.id}
						name={item.name}
						price={500}
						imgSrc={item.thumbnail}
						onSelect={onSelectItemInDrawer}
						key={item.id + '_' + item.name}
					/>
				))}
			</Drawer>
			<ActionPanel unityFunctions={unityF} />
		</div>
	);
}

const fetchUnityItems = async (token: string, setItemTypes: (itemTypes: []) => void) => {
	const response = await fetch(unityItemsURL, {
		method: 'GET',
		headers: new Headers({
			Accept: 'application/json',
			Authorization: 'token ' + token,
			'Content-Type': 'application/json',
		}),
	});

	const json = await response.json();

	console.log(json);

	const itemTypes = json.item_types;

	for (let i = 0; i < itemTypes.length; i++) {
		itemTypes[i].items = _.filter(json.items, ['type', itemTypes[i].name]);
	}

	console.log(itemTypes);

	setItemTypes(itemTypes);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: '100%',
		textAlign: 'center',
		padding: 30,
	},

	unityContainer: {
		height: 500,
		width: 1000,
	},

	drawerPaper: {
		backgroundColor: 'white',
		width: 300,
		height: '100%',
		marginTop: appBarHeight + 1,
	},
}));

export default UnityDemoPage;
