// prettier-ignore
import { makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
	headline: string;
	bullets: string[];
	footer?: string;
}

function Checklist(props: Props) {
	const { headline, bullets, footer } = props;
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<Typography variant="body1" className={classes.headline}>
				{headline}
			</Typography>
			{bullets.map((b) => (
				<Typography key={b} variant="body2" className={classes.bullet}>
					{'• ' + b}
				</Typography>
			))}
			{footer && (
				<Typography variant="body1" className={classes.footer}>
					{footer}
				</Typography>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},

	headline: {
		fontWeight: 'bold',
		marginBottom: 20,
	},

	footer: {
		marginTop: 41,
		fontWeight: 'bold',
		marginBottom: 5,
	},

	bullet: {
		marginBottom: 8,
	},
}));

export default Checklist;
