import {
	FeatureElement,
	RoomConfiguration,
	RoomDimensions,
	RoomSpec,
	WallAlignment,
	WallTiles,
} from '../../../model/model';
import { convertCentiMeterInMeter, convertLengthUnitToMeter } from '../LengthUnitConverter';
import {
	getLeftAdjacentWallAlignment,
	getOppositeWallAlignment,
	getRightAdjacentWallAlignment,
} from './wallCalculationFunctions';

export function calculateNeededTilesForAllWalls(
	roomSpec: RoomSpec,
	dim: RoomDimensions,
	roomConfig: RoomConfiguration
) {
	let needTilesSouthWall: WallTiles = calculateNeededTilesForWall(WallAlignment.SOUTH, roomSpec, dim, roomConfig);
	let needTilesWestWall: WallTiles = calculateNeededTilesForWall(WallAlignment.WEST, roomSpec, dim, roomConfig);
	let needTilesNorthWall: WallTiles = calculateNeededTilesForWall(WallAlignment.NORTH, roomSpec, dim, roomConfig);
	let needTilesEastWall: WallTiles = calculateNeededTilesForWall(WallAlignment.EAST, roomSpec, dim, roomConfig);

	return {
		needTilesSouthWall,
		needTilesWestWall,
		needTilesNorthWall,
		needTilesEastWall,
	};
}

function calculateNeededTilesForWall(
	wallAlingment: WallAlignment,
	roomSpec: RoomSpec,
	dim: RoomDimensions,
	roomConfig: RoomConfiguration
) {
	let neededShowerTiles = 0;
	let neededNormalTiles = 0;
	let neededBaseTiles = 0;

	let shower = roomSpec.featureElements['SH'];

	if (shower && shower.wallAlignment) {
		neededShowerTiles = calculateNeededShowerTilesForWall(wallAlingment, shower, roomSpec, dim);
	}

	neededNormalTiles = calculateNeededNormalTilesForWall(wallAlingment, shower, roomSpec, dim, roomConfig);

	neededBaseTiles = calculateNeededBaseTilesForWall(wallAlingment, shower, roomSpec, dim, roomConfig);

	//return and round with 3 decimal positions

	return {
		tileSquareMetersInShowerArea: neededShowerTiles,
		tileSquareMetersNotInShowerArea: neededNormalTiles,
		tileSquareMetersBaseTiles: neededBaseTiles,
	};
}

function calculateNeededBaseTilesForWall(
	wallAlingment: WallAlignment,
	shower: FeatureElement,
	roomSpec: RoomSpec,
	dim: RoomDimensions,
	roomConfig: RoomConfiguration
) {
	let neededBaseTiles = 0;
	let toilet = roomSpec.featureElements['WC'];
	let sink = roomSpec.featureElements['SI'];
	let showerWidthInMeter = 0;
	let baseTileHeight = convertLengthUnitToMeter(roomConfig.tileHeightBaseTiles);

	let currentWallWidthInMeter = getWallWidthByWallAlignmentInMeter(wallAlingment, dim);

	let isShowerOnOppositeWall = false;
	let isShowerOnCurrentWall = false;
	let isShowerInCornerOfAdjacentWall = false;
	if (shower && shower.wallAlignment) {
		isShowerOnOppositeWall = shower.wallAlignment === getOppositeWallAlignment(wallAlingment);
		isShowerOnCurrentWall = shower.wallAlignment === wallAlingment;
		showerWidthInMeter = convertLengthUnitToMeter(shower.elementWidth);
		isShowerInCornerOfAdjacentWall = getIsShowerInCornerToAdjacentWall(
			wallAlingment,
			shower,
			roomConfig.showerWallPositionLeftRight
		);
	}

	let hasWallInstallations =
		toilet && toilet.wallAlignment === wallAlingment ? true : sink && sink.wallAlignment === wallAlingment;

	//if sink or toilet is positioned at this wall, it is an installation wall and has already tiles in half room height -> no base tiles needed
	//if shower is on corner to adjacent wall, this wall is tiled completly with shower tiles -> no base tiles needed
	if (hasWallInstallations || isShowerInCornerOfAdjacentWall) {
		return neededBaseTiles;
	} else if (isShowerOnCurrentWall || isShowerOnOppositeWall) {
		//if shower is on current wall and it is no installation wall, the rest of the wall needs to be tiles with base tiles (e.g. a module without sink and wc)

		neededBaseTiles = (currentWallWidthInMeter - showerWidthInMeter) * baseTileHeight;
	} else {
		//if wall has no installations and no shower tiles it needes base tiles
		neededBaseTiles = currentWallWidthInMeter * baseTileHeight;
	}

	return neededBaseTiles;
}

function calculateNeededNormalTilesForWall(
	wallAlingment: WallAlignment,
	shower: FeatureElement,
	roomSpec: RoomSpec,
	dim: RoomDimensions,
	roomConfig: RoomConfiguration
) {
	try {
		let neededNormalTiles = 0;
		let toilet = roomSpec.featureElements['WC'];
		let sink = roomSpec.featureElements['SI'];
		let showerWidthInMeter = 0;
		let halfRoomHeightInMeter = convertLengthUnitToMeter(roomConfig.tileHeightHalfRoomHeight);

		let currentWallWidthInMeter = getWallWidthByWallAlignmentInMeter(wallAlingment, dim);
		let isShowerOnOppositeWall = false;
		let isShowerOnCurrentWall = false;
		let isShowerInCornerOfAdjacentWall = false;
		if (shower && shower.wallAlignment) {
			isShowerOnOppositeWall = shower.wallAlignment === getOppositeWallAlignment(wallAlingment);
			isShowerOnCurrentWall = shower.wallAlignment === wallAlingment;
			showerWidthInMeter = convertLengthUnitToMeter(shower.elementWidth);
			isShowerInCornerOfAdjacentWall = getIsShowerInCornerToAdjacentWall(
				wallAlingment,
				shower,
				roomConfig.showerWallPositionLeftRight
			);
		}

		//if sink or toilet is positioned at this wall, it is an installation wall and needs tiles in half room height (half room height is a fix value in the configuration, not really the half of the room height)
		let hasWallInstallations =
			toilet && toilet.wallAlignment === wallAlingment ? true : sink && sink.wallAlignment === wallAlingment;

		//if shower is on corner to adjacent wall, this wall is tiled completly with shower tiles, no normal tiles needed
		if (isShowerInCornerOfAdjacentWall) {
			return neededNormalTiles;
		} else if (hasWallInstallations) {
			//if the shower is on the current wall, the shower width has to be deducted, since this area is tiled in room height with shower tiles
			// if not the complete wall gets normal tils for the half of the room height (fix value from config)
			if (isShowerOnCurrentWall || isShowerOnOppositeWall) {
				neededNormalTiles = (currentWallWidthInMeter - showerWidthInMeter) * halfRoomHeightInMeter;
			} else {
				neededNormalTiles = currentWallWidthInMeter * halfRoomHeightInMeter;
			}
		}
		return neededNormalTiles;
	} catch (e) {
		return 0;
	}
}

function calculateNeededShowerTilesForWall(
	wallAlingment: WallAlignment,
	shower: FeatureElement,
	roomSpec: RoomSpec,
	dim: RoomDimensions
) {
	let neededShowerTiles = 0;

	const roomHeightInMeter = convertLengthUnitToMeter(roomSpec.roomHeight);
	const roomInnerLengthInMeter = (dim.moduleLengthInCM - 1) / 100;

	const showerWidthInMeter = convertLengthUnitToMeter(shower.elementWidth);
	const showerHeightInMeter = convertLengthUnitToMeter(shower.elementHeight);

	if (wallAlingment === WallAlignment.EAST) {
		neededShowerTiles = 0;
	} else if (wallAlingment === WallAlignment.NORTH) {
		neededShowerTiles = showerWidthInMeter * roomHeightInMeter;
	} else if (wallAlingment === WallAlignment.WEST) {
		neededShowerTiles = showerHeightInMeter * roomHeightInMeter;
	} else if (wallAlingment === WallAlignment.SOUTH && roomInnerLengthInMeter <= showerHeightInMeter) {
		neededShowerTiles = showerWidthInMeter * roomHeightInMeter;
	}

	return neededShowerTiles;
}

function getIsShowerInCornerToAdjacentWall(
	wallAlingment: WallAlignment,
	shower: FeatureElement,
	showerWallPositionLeftRight: 'left' | 'right'
) {
	let leftAdjacentWall = getLeftAdjacentWallAlignment(wallAlingment);
	let rightAdjacentWall = getRightAdjacentWallAlignment(wallAlingment);

	let isShowerInCornerOfAdjacentWall = false;

	if (shower.wallAlignment === leftAdjacentWall) {
		return showerWallPositionLeftRight === 'right';
	} else if (shower.wallAlignment === rightAdjacentWall) {
		return showerWallPositionLeftRight === 'left';
	}

	return isShowerInCornerOfAdjacentWall;
}

function getWallWidthByWallAlignmentInMeter(wallAlignment: WallAlignment, dim: RoomDimensions) {
	switch (wallAlignment) {
		case WallAlignment.EAST:
		case WallAlignment.WEST:
			return convertCentiMeterInMeter(dim.moduleLengthInCM);

		case WallAlignment.NORTH:
		case WallAlignment.SOUTH:
			return convertCentiMeterInMeter(dim.moduleWidthInCM);
	}
}
