// prettier-ignore
import { Grid, makeStyles, Slider, Theme, Typography, useTheme } from '@material-ui/core';
import * as React from 'react';
import { Unit } from '../../model/model';
import CaclulationRow from '../calculationRow/CaclulationRow';

interface Props {
	maxValue: number;
	minValue: number;
	unit: Unit;
	label: string;
	value: number;
	step: number;
	onChange: (value: number) => void;
}

function StepSlider(props: Props) {
	let { maxValue, minValue, unit, label, value, onChange, step } = props;
	const classes = useStyles(props);

	const [sliderValue, setSliderValue] = React.useState(value);
	const theme: Theme = useTheme();

	const valueLabelFormat = (value: number) => {
		return `${value} ${unit}`;
	};

	const handleChangeEvent = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
		if (newValue instanceof Array) {
			newValue = newValue[0];
		}
		//save slider value for each change, but do not trigger redux state change (=> rendering), e.g. while dragging
		setSliderValue(newValue);
	};

	const handleSliderChange = (event: any) => {
		onChange(sliderValue);
	};

	const handleNumberInput = (value: number) => {
		console.log(value);
		if (value > maxValue) {
			value = maxValue;
		} else if (value < minValue) {
			value = minValue;
		}
		setSliderValue(value);
		onChange(value);
	};

	let decimalCount = countDecimals(step);

	let marks = [
		{
			value: minValue,
			label: `${minValue.toFixed(decimalCount)} ${unit}`,
		},
		{
			value: maxValue,
			label: `${maxValue.toFixed(decimalCount)} ${unit}`,
		},
	];

	const numberBackgroundColor = {
		notEditable: theme.palette.background.paper,
		editable: theme.palette.background.default,
		editing: theme.palette.background.default,
	};

	return (
		<div className={classes.root}>
			<div className={classes.horizontalContainer}>
				<Grid container spacing={2}>
					<Grid item xs lg md>
						<div className={classes.labelContainer}>
							<Typography variant="subtitle1" className={classes.label}>
								{label}
							</Typography>
						</div>
					</Grid>
					<Grid item>
						<CaclulationRow
							value={value}
							onChange={handleNumberInput}
							units={[unit]}
							decimalDigits={decimalCount}
							editable
							numberBackgroundColor={numberBackgroundColor}
						/>
					</Grid>
				</Grid>
				<div className={classes.sliderContainer}>
					<Slider
						classes={{ markLabel: classes.markLabel }}
						value={sliderValue}
						valueLabelFormat={valueLabelFormat}
						step={step}
						marks={marks}
						min={minValue}
						max={maxValue}
						onChange={handleChangeEvent}
						onChangeCommitted={handleSliderChange}
						key={'slider_' + label}
					/>
				</div>
			</div>
		</div>
	);
}

const countDecimals = (num: number) => {
	if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
	return num.toString().split('.')[1].length || 0;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	horizontalContainer: {
		// display: "flex",
		// flexDirection: "row",
		// backgroundColor: "#ffffff",
		// padding: "5px",
		// alignItems: "center",
	},

	labelContainer: {
		// display: "flex",
		// flex: 1,
		// flexDirection: "row",
		// alignItems: "center",
		// justifyContent: "flex-start",
	},
	label: {
		marginTop: 10,
		marginLeft: 15,
		marginBottom: 10,
		marginRight: 10,
	},
	sliderContainer: {
		marginLeft: '40px',
		marginRight: '15px',
	},
	number: {
		marginLeft: '10px',
		width: '42px',
		textAlign: 'right',
		marginTop: 10,
	},
	markLabel: {
		fontSize: '0.8rem',
	},
}));

export default StepSlider;
