// prettier-ignore
import { Action, ActionType, LengthUnit, RoomSpec, RoomSpecPrice, Unit, WallAlignment, WallType } from "../model/model";
import createReducer from './createReducer';

const length: LengthUnit = { value: 0, unit: Unit.METER };
const width: LengthUnit = { value: 0, unit: Unit.METER };
const height: LengthUnit = { value: 2.5, unit: Unit.METER };
const subFloorHeight: LengthUnit = { value: 15, unit: Unit.CENTI_METER };
const roomHeight: LengthUnit = { value: 2.3, unit: Unit.METER };
const ceilingHeight: LengthUnit = { value: 11.25, unit: Unit.CENTI_METER };

const wallWest = {
	id: '1',
	name: 'steps.structuralElements.wallWest',
	wallType: WallType.IN_FRONT_OF_EXTERIOR,
	wallAlignment: WallAlignment.WEST,
};
const wallNorth = {
	id: '2',
	name: 'steps.structuralElements.wallNorth',
	wallType: WallType.IN_FRONT_OF_EXTERIOR,
	wallAlignment: WallAlignment.NORTH,
};
const wallEast = {
	id: '3',
	name: 'steps.structuralElements.wallEast',
	wallType: WallType.IN_FRONT_OF_EXTERIOR,
	wallAlignment: WallAlignment.EAST,
};
const wallSouth = {
	id: '4',
	name: 'steps.structuralElements.wallSouth',
	wallType: WallType.IN_FRONT_OF_EXTERIOR,
	wallAlignment: WallAlignment.SOUTH,
};

const defaultRoomSpec: RoomSpec = {
	dimensionLength: length,
	dimensionWidth: width,
	storeyHeight: height,
	subFloorHeight: subFloorHeight,
	roomHeight: roomHeight,
	ceilingHeight: ceilingHeight,
	featureElements: {},
	elements: [],
	wallTiles: null,
	floorTiles: null,
	showerTiles: null,
	accessoryElements: [],
	wallNorth: wallNorth,
	wallEast: wallEast,
	wallSouth: wallSouth,
	wallWest: wallWest,
	numberOfOutletsDoor: 1,
	numberOfOutletsSink: 2,
	lightingType: 'lightingArea',
	lightingMirror: false,
	lightingCount: 0,
	radiatorType: null,
	underfloorHeating: false,
	ventilationType: 'singleVentilationSystem',
	equipmentLineId: 'S',
	productCategoryId: '',
	productFamilyId: '',
	wallSwitches: { doorSwitches: [], sinkSwitches: [] },
	doorSpecification: null,
	productFamilyVariantId: '',
	radiatorConfig: null,
	shaftConfig: null,
	noShaftConfig: {
		cablesLeft: true,
		cablesRight: false,
	},
	wallEastTiles: {
		tileSquareMetersNotInShowerArea: 0,
		tileSquareMetersInShowerArea: 0,
		tileSquareMetersBaseTiles: 0,
	},
	wallNorthTiles: {
		tileSquareMetersNotInShowerArea: 0,
		tileSquareMetersInShowerArea: 0,
		tileSquareMetersBaseTiles: 0,
	},
	wallSouthTiles: {
		tileSquareMetersNotInShowerArea: 0,
		tileSquareMetersInShowerArea: 0,
		tileSquareMetersBaseTiles: 0,
	},
	wallWestTiles: {
		tileSquareMetersNotInShowerArea: 0,
		tileSquareMetersInShowerArea: 0,
		tileSquareMetersBaseTiles: 0,
	},
};

export const roomSpec = createReducer<RoomSpec>(defaultRoomSpec, {
	[ActionType.UPDATE_ROOMSPEC](state: RoomSpec, action: Action<RoomSpec>) {
		return { ...state, ...action.payload };
	},

	[ActionType.RESET_ROOMSPEC](state: RoomSpec, action: Action<void>) {
		return { ...defaultRoomSpec };
	},
});

export const roomSpecId = createReducer<number | null>(null, {
	[ActionType.SEND_ROOMSPEC_TO_SERVER](state: number | null, action: Action<any>) {
		return action.payload.id ? action.payload.id : null;
	},
	[ActionType.RESET_ROOMSPEC](state: number | null, action: Action<any>) {
		if (action.payload.keepRoomSpecId) {
			return state;
		} else {
			return null;
		}
	},
	[ActionType.SET_ROOMSPEC_ID](state: number | null, action: Action<null | number>) {
		return action.payload ? action.payload : null;
	},
});

export const roomSpecPostLoading = createReducer<null | boolean>(null, {
	[ActionType.POST_ROOMSPEC](state: number | null, action: Action<any>) {
		return action.payload;
	},
	[ActionType.RESET_ROOMSPEC](state: number | null, action: Action<any>) {
		return null;
	},
});

export const roomSpecPrice = createReducer<RoomSpecPrice>(
	{},
	{
		[ActionType.SET_ROOMSPEC_PRICE](state: {}, action: Action<RoomSpecPrice>) {
			return action.payload;
		},
		[ActionType.RESET_ROOMSPEC](state: {}, action: Action<void>) {
			return {};
		},
	}
);
