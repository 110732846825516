import { convertLengthUnitToCentimeter } from '../components/roomPlan/LengthUnitConverter';
import { getCalculatedRoomDimensions } from '../components/roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';
import { calculateNeededTilesForAllWalls } from '../components/roomPlan/roomPlanLogicFunctions/tileCalculationFunctions';
import { pricingURL, roomSpecURL } from '../endpoints';
import { Action, ActionType, RoomConfiguration, RoomSpec, RoomSpecPrice } from '../model/model';

export function patchRoomSpecVariant(roomSpec: RoomSpec, variant: string) {
	return async (dispatch: Function, getState: Function) => {
		patchRoomSpec(dispatch, getState, roomSpec, variant);
	};
}

export function updateRoomSpec(roomSpec: RoomSpec) {
	return async (dispatch: Function, getState: Function) => {
		sendRoomSpecToServer(dispatch, getState, roomSpec);
		dispatch({
			type: ActionType.UPDATE_ROOMSPEC,
			payload: roomSpec,
		});
	};
}

export function resetRoomSpec(keepRoomSpecId?: boolean): Action<{}> {
	return {
		type: ActionType.RESET_ROOMSPEC,
		payload: { keepRoomSpecId },
	};
}

export function loadRoomSpec(id: number, token: string) {
	return async (dispatch: Function, getState: Function) => {
		const response = await fetch(roomSpecURL + id + '/', {
			method: 'GET',
			headers: new Headers({
				Accept: 'application/json',
				Authorization: 'Token ' + token,
				'Content-Type': 'application/json',
			}),
		});

		const requestResponse = await response.json();

		dispatch({
			type: ActionType.SET_ROOMSPEC_ID,
			payload: requestResponse.id,
		});
		dispatch({
			type: ActionType.UPDATE_ROOMSPEC,
			payload: requestResponse.spec,
		});
	};
}

/**
 *
 * Saves the roomSpec on the server. If a roomSpecId is given, the roomSpec will be updated otherwise a new one will be created.
 *
 * @param roomSpec
 * @param roomConfig
 * @param authToken
 * @param roomSpecId
 */
async function sendRoomSpecToServer(dispatch: Function, getState: Function, roomSpec: RoomSpec) {
	const isAlreadyPosting = getState().roomSpecPostLoading;
	const roomSpecId = getState().roomSpecId;
	if ((isAlreadyPosting && !roomSpecId) || !roomSpec.productFamilyId || !roomSpec.productFamilyVariantId) {
		return;
	}

	const roomConfig: RoomConfiguration = getState().roomConfigurationValues;

	// update dimensions for pricing
	const dim = getCalculatedRoomDimensions(roomSpec, roomConfig);
	const innerRoomWidth = dim.moduleWidthInCM;
	const innerRoomLength = dim.moduleLengthInCM;

	// calcualte Tiles
	const neededTiles = calculateNeededTilesForAllWalls(roomSpec, dim, roomConfig);

	// set wallThickness
	if (roomConfig.wallConfig) {
		const wallThicknessWallDefault = convertLengthUnitToCentimeter(roomConfig.wallConfig.wallThicknessWallDefault);
		const wallThicknessWallWithToilet = convertLengthUnitToCentimeter(
			roomConfig.wallConfig.wallThicknessWallWithToilet
		);
		const wallThicknessWallWithBasinOrShower = convertLengthUnitToCentimeter(
			roomConfig.wallConfig.wallThicknessWallWithBasinOrShower
		);

		if (roomSpec.productFamilyId === 'suki' && roomSpec.productFamilyVariantId === 'common') {
			roomSpec.wallNorth.thicknessCM = wallThicknessWallWithBasinOrShower;
			roomSpec.wallEast.thicknessCM = wallThicknessWallWithToilet;
			roomSpec.wallSouth.thicknessCM = wallThicknessWallDefault;
			roomSpec.wallWest.thicknessCM = wallThicknessWallDefault;
		} else if (roomSpec.productFamilyId === 'suki' && roomSpec.productFamilyVariantId === 'guest') {
			roomSpec.wallNorth.thicknessCM = wallThicknessWallDefault;
			roomSpec.wallEast.thicknessCM = wallThicknessWallWithToilet;
			roomSpec.wallSouth.thicknessCM = wallThicknessWallWithBasinOrShower;
			roomSpec.wallWest.thicknessCM = wallThicknessWallDefault;
		} else if (roomSpec.productFamilyId === 'hinoki') {
			roomSpec.wallNorth.thicknessCM = wallThicknessWallWithToilet;
			roomSpec.wallEast.thicknessCM = wallThicknessWallDefault;
			roomSpec.wallSouth.thicknessCM = wallThicknessWallDefault;
			roomSpec.wallWest.thicknessCM = wallThicknessWallDefault;
		}
	}

	dispatch({
		type: ActionType.SET_ROOMSPEC_PRICE,
		payload: { loading: true },
	});

	if (isAlreadyPosting === null) {
		dispatch({
			type: ActionType.POST_ROOMSPEC,
			payload: true,
		});
	}

	const response = await fetch(roomSpecURL + (roomSpecId ? roomSpecId + '/' : ''), {
		method: roomSpecId ? 'PUT' : 'POST',
		headers: new Headers({
			Accept: 'application/json',
			Authorization: 'Token ' + getState().token,
			'Content-Type': 'application/json',
		}),
		body: JSON.stringify({
			spec: {
				...roomSpec,
				innerRoomWidth,
				innerRoomLength,
				wallSouthTiles: neededTiles.needTilesSouthWall,
				wallWestTiles: neededTiles.needTilesWestWall,
				wallNorthTiles: neededTiles.needTilesNorthWall,
				wallEastTiles: neededTiles.needTilesEastWall,
			},
			units: getState().units,
		}),
	});

	const requestResponse = await response.json();

	dispatch({
		type: ActionType.SEND_ROOMSPEC_TO_SERVER,
		payload: requestResponse,
	});

	if (isAlreadyPosting === true) {
		dispatch({
			type: ActionType.POST_ROOMSPEC,
			payload: false,
		});
	}

	// handle pricing API
	try {
		const priceResponse = await fetch(pricingURL + requestResponse.id + '/', {
			method: 'GET',
			headers: new Headers({
				Accept: 'application/json',
				Authorization: 'Token ' + getState().token,
			}),
		});

		if (priceResponse.status === 200) {
			const priceJSON = await priceResponse.json();

			const roomSpecPrice: RoomSpecPrice = {
				value: Number(priceJSON.total.replace(' eur', '')),
				currency: 'EUR',
				loading: false,
			};

			dispatch({
				type: ActionType.SET_ROOMSPEC_PRICE,
				payload: roomSpecPrice,
			});
		} else {
			console.error('Error while calculating the price for roomSpec ' + requestResponse.id);
			const roomSpecPrice: RoomSpecPrice = {
				error: ' - €',
				loading: false,
			};

			dispatch({
				type: ActionType.SET_ROOMSPEC_PRICE,
				payload: roomSpecPrice,
			});
		}
	} catch (e) {
		console.error('Error while calculating the price for roomSpec ' + requestResponse.id);
		console.log(e);
		const roomSpecPrice: RoomSpecPrice = {
			error: ' - €',
			loading: false,
		};

		dispatch({
			type: ActionType.SET_ROOMSPEC_PRICE,
			payload: roomSpecPrice,
		});
	}
}



async function patchRoomSpec(dispatch: Function, getState: Function, roomSpec: RoomSpec, variant: string) {
	const isAlreadyPosting = getState().roomSpecPostLoading;
	const roomSpecId = getState().roomSpecId;
	if ((isAlreadyPosting && !roomSpecId) || !roomSpec.productFamilyId || !roomSpec.productFamilyVariantId) {
		return;
	}

	// pricing loading indicator: loading
	dispatch({
		type: ActionType.SET_ROOMSPEC_PRICE,
		payload: { loading: true },
	});

	// roomspec loading: no double-posts
	if (isAlreadyPosting === null) {
		dispatch({
			type: ActionType.POST_ROOMSPEC,
			payload: true,
		});
	}

	const URL = roomSpecURL + roomSpecId + `/?variant=${variant}`;
	const response = await fetch(URL, {
		method: 'PATCH',
		headers: new Headers({
			Accept: 'application/json',
			Authorization: 'Token ' + getState().token,
			'Content-Type': 'application/json',
		}),
	});

	const requestResponse = await response.json();

	// update client state "roomSpecId"
	dispatch({
		type: ActionType.SEND_ROOMSPEC_TO_SERVER,
		payload: requestResponse,
	});

	// update client state "roomSpec"
	dispatch({
		type: ActionType.UPDATE_ROOMSPEC,
		payload: requestResponse.spec,
	});

	// not loading any more
	if (isAlreadyPosting === true) {
		dispatch({
			type: ActionType.POST_ROOMSPEC,
			payload: false,
		});
	}

	// handle pricing API
	try {
		const priceResponse = await fetch(pricingURL + requestResponse.id + '/', {
			method: 'GET',
			headers: new Headers({
				Accept: 'application/json',
				Authorization: 'Token ' + getState().token,
			}),
		});

		if (priceResponse.status === 200) {
			const priceJSON = await priceResponse.json();

			const roomSpecPrice: RoomSpecPrice = {
				value: Number(priceJSON.total.replace(' eur', '')),
				currency: 'EUR',
				loading: false,
			};

			dispatch({
				type: ActionType.SET_ROOMSPEC_PRICE,
				payload: roomSpecPrice,
			});
		} else {
			console.error('Error while calculating the price for roomSpec ' + requestResponse.id);
			const roomSpecPrice: RoomSpecPrice = {
				error: ' - €',
				loading: false,
			};

			dispatch({
				type: ActionType.SET_ROOMSPEC_PRICE,
				payload: roomSpecPrice,
			});
		}
	} catch (e) {
		console.error('Error while calculating the price for roomSpec ' + requestResponse.id);
		console.log(e);
		const roomSpecPrice: RoomSpecPrice = {
			error: ' - €',
			loading: false,
		};

		dispatch({
			type: ActionType.SET_ROOMSPEC_PRICE,
			payload: roomSpecPrice,
		});
	}
}
