import { Action, ActionType, ValidationField } from '../model/model';

export function updateFieldValidations(fieldRegistration: ValidationField[]): Action<ValidationField[]> {
	return {
		type: ActionType.UPDATE_FIELD_VALIDATIONS,
		payload: fieldRegistration,
	};
}

export function registerFieldValidations(fieldRegistration: ValidationField[]): Action<ValidationField[]> {
	return {
		type: ActionType.REGISTER_FIELD_VALIDATIONS,
		payload: fieldRegistration,
	};
}
