//prettier-ignore
import { FormControlLabel, Radio, RadioGroup, Theme } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useActions } from '../../actions';
import * as RoomSpecActions from '../../actions/roomSpec';
import { RoomConfiguration, RoomSpec, Route } from '../../model/model';
import { RootState } from '../../reducers';
import ConfigFieldList from '../configuratorView/ConfigFieldList';
import ConfigDivider from '../configuratorView/fields/ConfigDivider';
import ConfigFieldContainer from '../configuratorView/fields/ConfigFieldContainer';
import ConfigNextButton from '../configuratorView/fields/ConfigNextButton';

interface Props extends RouteComponentProps<void> {}

function VentilationConfigView(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const roomConfig: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);

	const updateVentilationType = (event: any) => {
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			ventilationType: event.target.value,
		});
	};

	return (
		<ConfigFieldList>
			<ConfigDivider title={t('steps.technicalDetails.ventilation')} />
			<ConfigFieldContainer>
				<RadioGroup
					className={classes.ventilationTypesContainer}
					name="ventilationType"
					value={roomSpec.ventilationType}
					onChange={updateVentilationType}
				>
					{roomConfig.ventilationTypes.map((ventilationType) => (
						<FormControlLabel
							key={ventilationType}
							value={ventilationType}
							control={<Radio color="primary" />}
							label={t('steps.technicalDetails.' + ventilationType)}
							labelPlacement="end"
						/>
					))}
				</RadioGroup>
			</ConfigFieldContainer>
			<ConfigDivider />
			<ConfigNextButton nextRoute={Route.OVERVIEW} />
		</ConfigFieldList>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	ventilationTypesContainer: {
		marginLeft: 20,
	},
}));

export default VentilationConfigView;
