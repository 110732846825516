import { assetURL, serverURL } from './urls';

// auth
export const loginURL = serverURL + '/rest-auth/login/';
export const logoutURL = serverURL + '/rest-auth/logout/';
export const passwordResetURL = serverURL + '/rest-auth/password/reset/';
export const confirmResetPasswordURL = serverURL + '/rest-auth/password/reset/confirm/';
export const changePasswordURL = serverURL + '/rest-auth/password/change/';
export const registrationURL = serverURL + '/rest-auth/registration/';

// Unity
export const unityPATH = '/unity/Build/';
export const unityItemsURL = serverURL + '/unity/1/';
export const unityURL = assetURL + unityPATH;

// tjiko data
export const appConfigURL = serverURL + '/appconfig/';
export const roomSpecURL = serverURL + '/roomspec/';
export const elementURL = serverURL + '/element/';
export const tilesURL = serverURL + '/tile/';
export const tilesWallURL = tilesURL + '?type=wall';
export const tilesFloorURL = tilesURL + '?type=floor';
export const pricingURL = serverURL + '/pricing/';

// PDF
export const roomListPdfURL = serverURL + '/ausstattungsliste/';
export const orderPdfURL = assetURL + '/pdf/Musterangebot.pdf';
export const bathDescriptionPdfURL = assetURL + '/pdf/Badbeschreibung.pdf';

// Others
export const orderSuccessURL = 'https://www.tjiko.de/bestellung-abgeschlossen/';
export const contactMail = 'BlaBlub@example.com';
export const feedbackMail = 'BlaBlub@example.com';
