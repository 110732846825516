// prettier-ignore
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import useReactRouter from 'use-react-router';
import configureStore from '../../configureStore';
import { Route as R } from '../../model/model';
import { ReplaceDefaultRoomSpecInAppConfigPanel } from './ReplaceDefaultRoomSpecInAppConfigPanel';

interface Props {
	open: boolean;
	onClose: Function;
}

function AdminDialog(props: Props) {
	const { open, onClose } = props;
	const classes = useStyles();
	const { history } = useReactRouter();
	const [showAppConfigPanel, setShowAppConfigPanel] = React.useState(false);

	const handleClose = (e: any) => {
		// prevent event bubbling in nested components on click
		e.stopPropagation();
		setShowAppConfigPanel(false);

		onClose();
	};

	const onOk = (e: any) => {
		// prevent event bubbling in nested components on click
		e.stopPropagation();

		setShowAppConfigPanel(false);

		onClose();
	};

	const clearCache = () => {
		let { persistor } = configureStore();
		persistor.purge().then(() => (window.location.href = window.location.origin));
		setShowAppConfigPanel(false);
		onClose();
	};

	const onUnityDemo = () => {
		history.push('/unityDemo');
	};

	const deleteAllCookies = () => {
		var cookies = document.cookie.split('; ');
		for (var c = 0; c < cookies.length; c++) {
			var d = window.location.hostname.split('.');
			while (d.length > 0) {
				var cookieBase =
					encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
					'=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
					d.join('.') +
					' ;path=';
				var p = window.location.pathname.split('/');
				document.cookie = cookieBase + '/';
				while (p.length > 0) {
					document.cookie = cookieBase + p.join('/');
					p.pop();
				}
				d.shift();
			}
		}
	};

	const clearCacheAndCookie = () => {
		deleteAllCookies();
		setTimeout(clearCache, 500);
	};

	const isLogging = localStorage.getItem('isLogging') === 'true';

	const toggleLogging = () => {
		if (isLogging) {
			localStorage.setItem('isLogging', 'false');
		} else {
			localStorage.setItem('isLogging', 'true');
		}

		const currentLocation = window.location.href;
		window.location.href = window.location.origin;
		window.location.href = currentLocation;
		onClose();
	};

	return (
		<Dialog maxWidth="lg" open={open} onClick={(e) => e.stopPropagation()} onClose={handleClose}>
			<DialogTitle>Admin</DialogTitle>
			<DialogContent className={classes.container}>
				{!showAppConfigPanel && (
					<>
						<Button
							className={classes.button}
							variant="contained"
							color="primary"
							onClick={() => history.push(R.LOAD_ROOMSPEC)}
						>
							Load RoomSpec
						</Button>
						<Button
							className={classes.button}
							variant="contained"
							color="primary"
							onClick={clearCacheAndCookie}
						>
							Clear Cache
						</Button>
						<Button className={classes.button} variant="contained" color="primary" onClick={toggleLogging}>
							{isLogging ? 'Disable Logging' : 'Enable Logging'}
						</Button>
						<Button
							className={classes.button}
							variant="contained"
							color="primary"
							onClick={() => setShowAppConfigPanel(true)}
						>
							AppConfig generator
						</Button>
						<Button color="inherit" onClick={onUnityDemo} className={classes.button}>
							Unity Demo
						</Button>
					</>
				)}
				{showAppConfigPanel && <ReplaceDefaultRoomSpecInAppConfigPanel />}
			</DialogContent>
			<DialogActions>
				<Button tabIndex={0} onClick={onOk} color="primary" autoFocus>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export function deleteAllCookies() {
	var cookies = document.cookie.split('; ');
	for (var c = 0; c < cookies.length; c++) {
		var d = window.location.hostname.split('.');
		while (d.length > 0) {
			var cookieBase =
				encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
				'=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
				d.join('.') +
				' ;path=';
			var p = window.location.pathname.split('/');
			document.cookie = cookieBase + '/';
			while (p.length > 0) {
				document.cookie = cookieBase + p.join('/');
				p.pop();
			}
			d.shift();
		}
	}
}

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: 30,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},

	button: {
		margin: 20,
	},
}));

export default AdminDialog;
