import { Action, ActionType, Step, SubStep } from '../model/model';

export function updateStep(step: Step): Action<Step> {
	return {
		type: ActionType.UPDATE_STEP,
		payload: step,
	};
}

export function updateSubStep(subStep: SubStep): Action<SubStep> {
	return {
		type: ActionType.UPDATE_SUB_STEP,
		payload: subStep,
	};
}

export function resetSteps(): Action<{}> {
	return {
		type: ActionType.RESET_STEPS,
		payload: {},
	};
}
