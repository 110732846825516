import { Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { useActions } from '../actions';
import * as AppConfigActions from '../actions/appConfig';
import * as RoomConfigurationValuesAction from '../actions/roomConfigurationValues';
import * as RoomSpecActions from '../actions/roomSpec';
import * as UnitActions from '../actions/unit';
import { getTokenFromCookie } from '../actions/user';
import { AppConfig, RoomConfiguration, RoomSpec, Route as R } from '../model/model';
import { RootState } from '../reducers';
import { parseQueryString } from '../utils';

interface Props extends RouteComponentProps<void> {}

function LoadRoomSpecPage(props: Props) {
	const classes = useStyles(props);

	const { history } = useReactRouter();
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);
	const id: number | null = useSelector((state: RootState) => state.roomSpecId);
	const [roomSpecId, setRoomSpecId] = React.useState<number | null>(id);
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const unitActions: typeof UnitActions = useActions(UnitActions);

	const token: string = useSelector((state: RootState) => state.token);
	const appConfig: AppConfig = useSelector((state: RootState) => state.appConfig);
	const roomConfigurationValues: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);
	const roomConfigurationValuesActions: typeof RoomConfigurationValuesAction = useActions(
		RoomConfigurationValuesAction
	);
	const appConfigActions: typeof AppConfigActions = useActions(AppConfigActions);

	const loadRoomSpec = () => {
		if (roomSpecId) {
			const t = getTokenFromCookie() ? getTokenFromCookie() : token;

			roomSpecActions.resetRoomSpec();
			roomSpecActions.loadRoomSpec(roomSpecId, t ? t : '');
		}
	};

	React.useEffect(() => {
		if (id && roomSpec && roomSpec.productFamilyVariantId && roomConfigurationValues.wallConfig) {
			history.push(R.PRODUCT_SELECTION);
		}
	}, [id, roomSpec, roomConfigurationValues]);

	React.useEffect(() => {
		if (appConfig && appConfig.productCategories && id && roomSpec && roomSpec.productFamilyVariantId) {
			const productCat = appConfig.productCategories.find((el) => el.id === roomSpec.productCategoryId);

			if (productCat && productCat.productFamilies) {
				const productFamilies = productCat!.productFamilies;
				const selectedProductFamVariant = productFamilies
					.find((el) => el.id === roomSpec.productFamilyId)!
					.productFamilyVariants.find((el) => el.id === roomSpec.productFamilyVariantId);

				roomConfigurationValuesActions.updateRoomConfigValues({
					...selectedProductFamVariant!.roomConfiguration,
				});
			}
		}
	}, [id, roomSpec, appConfig]);

	React.useEffect(() => {
		const queryParams = parseQueryString(window.location.search);

		const roomSpecId = queryParams['id'];
		const unit = Number(queryParams['unit']);

		if (roomSpecId) {
			const t = getTokenFromCookie() ? getTokenFromCookie() : token;
			setRoomSpecId(roomSpecId);
			roomSpecActions.resetRoomSpec();
			roomConfigurationValuesActions.updateRoomConfigValues({} as RoomConfiguration);

			roomSpecActions.loadRoomSpec(roomSpecId, t ? t : '');
			unitActions.setUnits([unit]);
		}
	}, []);

	React.useEffect(() => {
		const t = getTokenFromCookie() ? getTokenFromCookie() : token;

		appConfigActions.getAppConfig(t ? t : '', () => {});
	}, []);

	return (
		<div className={classes.root}>
			{!roomSpecId ? (
				<>
					<TextField
						onChange={(e) => setRoomSpecId(Number.parseInt(e.target.value))}
						placeholder="roomSpecId"
					/>
					<Button onClick={loadRoomSpec}>Go</Button>
				</>
			) : (
				<CircularProgress />
			)}
		</div>
	);
}

const useStyles = makeStyles({
	root: {
		width: '100%',
		height: '70%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default LoadRoomSpecPage;
