//prettier-ignore
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useActions } from '../../actions';
import * as ValidationActions from '../../actions/fieldValidator';
import { Step, SubStep, ValidationField } from '../../model/model';
import { RootState } from '../../reducers';

interface Props {
	step: Step;
	subStep?: SubStep;
	open: boolean;
	onClose(): void;
}

function StepValidationDialog(props: Props) {
	const { t } = useTranslation();

	const { open, onClose, step, subStep } = props;

	const validationActions: typeof ValidationActions = useActions(ValidationActions);

	const fieldValidation: ValidationField[] = useSelector((state: RootState) => state.fieldValidation);

	const stepList: Step[] = useSelector((state: RootState) => state.stepList);

	const { history } = useReactRouter();

	const handleClose = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		onClose();
		let routToInvalidField = '';
		if (subStep) {
			routToInvalidField = showInvalidFieldsForDisabledSubStep(subStep);
		} else {
			routToInvalidField = showInvalidFieldsForDisabledStep(step);
		}
		history.push(routToInvalidField);
	};

	//
	/**
	 * opens a dialog to inform the user that fields are missing to access this step.
	 *
	 * @returns {string} returns the first route which contains invalid fields,
	 * the user will be redirected to this route to correct the invalid fields
	 */
	const showInvalidFieldsForDisabledStep = (clickedStep: Step): string => {
		let updatedValidationFields: ValidationField[] = [];

		let routeOfMissingField: string = '';
		fieldValidation.forEach((field) => {
			if (clickedStep.requiredCompletedSteps.indexOf(field.stepId) === -1) {
				return;
			}
			updatedValidationFields.push({
				...field,
				showValidationState: true,
			});
			if (!routeOfMissingField && field.errorText) {
				let step = stepList.find((step) => step.id === field.stepId)!;
				if (!step.disabled) {
					//get route of substep if substep id exists on validation field
					if (field.subStepId && step.subSteps) {
						let subStep = step.subSteps.find((subStep) => subStep.id === field.subStepId);
						routeOfMissingField = subStep ? subStep.route : step.route;
					} else {
						routeOfMissingField = step.route;
					}
				}
			}
			if (field.additionalRoute && routeOfMissingField) {
				routeOfMissingField += field.additionalRoute;
			}
		});
		validationActions.updateFieldValidations(updatedValidationFields);

		return routeOfMissingField;
	};

	const showInvalidFieldsForDisabledSubStep = (clickedSubStep: SubStep): string => {
		let updatedValidationFields: ValidationField[] = [];

		let routeOfMissingField: string = '';
		fieldValidation.forEach((field) => {
			if (
				routeOfMissingField.length > 0 ||
				!field.subStepId ||
				!clickedSubStep.requiredCompletedSubSteps ||
				clickedSubStep.requiredCompletedSubSteps.indexOf(field.subStepId) === -1
			) {
				return routeOfMissingField;
			}
			updatedValidationFields.push({
				...field,
				showValidationState: true,
			});
			if (field.errorText) {
				var subStep: SubStep | undefined;
				stepList.forEach((step) => {
					if (step.subSteps && subStep === undefined) {
						subStep = step.subSteps.find((s) => field.subStepId === s.id);
					}
				});

				routeOfMissingField = subStep ? subStep!.route : '';
			}
		});
		validationActions.updateFieldValidations(updatedValidationFields);

		return routeOfMissingField;
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="confirmation-dialog-title" open={open}>
			<DialogTitle id="confirmation-dialog-title">{t('validation.validationDialog.title')}</DialogTitle>
			<DialogContent dividers>
				<Typography gutterBottom>{t('validation.validationDialog.content')}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} value="OK" color="primary">
					{t('validation.validationDialog.showInvalidFieldsButton')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default StepValidationDialog;
