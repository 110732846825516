// prettier-ignore
import InfoIcon from '@material-ui/icons/Info';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useActions } from '../../actions';
import * as RoomConfigurationValuesAction from '../../actions/roomConfigurationValues';
import * as RoomSpecActions from '../../actions/roomSpec';
import * as StepActions from '../../actions/step';
import { useValidator } from '../../middleware/fieldValidation/fieldValidatorHook';
import { AppConfig, RoomConfiguration, RoomSpec, Route } from '../../model/model';
import { RootState } from '../../reducers';
import CustomMessageBox from '../validation/CustomMessageBox';
import ProductButton from './ProductButton';
import ResetProductSelectionDialog from './ResetProductSelectionDialog';

interface Props extends RouteComponentProps<void> {}

function ProductFamily(props: Props) {
	const classes = useStyles();

	const { t } = useTranslation();

	const [isDialogOpen, setDialogOpen] = React.useState(false);
	const [selectedProdFamId, setSelectedProdFamId] = React.useState('');

	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const appConfig: AppConfig = useSelector((state: RootState) => state.appConfig);

	let { getErrorText } = useValidator();

	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);
	const stepActions: typeof StepActions = useActions(StepActions);

	const updateSelectedProductFam = (prodFamId?: string) => {
		let localProdFamId = prodFamId ? prodFamId : selectedProdFamId;
		stepActions.resetSteps();
		roomSpecActions.resetRoomSpec(true);
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			productFamilyVariantId: '',
			productFamilyId: localProdFamId,
			productCategoryId: roomSpec.productCategoryId,
		});
	};

	let showProdFamErrorBox =
		getErrorText('productFamilyId').length > 0 || getErrorText('productFamilyVariantId').length > 0;

	return (
		<div className={classes.root}>
			<ResetProductSelectionDialog
				isDialogOpen={isDialogOpen}
				onConfirmedClose={updateSelectedProductFam}
				setDialogOpen={setDialogOpen}
			/>
			<CustomMessageBox
				isErrorMessage={true}
				isVisible={showProdFamErrorBox}
				text={t('steps.productSelection.productFamIsRequired')}
			/>

			<ProductFamVariant {...props} />
		</div>
	);
}

function ProductFamVariant(props: Props) {
	const classes = useStyles();

	const { t } = useTranslation();
	const appConfig: AppConfig = useSelector((state: RootState) => state.appConfig);
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const productCat = appConfig.productCategories.find((el) => el.id === roomSpec.productCategoryId);
	const [isDialogOpen, setDialogOpen] = React.useState(false);
	const [selectedProdFamId, setSelectedProdFamId] = React.useState('');
	const [selectedProdFamVariantId, setSelectedProdFamVariantId] = React.useState('');

	let { getErrorText } = useValidator();

	const roomConfigurationValues: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);
	const stepActions: typeof StepActions = useActions(StepActions);
	const roomConfigurationValuesActions: typeof RoomConfigurationValuesAction = useActions(
		RoomConfigurationValuesAction
	);

	const showUpdateProductFamVariantConfirmDialog = (
		familyId: '' | 'hinoki' | 'suki',
		familyVariantId: '' | 'guest' | 'common'
	) => {
		let prodFamVariantId = familyVariantId;
		//check if confirmation dialog has to appear or not (no confirmation dialog of selection was empty or selection was not)
		if (prodFamVariantId === roomSpec.productFamilyVariantId && familyId === roomSpec.productFamilyId) {
			props.history.push(Route.STRUCTURAL_ELEMENTS);
		} else if (roomSpec.productFamilyVariantId === '' || roomSpec.productFamilyId === '') {
			updateSelectedProductFamVariantId(familyId, prodFamVariantId);
		} else {
			setSelectedProdFamId(familyId);
			setSelectedProdFamVariantId(prodFamVariantId);
			setDialogOpen(true);
		}
	};

	const updateSelectedProductFamVariantId = (
		familyId: '' | 'hinoki' | 'suki',
		familyVariantId: '' | 'guest' | 'common'
	) => {
		if (!familyId || !familyVariantId || !productCat) {
			return;
		}
		let productFamilies = productCat.productFamilies;
		let selectedProductFamVariant = productFamilies
			.find((el) => el.id === familyId)!
			.productFamilyVariants.find((el) => el.id === familyVariantId);
		stepActions.resetSteps();
		roomSpecActions.resetRoomSpec(true);
		roomSpecActions.updateRoomSpec({
			...selectedProductFamVariant!.defaultRoomData,
			productFamilyId: familyId,
			productCategoryId: roomSpec.productCategoryId,
			productFamilyVariantId: familyVariantId,
		});
		roomConfigurationValuesActions.updateRoomConfigValues({
			...selectedProductFamVariant!.roomConfiguration,
		});
		props.history.push(Route.STRUCTURAL_ELEMENTS);
	};

	return (
		<div>
			<ResetProductSelectionDialog
				isDialogOpen={isDialogOpen}
				onConfirmedClose={() =>
					updateSelectedProductFamVariantId(selectedProdFamId as any, selectedProdFamVariantId as any)
				}
				setDialogOpen={setDialogOpen}
			/>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				className={classes.containerVariants}
				spacing={4}
			>
        <Grid item sm={12}>
          <Typography variant="h4">Welche Grundrissart wollen Sie wählen?</Typography>
          <Typography variant="caption" className={classes.info}>
            <InfoIcon color="secondary" className={classes.infoIcon}/> Der Konfigurator befindet sich derzeit in Entwicklung und dient daher aktuell hauptsächlich zu
            Demonstrationszwecken. Bitte nehmen Sie bei Interesse an unseren Produkten direkt Kontakt zu uns auf.
          </Typography>
        </Grid>
				<Grid item sm={6}>
					<ProductButton
						familyId="hinoki"
						familyVariantId="common"
						selected={roomSpec.productFamilyId === 'hinoki' && roomSpec.productFamilyVariantId === 'common'}
						onClick={() => showUpdateProductFamVariantConfirmDialog('hinoki', 'common')}
            disabled={true}
					/>
				</Grid>
				<Grid item sm={6}>
					<ProductButton
						familyId="suki"
						familyVariantId="common"
						selected={roomSpec.productFamilyId === 'suki' && roomSpec.productFamilyVariantId === 'common'}
						onClick={() => showUpdateProductFamVariantConfirmDialog('suki', 'common')}
					/>
				</Grid>
				<Grid item sm={6}>
					<ProductButton
						familyId="hinoki"
						familyVariantId="guest"
						selected={roomSpec.productFamilyId === 'hinoki' && roomSpec.productFamilyVariantId === 'guest'}
						onClick={() => showUpdateProductFamVariantConfirmDialog('hinoki', 'guest')}
            disabled={true}
					/>
				</Grid>
				<Grid item sm={6}>
					<ProductButton
						familyId="suki"
						familyVariantId="guest"
						selected={roomSpec.productFamilyId === 'suki' && roomSpec.productFamilyVariantId === 'guest'}
						onClick={() => showUpdateProductFamVariantConfirmDialog('suki', 'guest')}
            disabled={true}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	paperDefault: {
		height: '200px',
		width: '200px',
		padding: '10px 0px 20px 0px',
		borderRadius: '50%',
		'&:hover': {
			boxShadow: 'inset 0 3px 15px  #888888',
		},
	},
	paperSelected: {
		height: '200px',
		width: '200px',
		padding: '10px 0px 20px 0px',
		borderRadius: '50%',
		boxShadow: 'inset 0 3px 15px  #c0de74',
		color: theme.palette.secondary.main,
	},
	prodVaraintPaperSelected: {
		height: '150px',
		width: '70%',
		margin: 'auto',
		boxShadow: 'inset 0 3px 15px  #c0de74',
		color: theme.palette.secondary.main,
	},

	prodVaraintPaperDefault: {
		height: '150px',
		width: '70%',
		margin: 'auto',
		'&:hover': {
			boxShadow: 'inset 0 3px 15px  #888888',
		},
	},
	prodFamVariantItem: {
		marginTop: '10px',
		marginLeft: '10px',
	},
	containerVariants: {
		marginLeft: 40,
		width: 770,
		marginTop: 20,
	},
	item: {
		flexGrow: 1,
		// minWidth: '100%',
		// minHeight: '100%',
	},
	buttonBase: {
		boxSizing: 'inherit',
		minWidth: '100%',
		minHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonBaseProdFamVariant: {
		minWidth: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	imageContainer: {
		borderRadius: '50%',
	},
	image: {
		height: '80%',
		width: '80%',
	},
	prodFamVariantImage: {
		maxHeight: '80%',
		maxWidth: '150px',
	},
	prodFamVariantImageContainer: { paddingRight: '30px' },
	text: {
		marginTop: '50px',
	},
	textProdFamVariant: {
		paddingLeft: '30px',
	},
  info: {
	  display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
	  marginRight: 8,
  }
}));

export default ProductFamily;
