import { Middleware } from 'redux';
import { ActionType } from '../../model/model';
import { RootState } from '../../reducers';
import { validateNewlyRegisteredFields, validateRoomSpec } from './fieldValidator';

/**
 * Middleware for field validation and step progress:
 * Every dispatched redux action will pass this middleware before it gets handeled.
 * Fields that are newly registered will be validated by this middleware and every update action of the roomSpec state will also trigger the validation
 * All other ActionTypes are passed through.
 *
 */
const ValidationMiddleware: Middleware = (store) => (next) => (action) => {
	let state: RootState = store.getState();

	switch (action.type) {
		case ActionType.REGISTER_FIELD_VALIDATIONS:
			//initially validate the registered fields and update the action payload to store only validated fields in the redux validation state
			action.payload = validateNewlyRegisteredFields(action.payload, state);
			break;
		case ActionType.UPDATE_ROOMSPEC:
			validateRoomSpec(action.payload, state, store.dispatch);
			break;
	}

	return next(action);
};

export default ValidationMiddleware;
