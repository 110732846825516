// prettier-ignore
import {Card, CardContent, CardMedia, Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/styles';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import useReactRouter from 'use-react-router';
import {RootState} from '../reducers';
import {RoomSpec, Route} from "../model/model";
import * as RoomSpecActions from "../actions/roomSpec";
import {useActions} from "../actions";
import {useTranslation} from "react-i18next";
import ConfigNextButton from "../components/configuratorView/fields/ConfigNextButton";
import VariantButton from "../components/equirect/VariantButton";

interface Props extends RouteComponentProps<void> {
}

/**
 * Note: there is a HACKy mapping concerning variant names:
 *
 * - economy --> dark
 * - standard --> chess
 * - exclusive --> tropic
 */
function EquiRectPage(props: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
    const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);
    interface Variante {'img': string, 'url': string }
    interface VariantenListe { [key: string]: Variante }
    const varianten: VariantenListe = {
        'dark': {
            'img': '/assets/equirect/economy.jpeg',
            'url': 'https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/equirects/suki-economy/index.html?a=1'
        },
        'chess': {
            'img': "/assets/equirect/standard.jpeg",
            'url': 'https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/equirects/suki-standard/index.html?a=2',
        },
        'tropic': {
            'img': "/assets/equirect/exclusive.jpeg",
            'url': 'https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/equirects/suki-exclusive/index.html?a=3',
        },
    };
    interface Element {'src': string, 'title': string }
    interface Variant2Elements { [key: string]: Element[] }
    const variant2elements: Variant2Elements = {
        "dark": [
            {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/dark/tst-14.jpg",
                "title": "WEDI Fundo Primo 900x900 mm"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/dark/twb-1.jpg",
                "title": "DIANA S100 Waschtisch"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/dark/twc-1.jpg",
                "title": "DIANA S100 WC"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/dark/tfsup-1.jfif",
                "title": "DIANA S100 UP-Brausemischer"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/dark/tshovs-1.jfif",
                "title": "DIANA L100 Kopfbrause"
            }
        ],
        "chess": [
            {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/chess/tfsup-2.jpg",
                "title": "Grohe Essence Brausemischer chrom"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/chess/tshovs-2.jpg",
                "title": "GROHE Cosmopolitan Kopfbrause"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/chess/tst-11.jpg",
                "title": "WEDI Fundo Primo 900x900 mm"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/chess/twb-2.jpg",
                "title": "GROHE Euro Waschtisch"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/chess/twc-3.jpg",
                "title": "GROHE Euro WC"
            }
        ],
        "tropic": [
            {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/tropic/tfsup-3.jpg",
                "title": "hansgrohe Citterio M Brausemischer chrom"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/tropic/tshovs-3.png",
                "title": "HANSGROHE Raindance E Kopfbrause"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/tropic/twb-3.jpg",
                "title": "GEBERIT ACANTO Waschtisch"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/tropic/twc-5.jpg",
                "title": "GEBERIT ACANTO WC"
            }, {
                "src": "https://tjiko-dev-assets.s3.eu-central-1.amazonaws.com/elements-beta/tropic/tst-15.jpg",
                "title": "WEDI Fundo Primo 900x900 mm"
            }
        ]
    };

    // if user is not authenticated -> redirect to login page
    const {history} = useReactRouter();
    const initialSeries = roomSpec.elements[0].slug;
    const series2variant: { [key: string]: string; }  = {
      'economy': 'dark',
      'standard': 'chess',
      'exclusive': 'tropic',
    };
    const initialVariant: string = series2variant[initialSeries];
    const [variante, setVariante] = React.useState(initialVariant);
    const [elementImages, setElementImages] = React.useState(variant2elements[initialVariant]);
    const spacing = 3;
    const token: string = useSelector((state: RootState) => state.token);
    if (!token) {
        history.push('/');
        return <div/>;
    }
    const handleVariante = (variant: string) => {
        setVariante(variant);
        setElementImages(variant2elements[variant]);
        roomSpecActions.patchRoomSpecVariant(roomSpec, variant);
    };

    return <div className={classes.viewContainer}>
        <Grid container className={classes.root} spacing={spacing}>
            <Grid item sm={10} className={classes.equirect}>
                <iframe className={classes.iframe}
                        allowFullScreen={true}
                        src={varianten[variante].url} />
                <p className={classes.hinweis}> Hinweis: Diese 3D Ansicht dient der Demonstration und bildet nicht exakt
                    die von Ihnen gewählte Konfiguration ab. </p>
                <Grid container className={classes.selector} justify="center" alignItems="center" spacing={spacing}>
                  {Object.keys(varianten).map((k: string, x: any) => (
                    <Grid item key={k}>
                      <VariantButton name={k}
                                     img={varianten[k].img}
                                     onClick={() => handleVariante(k)}
                                     selected={k === variante}
                      />
                    </Grid>
                  ))}
                </Grid>
            </Grid>
            <Grid item sm={2} className={classes.right}>
                <div className={classes.elemente}>
                    {/*<Typography variant="h6" gutterBottom>Elemente</Typography>*/}
                    {elementImages.map((x: any) => (
                        <Card className={classes.elementCard} key={x.src}>
                            <CardContent>
                                <Typography variant="subtitle2" gutterBottom>{x.title}</Typography>
                                <CardMedia className={classes.elementImage} image={x.src} title={x.title}/>
                            </CardContent>
                        </Card>
                    ))}
                </div>
                <Grid container className={classes.nextContainer} justify="center" alignItems="center">
                    <ConfigNextButton nextRoute={Route.TECHNICAL_DETAILS}/>
                    {/*<Button*/}
                    {/*    className={classes.nextButton}*/}
                    {/*    variant="contained"*/}
                    {/*    color="secondary"*/}
                    {/*    onClick={handleNext}*/}
                    {/*>*/}
                    {/*    {t('next')}*/}
                    {/*</Button>*/}
                </Grid>
            </Grid>
        </Grid>

    </div>
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
    paper: {
        height: 80,
        width: 80,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    viewContainer: {
        padding: 24,
        display: 'flex',
        justifyContent: 'flex-start',
        height: "850px",
    },
    iframe: {
        width: "100%",
        height: "85%",
        border: 'none',
    },
    equirect: {
        height: "100%",
    },
    hinweis: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 10,
    },
    selector: {
        paddingTop: 24,
        height: "15%",
    },
    right: {
        height: "100%",
    },
    elemente: {
        height: "85%",
        overflowY: 'scroll'
    },
    elementCard: {
        marginBottom: 8,
    },
    elementImage: {
        width: 180,
        height: 180,
    },
    nextContainer: {
        height: "15%",
        // here be dragons
        // ConfigNextButton does not accept className attribute *sigh
        paddingTop: 12,
    },
    nextButton: {
        marginTop: 30,
        marginBottom: 10,
        marginLeft: 22,
        marginRight: 22,
    },
}));

export default EquiRectPage;
