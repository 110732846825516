// prettier-ignore
import { Button, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { feedbackMail } from '../../endpoints';

interface Props {}

function Feedback(props: Props) {
	const {} = props;
	const classes = useStyles(props);
	const { t } = useTranslation();

	return (
		<Button
			className={classes.feedback}
			variant="contained"
			color="primary"
			onClick={() => {
				let subject = t('mail.feedback.subject');
				let body = t('mail.feedback.body');

				subject = subject.replace('<<URL>>', window.location.pathname);
				body = body.replace('<<URL>>', window.location.pathname);

				window.open('mailto:' + feedbackMail + '?subject=' + encodeURI(subject) + '&body=' + encodeURI(body));
			}}
		>
			Feedback
		</Button>
	);
}

const useStyles = makeStyles({
	feedback: {
		margin: 8,
	},
});

export default Feedback;
