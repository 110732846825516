import { FeatureElement, RoomConfiguration, RoomDimensions, RoomSpec, Unit, WallAlignment } from '../../../model/model';
import { convertLengthUnitToCentimeter } from '../LengthUnitConverter';
import {
	getElementCoordinatesForPositionAtWall,
	getStartPositionOFElementOnAdjacentWall,
} from './calculateElementPositionAndCoordinatesFunctions';
import {
	getCoordinatesAtAdjacentWallWithMarginToOppositeWall,
	getElementPositionOfElementNextToShower,
	getReferenceElementWallAlignment,
} from './dimensionCalculatorFunctions';
import {
	getLeftAdjacentWallAlignment,
	getOppositeWallAlignment,
	getWallAlignmentOfWallAndAdjacentWalls,
} from './wallCalculationFunctions';

export function getRadiatorCoordinatesForSuki(
	shower: FeatureElement | undefined,
	radiatorWallAlignment: WallAlignment,
	referenceRoomElWallAlignment: WallAlignment,
	dim: RoomDimensions,
	roomConfig: RoomConfiguration,
	roomSpec: RoomSpec,
	radiatorWidthInCM: number,
	radiatorHeightInCM: number,
	radiatorMarginToWallInCM: number
) {
	let coordinates = { xCoordinate: 0, yCoordinate: 0 };

	//suki guest variant
	if (!shower || !shower.wallAlignment) {
		let referenceElement = Object.values(roomSpec.featureElements).find(
			(el) => el.wallAlignment === referenceRoomElWallAlignment
		);

		//check if reference element is sink or toilet
		let radiatorPosition: 'left' | 'center' = referenceElement
			? referenceElement.featureElementCategory === 'SI'
				? 'center'
				: 'left'
			: 'left';
		let marginToStartOfWall = radiatorPosition === 'left' ? radiatorMarginToWallInCM : 0;

		coordinates = getElementCoordinatesForPositionAtWall(
			radiatorWallAlignment,
			dim,
			radiatorWidthInCM,
			radiatorHeightInCM,
			0,
			radiatorPosition,
			marginToStartOfWall,
			0
		);
	} else {
		//suki common variant
		let elementOnSameWallWithShower = Object.values(roomSpec.featureElements).filter(
			(el) => el.wallAlignment === shower.wallAlignment && el.featureElementCategory !== 'SH'
		)[0];

		let adjacentWallDirection: 'left' | 'right' =
			roomConfig.showerWallPositionLeftRight === 'left' ? 'right' : 'left';
		//if shower and WC are on same wall radiator should be centered to sink movement area
		if (elementOnSameWallWithShower && elementOnSameWallWithShower.featureElementCategory === 'WC') {
			let sinkElement = roomSpec.featureElements['SI'];

			if (!sinkElement) {
				coordinates = getElementCoordinatesForPositionAtWall(
					radiatorWallAlignment,
					dim,
					radiatorWidthInCM,
					radiatorHeightInCM,
					0,
					adjacentWallDirection,
					0,
					0
				);

				return coordinates;
			}

			let startPositionOfSinkMovementArea = getStartPositionOFElementOnAdjacentWall(
				sinkElement,
				radiatorWallAlignment,
				dim,
				adjacentWallDirection,
				false
			);

			let endPositionOfSinkMovementArea = getStartPositionOFElementOnAdjacentWall(
				sinkElement,
				radiatorWallAlignment,
				dim,
				adjacentWallDirection,
				true
			);

			let [firstElementPosition, secondElementPosition] =
				startPositionOfSinkMovementArea > endPositionOfSinkMovementArea
					? [startPositionOfSinkMovementArea, endPositionOfSinkMovementArea]
					: [endPositionOfSinkMovementArea, startPositionOfSinkMovementArea];

			coordinates = getElementCoordinatesForPositionAtWall(
				radiatorWallAlignment,
				dim,
				radiatorWidthInCM,
				radiatorHeightInCM,
				0,
				'centerBetweenTwoPositions',
				0,
				0,
				firstElementPosition,
				secondElementPosition
			);
		} else if (elementOnSameWallWithShower && elementOnSameWallWithShower.featureElementCategory === 'SI') {
			//if shower and WC are on same wall radiator should be 200 mm next to the end of the sink

			let sinkElement = roomSpec.featureElements['SI'];

			if (!sinkElement) {
				coordinates = getElementCoordinatesForPositionAtWall(
					radiatorWallAlignment,
					dim,
					radiatorWidthInCM,
					radiatorHeightInCM,
					0,
					adjacentWallDirection,
					0,
					0
				);

				return coordinates;
			}

			let endPositionOfSink = getElementPositionOfElementNextToShower(
				radiatorWallAlignment,
				sinkElement,
				roomConfig.showerWallPositionLeftRight
			);

			let radiatorPosition = getRadiatorPositionNextToSink(
				radiatorWallAlignment,
				endPositionOfSink,
				roomConfig.showerWallPositionLeftRight,
				convertLengthUnitToCentimeter(roomConfig.radiatorWidth),
				convertLengthUnitToCentimeter(roomConfig.marginRadiatorToSinkForSukiCommon)
			);

			//two position radiator exact at the calculated radiator position set it as start and end positioin with "centerBetweenTwoPositions"
			coordinates = getElementCoordinatesForPositionAtWall(
				radiatorWallAlignment,
				dim,
				radiatorWidthInCM,
				radiatorHeightInCM,
				0,
				'centerBetweenTwoPositions',
				0,
				0,
				radiatorPosition,
				radiatorPosition
			);

			coordinates.xCoordinate = coordinates.xCoordinate + 58;
		}
	}

	return coordinates;
}

//used for suki with sink on same wall as shower (radiator has to be placed 200 mm next to sink)
function getRadiatorPositionNextToSink(
	wallAlignemnt: WallAlignment,
	sinkEndPosition: number,
	showerWallPositionLeftRight: 'left' | 'right',
	radiatorWidth: number,
	marginRadiatorToSink: number
) {
	let radiatorPosition = sinkEndPosition;
	switch (wallAlignemnt) {
		case WallAlignment.NORTH:
			radiatorPosition +=
				showerWallPositionLeftRight === 'left'
					? radiatorWidth / 2 + marginRadiatorToSink
					: -radiatorWidth / 2 - marginRadiatorToSink;
			break;
		case WallAlignment.SOUTH:
			radiatorPosition +=
				showerWallPositionLeftRight === 'left'
					? -radiatorWidth / 2 - marginRadiatorToSink
					: radiatorWidth / 2 + marginRadiatorToSink;
			break;
		case WallAlignment.WEST:
			radiatorPosition +=
				showerWallPositionLeftRight === 'left'
					? -radiatorWidth / 2 - marginRadiatorToSink
					: radiatorWidth / 2 + marginRadiatorToSink;
			break;

		case WallAlignment.EAST:
			radiatorPosition +=
				showerWallPositionLeftRight === 'left'
					? radiatorWidth / 2 + marginRadiatorToSink
					: -radiatorWidth / 2 - marginRadiatorToSink;
			break;
	}

	return radiatorPosition;
}

export function getRadiatorWallAlignment(
	roomSpec: RoomSpec,
	roomConfig: RoomConfiguration,
	referenceWallAlignment: WallAlignment | undefined
) {
	let radiatorWallAlignment = WallAlignment.NORTH;

	const wallAlignemnts = [WallAlignment.WEST, WallAlignment.NORTH, WallAlignment.SOUTH, WallAlignment.EAST];

	let shower = roomSpec.featureElements['SH'];

	//return default value, if no feature element has been selected yet
	if (!referenceWallAlignment) {
		return radiatorWallAlignment;
	}

	//get radiator wall alingment for Hinoki
	if (roomSpec.productFamilyId === 'hinoki') {
		//get wallalignemnts that are not allowed as radiator position
		let invalidRadiatorWalls = getWallAlignmentOfWallAndAdjacentWalls(
			referenceWallAlignment,
			roomConfig.showerWallPositionLeftRight
		);

		let possibleRadiatorWallAligments = wallAlignemnts.filter((el) => invalidRadiatorWalls.indexOf(el) === -1);
		let oppositeWallAlignemntOfInstallationWall = getOppositeWallAlignment(referenceWallAlignment);

		//if door position is set, radiator has to be on other wall,
		//else one of the possible walls will be selected (does not matter which wall, since the door has to be selected during the configuration process)
		if (roomSpec.doorSpecification && roomSpec.doorSpecification.wall) {
			let doorWallAlignment = roomSpec.doorSpecification.wall.wallAlignment;
			radiatorWallAlignment = possibleRadiatorWallAligments.find((el) => el !== doorWallAlignment)!;

			//sepcial rule for HINOKI_Guest_VL, if no shower is selected and the door is  at the adjacent wall, the radiator is on the opposite side of the door wall
			if ((!shower || !shower.wallAlignment) && doorWallAlignment !== oppositeWallAlignemntOfInstallationWall) {
				radiatorWallAlignment = getOppositeWallAlignment(doorWallAlignment);
			}
		} else {
			//if no door is selected yet take the wall for radiator which is not on the opposite side of the installation wall
			radiatorWallAlignment = possibleRadiatorWallAligments.find(
				(el) => el !== oppositeWallAlignemntOfInstallationWall
			)!;
		}

		//get radiator wall alingment for Suki (always on same wall as the reference element)
	} else if (roomSpec.productFamilyId === 'suki') {
		// in suki guest (=no shower is selected) the radiator is on the left wall of
		if (!shower || !shower.wallAlignment) {
			radiatorWallAlignment = getLeftAdjacentWallAlignment(referenceWallAlignment);
		} else {
			// in suki common (= shower is selected) the radiator is always on the same wall as the shower element
			radiatorWallAlignment = referenceWallAlignment;
		}
	}

	return radiatorWallAlignment;
}

//calcualtes radiator positionx and y position
export function getRadiatorCoordinatesAndWallAlignment(
	roomSpec: RoomSpec,
	roomConfig: RoomConfiguration,
	dim: RoomDimensions
) {
	let radiatorWithInCM = convertLengthUnitToCentimeter(roomConfig.radiatorWidth);
	let radiatorHeightInCM = convertLengthUnitToCentimeter(roomConfig.radiatorHeight);
	let radiatorMarginToWallInCM = convertLengthUnitToCentimeter(roomConfig.marginRadiatorToWall);

	//get referenc element, which is used to determine the radiator wallalignment
	let referenceRoomElWallAlignment = getReferenceElementWallAlignment(roomSpec.featureElements);

	let radiatorWallAlignment = getRadiatorWallAlignment(roomSpec, roomConfig, referenceRoomElWallAlignment);

	let shower = roomSpec.featureElements['SH'];
	let coordinates = { xCoordinate: 0, yCoordinate: 0 };

	//get coordinates for Hinoki
	if (roomSpec.productFamilyId === 'hinoki') {
		coordinates = getRadiatorCoordinatesForHinoki(
			shower,
			radiatorWallAlignment,
			referenceRoomElWallAlignment,
			dim,
			roomConfig,
			radiatorWithInCM,
			radiatorHeightInCM,
			radiatorMarginToWallInCM
		);
	} else {
		coordinates = getRadiatorCoordinatesForSuki(
			shower,
			radiatorWallAlignment,
			referenceRoomElWallAlignment,
			dim,
			roomConfig,
			roomSpec,
			radiatorWithInCM,
			radiatorHeightInCM,
			radiatorMarginToWallInCM
		);
	}

	return {
		xCoordinate: {
			value: coordinates!.xCoordinate,
			unit: Unit.CENTI_METER,
		},
		yCoordinate: {
			value: coordinates!.yCoordinate,
			unit: Unit.CENTI_METER,
		},
		wallAlignment: radiatorWallAlignment,
	};
}

export function getRadiatorCoordinatesForHinoki(
	shower: FeatureElement | undefined,
	radiatorWallAlignment: WallAlignment,
	referenceRoomElWallAlignment: WallAlignment,
	dim: RoomDimensions,
	roomConfig: RoomConfiguration,
	radiatorWidthInCM: number,
	radiatorHeightInCM: number,
	radiatorMarginToWallInCM: number
) {
	let coordinates = { xCoordinate: 0, yCoordinate: 0 };
	//Hinoki Guest Variant
	if (!shower || !shower.wallAlignment) {
		coordinates = getCoordinatesAtAdjacentWallWithMarginToOppositeWall(
			referenceRoomElWallAlignment,
			radiatorWallAlignment,
			dim,
			radiatorWidthInCM,
			radiatorHeightInCM,
			radiatorMarginToWallInCM
		);
	} else {
		//check if radiator wall is on opposite side of reference element wall
		//-> if true positin is middle of wall
		// ->if false psoition is positioned at end of adjacent wall (adjacent wall that is not in the corner of the shower)
		if (radiatorWallAlignment === getOppositeWallAlignment(referenceRoomElWallAlignment)) {
			coordinates = getElementCoordinatesForPositionAtWall(
				radiatorWallAlignment,
				dim,
				radiatorWidthInCM,
				radiatorHeightInCM,
				0,
				'center',
				0,
				0
			);
		} else {
			let positionOfRadiatorOnAdjacentWall: 'left' | 'right' =
				roomConfig.showerWallPositionLeftRight === 'left' ? 'right' : 'left';
			let marginToStartOfWall = positionOfRadiatorOnAdjacentWall === 'left' ? radiatorMarginToWallInCM : 0;
			let marginToEndOfWall = positionOfRadiatorOnAdjacentWall === 'right' ? radiatorMarginToWallInCM : 0;
			coordinates = getElementCoordinatesForPositionAtWall(
				radiatorWallAlignment,
				dim,
				radiatorWidthInCM,
				radiatorHeightInCM,
				0,
				positionOfRadiatorOnAdjacentWall,
				marginToStartOfWall,
				marginToEndOfWall
			);
		}
	}

	return coordinates;
}
