// prettier-ignore
import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RoomSpecPrice } from '../../model/model';
import { RootState } from '../../reducers';

interface Props {}

function Price(props: Props) {
	const {} = props;
	const classes = useStyles();

	const roomSpecPrice: RoomSpecPrice = useSelector((state: RootState) => state.roomSpecPrice);

	return (
		<div className={classes.root}>
			<Typography variant="h3" className={classes.price}>
				{roomSpecPrice && roomSpecPrice.value
					? roomSpecPrice.value.toFixed(2) + ' €'
					: roomSpecPrice.error
					? roomSpecPrice.error
					: ' - €'}
			</Typography>
			<Typography className={classes.netto}>Netto</Typography>
		</div>
	);
}

const useStyles = makeStyles({
	root: {
		height: 124,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	price: {
		marginLeft: 48,
		marginBottom: 10,
	},

	netto: {
		marginTop: 24,
		marginLeft: 8,
	},
});

export default Price;
