import { loginURL } from '../endpoints';
import { ActionType } from '../model/model';

// Async Function expample with redux-thunk

export function getUserToken(username: string, password: string) {
	// here you could do API eg

	return async (dispatch: Function, getState: Function) => {
		let status: number = 0;

		try {
			const response = await fetch(loginURL, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					username: username,
					password: password,
				}),
			});

			status = response.status;

			if (status === 200) {
				const json = await response.json();

				const token: string = json.key;
				setCookie(token);

				dispatch({ type: ActionType.GET_USER_TOKEN, payload: token });
			} else {
				dispatch({ type: ActionType.SET_AUTH_ERROR, payload: status });
			}
		} catch (e) {
			console.log(e);
			dispatch({ type: ActionType.SET_AUTH_ERROR, payload: status });
		}
	};
}

function setCookie(token: string) {
	let cookieDate = new Date();
	cookieDate.setFullYear(cookieDate.getFullYear() + 365);
	document.cookie =
		'token=' + token + '; expires=' + cookieDate.toUTCString() + ';domain=.' + 'tjiko-dev.de' + ';path=/';
}

const getCookie = (name: string) => {
	const value = '; ' + document.cookie;
	const parts = value.split('; ' + name + '=');
	if (!!parts && parts.length === 2) {
		const pp = parts.pop();
		if (pp) {
			return pp.split(';').shift();
		}
	}
};

export function getTokenFromCookie() {
	const tokenFromCookie = getCookie('token');
	if (tokenFromCookie) {
		return tokenFromCookie.trim();
	}
}

export function setTokenFromCookie() {
	// try to get token via token cookie
	const tokenFromCookie = getTokenFromCookie();
	if (tokenFromCookie) {
		return {
			type: ActionType.SET_TOKEN,
			payload: tokenFromCookie,
		};
	} else {
		return {
			type: ActionType.SET_TOKEN,
			payload: '',
		};
	}
}

export function setAuthError(value: number) {
	return {
		type: ActionType.SET_AUTH_ERROR,
		payload: value,
	};
}
