import { convertLengthUnitToCentimeter } from '../../components/roomPlan/LengthUnitConverter';
import {
	calculateRoomElementCoordinatesWithoutMovementArea,
	getUpdatedWallAlignment,
} from '../../components/roomPlan/roomPlanLogicFunctions/calculateElementPositionAndCoordinatesFunctions';
import {
	adjustRoomElementCoordinatesToPreventOverlapping,
	getCalculatedRoomDimensions,
} from '../../components/roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';
import { getRadiatorCoordinatesAndWallAlignment } from '../../components/roomPlan/roomPlanLogicFunctions/radiatorPositionFunctions';
import { getSinkWallSocket } from '../../components/roomPlan/roomPlanLogicFunctions/socketFunctions';
import { calculateNeededTilesForAllWalls } from '../../components/roomPlan/roomPlanLogicFunctions/tileCalculationFunctions';
import {
	LengthUnit,
	RadiatorConfig,
	RoomConfiguration,
	RoomDimensions,
	RoomSpec,
	SocketConfig,
	Unit,
	WallAlignment,
	WallSwitch,
	WallSwitchType,
} from '../../model/model';
import { RootState } from '../../reducers';

export function calculateFeatureElementCoordinates(state: RootState, newRoomSpec: RoomSpec) {
	const { roomConfigurationValues } = state;

	const dim = getCalculatedRoomDimensions(newRoomSpec, roomConfigurationValues);
	let updatedFeatureElements = getUpdatedFeatureElements(newRoomSpec, dim, roomConfigurationValues);

	let sink = updatedFeatureElements['SI'];

	let sinkSwitches = [];
	if (sink) {
		sinkSwitches.push(
			getSinkWallSocket(
				sink.xCoordinate,
				sink.yCoordinate,
				sink.elementHeight,
				sink.elementWidth,
				sink.wallAlignment,
				updatedFeatureElements['SH'] ? updatedFeatureElements['SH'].wallAlignment : undefined,
				roomConfigurationValues.socketConfig,
				roomConfigurationValues.showerWallPositionLeftRight
			)
		);
	}

	let roomspecWithUpdatedFeatureElements = {
		...newRoomSpec,
		featureElements: updatedFeatureElements,
	};
	let radiatorConfig: RadiatorConfig | null = null;
	if (newRoomSpec.radiatorType !== null) {
		const radiatorCoords = getRadiatorCoordinatesAndWallAlignment(
			roomspecWithUpdatedFeatureElements,
			state.roomConfigurationValues,
			dim
		);

		radiatorConfig = {
			xCoordinate: radiatorCoords.xCoordinate,
			yCoordinate: radiatorCoords.yCoordinate,
			wallAlignment: radiatorCoords.wallAlignment,
		};
	}

	const neededTiles = calculateNeededTilesForAllWalls(
		roomspecWithUpdatedFeatureElements,
		dim,
		roomConfigurationValues
	);

	let updatedWallSwitches = {
		...newRoomSpec.wallSwitches,
		sinkSwitches: sinkSwitches,
	};

	let updatedRoomSpec: RoomSpec = {
		...newRoomSpec,
		wallSwitches: updatedWallSwitches,
		featureElements: updatedFeatureElements,
		radiatorConfig,
		wallSouthTiles: neededTiles.needTilesSouthWall,
		wallWestTiles: neededTiles.needTilesWestWall,
		wallNorthTiles: neededTiles.needTilesNorthWall,
		wallEastTiles: neededTiles.needTilesEastWall,
	};

	return updatedRoomSpec;
}

export function getUpdatedFeatureElements(roomSpec: RoomSpec, dim: RoomDimensions, roomConfig: RoomConfiguration) {
	let updatedFeatureElements = { ...roomSpec.featureElements };

	// in prod fami Suki all element wall alignments depend on the shower wall alignment
	// the wallalginments have to be calculated for all elements before the calculation of the coordinates
	if (roomSpec.productFamilyId === 'suki') {
		for (var elementCat in updatedFeatureElements) {
			let element = updatedFeatureElements[elementCat];
			if (element.featureElementCategory !== 'SH') {
				let showerEl = updatedFeatureElements['SH'];
				let secondInstallationWallDirection: 'left' | 'right' =
					roomConfig.showerWallPositionLeftRight === 'right' ? 'left' : 'right';
				if (showerEl) {
					let wallAlignment = getUpdatedWallAlignment(
						element.featureElementCategory,
						roomConfig.isSinkAndWCPositionSwapped,
						secondInstallationWallDirection,
						showerEl.wallAlignment
					);
					updatedFeatureElements[elementCat].wallAlignment = wallAlignment;
				}
			}
		}
	}

	//sort order has to adapted since the element position calculation depends on the position of other elements
	let sortedElementCats = roomConfig.isSinkAndWCPositionSwapped ? ['SH', 'SI', 'WC'] : ['SH', 'WC', 'SI'];
	//update all  feature elements since position in drawing could influenced by room spec update
	for (let idx in sortedElementCats) {
		let elementCat = sortedElementCats[idx];
		let element = updatedFeatureElements[elementCat];
		if (!element) {
			continue;
		}

		let coordiantes = calculateRoomElementCoordinatesWithoutMovementArea(
			element,
			dim,
			roomConfig,
			roomSpec,
			updatedFeatureElements
		);

		updatedFeatureElements[elementCat] = {
			...updatedFeatureElements[elementCat],
			xCoordinate: coordiantes.xCoordinate,
			yCoordinate: coordiantes.yCoordinate,
		};
	}

	for (var elementCat in updatedFeatureElements) {
		let element = updatedFeatureElements[elementCat];

		let {
			adjustedElementXPosition,
			adjustedElementYPosition,
			adjustedMovementAreaXPosition,
			adjustedMovementAreaYPosition,
		} = adjustRoomElementCoordinatesToPreventOverlapping(
			element.xCoordinate,
			element.yCoordinate,
			element,
			updatedFeatureElements,
			dim
		);

		updatedFeatureElements[elementCat] = {
			...updatedFeatureElements[elementCat],
			xCoordinate: {
				value: adjustedElementXPosition,
				unit: Unit.CENTI_METER,
			},
			yCoordinate: {
				value: adjustedElementYPosition,
				unit: Unit.CENTI_METER,
			},
			movementAreaYCoordinate: {
				value: adjustedMovementAreaYPosition,
				unit: Unit.CENTI_METER,
			},
			movementAreaXCoordinate: {
				value: adjustedMovementAreaXPosition,
				unit: Unit.CENTI_METER,
			},
		};
	}

	return updatedFeatureElements;
}

const getWashBasinWallSocket = (
	basinPositionX: LengthUnit,
	basinPositionY: LengthUnit,
	basinHeight: LengthUnit,
	basinWidth: LengthUnit,
	washBasinWallAlignment: WallAlignment,
	showerWallAlignment: WallAlignment | undefined,
	socketConfig: SocketConfig,
	showerWallPositionLeftRight: 'left' | 'right'
) => {
	let socketPositionX = { value: 0, unit: Unit.CENTI_METER };
	let socketPositionY = { value: 0, unit: Unit.CENTI_METER };

	let socketWithInCM = convertLengthUnitToCentimeter(socketConfig.doubleSocketWidth);

	let basinPositionXInCM = convertLengthUnitToCentimeter(basinPositionX);
	let basinPositionYInCM = convertLengthUnitToCentimeter(basinPositionY);
	let basinHeightInCM = convertLengthUnitToCentimeter(basinHeight);
	let basinWidthInCM = convertLengthUnitToCentimeter(basinWidth);

	let positionFromWashBasin: 'left' | 'right' =
		showerWallAlignment === undefined
			? 'right'
			: getSocketPositionFromWashBasin(washBasinWallAlignment, showerWallAlignment, showerWallPositionLeftRight);

	switch (washBasinWallAlignment) {
		case WallAlignment.WEST:
			socketPositionY.value =
				positionFromWashBasin === 'right'
					? basinPositionYInCM - basinWidthInCM / 2 + socketWithInCM / 2
					: basinPositionYInCM + basinWidthInCM / 2 - socketWithInCM / 2;

			break;
		case WallAlignment.EAST:
			socketPositionY.value =
				positionFromWashBasin === 'right'
					? basinPositionYInCM + basinWidthInCM / 2 - socketWithInCM / 2
					: basinPositionYInCM - basinWidthInCM / 2 + socketWithInCM / 2;
			break;
		case WallAlignment.NORTH:
			socketPositionX.value =
				positionFromWashBasin === 'right'
					? basinPositionXInCM + basinWidthInCM / 2 - socketWithInCM / 2
					: basinPositionXInCM - basinWidthInCM / 2 + socketWithInCM / 2;
			break;
		case WallAlignment.SOUTH:
			socketPositionX.value =
				positionFromWashBasin === 'right'
					? basinPositionXInCM - basinWidthInCM / 2 + socketWithInCM / 2
					: basinPositionXInCM + basinWidthInCM / 2 - socketWithInCM / 2;
			break;
	}

	let doubleSocket: WallSwitch = {
		id: 'LightSwitch_Door_1',
		height: socketConfig.doubleSocketSinkHeight,
		type: WallSwitchType.SAFETY_DOUBLE_SOCKET,
		wallAlignment: washBasinWallAlignment,
		width: { value: socketWithInCM, unit: Unit.CENTI_METER },
		xCoordinate: socketPositionX,
		yCoordinate: socketPositionY,
	};

	return doubleSocket;
};

//socket at wash basin should be on as far away from the shower as possible: shower on the left of wash basin => socket on the right, etc.
const getSocketPositionFromWashBasin = (
	washBasinWallAlignment: WallAlignment,
	showerWallAlignment: WallAlignment,
	showerPositionLeftRight: 'left' | 'right'
) => {
	let socketPositionFromWashbasin: 'left' | 'right' = 'right';

	//shower is always in the  corner of a wall, so socket position has to be on the opposite side if wall alignments are equal
	if (washBasinWallAlignment === showerWallAlignment) {
		socketPositionFromWashbasin = showerPositionLeftRight === 'right' ? 'left' : 'right';
		return socketPositionFromWashbasin;
	}
	switch (washBasinWallAlignment) {
		case WallAlignment.WEST:
			socketPositionFromWashbasin =
				showerWallAlignment === WallAlignment.NORTH || showerWallAlignment === WallAlignment.EAST
					? 'left'
					: 'right';
			break;
		case WallAlignment.EAST:
			socketPositionFromWashbasin =
				showerWallAlignment === WallAlignment.WEST || showerWallAlignment === WallAlignment.SOUTH
					? 'left'
					: 'right';
			break;
		case WallAlignment.NORTH:
			socketPositionFromWashbasin =
				showerWallAlignment === WallAlignment.EAST || showerWallAlignment === WallAlignment.SOUTH
					? 'left'
					: 'right';
			break;
		case WallAlignment.SOUTH:
			socketPositionFromWashbasin =
				showerWallAlignment === WallAlignment.WEST || showerWallAlignment === WallAlignment.NORTH
					? 'left'
					: 'right';
			break;
	}

	return socketPositionFromWashbasin;
};
