// prettier-ignore
import { AppBar as MuiAppBar, Button, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import withWidth, { isWidthUp, WithWidth } from '@material-ui/core/withWidth';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import cheet from 'cheet.js';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useActions } from '../actions';
import configureStore from '../configureStore';
import { Step } from '../model/model';
import * as UserActions from './../actions/user';
import AdminDialog, { deleteAllCookies } from './adminDialog/AdminDialog';
import { drawerWidth } from './drawer/Drawer';

interface Props extends WithWidth {
	stepList: Step[];
}

function AppBar(props: Props) {
	const { stepList } = props;
	const classes = useStyles();
	const { history, location } = useReactRouter();
	const { t, i18n } = useTranslation();
	const [showAdminDialog, setShowAdminDialog] = React.useState(false);
	const userActions: typeof UserActions = useActions(UserActions);

	const onAdminDialog = (visible: boolean) => () => setShowAdminDialog(visible);
	cheet('a d m i n', onAdminDialog(true));

	const step = stepList.filter((s) => s.route === location.pathname)[0];

	let appBarTitle = '';
	if (step) {
		appBarTitle = t(step.description);
	} else {
		// search for the right substep
		stepList.forEach((s) => {
			if (s.subSteps) {
				const subStep = s.subSteps.filter((ss) => ss.route === location.pathname)[0];
				if (subStep) {
					appBarTitle = t(s.description);
				}
			}
		});
	}

	const onUnityDemo = () => {
		history.push('/unityDemo');
	};

	const clearCache = () => {
		let { persistor } = configureStore();
		persistor
			.purge()
			.then(
				() =>
					(window.location.href = window.location.origin.includes('dev')
						? 'https://projekt.tjiko-dev.de/logout'
						: 'https://projekt.tjiko.de/logout')
			);
	};

	const clearCacheAndCookie = () => {
		deleteAllCookies();
		setTimeout(clearCache, 500);
	};

	return (
		<MuiAppBar position="fixed" className={classes.appBar}>
			<Toolbar style={{ position: 'relative' }}>
				<Paper
					style={{
						position: 'absolute',
						left: 0,
						top: 0,
						display: 'flex',
					}}
				>
					<BackgroundImageRow />
				</Paper>
				<div className={classes.backgroundGradient} />
				<div className={classes.contentContainer}>
					<Typography variant="h5" color="inherit" noWrap={isWidthUp('sm', props.width)}>
						{appBarTitle}
					</Typography>
					<div className={classes.loginButtonContainer}>
						<Button color="inherit" className={classes.button} onClick={clearCacheAndCookie}>
							<ExitToAppIcon className={classes.rightIcon} />
							Abmelden
						</Button>
						{/* <Button
							color="inherit"
							onClick={onAdminDialog(true)}
							className={classes.button}
						>
							<AdminIcon className={classes.rightIcon} />
							Admin
						</Button> */}
						{/* <Button
							color="inherit"
							onClick={onUnityDemo}
							className={classes.button}
						>
							<UnityIcon className={classes.rightIcon} />
							Unity Demo
						</Button> */}
					</div>
				</div>
			</Toolbar>
			<AdminDialog open={showAdminDialog} onClose={onAdminDialog(false)} />
		</MuiAppBar>
	);
}

function BackgroundImageRow(): any {
	const imageWidth = 446;
	const imageCount = window.innerWidth / imageWidth;
	const images: JSX.Element[] = [];

	for (let i = 0; i < imageCount; i++) {
		images.push(<BackgroundImage key={i} />);
	}

	return images;
}

function BackgroundImage() {
	const classes = useStyles();
	return (
		<img
			alt=""
			src="/assets/demo_pictures/nature-landscape-set.png"
			height={appBarHeight}
			className={classes.backgroundImage}
		/>
	);
}
export const appBarHeight = 112;
const useStyles = makeStyles({
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},

	loginButtonContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row-reverse',
	},

	rightIcon: {
		marginRight: 7,
	},

	backgroundImage: {
		'-webkit-filter': 'grayscale(1)' /* Google Chrome, Safari 6+ & Opera 15+ */,
		filter: 'grayscale(1)' /* Microsoft Edge and Firefox 35+ */,
	},

	backgroundGradient: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: appBarHeight,
		backgroundImage: 'linear-gradient(-175deg, #80B3FF, #7a7a7a, #4c4c4c, #242424)',
		opacity: 0.7,
	},

	contentContainer: {
		position: 'absolute',
		left: 0,
		top: 0,
		display: 'flex',
		width: '100%',
		height: appBarHeight,
		alignItems: 'center',
		padding: 15,
	},

	button: {
		marginLeft: 10,
	},
});

export default withWidth()(AppBar);
