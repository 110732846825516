// prettier-ignore
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import ExpertTable from '../components/overview/ExpertTable';

interface Props {}

function ExpertPage(props: Props) {
	const {} = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<ExpertTable />
		</div>
	);
}

const useStyles = makeStyles({
	root: {},
});

export default ExpertPage;
