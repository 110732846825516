import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useValidator } from '../../middleware/fieldValidation/fieldValidatorHook';
//prettier-ignore
import { DoorSpecification, LengthUnit, RoomConfiguration, RoomSpec, Unit, Wall, WallAlignment } from "../../model/model";
import { RootState } from '../../reducers';
import ConfigDivider from '../configuratorView/fields/ConfigDivider';
import ConfigFieldContainer from '../configuratorView/fields/ConfigFieldContainer';
import { convertLengthUnitToMeter } from '../roomPlan/LengthUnitConverter';
import { getCalculatedRoomDimensions } from '../roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';
import {
	calculateDoorPositionAtWallMaxAndMinValue,
	getSelectableWallsForDoor,
} from '../roomPlan/roomPlanLogicFunctions/doorFunctions';
import SelectionInput from '../selectionInput/SelectionInput';
import StepSlider from '../sliderWithInput/StepSlider';
import CustomMessageBox from '../validation/CustomMessageBox';

interface Props {
	label: string;
	walls: Wall[];
	updateAction(el: DoorSpecification): void;
}

function DoorWallPositionConfig(props: Props) {
	const { t } = useTranslation();

	const [errorMsg, setErrorMsg] = React.useState('');

	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const roomConfig: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);

	const classes = useStyles();

	const door = roomSpec.doorSpecification!;

	const { xCoordinate, yCoordinate } = door;

	const dim = getCalculatedRoomDimensions(roomSpec, roomConfig);

	const { getErrorText } = useValidator();

	function handleWallSelection(id: string) {
		let selectedWall = props.walls.filter((el: Wall) => el.id === id)[0];
		props.updateAction({ ...door, wall: selectedWall });
	}

	function updateXPosition(value: number) {
		let updatedXCoordinate: LengthUnit = {
			value: value,
			unit: xCoordinate.unit,
		};
		props.updateAction({ ...door, xCoordinate: updatedXCoordinate });
	}

	function updateYPosition(value: number) {
		let updatedYCoordinate: LengthUnit = {
			value: value,
			unit: yCoordinate.unit,
		};
		props.updateAction({ ...door, yCoordinate: updatedYCoordinate });
	}

	let { doorMaxPositionValue, doorMinPositionValue } = calculateDoorPositionAtWallMaxAndMinValue(
		dim,
		roomSpec.doorSpecification,
		roomSpec.featureElements,
		roomConfig
	);

	// HACK
	let doorMinPositionValueInM = 1;
	let doorMaxPositionValueInM = 2.9;

	let elementAlignment: 'vertical' | 'horizontal' = 'vertical';

	if (door.wall) {
		elementAlignment =
			door.wall.wallAlignment === WallAlignment.NORTH || door.wall.wallAlignment === WallAlignment.SOUTH
				? 'horizontal'
				: 'vertical';

		checkMinAndMaxValuesForDoorPosition(
			doorMinPositionValueInM,
			doorMaxPositionValueInM,
			elementAlignment,
			door,
			updateXPosition,
			updateYPosition,
			(msg) => (errorMsg !== msg ? setErrorMsg(msg) : {})
		);
	}

	let selectableWallsForDoor = getSelectableWallsForDoor(
		props.walls,
		roomSpec.featureElements,
		roomConfig,
		roomSpec.productFamilyId,
		roomSpec.productFamilyVariantId,
		roomSpec.radiatorType
	);

	let showNoSelectableDoorWallInfoBox = selectableWallsForDoor.length < 1;

	//set selected wall if there is no currently selected wall and only one wall can be selected
	if (!door.wall && selectableWallsForDoor.length === 1) {
		let defaultSelectedWall = selectableWallsForDoor[0];
		props.updateAction({ ...door, wall: defaultSelectedWall });
	} else if (selectableWallsForDoor.length === 0 && door.wall) {
		props.updateAction({ ...door, wall: undefined });
	}

	return (
		<div>
			<ConfigDivider title={props.label} />
			<ConfigFieldContainer>
				{/*<SelectionInput*/}
				{/*	selectedElementId={door.wall ? door.wall.id : ''}*/}
				{/*	selectableElements={selectableWallsForDoor}*/}
				{/*	handleElementSelection={handleWallSelection}*/}
				{/*	errorText={t(getErrorText('doorSpecification.wall'))}*/}
				{/*/>*/}
				<div className={classes.noSelectableDoorErrorContainer}>
					<CustomMessageBox
						isErrorMessage={true}
						isVisible={showNoSelectableDoorWallInfoBox}
						text={t('steps.structuralElements.noSelectableDoorWallInfo')}
					/>
					<CustomMessageBox isErrorMessage={true} isVisible={errorMsg !== ''} text={errorMsg} />
				</div>

				{door.wall && elementAlignment === 'vertical' && (
					<StepSlider
						label={t('steps.structuralElements.yPosition')}
						value={yCoordinate.value}
						onChange={updateYPosition}
						minValue={doorMinPositionValueInM}
						maxValue={doorMaxPositionValueInM}
						unit={Unit.METER}
						step={0.0001}
					/>
				)}

				{door.wall && elementAlignment === 'horizontal' && (
					<StepSlider
						label={t('steps.structuralElements.xPosition')}
						value={xCoordinate.value}
						onChange={updateXPosition}
						minValue={doorMinPositionValueInM}
						maxValue={doorMaxPositionValueInM}
						unit={Unit.METER}
						step={0.0001}
					/>
				)}
			</ConfigFieldContainer>
		</div>
	);
}

//checks if current door position is between min and max values and adapts it if it isn´t
const checkMinAndMaxValuesForDoorPosition = (
	elementPositionMinValue: number,
	elementPositionMaxValue: number,
	elementAlignment: 'vertical' | 'horizontal',
	door: DoorSpecification,
	updateXPosition: (value: number) => void,
	updateYPosition: (value: number) => void,
	onError: (msg: string) => void
) => {
	if (elementPositionMaxValue < elementPositionMinValue) {
		const errorMsg = 'Door has no space: Door Position Min Value is bigger than door position max value';
		console.error(errorMsg);
		onError(errorMsg);
		if (elementAlignment == 'horizontal') {
			door.yCoordinate.value !== 0 && updateYPosition(0);
		} else {
			door.xCoordinate.value !== 0 && updateXPosition(0);
		}

		return;
	}
	// const space = convertLengthUnitToMeter(roomConfig.doorSpaceToWcOrSink);
	// if (
	// 	elementPositionMaxValue - elementPositionMinValue + space <
	// 	convertLengthUnitToMeter(door.width)
	// ) {
	// 	const errorMsg = "Door has no space: The slot is to small.";
	// 	console.error(errorMsg);
	// 	onError(errorMsg);
	// 	if (elementAlignment == "horizontal") {
	// 		door.yCoordinate.value != 0 && updateYPosition(0);
	// 	} else {
	// 		door.xCoordinate.value != 0 && updateXPosition(0);
	// 	}

	// 	return;
	// }
	if (elementAlignment === 'horizontal') {
		//ensure correct y Position (0)
		if (door.yCoordinate && door.yCoordinate.value !== 0) {
			updateYPosition(0);
		}
		if (convertLengthUnitToMeter(door.xCoordinate) > elementPositionMaxValue) {
			updateXPosition(elementPositionMaxValue);
		}
		if (convertLengthUnitToMeter(door.xCoordinate) < elementPositionMinValue) {
			updateXPosition(elementPositionMinValue);
		}
	} else if (elementAlignment === 'vertical') {
		//ensure correct x Position (0)
		if (door.xCoordinate && door.xCoordinate.value !== 0) {
			updateXPosition(0);
		}
		if (convertLengthUnitToMeter(door.yCoordinate) > elementPositionMaxValue) {
			updateYPosition(elementPositionMaxValue);
		}
		if (convertLengthUnitToMeter(door.yCoordinate) < elementPositionMinValue) {
			updateYPosition(elementPositionMinValue);
		}
	}

	onError('');
};

const useStyles = makeStyles({
	noSelectableDoorErrorContainer: {
		paddingLeft: '10px',
		marginLeft: '10px',
	},
});

export default DoorWallPositionConfig;
