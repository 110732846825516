import * as React from "react";
import {useEffect} from "react";
import * as SD from "shapediver-types";
import {RoomSpec, Step} from "../../model/model";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";

// this is based on
// https://codepen.io/ShapeDiver/pen/oNvoBxb

// We must declare an interface for "window.SDVApp"; the namespace is not enough
// https://stackoverflow.com/a/44060871/241240
declare interface SDVApp {
  ParametricViewer: SD.Api.ApiInterfaceV2;
}

declare global {
  interface Window {
    SDVApp: SDVApp,
    api: any
  }
}

interface Props {
  ticket: string,
  modelViewUrl: string
}

function SDViewer(props: Props) {
  const containerSD = React.useRef(null);
  const [initialized, setInitialized] = React.useState(false);
  const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);

  async function update_params_from_roomspec(api: any, rs: RoomSpec) {
    // das mappt wirklich Width auf laenge und macht aus metern millimeter
    let aussenlaenge = rs.dimensionWidth.value * 1000;
    // das mappt wirklich Length auf breite und macht aus metern millimeter
    let aussenbreite = rs.dimensionLength.value * 1000;
    // siehe DoorWallPositionConfig für min/max/default
    let tuerposi = rs.doorSpecification!.xCoordinate.value * 1000;

    if (aussenlaenge < 2800 || aussenlaenge > 4000) {
      console.error('------ aussenlaenge out of bounds', aussenlaenge);
      return;
    }
    if (aussenbreite < 1500 || aussenbreite > 2500) {
      console.error('------ aussenbreite out of bounds', aussenbreite);
      return;
    }

    let params = [
      {name: "OuterWidth", value: aussenbreite},
      {name: "OuterLength", value: aussenlaenge},
      {name: "DoorPosition", value: tuerposi},
    ];
    console.log('---- set params in shapediver viewer', params);

    const response = await api.parameters.updateAsync(params);
    if (response.err) {
      console.error(response.err);
    } else {
      console.log(`---- set`, params)
    }
  }

  console.log('-- SDViewer Component', {containerSD: containerSD});

  useEffect(() => {
    let _container = containerSD.current!;
    console.log('---- element rendered:', _container);
    // https://viewer.shapediver.com/v2/2.2.0/doc/SDVApp.ParametricViewer.html
    let settings = {
      // "container" is an array of containers (for multiple viewports)
      container: [_container],
      showSceneMode: 1 // do not show the scene automatically
    };

    async function ini() {
      if (!initialized) {
        window.api = new window.SDVApp.ParametricViewer(settings)!;
        await window.api.plugins.registerCommPluginAsync({
          ticket: props.ticket,
          modelViewUrl: props.modelViewUrl,
          // runtime id to use for this CommPlugin (you might register several)
          runtimeId: 'CommPlugin_1',
          // tell the viewer to wait with loading of geometry
          deferGeometryLoading: true
        });
        console.log('ShapeDiver CommPlugin successfully loaded');

        const parameters = window.api.parameters.get().data;
        console.log('---- model parameters', parameters);

        // refresh (load geometry), because the initial parameter update might not have changed any values
        await window.api.plugins.refreshPluginAsync('CommPlugin_1');
        setInitialized(true);
      }

      await update_params_from_roomspec(window.api, roomSpec);
      await window.api.updateSettingAsync('scene.show', true);
    }

    ini();
  }, [props.ticket, roomSpec.dimensionWidth, roomSpec.dimensionLength, roomSpec.doorSpecification!.xCoordinate]);

  return (
    <div ref={containerSD} style={{width: '100%', height: '100%'}}/>
  );
}

export default SDViewer;
