// prettier-ignore
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
	text?: string;
}

function ErrorContainer(props: Props) {
	const { text } = props;
	const classes = useStyles();
	const isError = text !== '' && text !== null && text !== undefined;

	return (
		<Collapse in={isError} className={classes.errorContainer}>
			<Typography className={classes.collapseText}>{text}</Typography>
		</Collapse>
	);
}

const useStyles = makeStyles({
	collapseText: {
		padding: 2,
		color: 'white',
	},

	errorContainer: {
		backgroundColor: 'red',
	},
});

export default ErrorContainer;
