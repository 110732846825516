import createReducer from "./createReducer";
import {Action, ActionType} from "../model/model";

export const shapediver = createReducer<null | boolean>(
  null,
  {
    [ActionType.SHAPEDIVER](state: number, action: Action<any>) {
      const aussenlaenge_in_mm = action.payload.aussenlaenge * 1000;
      return {
        aussenlaenge: aussenlaenge_in_mm
      };
    },
  });
