import {makeStyles, Paper, Theme} from '@material-ui/core';
import * as React from 'react';

interface Props {
  name: string;
  img: string;
  onClick?: () => void;
  selected?: boolean;
}

function VariantButton(props: Props) {
  const classes = useStyles();
  const [hover, setHover] = React.useState(false);
  let className = classes.root;
  if (!props.selected) {
    className += " " + classes.disabled;
  }

  return (
    <Paper
      className={className}
      square
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseOut={() => setHover(false)}
      elevation={hover ? 4 : props.selected ? 8 : 0}
      onClick={props.onClick}
    >
      <img src={props.img} alt={props.name}/>
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 89,
    width: 89,
  },
  disabled: {
    opacity: .3,
    '&:hover': {
      opacity: 1,
    },
  },

}));

export default VariantButton;
