//prettier-ignore
import { Action, ActionType, ConfigValidation, RoomElementCoords } from "../model/model";
import createReducer from './createReducer';

const defaultConfigValidation: ConfigValidation = {
	overlappingDoorAreaCoords: [],
};

export const configValidation = createReducer<ConfigValidation>(defaultConfigValidation, {
	[ActionType.UPDATE_OVERLAPPING_AREAS_COORDINATES](
		state: ConfigValidation,
		action: Action<Array<RoomElementCoords>>
	) {
		return { ...state, overlappingDoorAreaCoords: action.payload };
	},
});
