// prettier-ignore
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	isDialogOpen: boolean;
	setDialogOpen: (b: boolean) => void;
	onConfirmedClose: () => void;
}

function ResetProductSelectionDialog(props: Props) {
	const classes = useStyles(props);

	const { isDialogOpen, setDialogOpen, onConfirmedClose } = props;
	const { t } = useTranslation();

	const handleClose = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		let actionValue = ev.currentTarget.value;
		if (actionValue === 'OK') {
			onConfirmedClose();
		}
		setDialogOpen(false);
	};
	return (
		<div className={classes.root}>
			<Dialog onClose={handleClose} aria-labelledby="confirmation-dialog-title" open={isDialogOpen}>
				<DialogTitle id="confirmation-dialog-title">
					{t('steps.productSelection.resetSelectionConfirmDialogTitle')}
				</DialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom>
						{t('steps.productSelection.resetSelectionConfirmDialogContent')}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} value="OK" color="primary">
						{t('steps.productSelection.resetSelectionConfirmDialogOK')}
					</Button>
					<Button onClick={handleClose} value="Cancel" color="primary">
						{t('cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: (props: Props) => ({}),
}));

export default ResetProductSelectionDialog;
