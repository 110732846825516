import { UnityContent } from 'react-unity-webgl';

// documentation for the unity function can is under following link
// https://gitlab.tjiko.de/Tjiko-Digital/software/gestaltungskonfigurator/wikis/Browser-Communication-Manager

export class UnityFunctions {
	private uc: UnityContent;

	constructor(unityContent: UnityContent) {
		this.uc = unityContent;
	}

	/// Params: string json (item object, needs "id")
	/// This function changes the current item with the selected item.
	changeItem(item: { id: number }) {
		console.log('Send item to Unity: ', item);
		this.uc.send('InsertManager', 'M_ChangeItem', JSON.stringify(item));
	}

	/// Params: string json (texture object, needs "id")
	/// This function changes the current tile texture with the selected tile texture.
	changeTileTexture(texture: { id: number }) {
		this.uc.send('InsertManager', 'M_ChangeTileTexture', JSON.stringify(texture));
	}

	/// This function changes the tile height to room height (RHO).
	setTilesToRoomHeight() {
		this.uc.send('BrowserComManager', 'M_SetTilesRHO');
	}

	/// This function changes the tile height to 120cm (OneTwenty).
	setTilesTo120() {
		this.uc.send('BrowserComManager', 'M_SetTilesOneTwenty');
	}

	/// This function changes the tile height to sockel (SOCK).
	setTilesToSock() {
		this.uc.send('BrowserComManager', 'M_SetTilesSOCK');
	}

	/// Params: bool state (true or false)
	/// This function changes the selection of tiled shower wall or wedi wall.
	setShowerTilesOrShowerWedi(wediOnShower: boolean) {
		this.uc.send('BrowserComManager', 'M_ToggleWediWall', JSON.stringify(wediOnShower));
	}

	/// Params: int id, string category (id of current item, category of selected item, category "Tiles" for wall or floor tiles)
	/// This function sends infos to a react function to show the selection for the given category.
	onItemDoubleClick(callback: (item: { id: number; category: string }) => void) {
		this.uc.on('ChangeItem', callback);
	}

	/// get the complete item list from unity
	/// Params: string json
	/// This function sends json with all inserted items and attachements as well as selected wall and floor tiles.
	onItemList(callback: (items: any) => void) {
		this.uc.on('SendEquipmentList', (data: any) => callback);
	}

	/// sends the price differnce
	/// Params: string json (eg. {"priceDifference":-38.80})
	/// This function sends a json with the calculated price difference.
	onPriceDifference(callback: (data: any) => void) {
		this.uc.on('SendPriceDifference', (data: any) => callback);
	}
}
