// prettier-ignore
import { Theme } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import DimensionsView from '../components/structureElementViews/DimensionsView';
import DoorSpecificationView from '../components/structureElementViews/DoorSpecificationView';
import InteriorDetailsConfigContainer from '../components/structureElementViews/interiorDetails/InteriorDetailsConfigContainer';
import { Route } from '../model/model';
import { RootState } from '../reducers';
import EquiRectPage from "./EquiRectPage";
interface Props extends RouteComponentProps<void> {}

function StructuralElementsPage(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	// if user is not authenticated -> redirect to login page
	const { history } = useReactRouter();
	const token: string = useSelector((state: RootState) => state.token);
	if (!token) {
		history.push('/');
		return <div />;
	}

	//important note!: the order tab routs in this array has to match the order of the HTML Views bellow, e.g. the first view is Dimensionsview and the first element in the tabRoutes array is the rout to the dimensions view
	const tabRoutes = [
		Route.STRUCTURAL_ELEMENTS.toString(),
		Route.STRUCTURAL_ELEMENTS_DOOR_SPECIFICATION.toString(),
		//Route.STRUCTURAL_ELEMENTS_WINDOWS_SPECIFICATION.toString(),
		Route.STRUCTURAL_ELEMENTS_INTERIOR_DETAILS.toString(),
	];

	const handleChangeIndex = (index: number) => {
		props.history.push(tabRoutes[index]);
	};

	const getViewIndexFromRoute = (route: string) => {
		return tabRoutes.indexOf(route) === -1 ? 0 : tabRoutes.indexOf(route);
	};

	const index = getViewIndexFromRoute(props.history.location.pathname);

	switch (index) {
		case 0:
			return <DimensionsView {...props} />;
		case 1:
			return <DoorSpecificationView {...props} />;
		case 2:
			return <EquiRectPage {...props} />;
		default:
			return <></>;
	}
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
	},
	viewContainer: {
		padding: 30,
		display: 'flex',
		justifyContent: 'flex-start',
	},
}));

export default StructuralElementsPage;
