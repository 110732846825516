// prettier-ignore
import { makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
	text: string;
	isVisible: boolean;
	isErrorMessage: boolean;
}

function CustomMessageBox(props: Props) {
	const { text, isErrorMessage, isVisible } = props;
	const classes = useStyles(props);
	if (!isVisible) {
		return <div />;
	}

	return (
		<Paper className={classes.root}>
			<div className={isErrorMessage ? classes.errorMessageBox : classes.infoMessageBox}>
				<Typography>{text}</Typography>
			</div>
		</Paper>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: '10px',
		border: '1px grey solid',
		margin: 'auto',
		marginTop: '10px',
		marginBottom: '10px',
		maxWidth: '500px',
		textAlign: 'center',
	},
	errorMessageBox: {
		color: '#ca0000',
	},
	infoMessageBox: {},
}));

export default CustomMessageBox;
