/**
 * This file contains the logic for executing the validation middleware (validation of fields and updating step progress)
 */

//prettier-ignore
import { AnyAction, Dispatch } from "redux";
import traverse from 'traverse';
import * as ValidationActions from '../../actions/fieldValidator';
import * as StepActions from '../../actions/step';
//prettier-ignore
import { Step, StepId, SubStepId, ValidationField, ValidationResult } from "../../model/model";
import { RootState } from '../../reducers';
import { validationFunctions } from './fieldValidationFunctions';

/**
 *
 * @param {ValidationField[]} fieldsToValidate
 * array of fields which should be validated
 * @param {{}} jsonObjectForValidation
 * RoomSprec redux state as JSON object. Field validation is based on the propertyname of the ValidationField.
 * E.g. the propertyname "dimensionLength.value" would represent the value "2" of following JSON oject
 * {
 * 	dimensionLenght:
 *		{
 * 			unit:"cm",
 * 			value:2
 *	 	}
 * }
 * @param {(field: ValidationField, result: ValidationResult) => void} handleResult
 * Callback which will be executed after each field validation with the result as callback parameter
 */
function validateFields(
	fieldsToValidate: ValidationField[],
	jsonObjectForValidation: {},
	handleResult: (field: ValidationField, result: ValidationResult) => void
) {
	fieldsToValidate.forEach((field) => {
		let propPath = field.propertyName.split('.');

		//get the value to validate of the jsonobject by a property path
		let value: any = traverse(jsonObjectForValidation).get(propPath);

		//if validation field is a combined field the value is a array of all the value for each property in propertyName seperated by "|"
		if (field.combinedField) {
			let props = field.propertyName.split('|');
			let allPropsValues: String[] = [];
			props.forEach((prop) => {
				let propPath = prop.split('.');
				allPropsValues.push(traverse(jsonObjectForValidation).get(propPath));
			});
			value = allPropsValues;
		}

		let result: ValidationResult = {
			isValid: true,
			message: '',
		};

		//execute all validation functions registered for this validation field
		for (let i = 0; i < field.errorValidationFunctionNames.length; i++) {
			let validationFunctionName = field.errorValidationFunctionNames[i];
			let func = validationFunctions[validationFunctionName];
			result = func(value, field.combinedField, field.errorValidationFunctionParams);
			handleResult(field, result);
		}
	});
}

/**
 *
 *
 * @param {ValidationField[]} validationFields
 *
 * @returns {ValidationField[]}
 */
export function validateNewlyRegisteredFields(
	validationFields: ValidationField[],
	state: RootState
): ValidationField[] {
	const { roomSpec } = state;

	//callback to handle validation result,  sets initial validation errorText (error text is empty if validation was successfull)
	const handleResult = (field: ValidationField, result: ValidationResult) => {
		field.errorText = result.message;
	};
	validateFields(validationFields, roomSpec, handleResult);
	return validationFields;
}

interface StepProgress {
	[key: string]: {
		sumAllFields: number;
		sumValidFields: number;
		validatedSubSteps: { [subSetId: string]: boolean };
	};
}

export function validateRoomSpec(jsonObjectForValidation: {}, state: RootState, dispatcher: Dispatch<AnyAction>) {
	const { fieldValidation, stepList } = state;

	//stores valiation fields with result foreach step
	let progressByStep: StepProgress = {};

	let updatedFields: ValidationField[] = [];

	//callback to set errorText prepare the step progress validation
	const handleResult = (field: ValidationField, result: ValidationResult) => {
		updateProgressByStep(progressByStep, field, result);

		let updatedField = { ...field };
		updatedField.errorText = result.message;
		updatedFields.push(updatedField);
	};
	validateFields(fieldValidation, jsonObjectForValidation, handleResult);

	//hooks cannot be used in a middleware, the actions have to be dispatched like this
	dispatcher(ValidationActions.updateFieldValidations(updatedFields));

	updateStepProgress(progressByStep, stepList, dispatcher);
}

function updateProgressByStep(
	progressByStep: StepProgress,
	field: ValidationField,
	validationResult: ValidationResult
) {
	let stepIdAsString = StepId[field.stepId];

	if (!progressByStep[stepIdAsString]) {
		progressByStep[stepIdAsString] = {
			sumAllFields: 0,
			sumValidFields: 0,
			validatedSubSteps: {},
		};
	}

	let stepProgress = progressByStep[stepIdAsString];

	stepProgress.sumAllFields++;
	if (validationResult.isValid) {
		stepProgress.sumValidFields++;
	}
	//following lines check if a substep is mapped to this field and stores the result value.
	//If one value is false, the falsy result will be saved and the substep is marked as incomplete
	if (field.subStepId !== undefined) {
		let subStepIdAsString = SubStepId[field.subStepId];
		if (
			stepProgress.validatedSubSteps[subStepIdAsString] === undefined ||
			stepProgress.validatedSubSteps[subStepIdAsString] === true
		) {
			stepProgress.validatedSubSteps[subStepIdAsString] = validationResult.isValid;
		}
	}
}

/**
 * calculates the step progress foreach step and dispatches a step update action
 * The step update action reducer enables a step, if all previous steps have a progress of 100%
 */
function updateStepProgress(progressByStep: StepProgress, stepList: Step[], dispatcher: Dispatch<AnyAction>) {
	for (let stepIdAsString in progressByStep) {
		let stepProgress = progressByStep[stepIdAsString];
		let step = getStepById((StepId as any)[stepIdAsString], stepList)!;
		if (step.disabled) {
			continue;
		}
		let progress = (stepProgress.sumValidFields / stepProgress.sumAllFields) * 100;
		if (step.subSteps) {
			step.subSteps = step.subSteps.map((subStep) => {
				let subStepIdAsString = SubStepId[subStep.id];
				if (stepProgress.validatedSubSteps[subStepIdAsString] !== undefined)
					subStep.completed = stepProgress.validatedSubSteps[subStepIdAsString];
				return subStep;
			});
		}

		//hooks cannot be used in a middleware, therefore the actions have to be dispatched like this
		dispatcher(StepActions.updateStep({ ...step, progress }));
	}
}

function getStepById(stepId: StepId, stepList: Step[]): Step | undefined {
	return stepList.find((el: Step) => el.id === stepId);
}
