// prettier-ignore
import { makeStyles, Theme, Typography } from '@material-ui/core';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import classnames from 'classnames';
import * as React from 'react';
import { isMobile } from '../../responsive';
import ErrorContainer from './ErrorContainer';
import Icon from './Icon';
import InfoContainer from './InfoContainer';
import NumberFieldWrapper from './NumberFieldWrapper';
import UnitSwitch from './UnitSwitch';

interface Props extends WithWidth {
	value?: number;
	editable?: boolean;
	label?: string;
	units?: string[]; // e.g. ['€', '%']
	selectedUnit?: string;
	numberBackgroundColor?: {
		notEditable: string;
		editable: string;
		editing: string;
	};
	onUnitChange?: (unit: string) => void;
	infoText?: string;
	warningText?: string;
	onChange?: (value: number) => void;
	onKeyPress?: (key: string) => void;
	bold?: boolean;
	borderBottom?: boolean;
	isEditing?: boolean;
	errorText?: string;
	decimalDigits?: number;
	onFocusCapture?: Function;
	disabled?: boolean;
	undefinedValuePlaceholder?: string; // default is '-'
}

function CaclulationRow(props: Props) {
	const {
		selectedUnit,
		units,
		borderBottom,
		disabled,
		editable,
		errorText,
		label,
		width,
		onFocusCapture,
		bold,
		infoText,
		warningText,
		onUnitChange,
		value,
		onChange,
	} = props;
	const classes = useStyles();
	const [isEditing, setIsEditing] = React.useState(props.isEditing ? props.isEditing : false);
	const [currentUnit, setCurrentUnit] = React.useState(selectedUnit ? selectedUnit : units ? units[0] : '');
	const [isInfoContainerOpen, setIsInfoContainerOpen] = React.useState(false);
	const decimalDigits = props.decimalDigits !== undefined ? props.decimalDigits : 2;

	const receiveFocus = () => {
		if (!isEditing) {
			setIsEditing(true);

			if (onFocusCapture) {
				onFocusCapture();
			}
		}
	};

	const onFinished = (v: number) => {
		setIsEditing(false);
		if (onChange) {
			onChange(v);
		}
	};

	const onUnit = (u: string) => {
		setCurrentUnit(u);
		if (onUnitChange) {
			onUnitChange(u);
		}
	};

	return (
		<div className={classnames(classes.root, borderBottom && classes.borderBottom)}>
			<div
				className={classnames(classes.horizontalContainer)}
				onFocusCapture={receiveFocus}
				tabIndex={editable && !disabled ? 0 : -1}
				style={{
					backgroundColor: errorText ? '#FFF0F0' : warningText ? '#ffe3ba' : 'white',
				}}
			>
				{label && (
					<div className={classes.labelContainer}>
						<Typography
							variant="subtitle1"
							className={classnames(classes.text, bold && classes.bold, disabled && classes.disabledText)}
						>
							{label}
						</Typography>
						<Icon onClick={setIsInfoContainerOpen} text={infoText} disabled={disabled} iconType="info" />
						<Icon onClick={() => {}} text={warningText} disabled={disabled} iconType="warning" />
					</div>
				)}
				<NumberFieldWrapper
					{...props}
					value={value}
					currentUnit={currentUnit}
					decimalDigits={decimalDigits}
					receiveFocus={receiveFocus}
					isEditing={isEditing}
					onFinished={onFinished}
				/>
			</div>
			<UnitSwitch units={units} selectedUnit={currentUnit} onChange={onUnit} />
			{isMobile(width) && <InfoContainer text={infoText} open={isInfoContainerOpen} />}
			{isMobile(width) && <ErrorContainer text={errorText} />}
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
	},
	borderBottom: {
		borderBottomStyle: 'dashed',
		borderBottomColor: '#d3d3d3',
		borderBottomWidth: '1px',
	},

	horizontalContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		backgroundColor: '#ffffff',
		padding: '5px',
		alignItems: 'center',
	},

	labelContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},

	text: {
		marginTop: 10,
		marginLeft: 10,
		marginBottom: 10,
		marginRight: 10,
	},

	bold: {
		fontWeight: 'bold',
	},

	disabledText: {
		color: 'lightgrey',
	},
}));

export default withWidth()(CaclulationRow);
