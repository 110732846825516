// prettier-ignore
import { Button, makeStyles, Theme } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { Step } from '../../../model/model';
import { RootState } from '../../../reducers';
import StepValidationDialog from '../../validation/StepValidationDialog';
import ConfigFieldContainer from './ConfigFieldContainer';

interface Props {
	nextRoute?: string;
	headerActions?: React.ReactNode | React.ReactNode[];
	bottomActions?: React.ReactNode | React.ReactNode[];
}

function ConfigNextButton(props: Props) {
	const { nextRoute, bottomActions } = props;

	return <BottomActions nextRoute={nextRoute} actions={bottomActions} />;
}

function BottomActions(props: { nextRoute?: string; actions?: React.ReactNode | React.ReactNode[] }) {
	const classes = useStyles(props);
	const stepList: Step[] = useSelector((state: RootState) => state.stepList);
	const [openDialog, setOpenDialog] = React.useState(false);
	const { nextRoute, actions } = props;
	const { t } = useTranslation();
	const { history } = useReactRouter();
	const nextStep = nextRoute ? stepList.find((step) => step.route === nextRoute) : undefined;

	const onNextRoute = () => {
		if (nextRoute) {
			if (nextStep && nextStep.disabled) {
				setOpenDialog(true);
			} else {
				history.push(nextRoute);
			}
		}
	};
	if (nextRoute || actions) {
		return (
			<ConfigFieldContainer>
				<div className={classes.bottomContainer}>
					{nextStep && (
						<StepValidationDialog
							{...props}
							step={nextStep}
							open={openDialog}
							onClose={() => setOpenDialog(false)}
						/>
					)}
					{actions}
					{nextRoute && (
						<Button variant="contained" color="secondary" onClick={onNextRoute} style={{ width: '100%' }}>
							{t('next')}
							<ForwardIcon color="inherit" className={classes.iconRight} />
						</Button>
					)}
				</div>
			</ConfigFieldContainer>
		);
	} else {
		return <></>;
	}
}

const useStyles = makeStyles((theme: Theme) => ({
	bottomContainer: {
		marginTop: 30,
		marginBottom: 40,
		height: 60,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	iconRight: {
		marginLeft: 5,
	},
}));

export default ConfigNextButton;
