//prettier-ignore
import { Action, ActionType, ValidationField } from "../model/model";
import createReducer from './createReducer';

const addOrUpdateValidationFields = (
	state: ValidationField[],
	action: Action<ValidationField[]>
): ValidationField[] => {
	let updatedState = [...state];
	action.payload.forEach((field: ValidationField) => {
		let fieldIndex = state.findIndex((el) => el.propertyName === field.propertyName);
		if (fieldIndex > -1) {
			updatedState[fieldIndex] = field;
		} else {
			updatedState.push(field);
		}
	});
	return updatedState;
};

export const fieldValidation = createReducer<ValidationField[]>([], {
	[ActionType.UPDATE_FIELD_VALIDATIONS](state: ValidationField[], action: Action<ValidationField[]>) {
		return addOrUpdateValidationFields(state, action);
	},
	[ActionType.REGISTER_FIELD_VALIDATIONS](state: ValidationField[], action: Action<ValidationField[]>) {
		return addOrUpdateValidationFields(state, action);
	},
});
