import 'core-js';
// polyfills for IE 11
import 'core-js/es/array/find';
import 'core-js/es/array/find-index';
import 'core-js/es/array/from';
import 'core-js/es/map';
import 'core-js/es/number/is-finite';
import 'core-js/es/number/parse-float';
import 'core-js/es/number/parse-int';
import 'core-js/es/object/values';
// polyfills for IE 11 and React.lazy
// https://github.com/facebook/react/issues/14583
import 'core-js/es/promise';
import 'core-js/es/set';
import 'core-js/es/string/includes';
import 'core-js/es/string/starts-with';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReduxRoot from './ReduxRoot';
require('es6-promise').polyfill();

const rootEl = document.getElementById('root');
ReactDOM.render(
	<React.Suspense fallback="loading">
		<ReduxRoot />
	</React.Suspense>,
	rootEl
);

// comment in for PWA with service worker in production mode
// registerServiceWorker();
