import { validationFunctions } from '../middleware/fieldValidation/fieldValidationFunctions';
export enum WallType {
	IN_FRONT_OF_EXTERIOR = 'steps.structuralElements.inFrontOfExterior',
	IN_FRONT_OF_INTERIOR = 'steps.structuralElements.inFrontOfInterior',
	FREE_STANDING = 'steps.structuralElements.freeStanding',
}

export enum WallAlignment {
	NORTH = 'NORTH',
	SOUTH = 'SOUTH',
	WEST = 'WEST',
	EAST = 'EAST',
}

export enum FeatureElementCat {
	SHOWER = 'SH',
	SINK = 'SI',
	WC = 'WC',
}

export enum StepId {
	PRODUCT_SELECTION,
	STRUCTURAL_ELEMENTS,
	TECHNICAL_DETAILS,
	FEATURE_ELEMENTS,
	ACCESSORY_ELEMENTS,
	OVERVIEW,
}

export enum SubStepId {
	TECHNICAL_DETAILS_ELECTRICAL,
	TECHNICAL_DETAILS_HEATING,
	TECHNICAL_DETAILS_VENTILATION,
	STRUCTURAL_ELEMENTS_DIMENSION,
	STRUCTURAL_ELEMENTS_DOOR_SPECIFICATION,
	STRUCTURAL_ELEMENTS_INTERIOR_DETAILS,
	PRODUCT_SELECTION_PRODUCT_CATEGORIES,
	PRODUCT_SELECTION_PRODUCT_FAMILIES,
	PRODUCT_SELECTION_PRODUCT_TYPES,
}

export enum ActionType {
	UPDATE_ROOMSPEC = 'UPDATE_ROOMSPEC',
	RESET_ROOMSPEC = 'RESET_ROOMSPEC',
	POST_ROOMSPEC = 'POST_ROOMSPEC',
	SET_ROOMSPEC_ID = 'SET_ROOMSPEC_ID',
	SEND_ROOMSPEC_TO_SERVER = 'SEND_ROOMSPEC_TO_SERVER',
	SET_ROOMSPEC_PRICE = 'SET_ROOMSPEC_PRICE',
	UPDATE_STEP = 'UPDATE_STEP',
	UPDATE_APP_CONFIG = 'UPDATE_APP_CONFIG',
	UPDATE_FIELD_VALIDATIONS = 'UPDATE_FIELD_VALIDATIONS',
	REGISTER_FIELD_VALIDATIONS = 'REGISTER_FIELD_VALIDATIONS',
	UPDATE_SUB_STEP = 'UPDATE_SUB_STEP',
	GET_USER_TOKEN = 'GET_USER_TOKEN',
	SET_TOKEN = 'SET_TOKEN',
	SET_AUTH_ERROR = 'SET_AUTH_ERROR',
	GET_PASSWORD_RESET_LINK = 'GET_PASSWORD_RESET_LINK',
	GET_APP_CONFIG = 'GET_APP_CONFIG',
	RESET_STEPS = 'RESET_STEPS',
	UPDATE_OVERLAPPING_AREAS_COORDINATES = 'UPDATE_OVERLAPPING_AREAS_COORDINATES',

	LOAD_ELEMENTS = 'LOAD_ELEMENTS',
	LOAD_TILES = 'LOAD_TILES',
	SELECTORLIST_RESET = 'SELECTORLIST_RESET',

	SET_UNITS = 'SET_UNITS',
  SHAPEDIVER = 'SHAPEDIVER',
}

export enum Route {
	PRODUCT_SELECTION = '/productSelection',
	PRODUCT_SELECTION_PRODUCT_FAMILIES = '/productSelection/productFamilies',
	PRODUCT_SELECTION_PRODUCT_TYPES = '/productSelection/productTypes',
	STRUCTURAL_ELEMENTS = '/structuralElements',
	STRUCTURAL_ELEMENTS_ACCESSORY_ELEMENTS = '/structuralElements/accessoryElements',
	STRUCTURAL_ELEMENTS_DOOR_SPECIFICATION = '/structuralElements/doorSpecification',
	STRUCTURAL_ELEMENTS_INTERIOR_DETAILS = '/structuralElements/interiorDetails',
	TECHNICAL_DETAILS = '/technicalDetails',
	TECHNICAL_DETAILS_ELECTRICAL = '/technicalDetails/electrical',
	TECHNICAL_DETAILS_HEATING = '/technicalDetails/heating',
	TECHNICAL_DETAILS_VENTILATION = '/technicalDetails/ventilation',
	OVERVIEW = '/overview',
	EXPERT = '/expert',
	RECEIVE_UNITS = '/setUnits',
	LOAD_ROOMSPEC = '/loadRoomSpec',
}

export enum Unit {
	METER = 'm',
	CENTI_METER = 'cm',
	MILLI_METER = 'mm',
}

export enum DoorType {
	SLIDING,
	NORMAL,
}

export enum WallSwitchType {
	SAFETY_DOUBLE_SOCKET,
	SINGLE_SOCKET,
	SINGLE_SWITCH,
}

export interface SelectableElement {
	id: string;
	name: string;
}

export interface ProductCategory {
	id: string;
	name: string;
	imageUrl: string;
	descriptionText: string;
	productFamilies: ProductFamily[];
}

export type EquipmentLineId = '' | 'S' | 'M' | 'L';

export interface EquipmentLine {
	id: EquipmentLineId;
	name: string;
	mainImageUrl: string;
	descriptionText: string;
	exampleImages: { imageTitle: string; imageUrl: string }[];
}

export interface ProductFamily {
	id: string;
	name: string;
	imageUrl: string;
	descriptionText: string;
	productFamilyVariants: ProductFamilyVariant[];
}

export interface ProductFamilyVariant {
	id: string;
	name: string;
	imageUrl: string;
	descriptionText: string;
	defaultRoomData: RoomSpec;
	roomConfiguration: RoomConfiguration;
}

export interface Step {
	id: StepId;
	name: string;
	description: string;
	progress?: number;
	disabled?: boolean;
	route: Route;
	requiredCompletedSteps: StepId[];
	subSteps?: SubStep[];
}

export interface SubStep {
	id: SubStepId;
	name: string;
	disabled?: boolean;
	completed: boolean;
	requiredCompletedSubSteps?: SubStepId[];
	route: Route;
}

export interface LengthUnit {
	/**
	 * value of Length Unit
	 * */
	value: number;
	/**
	 * Unit of Length Unit ("m","cm" or "mm")
	 * */
	unit: Unit;
}

export interface Wall extends SelectableElement {
	/**
	 * name of wall (translation key)
	 * */
	name: string;
	/**
	 * Walltype to define if wall is free standing or in front of interior / exterior wall
	 */
	wallType: WallType;
	/**
	 * Alignment of wall (Souht, West, East or North)
	 * */
	wallAlignment: WallAlignment;

	thicknessCM?: number;
}

export interface CheckBoxElement {
	id: string;
	name: string;
	isSelected: boolean;
}

export interface AccessoryElement extends CheckBoxElement {}

export interface FeatureElement {
	/**
	 * id of selected  element
	 */
	elementId: string;
	/**
	 * translation key of for display name of element (key has to exist in translation file)
	 */
	name: string;
	/**
	 * wall alignment of wall on which the element is placed / installed
	 */
	wallAlignment: WallAlignment;
	/**
	 * height/depth of element (in two dimensional view). Height/Depth is  the side of the element which is perpendicular to the aligned wall
	 */
	elementHeight: LengthUnit;
	/**
	 * width of element. widht is the side of the element that is parallel to the aligned wall
	 */
	elementWidth: LengthUnit;
	/**
	 * xCoordinate of the center of element
	 */
	xCoordinate: LengthUnit;
	/**
	 * yCoordinate of the center of element
	 */
	yCoordinate: LengthUnit;
	/**
	 * yCoordinate of the center of the elements movement area
	 */
	movementAreaYCoordinate: LengthUnit;
	/**
	 * xCoordinate of the center of the elements movement area
	 */
	movementAreaXCoordinate: LengthUnit;
	/**
	 * width the elements movement area.  widht is the side of the movement area that is parallel to the aligned wall
	 */
	movementAreaWidth: LengthUnit;
	/**
	 * height the elements movement area.  height is the side of the movement area that is perpendicular to the aligned wall
	 */
	movementAreaHeight: LengthUnit;
	/**
	 * feature element category like "SH","WC" or "SI"
	 */
	featureElementCategory: string;
	/**
	 * sanitary type of the element, used to define the size group ("SH 80x80", "SH 100x100", "SI", "Double SI")
	 */
	sanitaryType: string;
	/**
	 * optional minimum offset to other elements as dictionary with the sanitary type of the other element as key
	 */
	offsets?: { [sanitaryTypeForOffset: string]: LengthUnit };
}

export interface DimensionRange extends SelectableElement {
	startValue: number;
	endValue: number;
	unit: string;
}

export interface ElementPackage extends SelectableElement {
	name: string;
}

export interface ModuleShape extends SelectableElement {
	name: string;
}

export interface DoorSpecification {
	/*
	 * id of the selected door
	 */
	id: string;
	/*
	 * name (translation key) of the selected door
	 */
	name: string;
	/*
	 * frame height of the wall element
	 */
	frameHeight: LengthUnit;
	/*
	 *frame width of the wall element
	 */
	frameWidth: LengthUnit;
	/*
	 * xCoordinate of the door position (only relevant if door is on an north or south wall)
	 */
	xCoordinate: LengthUnit;
	/*
	 * yCoordinate of the door position (only relevant if door is on an east or west wall)
	 */
	yCoordinate: LengthUnit;
	/**
	 * wall where door is positioned
	 */
	wall?: Wall;
	/**
	 * selected door image id (currently not further defined)
	 */
	selectedImageId: string;
	/**
	 * depth of door
	 */
	depth: LengthUnit;
	/**
	 * door type (in MVP only "regular", but later "sliding" is planned)
	 */
	doorType: DoorType;
	/**
	 * types for the door frame: eg. "surroundingFrame", "blockFrame"
	 */
	frameType: string;
	/**
	 * opening direction of the door
	 *
	 */
	openingDirectionLeftRight: 'left' | 'right';
	/**
	 * swinging direction of the door (currently for MVP always outside)
	 */
	swingingDirectionInsideOutside?: 'inside' | 'outside';
	/**
	 * width of the door
	 */
	width: LengthUnit;
	/**
	 * height of the door
	 */
	height: LengthUnit;
}

export interface WallSwitch {
	/**
	 * id of wall switch or socket
	 */
	id: string;
	/**
	 * height of wall switch or socket (three dimensional view)
	 */
	height: LengthUnit;
	/**
	 * widht of wall switch or socket. The width is always parallel to the aligned wall
	 */
	width: LengthUnit;
	/**
	 * type of wall switch / socket
	 */
	type: WallSwitchType;
	/*
	 * xCoordinate of the switch position (only relevant if switch is on an north or south wall)
	 */
	xCoordinate: LengthUnit;
	/*
	 * yCoordinate of the switch position (only relevant if switch is on an east or west wall)
	 */
	yCoordinate: LengthUnit;
	/**
	 * wall alignment of wall where switch is positioned
	 */
	wallAlignment: WallAlignment;
}

export interface SocketConfig {
	doubleSocketWidth: LengthUnit;
	doubleSocketSinkHeight: LengthUnit;
	singleSocketWidth: LengthUnit;
	switchWidth: LengthUnit;
	doorSwitchHeight: LengthUnit;
	doorSocketHeight: LengthUnit;
	marginSocketSwitchToDoor: LengthUnit;
	marginSocketSwitchToWall: LengthUnit;
}

export interface WallConfig {
	wallThicknessWallWithToilet: LengthUnit;
	wallThicknessWallWithBasinOrShower: LengthUnit;
	wallThicknessWallDefault: LengthUnit;
	roomWallThicknessExterior: LengthUnit;
	roomWallThicknessInterior: LengthUnit;
}

export interface SillType extends SelectableElement {}

export interface RoomConfiguration {
	minShorterDimensionLength: LengthUnit;
	minLongerDimensionLength: LengthUnit;
	maxShorterDimensionLength: LengthUnit;
	maxLongerDimensionLength: LengthUnit;
	roomHeights: { min: LengthUnit; max: LengthUnit };
	subFloorHeights: { min: LengthUnit; max: LengthUnit };
	ceilingHeight: LengthUnit;
	featureElements: FeatureElementCategoryConfig[];
	acccessoryElements: AccessoryElement[];
	doors: DoorSpecification[];
	frameTypes: string[];
	doorHeights: LengthUnit[];
	doorWidths: LengthUnit[];
	doorImageSelection: SelectableImage[];
	equipmentLines: EquipmentLine[];
	isSinkAndWCPositionSwapped: boolean;
	showerWallPositionLeftRight: 'left' | 'right';
	socketConfig: SocketConfig;
	lightingTypes: string[];
	radiatorTypes: string[];
	/// doorSpaceToWcOrSink can be also negative: then it's overlapping as in the previous version: maxAllowedOverlapBetweenDoorAndHeightOfElementOnAdjacentWall
	doorSpaceToWcOrSink: LengthUnit;
	wallConfig: WallConfig;
	radiatorWidth: LengthUnit;
	radiatorHeight: LengthUnit;
	marginRadiatorToWall: LengthUnit;
	marginRadiatorToSinkForSukiCommon: LengthUnit;
	tileHeightHalfRoomHeight: LengthUnit;
	tileHeightBaseTiles: LengthUnit;
	ventilationTypes: string[];
}

export interface FeatureElementConfig {
	id: string;
	name: string;
	sanitaryType: string;
	width: LengthUnit;
	length: LengthUnit;
	movementAreaWidth: LengthUnit;
	movementAreaLength: LengthUnit;
	offsets?: { [sanitaryTypeForOffset: string]: LengthUnit };
}

export interface FeatureElementCategoryConfig {
	categoryId: string;
	categoryName: string;
	elements: FeatureElementConfig[];
}

export interface RoomSpec {
	/**
	 * Outer dimension length / Room Module Length
	 * */
	dimensionLength: LengthUnit;
	/**
	 * Outer dimension width  / Room Module Width
	 * */
	dimensionWidth: LengthUnit;
	/**
	 * Outer dimension Height / height of room module
	 * */
	storeyHeight: LengthUnit;
	/**
	 * Sub Floor Height / Höhe Fußbodenaufbau
	 * */
	subFloorHeight: LengthUnit;
	/**
	 * Inner Room Height / inside module height
	 * */
	roomHeight: LengthUnit;
	/**
	 * height of ceiling construction
	 * */
	ceilingHeight: LengthUnit;
	/**
	 * Wall North Properties (Wall 3)
	 * */
	wallNorth: Wall;
	/**
	 * Needed tiles for North Wall
	 * */
	wallNorthTiles: WallTiles;
	/**
	 * Wall South Properties (Wall 1)
	 * */
	wallSouth: Wall;
	/**
	 * Needed tiles for South Wall
	 * */
	wallSouthTiles: WallTiles;
	/**
	 * Wall West Properties (Wall 2)
	 * */
	wallWest: Wall;
	/**
	 * Needed tiles for West Wall
	 * */
	wallWestTiles: WallTiles;
	/**
	 * Wall East Properties (Wall 4)
	 * */
	wallEast: Wall;
	/**
	 * Needed tiles for East Wall
	 * */
	wallEastTiles: WallTiles;
	/**
	 * Selected Room Elements / Feature Elements as dictionary with element category ("SH","WC","SI") as key
	 * */
	featureElements: {
		[featureElCategory: string]: FeatureElement;
	};
	/**
	 * Selected Room Elements for pricing
	 */
	elements: any[];
	/**
	 * Selected wall tiles for pricing
	 */
	wallTiles: any;
	/**
	 * Selected shower tiles for pricing
	 */
	showerTiles: any;
	/**
	 * Selected floor tiles for pricing
	 */
	floorTiles: any;
	/*
	 * string ids of selected accessory elements
	 */
	accessoryElements: string[];
	/*
	 * door specification of selected door
	 */
	doorSpecification: DoorSpecification | null;
	/*
	 * count of electrical outlets placed at the door
	 */
	numberOfOutletsDoor: number;
	/*
	 * count of electrical outlets placed at the sink
	 */
	numberOfOutletsSink: number;
	/*
	 * type of lighting, e.g. "lightingArea", "lightingObject"
	 */
	lightingType: string;
	/*
	 * should lights be placed at the mirror
	 */
	lightingMirror: boolean;
	/*
	 * count of lamps
	 */
	lightingCount: number;
	/*
	 * type of radiator, e.g. "hotWaterRadiator", "electricalRadiator"
	 * NULL if no radiator has been selected
	 */
	radiatorType: string | null;
	/*
	 * should there be a underfloor heating
	 */
	underfloorHeating: boolean;
	/*
id of selected main equipmentLine
*/
	equipmentLineId: EquipmentLineId;
	/*
id of selected procut category
*/
	productCategoryId: string;
	/*
id of selected procut family
*/
	productFamilyId: string;
	/*
id of selected procut family variant
*/
	productFamilyVariantId: string;
	/*
selected wall switches (switches are currently only next to the door and the Sink)
*/
	wallSwitches: {
		doorSwitches: WallSwitch[];
		sinkSwitches: WallSwitch[];
	};
	/*
configuration of the radiator
NULL if no radiator has been selected
	*/
	radiatorConfig: RadiatorConfig | null;
	/*
	 * type of ventilation, e.g. "centralVentilationSystem", "singleVentilationSystem"
	 */
	ventilationType: string;
	/*
	 * shaft
	 * NULL if no shaft has been selected
	 */
	shaftConfig: ShaftConfig | null;
	/*
	 * no shaft
	 * NULL if shaft has been selected
	 */
	noShaftConfig: NoShaftConfig | null;
}

export interface ShaftConfig {
	/*
	 * cables for ventilation
	 */
	ventilation: boolean;
	/*
	 * cables for sewage (Abwasser)
	 */
	sewage: boolean;
	/*
	 * cables for drinking water
	 */
	drinkingWater: boolean;
	/*
	 * cables for heating
	 */
	heating: boolean;
	/*
	 * cables for electrical sub distribution
	 */
	electricalSubDistribution: boolean;
	/*
	 * cables for water meter
	 */
	waterMeter: boolean;
}

export interface NoShaftConfig {
	/*
	 * hand over cables on the left
	 * has to be false if cablesRight is true
	 */
	cablesLeft: boolean;
	/*
	 * hand over cables on the right
	 * has to be false if cablesLeft is true
	 */
	cablesRight: boolean;
}

export interface WallTiles {
	/**
	 * needed tiles in shower area in m²
	 * this tiles need a special mortar like ""Epoxy resin" which has a special price
	 * @minimum 0
	 * */
	tileSquareMetersInShowerArea: number;
	/**
	 * needed tiles in m² that are not in the shower area and don´t need a special mortar
	 * @minimum 0
	 * */
	tileSquareMetersNotInShowerArea: number;
	/**
	 * needed base tiles in m² for areas without other tiles
	 * These tiles have an own price per square meter
	 *
	 * @minimum 0
	 * */
	tileSquareMetersBaseTiles: number;
}

export interface RadiatorConfig {
	/*
	 * xCoordinate of the radiator position (only relevant if radiator is on an north or south wall)
	 */
	xCoordinate: LengthUnit;
	/*
	 * yCoordinate of the radiator position (only relevant if radiator is on an east or west wall)
	 */
	yCoordinate: LengthUnit;
	/*
	 * wallalignment of wall on which the radiator is positioned
	 */
	wallAlignment: WallAlignment;
}

export interface SelectableImage {
	id: string;
	src: string;
	title: string;
}

export interface Selection {
	id: string;
	name: string;
	selection_options: string[];
	image_options: string[][];
	added?: boolean;
}

export interface Action<T> {
	type: ActionType;
	payload: T;
}

export interface ValidationField {
	propertyName: string;
	errorValidationFunctionNames: (keyof typeof validationFunctions)[];
	errorValidationFunctionParams?: any;
	errorText: string;
	warningText: string;
	showValidationState?: boolean;
	stepId: StepId;
	subStepId?: SubStepId;
	//if the validation is based on multiple fields or propertys, the combinedField porperty indicates that
	// if combined Field is true, multiple property names can be set in the "propertyName" prop seperated by "|"
	combinedField?: boolean;
	//the additional route gets appendend to the step or substep route, if the error should be displayed
	additionalRoute?: string;
}

export interface ValidationResult {
	isValid: boolean;
	message: string;
}

export interface AppConfig {
	productCategories: ProductCategory[];
}

// used for calculations in floor plan drawing, all values are in cm
export interface RoomDimensions {
	wallNorthWidth: number;
	wallSouthWidth: number;
	wallEastWidth: number;
	wallWestWidth: number;
	wallNorthDepth: number;
	wallSouthDepth: number;
	wallEastDepth: number;
	wallWestDepth: number;
	//room length and width (inside values, without walls)
	moduleWidthInCM: number;
	moduleLengthInCM: number;
	roomInsideMeasure: number;
	dimensionWidth: number;
	dimensionLength: number;
	generalMarginForDimensionArrows: number;
	marginDimensionArrowsSouth: number;
	marginDimensionArrowsNorth: number;
	marginDimensionArrowsWest: number;
	marginDimensionArrowsEast: number;
	marginForRoomWalls: number;
	// (0,0) coordinates of bathroom module (area where elements can be placed)
	bathRoomModuleXZeroPoint: number;
	bathRoomModuleYZeroPoint: number;
	// (0,0) coordinates in cm of room (area where module including bath room walls are placed)
	roomXZeroPoint: number;
	roomYZeroPoint: number;
	roomWallNorthDepth: number;
	roomWallSouthDepth: number;
	roomWallWestDepth: number;
	roomWallEastDepth: number;
	roomWallNorthWidth: number;
	roomWallSouthWidht: number;
	roomWallWestWidth: number;
	roomWallEastWidth: number;
	arrowLabelMargin: number;
}

export interface RoomElementCoords {
	upperLeftCoord: Array<number>;
	lowerRightCoord: Array<number>;
}

export interface ConfigValidation {
	//outer array represents different overlapping areas, second array the array of coordinates, third array with two elements (first = xCoordinate, second = yCoordinate) in centimeters
	overlappingDoorAreaCoords: Array<RoomElementCoords>;
}

export interface RoomSpecPrice {
	value?: number;
	currency?: 'EUR' | 'USD';
	error?: string;
	loading?: boolean;
}
