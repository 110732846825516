import { Paper } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getTokenFromCookie } from '../actions/user';
import LoginForm from '../components/login/LoginForm';
import MovingBackground from '../components/login/MovingBackground';
import { RootState } from '../reducers';

interface Props extends RouteComponentProps<void> {}

function LoginPage(props: Props) {
	const classes = useStyles();
	const token: string = useSelector((state: RootState) => state.token);
	const tokenFromCookie = getTokenFromCookie();

	React.useEffect(() => {
		if (!(!!token || !!tokenFromCookie) && !window.location.origin.includes('localhost')) {
			window.location.href = window.location.origin.includes('dev')
				? 'https://projekt.tjiko-dev.de'
				: 'https://projekt.tjiko.de';
		}
	}, []);

	return (
		<Paper className={classes.root}>
			<MovingBackground
				images={[
					'/assets/landing_background/1.jpg',
					'/assets/landing_background/2.jpg',
					'/assets/landing_background/3.jpg',
					'/assets/landing_background/4.jpg',
				]}
			>
				<LoginForm />
			</MovingBackground>
		</Paper>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		// width: '1000px',
		margin: 'auto',
		width: '100%',
		height: '100%',
	},
}));

export default LoginPage;
