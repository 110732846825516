//prettier-ignore
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Theme } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useActions } from '../../actions';
import * as RoomSpecActions from '../../actions/roomSpec';
import CaclulationRow from '../../components/calculationRow/CaclulationRow';
import { RoomConfiguration, RoomSpec, Route } from '../../model/model';
import { RootState } from '../../reducers';
import ConfigFieldList from '../configuratorView/ConfigFieldList';
import ConfigDivider from '../configuratorView/fields/ConfigDivider';
import ConfigFieldContainer from '../configuratorView/fields/ConfigFieldContainer';
import ConfigNextButton from '../configuratorView/fields/ConfigNextButton';
import { getCalculatedRoomDimensions } from '../roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';

interface Props extends RouteComponentProps<void> {}

function ElectricalConfigView(props: Props) {
	const classes = useStyles();
	return (
		<ConfigFieldList>
			{/*<EletricalOutputsConfig {...props} />*/}
			<LightingConfig {...props} />
			<ConfigDivider />
			<ConfigNextButton nextRoute={Route.TECHNICAL_DETAILS_HEATING} />
		</ConfigFieldList>
	);
}

function EletricalOutputsConfig(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);

	const theme: Theme = useTheme();

	const numberBackgroundColor = {
		notEditable: theme.palette.background.paper,
		editable: theme.palette.background.default,
		editing: theme.palette.background.default,
	};

	const { numberOfOutletsDoor, numberOfOutletsSink } = roomSpec;

	return (
		<div>
			<ConfigDivider title={t('steps.technicalDetails.electricalOutlets')} />
			<div className={classes.calcRowContainer}>
				<CaclulationRow
					label={t('steps.technicalDetails.numberOfOutletsDoor')}
					value={numberOfOutletsDoor}
					editable={false}
					numberBackgroundColor={numberBackgroundColor}
					decimalDigits={0}
				/>
			</div>
			<div className={classes.calcRowContainer}>
				<CaclulationRow
					label={t('steps.technicalDetails.numberOfOutletsSink')}
					value={numberOfOutletsSink}
					editable={false}
					numberBackgroundColor={numberBackgroundColor}
					decimalDigits={0}
				/>
			</div>
		</div>
	);
}

function LightingConfig(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const roomConfig: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);

	const theme: Theme = useTheme();
	const numberBackgroundColor = {
		notEditable: theme.palette.background.paper,
		editable: theme.palette.background.default,
		editing: theme.palette.background.default,
	};

	const updateLightingType = (event: any) => {
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			lightingType: event.target.value,
		});
	};
	const updateLightingMirror = (event: any) => {
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			lightingMirror: event.target.checked,
		});
	};
	if (roomSpec.lightingCount === 0) {
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			lightingCount: Math.ceil(getCalculatedRoomDimensions(roomSpec, roomConfig).roomInsideMeasure / (100 * 100)),
		});
	}
	return (
		<div>
			<ConfigDivider title={t('steps.technicalDetails.lighting')} />
			<ConfigFieldContainer>
				<RadioGroup name="lightingType" value={roomSpec.lightingType} onChange={updateLightingType}>
					{roomConfig.lightingTypes.map((lightingType) => (
						<FormControlLabel
							className={classes.checkboxMargin}
							key={lightingType}
							value={lightingType}
							control={<Radio color="primary" />}
							label={t('steps.technicalDetails.' + lightingType)}
							labelPlacement="end"
						/>
					))}
				</RadioGroup>
				{/*
				<FormGroup row>
					<FormControlLabel
						control={
							<Checkbox
								className={classes.checkboxMargin}
								checked={roomSpec.lightingMirror}
								onChange={updateLightingMirror}
								value={roomSpec.lightingMirror}
								inputProps={{
									'aria-label': 'primary checkbox',
								}}
							/>
						}
						label={t('steps.technicalDetails.lightingMirror')}
					/>
				</FormGroup>
				*/}
				<ConfigFieldContainer>
					<CaclulationRow
						label={t('steps.technicalDetails.lightingCount')}
						value={roomSpec.lightingCount}
						editable={false}
						numberBackgroundColor={numberBackgroundColor}
						decimalDigits={0}
					/>
				</ConfigFieldContainer>
			</ConfigFieldContainer>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	checkboxMargin: {
		marginTop: 10,
		marginBottom: 10,
	},

	calcRowContainer: {
		paddingRight: 60,
	},
}));

export default ElectricalConfigView;
