//prettier-ignore
import { Input, makeStyles, MenuItem, Select, Theme, Tooltip, Typography } from "@material-ui/core/";
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectableElement } from '../../model/model';

interface Props extends WithWidth {
	label?: string;
	selectedElementId: string;
	selectableElements: SelectableElement[];
	handleElementSelection(selectedElId: string): void;
	sortFunction?(a: SelectableElement, b: SelectableElement): number;
	errorText?: string;
	allowNoneSelection?: boolean;
}

function SelectionInput(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	let {
		label,
		selectedElementId,
		selectableElements: selectablEelements,
		handleElementSelection,
		errorText,
		sortFunction,
		allowNoneSelection,
	} = props;
	const [isOpen, setOpen] = React.useState();
	const tooltipTitle = errorText ? errorText : '';

	if (!selectedElementId) {
		selectedElementId = '-1';
	}

	if (!props.selectableElements) {
		return <div />;
	}

	if (!sortFunction) {
		sortFunction = (a, b) => t(a.name).localeCompare(t(b.name));
	}

	const onChange = (ev: any) => {
		let id = ev.target.value;
		handleElementSelection(id);
	};

	return (
		<div
			className={classes.horizontalContainer}
			style={{
				backgroundColor: errorText ? '#FFF0F0' : 'white',
			}}
		>
			{label && (
				<div className={classes.labelContainer}>
					<Typography variant="subtitle1" className={classes.selectionInputLabel}>
						{label}
					</Typography>
				</div>
			)}
			<Tooltip
				className={classes.tooltip}
				title={tooltipTitle}
				//below shorthand if condition prevents error in console if using 'errorText && isOpen'
				open={(errorText ? true : false) && isOpen}
				placement={'left'}
			>
				<Select
					value={selectedElementId}
					onChange={onChange}
					input={<Input name="selectionInput" id="selectionInput" />}
					displayEmpty
					name="selectionControl"
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					MenuProps={{ classes: { paper: classes.selectMenuPaper } }}
				>
					{!allowNoneSelection && (
						<MenuItem disabled={true} value="-1">
							{t('selectOption')}
						</MenuItem>
					)}
					{allowNoneSelection && <MenuItem value="-1">{t('selectNoOption')}</MenuItem>}

					{selectablEelements.sort(sortFunction).map(function (element: SelectableElement) {
						return (
							<MenuItem key={element.id} value={element.id}>
								{t(element.name)}
							</MenuItem>
						);
					})}
				</Select>
			</Tooltip>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	horizontalContainer: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#ffffff',
		padding: '5px',
		alignItems: 'center',
	},

	labelContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	selectionInputLabel: {
		marginTop: 10,
		marginLeft: 10,
		marginBottom: 10,
		marginRight: 10,
		fontWeight: 'bold',
	},
	selectMenuPaper: {
		'& li:hover,& .Mui-selected,& .Mui-selected:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	tooltip: {
		minWidth: '140px',
	},
}));

export default withWidth()(SelectionInput);
