import * as _ from 'lodash';
import {
	DoorSpecification,
	FeatureElement,
	LengthUnit,
	RoomConfiguration,
	RoomDimensions,
	Unit,
	Wall,
	WallAlignment,
	WallType,
} from '../../../model/model';
import {
	convertCentiMeterInMeter,
	convertLengthUnitToCentimeter,
	convertLengthUnitToMeter,
} from '../LengthUnitConverter';
import { getHeightOfLastOrFirstWallElementOnAdjacentWall } from './calculateElementPositionAndCoordinatesFunctions';
import { getEndOfShowerOnOppositeWall, getReferenceElementWallAlignment } from './dimensionCalculatorFunctions';
import { getOppositeWallAlignment, getWallAlignmentOfWallAndAdjacentWalls } from './wallCalculationFunctions';

//param ignoreDoorWidth can be used to get MinAndMaxValues without calculating the door width -> can be used to get available space for door
export function calculateDoorPositionAtWallMaxAndMinValue(
	dim: RoomDimensions,
	doorSpecification: DoorSpecification | null,
	featureElements: {
		[featureElCategory: string]: FeatureElement;
	},
	roomConfig: RoomConfiguration,
	ignoreDoorWidth?: boolean
) {
	const socketConfig = roomConfig.socketConfig;
	const marginForSwitchesToDoorInCM = convertLengthUnitToCentimeter(socketConfig.marginSocketSwitchToDoor);
	const marginForSwitchesToWallInCM = convertLengthUnitToCentimeter(socketConfig.marginSocketSwitchToWall);

	//default values if no door or door wall is selected, will be overwritten later
	let doorMinPositionValue = 0;
	let doorMaxPositionValue = dim.moduleLengthInCM;

	if (!doorSpecification || !doorSpecification.wall) {
		return {
			doorMinPositionValue: {
				value: doorMinPositionValue,
				unit: Unit.METER,
			},
			doorMaxPositionValue: {
				value: doorMaxPositionValue,
				unit: Unit.METER,
			},
		};
	}

	const wallAlignment = doorSpecification.wall.wallAlignment;

	// in hinoki the door position is dependet on the first/last element of the right/left adjacent wall (both depending on the shower position)
	let firstOrLastElementOnWall: 'first' | 'last' =
		roomConfig.showerWallPositionLeftRight === 'left' ? 'last' : 'first';

	//door must not overlap more than the allowed overlap with most right positioned element on left adjacent wall
	let heightOfWallElementOnAdjacentWall = getHeightOfLastOrFirstWallElementOnAdjacentWall(
		wallAlignment,
		featureElements,
		roomConfig.showerWallPositionLeftRight,
		firstOrLastElementOnWall
	);

	//door must not overlap with shower, if shower is positioned on opposite wall
	let endOfShowerOnOppositeWall = getEndOfShowerOnOppositeWall(wallAlignment, featureElements);

	let heightOfWallElementOnAdjacentWallInM = convertLengthUnitToMeter(heightOfWallElementOnAdjacentWall);

	let endOfShowerOnOppositeWallInM = convertLengthUnitToMeter(endOfShowerOnOppositeWall);

	let minAndMaxDoorPositionWithRoomElementMargin = getMinAndMaxDoorPositionDependingOnOtherRoomElementsInMeter(
		wallAlignment,
		dim,
		roomConfig,
		endOfShowerOnOppositeWallInM,
		heightOfWallElementOnAdjacentWallInM
	);

	doorMinPositionValue = minAndMaxDoorPositionWithRoomElementMargin.doorMinPosition;
	doorMaxPositionValue = minAndMaxDoorPositionWithRoomElementMargin.doorMaxPosition;

	const doorSpaceToLightSwitch = calculateDoorSpaceToLightSwitch(
		wallAlignment,
		doorSpecification,
		marginForSwitchesToDoorInCM,
		marginForSwitchesToWallInCM
	);

	// if doorSpaceToWcOrSink is positive and sink/wc should not overlap with door
	// take care that the door is not directly next to the toilett or sink
	// Otherwise a person would open the door and stands immidiatly on the toilett.
	const doorSpaceToWcOrSink =
		roomConfig.doorSpaceToWcOrSink.value > 0
			? calculateDoorSpaceToWcOrSink(wallAlignment, featureElements, roomConfig.doorSpaceToWcOrSink)
			: { offsetToMin: 0, offsetToMax: 0 };

	doorMinPositionValue =
		doorMinPositionValue + _.max([doorSpaceToWcOrSink.offsetToMin, doorSpaceToLightSwitch.offsetToMin])!;
	doorMaxPositionValue =
		doorMaxPositionValue - _.max([doorSpaceToWcOrSink.offsetToMax, doorSpaceToLightSwitch.offsetToMax])!;

	//to get available Space for door, the door with will be ignored (e.g. to check which door widths are selectable)
	if (!ignoreDoorWidth) {
		doorMaxPositionValue -= convertLengthUnitToMeter(doorSpecification!.width) / 2;

		doorMinPositionValue += convertLengthUnitToMeter(doorSpecification!.width) / 2;
	}

	return {
		doorMinPositionValue: {
			value: doorMinPositionValue,
			unit: Unit.METER,
		},
		doorMaxPositionValue: {
			value: doorMaxPositionValue,
			unit: Unit.METER,
		},
	};
}

export function getSelectableWallsForDoor(
	walls: Wall[],
	featureElements: {
		[featureElCategory: string]: FeatureElement;
	},
	roomConfig: RoomConfiguration,
	productFamiliyId: string,
	productFamilyVariantId: string,
	radiatorType: string | null
) {
	//doors are only allowed on free standing walls
	let selectableWalls = walls.filter((el) => el.wallType === WallType.FREE_STANDING);

	let referenceRoomElWallAlignment = getReferenceElementWallAlignment(featureElements);

	//door position rules for hinoki
	if (productFamiliyId === 'hinoki') {
		if (productFamilyVariantId === 'guest') {
			selectableWalls = selectableWalls.filter((el) => el.wallAlignment === WallAlignment.SOUTH);
		} else {
			// door must not positioned on wall with reference element or the wall right next to it, since the shower is always positioned in a corner
			//get wallalignemnts that are not allowed as door position
			let refElementAdjacentWalls = getWallAlignmentOfWallAndAdjacentWalls(
				referenceRoomElWallAlignment,
				roomConfig.showerWallPositionLeftRight
			);

			selectableWalls = selectableWalls.filter((el) => refElementAdjacentWalls.indexOf(el.wallAlignment) < 0);
		}

		//door position rules for suki
	} else if (productFamiliyId === 'suki') {
		// in suki the door is always positioned at the opposite wall

		// if no shower and radiator was set, the door has one wall more to be there
		if (!featureElements['SH'] && !radiatorType && featureElements['SI'] && featureElements['WC']) {
			let oppositeWalls = [
				getOppositeWallAlignment(featureElements['SI'].wallAlignment),
				getOppositeWallAlignment(featureElements['WC'].wallAlignment),
			];

			selectableWalls = selectableWalls.filter(
				(el) => el.wallAlignment === oppositeWalls[0] || el.wallAlignment === oppositeWalls[1]
			);
		} else {
			// get wallalignemnt for door position
			let oppositeWallAlignemnt = getOppositeWallAlignment(referenceRoomElWallAlignment);
			selectableWalls = selectableWalls.filter((el) => el.wallAlignment === oppositeWallAlignemnt);
		}

		if (productFamilyVariantId === 'guest') {
			selectableWalls = selectableWalls.filter((el) => el.wallAlignment === WallAlignment.WEST);
		}
	}

	if (productFamilyVariantId === 'common') {
		selectableWalls = selectableWalls.filter((el) => el.wallAlignment === WallAlignment.SOUTH);
	}

	return selectableWalls;
}

/// handles also overlapping if doorSpaceToWcOrSink is negative
function getMinAndMaxDoorPositionDependingOnOtherRoomElementsInMeter(
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	roomConfig: RoomConfiguration,
	endOfShowerOnOppositeWallInM: number,
	heightOfWallElementOnAdjacentWallInM: number
) {
	let doorMinPosition = 0;
	let doorMaxPosition = 0;

	let minMarginPositionToHeightOfWallElementOnAdjacentWallInM =
		roomConfig.doorSpaceToWcOrSink.value < 0
			? heightOfWallElementOnAdjacentWallInM -
			  convertLengthUnitToMeter({
					unit: roomConfig.doorSpaceToWcOrSink.unit,
					value: roomConfig.doorSpaceToWcOrSink.value * -1,
			  })
			: 0;
	//prevent negative margin
	minMarginPositionToHeightOfWallElementOnAdjacentWallInM =
		minMarginPositionToHeightOfWallElementOnAdjacentWallInM < 0
			? 0
			: minMarginPositionToHeightOfWallElementOnAdjacentWallInM;

	switch (wallAlignment) {
		case WallAlignment.EAST:
		case WallAlignment.WEST:
			doorMaxPosition = convertCentiMeterInMeter(dim.moduleLengthInCM);
			break;
		case WallAlignment.NORTH:
		case WallAlignment.SOUTH:
			doorMaxPosition = convertCentiMeterInMeter(dim.moduleWidthInCM);
			break;
	}

	switch (wallAlignment) {
		case WallAlignment.SOUTH:
		case WallAlignment.WEST:
			if (roomConfig.showerWallPositionLeftRight === 'left') {
				doorMinPosition = endOfShowerOnOppositeWallInM;
				doorMaxPosition -= minMarginPositionToHeightOfWallElementOnAdjacentWallInM;
			} else {
				doorMinPosition = minMarginPositionToHeightOfWallElementOnAdjacentWallInM;
				doorMaxPosition -= endOfShowerOnOppositeWallInM;
			}
			break;

		case WallAlignment.EAST:
		case WallAlignment.NORTH:
			if (roomConfig.showerWallPositionLeftRight === 'left') {
				doorMinPosition = minMarginPositionToHeightOfWallElementOnAdjacentWallInM;
				doorMaxPosition -= endOfShowerOnOppositeWallInM;
			} else {
				doorMinPosition = endOfShowerOnOppositeWallInM;
				doorMaxPosition -= minMarginPositionToHeightOfWallElementOnAdjacentWallInM;
			}
			break;
	}

	return { doorMinPosition, doorMaxPosition };
}

/// get the element which is next to the given wallAlignment and returns it.
/// e.g.: wallAlignment is WEST and Sink on NORTH but next to the wall west (wc is sticking to the EAST wall), so return the sink
function getElementLengthNextToWall(
	wallAlignment: WallAlignment,
	featureElements: {
		[featureElCategory: string]: FeatureElement;
	}
): FeatureElement | undefined {
	const xSink = featureElements['SI'].xCoordinate.value;
	const xWC = featureElements['WC'].xCoordinate.value;
	const ySink = featureElements['SI'].yCoordinate.value;
	const yWC = featureElements['WC'].yCoordinate.value;

	switch (wallAlignment) {
		case WallAlignment.WEST:
			if (xSink < xWC) {
				return featureElements['SI'];
			} else {
				return featureElements['WC'];
			}
		case WallAlignment.EAST:
			if (xSink > xWC) {
				return featureElements['SI'];
			} else {
				return featureElements['WC'];
			}
		case WallAlignment.NORTH:
			if (ySink < yWC) {
				return featureElements['SI'];
			} else {
				return featureElements['WC'];
			}
		case WallAlignment.SOUTH:
			if (ySink > yWC) {
				return featureElements['SI'];
			} else {
				return featureElements['WC'];
			}
		default:
			return undefined;
	}
}

/// If the door is on the West side and the WC and Sink on the North or South, the door need a space to it.
/// Otherwise a person would open the door and stands immidiatly on the toilett.
function calculateDoorSpaceToWcOrSink(
	wallAlignmentOfDoor: WallAlignment,
	featureElements: {
		[featureElCategory: string]: FeatureElement;
	},
	doorSpaceToWcOrSink: LengthUnit
): { offsetToMin: number; offsetToMax: number } {
	const space = convertLengthUnitToMeter(doorSpaceToWcOrSink);
	let offsetToMin = 0;
	let offsetToMax = 0;

	switch (wallAlignmentOfDoor) {
		case WallAlignment.WEST:
		case WallAlignment.EAST:
			// door is on the west or east wall, so now let's check if the sink/wc is on north or south and has impact to the door
			// we only have to check the sink because the alignment for the wc is the same here
			if (featureElements['SI'].wallAlignment == WallAlignment.NORTH) {
				// use height of element next to the door as offset to north
				offsetToMin = convertLengthUnitToMeter(
					getElementLengthNextToWall(wallAlignmentOfDoor, featureElements)!.elementHeight
				);
				offsetToMin = offsetToMin + space;
			} else if (featureElements['SI'].wallAlignment == WallAlignment.SOUTH) {
				// use height of element next to the door as offset to south
				offsetToMax = convertLengthUnitToMeter(
					getElementLengthNextToWall(wallAlignmentOfDoor, featureElements)!.elementHeight
				);
				offsetToMax = offsetToMax + space;
			}
			break;
		case WallAlignment.NORTH:
		case WallAlignment.SOUTH:
			// door is on the north or south wall, so now let's check if the sink/wc is on west or east and has impact to the door
			// we only have to check the sink because the alignment for the wc is the same here
			if (featureElements['SI'].wallAlignment == WallAlignment.WEST) {
				// use height of element next to the door as offset to west
				offsetToMin = convertLengthUnitToMeter(
					getElementLengthNextToWall(wallAlignmentOfDoor, featureElements)!.elementHeight
				);
				offsetToMin = offsetToMin + space;
			} else if (featureElements['SI'].wallAlignment == WallAlignment.EAST) {
				// use height of element next to the door as offset to east
				offsetToMax = convertLengthUnitToMeter(
					getElementLengthNextToWall(wallAlignmentOfDoor, featureElements)!.elementHeight
				);
				offsetToMax = offsetToMax + space;
			}
			break;
	}

	return { offsetToMin, offsetToMax };
}

function calculateDoorSpaceToLightSwitch(
	wallAlignment: WallAlignment,
	doorSpecification: DoorSpecification,
	marginForSwitchesToDoorInCM: number,
	marginForSwitchesToWallInCM: number
): { offsetToMin: number; offsetToMax: number } {
	let offsetToMin = 0;
	let offsetToMax = 0;

	//margin of door and wall to switches is always calcualted to the center of the switches. This means the socket wicht does not influence the margin

	switch (wallAlignment) {
		case WallAlignment.EAST:
		case WallAlignment.NORTH:
			if (doorSpecification!.openingDirectionLeftRight === 'right') {
				offsetToMax =
					convertCentiMeterInMeter(marginForSwitchesToDoorInCM) +
					convertCentiMeterInMeter(marginForSwitchesToWallInCM);
				offsetToMin = convertCentiMeterInMeter(marginForSwitchesToWallInCM);
			} else if (doorSpecification!.openingDirectionLeftRight === 'left') {
				offsetToMin =
					convertCentiMeterInMeter(marginForSwitchesToDoorInCM) +
					convertCentiMeterInMeter(marginForSwitchesToWallInCM);
				offsetToMax = convertCentiMeterInMeter(marginForSwitchesToWallInCM);
			}
			break;
		case WallAlignment.WEST:
		case WallAlignment.SOUTH:
			if (doorSpecification!.openingDirectionLeftRight === 'left') {
				offsetToMax =
					convertCentiMeterInMeter(marginForSwitchesToDoorInCM) +
					convertCentiMeterInMeter(marginForSwitchesToWallInCM);
				offsetToMin = convertCentiMeterInMeter(marginForSwitchesToWallInCM);
			} else if (doorSpecification!.openingDirectionLeftRight === 'right') {
				offsetToMin =
					convertCentiMeterInMeter(marginForSwitchesToDoorInCM) +
					convertCentiMeterInMeter(marginForSwitchesToWallInCM);
				offsetToMax = convertCentiMeterInMeter(marginForSwitchesToWallInCM);
			}
			break;
	}

	return { offsetToMin, offsetToMax };
}
