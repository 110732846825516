// prettier-ignore
import { ClickAwayListener, makeStyles, Tooltip, Typography } from '@material-ui/core';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from 'react';
import { isDesktop, isMobile } from '../../responsive';

interface Props extends WithWidth {
	text?: string;
	disabled?: boolean;
	iconType: 'warning' | 'info';
	onClick: (toggle: boolean) => void;
}

function Icon(props: Props) {
	const { text, disabled, width, onClick, iconType } = props;
	const classes = useStyles();

	const onToggle = (b: boolean) => () => {
		onClick(b);
	};

	if (text && isDesktop(width)) {
		return (
			<Tooltip
				title={
					<Typography variant="subtitle1" className={classes.tooltipText}>
						{text}
					</Typography>
				}
			>
				<div>
					{iconType === 'info' && <InfoIcon color={disabled ? 'disabled' : 'inherit'} />}
					{iconType === 'warning' && <WarningIcon color={disabled ? 'disabled' : 'inherit'} />}
				</div>
			</Tooltip>
		);
	} else if (text && isMobile(width)) {
		return (
			<ClickAwayListener onClickAway={onToggle(false)}>
				{iconType === 'info' && <InfoIcon onClick={onToggle(true)} color={disabled ? 'disabled' : 'inherit'} />}

				{iconType === 'warning' && (
					<WarningIcon onClick={onToggle(true)} color={disabled ? 'disabled' : 'inherit'} />
				)}
			</ClickAwayListener>
		);
	} else {
		return null;
	}
}

const useStyles = makeStyles({
	tooltipText: {
		color: '#ffffff',
	},
});

export default withWidth()(Icon);
