// prettier-ignore
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Input, MenuItem, Select, Theme, Tooltip, Typography } from "@material-ui/core/";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useActions } from '../../actions';
import * as RoomSpecActions from '../../actions/roomSpec';
// prettier-ignore
import { RoomSpec, WallAlignment, WallType } from "../../model/model";
import { RootState } from '../../reducers';

interface Props {}

function WallConfig(props: Props) {
	const { t } = useTranslation();
	const classes = useStyles();
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);
	const [isConfirmDialogOpen, setConfimrDialogOpen] = React.useState(false);
	const [updatedWallLocation, setUpdatedWallLocation] = React.useState<WallAlignment>(WallAlignment.NORTH);
	const [newWallType, setNewWallType] = React.useState<WallType>(WallType.FREE_STANDING);

	const { wallEast: walllEast, wallSouth: walllSouth, wallWest: walllWest, wallNorth: walllNorth } = roomSpec;
	const walls = [walllEast, walllSouth, walllWest, walllNorth];
	walls.sort((a, b) => {
		return t(a.name).localeCompare(t(b.name));
	});
	const checkIsWallUsedForDoor = (wallType: WallType, wallLocation: WallAlignment) => {
		if (
			roomSpec.doorSpecification &&
			roomSpec.doorSpecification.wall &&
			roomSpec.doorSpecification.wall.wallAlignment.toString() === wallLocation
		) {
			setConfimrDialogOpen(true);
		} else {
			updateWallType(wallType, wallLocation, false);
		}
	};
	const handleWallTypeChange = (event: any) => {
		let wallLocation = event.target.name;
		let newWallType = event.target.value;
		setUpdatedWallLocation(wallLocation);
		setNewWallType(newWallType);
		if (newWallType !== WallType.FREE_STANDING.toString()) {
			checkIsWallUsedForDoor(newWallType, wallLocation);
		} else {
			updateWallType(newWallType, wallLocation, false);
		}
	};

	const updateWallType = (wallType: WallType, wallLocation: WallAlignment, resetDoorLocation: boolean) => {
		let newRoomSpec = { ...roomSpec };
		switch (wallLocation) {
			case WallAlignment.EAST:
				newRoomSpec.wallEast = {
					...roomSpec.wallEast,
					wallType: wallType,
				};
				break;
			case WallAlignment.WEST:
				newRoomSpec.wallWest = {
					...roomSpec.wallWest,
					wallType: wallType,
				};
				break;
			case WallAlignment.NORTH:
				newRoomSpec.wallNorth = {
					...roomSpec.wallNorth,
					wallType: wallType,
				};
				break;
			case WallAlignment.SOUTH:
				newRoomSpec.wallSouth = {
					...roomSpec.wallSouth,
					wallType: wallType,
				};
				break;
		}
		if (resetDoorLocation && newRoomSpec.doorSpecification) {
			newRoomSpec.doorSpecification.wall = undefined;
			newRoomSpec.wallSwitches.doorSwitches = [];
		}
		roomSpecActions.updateRoomSpec(newRoomSpec);
	};

	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={10} md={10} lg={10}>
					{walls.map(function (el) {
						const isHinokiDoor =
							roomSpec.productFamilyId === 'hinoki' && el.wallAlignment === WallAlignment.SOUTH;
						const isSukiCommonDoor =
							roomSpec.productFamilyId === 'suki' &&
							roomSpec.productFamilyVariantId === 'common' &&
							el.wallAlignment === WallAlignment.SOUTH;
						const isSukiGuestDoor =
							roomSpec.productFamilyId === 'suki' &&
							roomSpec.productFamilyVariantId === 'guest' &&
							el.wallAlignment === WallAlignment.WEST;

						if (isHinokiDoor || isSukiCommonDoor || isSukiGuestDoor) {
							return <></>;
						}

						return (
							<div key={el.id} className={classes.horizontalContainer}>
								<div className={classes.labelContainer}>
									<Typography variant="subtitle1" className={classes.text}>
										{t(el.name)}
									</Typography>
								</div>
								<div>
									<Select
										value={el.wallType}
										onChange={handleWallTypeChange}
										input={<Input name="selectionInput" id="selectionInput" />}
										displayEmpty
										name={el.wallAlignment}
										MenuProps={{
											classes: {
												paper: classes.selectMenuPaper,
											},
										}}
									>
										{Object.keys(WallType).map((key: string) => {
											let name = (WallType as any)[key];
											return (
												<MenuItem key={key} value={name}>
													{t(name)}
												</MenuItem>
											);
										})}
									</Select>
								</div>
							</div>
						);
					})}
				</Grid>
				<Grid item md lg xs>
					<Tooltip title={t('steps.structuralElements.infoWallType')}>
						<IconButton aria-label="info">
							<InfoIcon />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<ResetDoorLocationDialog
				dialogContentTranslationKey="steps.structuralElements.wallTypeResetDoorLocationConfirmDialogContent"
				isDialogOpen={isConfirmDialogOpen}
				setDialogOpen={setConfimrDialogOpen}
				onConfirmedClose={() => updateWallType(newWallType, updatedWallLocation, true)}
			/>
		</div>
	);
}

interface DialogProps {
	dialogContentTranslationKey: string;
	isDialogOpen: boolean;
	setDialogOpen: (b: boolean) => void;
	onConfirmedClose: () => void;
}
export function ResetDoorLocationDialog(props: DialogProps) {
	const classes = useStyles();

	const { isDialogOpen, dialogContentTranslationKey, setDialogOpen, onConfirmedClose } = props;
	const { t } = useTranslation();

	const handleClose = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		let actionValue = ev.currentTarget.value;
		if (actionValue === 'OK') {
			onConfirmedClose();
		}
		setDialogOpen(false);
	};
	return (
		<div>
			<Dialog onClose={handleClose} aria-labelledby="confirmation-dialog-title" open={isDialogOpen}>
				<DialogTitle id="confirmation-dialog-title">
					{t('steps.structuralElements.resetDoorLocationConfirmDialogTitle')}
				</DialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom>{t(dialogContentTranslationKey)}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} value="OK" color="primary">
						{t('steps.structuralElements.resetDoorLocationConfirmDialogOKButton')}
					</Button>
					<Button onClick={handleClose} value="Cancel" color="primary">
						{t('cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	horizontalContainer: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#ffffff',
		padding: '5px',
		alignItems: 'center',
		flexGrow: 1,
		paddingLeft: 50,
	},

	labelContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},

	text: {
		marginTop: 10,
		marginLeft: 10,
		marginBottom: 10,
		marginRight: 10,
	},

	selectMenuPaper: {
		'& li:hover,& .Mui-selected,& .Mui-selected:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
}));

export default WallConfig;
