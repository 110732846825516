import { History } from 'history';
import { combineReducers } from 'redux';
//prettier-ignore
import { AppConfig, ConfigValidation, RoomConfiguration, RoomSpec, RoomSpecPrice, Step, ValidationField } from "../model/model";
import * as appConfigReducer from './appConfig';
import * as configValidation from './configValidation';
import * as validationReducer from './fieldValidation';
import * as roomConfigurationValuesReducer from './roomConfigurationValues';
import * as roomSpecReducer from './roomSpec';
import * as stepReducer from './step';
import * as unitReducer from './unit';
import * as userReducer from './user';
import {shapediver} from './shapediver';

export interface RootState {
	stepList: Step[];
	roomSpec: RoomSpec;
	roomConfigurationValues: RoomConfiguration;
	fieldValidation: ValidationField[];
	appConfig: AppConfig;
	token: string;
	authError: number;
	serverStatus: number;
	configValidation: ConfigValidation;
	roomSpecId: number | null;
	roomSpecPrice: RoomSpecPrice;
	units: number[];
	roomSpecPostLoading: null | boolean;
	shapediver: object | null;
}

export default (history: History) =>
	combineReducers({
		...stepReducer,
		...roomSpecReducer,
		...roomConfigurationValuesReducer,
		...validationReducer,
		...userReducer,
		...appConfigReducer,
		...configValidation,
		...unitReducer,
    shapediver,
	});
