import { LengthUnit, Unit } from '../../model/model';

export const convertLengthUnitToCentimeter = (lengthUnit: LengthUnit): number => {
	let result = lengthUnit.value;
	if (result == 0) {
		return 0;
	}

	switch (lengthUnit.unit) {
		case Unit.METER:
			result *= 100;
			break;
		case Unit.CENTI_METER:
			break;
		case Unit.MILLI_METER:
			result /= 10;
			break;
	}
	return Math.round(result * 1000000) / 1000000;
};

export const convertLengthUnitToMeter = (lengthUnit: LengthUnit): number => {
	try {
		let result = lengthUnit.value;
		if (result == 0) {
			return 0;
		}

		switch (lengthUnit.unit) {
			case Unit.METER:
				break;
			case Unit.CENTI_METER:
				result /= 100;
				break;
			case Unit.MILLI_METER:
				result /= 1000;
				break;
		}
		//round to avoid inaacurate and very long results sind devision isn´t always exaclty correct in js (in programming in general)
		return Math.round(result * 1000000) / 1000000;
	} catch (e) {
		return 0;
	}
};

export const convertLengthUnitToMiliMeter = (lengthUnit: LengthUnit): number => {
	let result = lengthUnit.value;
	switch (lengthUnit.unit) {
		case Unit.METER:
			result *= 1000;
			break;
		case Unit.CENTI_METER:
			result *= 10;
			break;
		case Unit.MILLI_METER:
			break;
	}
	return Math.round(result * 1000000) / 1000000;
};

export const convertCentiMeterInMeter = (cm: number) => {
	let result = cm / 100;
	return Math.round(result * 1000000) / 1000000;
};

export const convertSquareCentiSquareMeterInMeter = (squareCm: number) => {
	let result = squareCm / 10000;
	return Math.round(result * 1000000) / 1000000;
};
