import { CircularProgress, makeStyles } from '@material-ui/core';
import * as _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { useActions } from '../actions';
import * as RoomSpecActions from '../actions/roomSpec';
import * as UnitActions from '../actions/unit';
import { RootState } from '../reducers';
import { parseQueryString } from '../utils';

interface Props extends RouteComponentProps<void> {}

function ReceiveUnitPage(props: Props) {
	const classes = useStyles(props);

	const { history } = useReactRouter();
	const unitActions: typeof UnitActions = useActions(UnitActions);
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);

	const token: string = useSelector((state: RootState) => state.token);
	const oldUnits: number[] = useSelector((state: RootState) => state.units);
	const [loaded, setLoaded] = React.useState(false);
	const { t } = useTranslation();

	React.useEffect(() => {
		const queryParams = parseQueryString(window.location.search);

		// find all unit ids
		const units: number[] = [];
		for (let i = 0; i < 1000; i++) {
			const unitId = queryParams['unit' + i];
			if (unitId) {
				units.push(parseInt(unitId));
			}
		}

		if (!_.isEqual(oldUnits.sort(), units.sort())) {
			roomSpecActions.resetRoomSpec();
			setTimeout(() => {
				unitActions.setUnits(units);
			}, 500);
		} else {
			setTimeout(() => {
				window.location.href = window.location.origin;
			}, 1000);
		}
	}, [oldUnits]);

	return (
		<div className={classes.root}>
			<CircularProgress />
		</div>
	);
}

const useStyles = makeStyles({
	root: {
		width: '100%',
		height: '70%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default ReceiveUnitPage;
