import * as React from 'react';
import { useSelector } from 'react-redux';
import { useValidator } from '../../middleware/fieldValidation/fieldValidatorHook';
import { StepId, SubStepId } from '../../model/model';
import { RootState } from '../../reducers';

interface Props {}
/**
 * Validator component to register all field validations
 * Example validation field registration for the Strucutral Elements Step:
 * 	registerFields(
 *		[
 *			{
 *				propertyName: "dimensionLength.value", <- property name in dot notation, compare with JSON bellow
 *				validationFunctionNames: ["greaterThanZero"], <- validation function as string
 *			}
 *		],
 *		StepId.STRUCTURAL_ELEMENTS, <- Step id of structural Elements step
 *		SubStepId.STRUCTURAL_ELEMENTS_DIMENSION <- optional substepid, if field is located in a substep
 *	);
 *
 *	The propertyname is used to access the value of the fields in the roomspec state, e.g. "dimensionLength.value" is used to access the value "2":
 *	{
 * 		dimensionLenght:
 *			{
 *				unit:"cm",
 *				value:2
 *	 		}
 *   }
 */

function Validator(props?: Props) {
	const { registerFields } = useValidator();
	const state: RootState = useSelector((state: RootState) => state);

	registerFields(
		[
			{
				propertyName: 'productCategoryId',
				errorValidationFunctionNames: ['required'],
			},
		],
		StepId.PRODUCT_SELECTION,
		SubStepId.PRODUCT_SELECTION_PRODUCT_CATEGORIES
	);

	registerFields(
		[
			{
				propertyName: 'productFamilyId',
				errorValidationFunctionNames: ['required'],
			},
		],
		StepId.PRODUCT_SELECTION,
		SubStepId.PRODUCT_SELECTION_PRODUCT_FAMILIES
	);

	registerFields(
		[
			{
				propertyName: 'productFamilyVariantId',
				errorValidationFunctionNames: ['required'],
			},
		],
		StepId.PRODUCT_SELECTION,
		SubStepId.PRODUCT_SELECTION_PRODUCT_FAMILIES
	);

	registerFields(
		[
			{
				propertyName: 'accessoryElements',
				errorValidationFunctionNames: ['required'],
				additionalRoute: '/accessoryElements',
			},
		],
		StepId.STRUCTURAL_ELEMENTS,
		SubStepId.STRUCTURAL_ELEMENTS_DIMENSION
	);

	state.roomConfigurationValues.roomHeights &&
		registerFields(
			[
				{
					propertyName: 'roomHeight',
					errorValidationFunctionNames: ['greaterOrEqualMinValue'],
					errorValidationFunctionParams: {
						greaterOrEqualMinValue: state.roomConfigurationValues.roomHeights.min,
					},
				},
			],
			StepId.STRUCTURAL_ELEMENTS,
			SubStepId.STRUCTURAL_ELEMENTS_DIMENSION
		);

	registerFields(
		[
			{
				propertyName: 'doorSpecification',
				errorValidationFunctionNames: ['required'],
			},
			{
				propertyName: 'doorSpecification.wall',
				errorValidationFunctionNames: ['required'],
			},
		],
		StepId.STRUCTURAL_ELEMENTS,
		SubStepId.STRUCTURAL_ELEMENTS_DOOR_SPECIFICATION
	);

	registerFields(
		[
			{
				propertyName: 'equipmentLineId',
				errorValidationFunctionNames: ['required'],
			},
		],
		StepId.STRUCTURAL_ELEMENTS,
		SubStepId.STRUCTURAL_ELEMENTS_INTERIOR_DETAILS
	);

	registerFields(
		[
			{
				propertyName: 'radiatorType|underfloorHeating',
				errorValidationFunctionNames: ['requiredNoFalse'],
				combinedField: true,
			},
		],
		StepId.TECHNICAL_DETAILS,
		SubStepId.TECHNICAL_DETAILS_HEATING
	);

	return <div />;
}

export default Validator;
