//prettier-ignore
import { Checkbox, FormControlLabel, FormGroup, Theme, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useActions } from '../../actions';
import * as RoomSpecActions from '../../actions/roomSpec';
import { RoomConfiguration, RoomSpec, Route } from '../../model/model';
import { RootState } from '../../reducers';
import ConfigFieldList from '../configuratorView/ConfigFieldList';
import ConfigDivider from '../configuratorView/fields/ConfigDivider';
import ConfigFieldContainer from '../configuratorView/fields/ConfigFieldContainer';
import ConfigNextButton from '../configuratorView/fields/ConfigNextButton';
import { getCalculatedRoomDimensions } from '../roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';
import { getSelectableWallsForDoor } from '../roomPlan/roomPlanLogicFunctions/doorFunctions';
import { getRadiatorCoordinatesAndWallAlignment } from '../roomPlan/roomPlanLogicFunctions/radiatorPositionFunctions';
import { ResetDoorLocationDialog } from '../structureElementViews/WallConfig';

interface Props extends RouteComponentProps<void> {}

function HeatingConfigView(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const roomConfig: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);

	const [showResetDoorDialog, setShowResetDoorDialog] = React.useState(false);

	const updateRadiatorType = (radiatorType: string | null) => {
		const radiatorConfig = getRadiatorCoordinatesAndWallAlignment(
			roomSpec,
			roomConfig,
			getCalculatedRoomDimensions(roomSpec, roomConfig)
		);

		//  update radiator
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			radiatorType,
		});

		// check if radiator is on the wall of the door
		if (
			radiatorType !== null &&
			roomSpec &&
			radiatorConfig &&
			roomSpec.doorSpecification &&
			roomSpec.doorSpecification.wall &&
			radiatorConfig.wallAlignment == roomSpec.doorSpecification.wall.wallAlignment
		) {
			setShowResetDoorDialog(true);
		}
	};

	const walls = [roomSpec.wallEast, roomSpec.wallNorth, roomSpec.wallSouth, roomSpec.wallWest];

	const updateDoorWall = () => {
		if (!roomSpec || !roomSpec.doorSpecification) {
			return;
		}

		// search for a wall which we can place the door
		const selectableWall = getSelectableWallsForDoor(
			walls,
			roomSpec.featureElements,
			roomConfig,
			roomSpec.productFamilyId,
			roomSpec.productFamilyVariantId,
			'someRadiator'
		)[0];
		let doorSpec = { ...roomSpec.doorSpecification, wall: selectableWall };

		// no conflict with door: update radiator
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			doorSpecification: doorSpec,
		});
	};

	const updateUnderfloorHeating = (event: any) => {
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			underfloorHeating: event.target.checked,
		});
	};

	return (
		<ConfigFieldList>
			<ConfigDivider title={t('steps.technicalDetails.heatingType')} />
			<ConfigFieldContainer>
				{roomSpec.productFamilyVariantId !== 'guest' && (
					<FormGroup row>
						<FormControlLabel
							control={
								<Checkbox
									checked={roomSpec.underfloorHeating}
									onChange={updateUnderfloorHeating}
									value={roomSpec.underfloorHeating}
									inputProps={{
										'aria-label': 'primary checkbox',
									}}
								/>
							}
							label={t('steps.technicalDetails.underfloorHeating')}
						/>
					</FormGroup>
				)}
			</ConfigFieldContainer>
			<ConfigDivider title={t('steps.technicalDetails.radiatorPosition')} />
			<ConfigFieldContainer>
				<Typography>
					{roomSpec.radiatorConfig !== null
						? t(walls.find((wall) => wall.wallAlignment === roomSpec.radiatorConfig!.wallAlignment)!.name)
						: t('steps.technicalDetails.radiatorPositionNoRadiator')}
				</Typography>
			</ConfigFieldContainer>
			<ConfigDivider />
			<ConfigNextButton nextRoute={Route.TECHNICAL_DETAILS_VENTILATION} />
			<ResetDoorLocationDialog
				dialogContentTranslationKey="steps.structuralElements.featureElementResetDoorLocationConfirmDialogContent"
				isDialogOpen={showResetDoorDialog}
				setDialogOpen={setShowResetDoorDialog}
				onConfirmedClose={updateDoorWall}
			/>
		</ConfigFieldList>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	radiatorTypesContainer: {
		marginLeft: 20,
	},
}));

export default HeatingConfigView;
