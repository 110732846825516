// prettier-ignore
import { FormControl, Input, InputAdornment, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Cleave from 'cleave.js/react';
import * as _ from 'lodash';
import * as React from 'react';

interface Props extends WithWidth {
	value?: number | null;
	endAdornment?: string;
	isTooltipOpen?: boolean;
	tooltipTitle?: string;
	closeTooltip?: any;
	tooltipPlacement?: any;
	thousandSeparator?: string;
	decimalSeparator?: string;
	onFinished?: (value: number) => void;
	autoFocus?: boolean;
	style?: any;
	negativeValue?: boolean; // allow negative values (default: false)
	onKeyPress?: (key: string) => void;
	decimalDigits?: number;
}

function NumberField(props: Props) {
	const classes = useStyles(props);

	const decimalDigits = props.decimalDigits ? props.decimalDigits : 2;
	// initial attributes
	const thousandSeparator = props.thousandSeparator ? props.thousandSeparator : '.';
	const decimalSeparator = props.decimalSeparator ? props.decimalSeparator : ',';
	const tooltipPlacement = props.tooltipPlacement ? props.tooltipPlacement : 'left';
	const isTooltipOpen = props.isTooltipOpen !== undefined ? props.isTooltipOpen : false;
	const tooltipTitle = props.tooltipTitle ? props.tooltipTitle : '';
	const closeTooltip = props.closeTooltip
		? props.closeTooltip
		: () => {
				return;
		  };
	const initialValue = props.value ? (props.value + '').replace('.', decimalSeparator) : '';
	const [value, setValue] = React.useState(initialValue);
	const [isDirty, setDirty] = React.useState(false);

	/**
	 * select entire input if field is focused
	 */
	const handleFocus = (event: any) => {
		if (isTooltipOpen) {
			closeTooltip();
		}

		const e = _.cloneDeep(event);

		if (!isDirty) {
			setTimeout(() => e.target.select(), 0);
		}
	};

	const maskedTextField = (props: any) => {
		let { inputRef, ...other } = props;
		return (
			<Cleave
				{...other}
				ref={(r: any) => {
					inputRef = r;
				}}
				options={{
					numeral: true,
					numeralDecimalMark: decimalSeparator,
					delimiter: thousandSeparator,
					numeralDecimalScale: decimalDigits,
				}}
			/>
		);
	};

	const onKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
		const { onKeyPress } = props;

		if (onKeyPress) {
			onKeyPress(ev.key);
		}

		if (ev.key === 'Enter') {
			onFinished();
		}
	};

	const onFinished = () => {
		const { onFinished, negativeValue } = props;

		if (!onFinished) {
			return;
		}

		try {
			let v = value;
			if (value) {
				v = v.replace(thousandSeparator, '');
				v = v.replace(thousandSeparator, '');
				v = v.replace(decimalSeparator, '.');
			}
			const num = Number.parseFloat(v);

			if (isNaN(num)) {
				onFinished(0);
				setValue('');
			} else {
				// if value is allowed to be negative
				if (negativeValue) {
					onFinished(num);
					setValue(num + '');
				} else {
					// make sure value is not negative
					onFinished(Math.abs(num));
					setValue(Math.abs(num) + '');
				}
			}
		} catch (e) {
			onFinished(0);
			setValue('');
		}
	};

	return (
		<FormControl className={classes.numberField}>
			<Tooltip className={classes.tooltip} title={tooltipTitle} open={isTooltipOpen} placement={tooltipPlacement}>
				<Input
					data-cy="numberField"
					autoFocus={props.autoFocus}
					onBlur={onFinished}
					onKeyUp={() => {
						setDirty(true);
					}}
					onKeyPress={onKeyPress}
					onKeyDown={(ev) => {
						if (ev.key === 'ArrowDown' || ev.key === 'ArrowUp') {
							onFinished();
						}
					}}
					classes={{
						input: classes.input,
						underline: classes.underline,
					}}
					style={props.style}
					value={value ? value : ''}
					onChange={(e) => {
						const value = e.target.value;
						setValue(value);
					}}
					endAdornment={
						<InputAdornment className={classes.endAdornment} position={'end'}>
							<Typography variant="subtitle1">{props.endAdornment}</Typography>
						</InputAdornment>
					}
					disableUnderline={true}
					inputComponent={maskedTextField}
					onFocus={handleFocus}
				/>
			</Tooltip>
		</FormControl>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: '#FF0000',
		color: '#ffffff',
	},
	input: {
		color: 'black',
		fontSize: theme.typography.subtitle1.fontSize,
		fontWeight: theme.typography.subtitle1.fontWeight,
		fontFamily: theme.typography.subtitle1.fontFamily,
		textAlign: 'right',
		paddingLeft: '10px',
		width: '100%',
		paddingBottom: 8,
	},

	underline: {
		'&:hover:before': {
			backgroundColor: '#d3d3d3 !important',
			height: 2,
		},
		'&:before': {
			backgroundColor: '#d3d3d3',
			height: 2,
		},
		'&:after': {
			backgroundColor: '#d3d3d3',
			height: 2,
		},
	},
	endAdornment: {
		color: 'black',
		padding: '4px 5px 0 0',
		marginBottom: 5,
	},

	numberField: {
		width: 150,
		borderColor: 'black',
		textAlign: 'right',
		borderWidth: 1,
		marginTop: 5,
		marginRight: 5,
		marginBottom: 5,
	},
}));

export default withWidth()(NumberField);
