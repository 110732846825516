//prettier-ignore
import { Action, ActionType, Route, Step, StepId, SubStep, SubStepId } from "../model/model";
import createReducer from './createReducer';

const steps: Step[] = [
	{
		id: StepId.PRODUCT_SELECTION,
		name: 'steps.productSelection.name',
		description: 'steps.productSelection.description',
		route: Route.PRODUCT_SELECTION_PRODUCT_FAMILIES,
		requiredCompletedSteps: [],
		subSteps: [
			// {
			// 	id: SubStepId.PRODUCT_SELECTION_PRODUCT_CATEGORIES,
			// 	name: "steps.productSelection.productCategory",
			// 	route: Route.PRODUCT_SELECTION,
			// 	completed: false,
			// },
			{
				id: SubStepId.PRODUCT_SELECTION_PRODUCT_FAMILIES,
				name: 'steps.productSelection.productFamily',
				route: Route.PRODUCT_SELECTION_PRODUCT_FAMILIES,
				completed: false,
				disabled: true,
				requiredCompletedSubSteps: [],
			},
			// {
			// 	id: SubStepId.PRODUCT_SELECTION_PRODUCT_TYPES,
			// 	name: "steps.productSelection.productSeriesModules",
			// 	route: Route.PRODUCT_SELECTION_PRODUCT_TYPES,
			// 	completed: false,
			// 	disabled: true,
			// 	requiredCompletedSubSteps: [
			// 		SubStepId.PRODUCT_SELECTION_PRODUCT_CATEGORIES,
			// 		SubStepId.PRODUCT_SELECTION_PRODUCT_FAMILIES,
			// 	],
			// },
		],
	},
	{
		id: StepId.STRUCTURAL_ELEMENTS,
		name: 'steps.structuralElements.name',
		description: 'steps.structuralElements.description',
		progress: 100,
		route: Route.STRUCTURAL_ELEMENTS, // Route is set to step route, otherwise first substep wouldn´t be shown as active, without clicking it explicitly
		disabled: true,
		requiredCompletedSteps: [StepId.PRODUCT_SELECTION],
		subSteps: [
			{
				id: SubStepId.STRUCTURAL_ELEMENTS_DIMENSION,
				name: 'steps.structuralElements.dimension',
				route: Route.STRUCTURAL_ELEMENTS,
				completed: true,
			},
			{
				id: SubStepId.STRUCTURAL_ELEMENTS_DOOR_SPECIFICATION,
				name: 'steps.structuralElements.doorSpecification',
				route: Route.STRUCTURAL_ELEMENTS_DOOR_SPECIFICATION,
				completed: true,
			},
			// {
			// 	id: SubStepId.STRUCTURAL_ELEMENTS_WINDOWS_SPECIFICATION,
			// 	name: "steps.structuralElements.windowSpecification",
			// 	route: Route.STRUCTURAL_ELEMENTS_WINDOWS_SPECIFICATION,
			// 	completed: false,
			// },
			{
				id: SubStepId.STRUCTURAL_ELEMENTS_INTERIOR_DETAILS,
				name: 'steps.structuralElements.interiorDetails',
				route: Route.STRUCTURAL_ELEMENTS_INTERIOR_DETAILS,
				completed: true,
			},
		],
	},
	{
		id: StepId.TECHNICAL_DETAILS,
		name: 'steps.technicalDetails.name',
		description: 'steps.technicalDetails.description',
		route: Route.TECHNICAL_DETAILS,
		progress: 100,
		disabled: true,
		requiredCompletedSteps: [StepId.PRODUCT_SELECTION],
		subSteps: [
			{
				id: SubStepId.TECHNICAL_DETAILS_ELECTRICAL,
				name: 'steps.technicalDetails.electrical',
				route: Route.TECHNICAL_DETAILS,
				completed: true,
			},
			{
				id: SubStepId.TECHNICAL_DETAILS_HEATING,
				name: 'steps.technicalDetails.heating',
				route: Route.TECHNICAL_DETAILS_HEATING,
				completed: true,
			},
			{
				id: SubStepId.TECHNICAL_DETAILS_VENTILATION,
				name: 'steps.technicalDetails.ventilation',
				route: Route.TECHNICAL_DETAILS_VENTILATION,
				completed: true,
			},
		],
	},
	// {
	// 	id: StepId.FEATURE_ELEMENTS,
	// 	name: "steps.featureElements.name",
	// 	description: "steps.featureElements.description",
	// 	route: Route.FEATURE_ELEMENTS,
	// 	progress: 0,
	// 	disabled: true,
	// 	requiredCompletedSteps: [
	// 		StepId.PRODUCT_SELECTION,
	// 		StepId.STRUCTURAL_ELEMENTS,
	// 	],
	// 	subSteps: [
	// 		{
	// 			id: SubStepId.FEATURE_ELEMENTS_SANITARY_ELEMENTS,
	// 			name: "steps.featureElements.sanitaryFeatures",
	// 			route: Route.FEATURE_ELEMENTS, // Route is set to step route, otherwise first substep wouldn´t be shown as active, without clicking it explicitly
	// 			completed: false,
	// 		},
	// 	],
	// },
	// {
	// 	id: StepId.ACCESSORY_ELEMENTS,
	// 	name: "steps.accessoryElements.name",
	// 	description: "steps.accessoryElements.description",
	// 	route: Route.ACCESSORY_ELEMENTS,
	// 	progress: 0,
	// 	disabled: true,
	// 	requiredCompletedSteps: [
	// 		StepId.PRODUCT_SELECTION,
	// 		StepId.STRUCTURAL_ELEMENTS,
	// 		StepId.FEATURE_ELEMENTS,
	// 	],
	// },
	{
		id: StepId.OVERVIEW,
		name: 'steps.overview.name',
		description: 'steps.overview.description',
		route: Route.OVERVIEW,
		progress: 100,
		disabled: true,
		requiredCompletedSteps: [StepId.PRODUCT_SELECTION],
	},
];

function isStepDisabled(step: Step, currentStepState: Step[]): boolean {
	let requiredSteps = step.requiredCompletedSteps;

	let isStepDisabeld = false;

	requiredSteps.forEach((stepId: StepId) => {
		let stepProgress = currentStepState.find((step) => step.id === stepId)!.progress;
		if (stepProgress !== 100) {
			isStepDisabeld = true;
		}
	});

	return isStepDisabeld;
}

function isSubStepDisabled(subStep: SubStep, currentStep: Step): boolean {
	let requiredSubSteps = subStep.requiredCompletedSubSteps;
	if (!requiredSubSteps || !currentStep.subSteps) {
		return false;
	}

	let isSubStepDisabeld = false;

	requiredSubSteps.forEach((subStepId: SubStepId) => {
		let isSubStepCompleted = currentStep.subSteps!.find((subStep) => subStep.id === subStepId)!.completed;
		if (!isSubStepCompleted) {
			isSubStepDisabeld = true;
		}
	});

	return isSubStepDisabeld;
}

export const stepList = createReducer<Step[]>(steps, {
	[ActionType.UPDATE_STEP](state: Step[], action: Action<Step>) {
		let updatedState = state.map((t) => (t.id === action.payload.id ? { ...t, ...action.payload } : t));

		return updatedState.map((t) => {
			//a step is disabled of at least one required step of it has a progress < 100%
			let isStepDiabeld = isStepDisabled(t, updatedState);
			let updatedStep = { ...t };
			if (t.subSteps) {
				updatedStep.subSteps = t.subSteps.map((s) => {
					let isSubStepDiabeld = isSubStepDisabled(s, t);
					return { ...s, disabled: isSubStepDiabeld };
				});
			}
			return { ...updatedStep, disabled: isStepDiabeld };
		});
	},
	[ActionType.UPDATE_SUB_STEP](state: Step[], action: Action<SubStep>) {
		let updatedState = state.map((t) => {
			if (t.subSteps) {
				t.subSteps = t.subSteps.map((subStep: SubStep) =>
					subStep.id === action.payload.id ? action.payload : subStep
				);
			}
			return t;
		});

		return updatedState;
	},
	[ActionType.RESET_STEPS](state: Step[], action: Action<SubStep>) {
		return steps;
	},
	[ActionType.RESET_ROOMSPEC](state: Step[], action: Action<void>) {
		return steps;
	},
});
