import {
	FeatureElement,
	LengthUnit,
	RoomConfiguration,
	RoomDimensions,
	RoomSpec,
	Unit,
	WallAlignment,
} from '../../../model/model';
import { convertLengthUnitToCentimeter } from '../LengthUnitConverter';
import {
	getLeftAdjacentWallAlignment,
	getOppositePositionAtWallBasedOnWallAlignments,
	getRightAdjacentWallAlignment,
	getSecondInstallationWall,
} from './wallCalculationFunctions';

/* calculates coordinates for drawing in canvas
   since the coordinates stored in the room spec are always centered (origin of element position is the center of the element )
   and origin of the drawing coordinates is the left upper corner this function also recaculates the coordiantes from centered origin to the upper left corner origin
*/
export function calculateWallElementPosition(
	xCoordinate: LengthUnit,
	yCoordinate: LengthUnit,
	elementHeight: number,
	elementWidth: number,
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	// if true, calculates the position of the element inside of the module, e.g. shower
	//if false calculates the position outside of the walls, e.g. door
	setPositionInsideOfWalls: boolean
) {
	let elementXPosition = dim.bathRoomModuleXZeroPoint;
	let elementYPosition = dim.bathRoomModuleYZeroPoint;

	if (!setPositionInsideOfWalls) {
		elementXPosition = dim.roomXZeroPoint;
		elementYPosition = dim.roomYZeroPoint;
	}

	let elementDimArrowBaseXPosition = 0;
	let elementDimArrowBaseYPosition = 0;

	let elYCoord = convertLengthUnitToCentimeter(yCoordinate);
	let elXCoord = convertLengthUnitToCentimeter(xCoordinate);
	let alignment: 'vertical' | 'horizontal' = 'horizontal';

	let arrowLabelPosition: 'above' | 'below' | 'left' | 'right' = 'below';
	if (wallAlignment) {
		switch (wallAlignment) {
			case WallAlignment.WEST:
				elementXPosition += elXCoord - elementHeight / 2;
				elementYPosition += elYCoord - elementWidth / 2;
				if (!setPositionInsideOfWalls) {
					elementYPosition += dim.wallNorthDepth;
				}
				elementDimArrowBaseXPosition += dim.arrowLabelMargin;
				elementDimArrowBaseYPosition += dim.bathRoomModuleYZeroPoint;
				arrowLabelPosition = 'left';
				break;
			case WallAlignment.EAST:
				elementXPosition += elXCoord + dim.moduleWidthInCM - elementHeight / 2;

				elementYPosition += elYCoord - elementWidth / 2;
				if (!setPositionInsideOfWalls) {
					elementXPosition += dim.wallEastDepth + dim.wallWestDepth;
					elementYPosition += dim.wallNorthDepth;
				}

				elementDimArrowBaseXPosition +=
					dim.roomXZeroPoint +
					dim.dimensionWidth +
					dim.marginDimensionArrowsEast -
					dim.generalMarginForDimensionArrows -
					dim.arrowLabelMargin;
				elementDimArrowBaseYPosition += dim.bathRoomModuleYZeroPoint;
				arrowLabelPosition = 'right';
				break;
			case WallAlignment.NORTH:
				elementXPosition += elXCoord - elementWidth / 2;
				if (!setPositionInsideOfWalls) {
					elementXPosition += dim.wallWestDepth;
				}

				elementYPosition += elYCoord - elementHeight / 2;
				elementDimArrowBaseXPosition += dim.bathRoomModuleXZeroPoint;
				elementDimArrowBaseYPosition += dim.generalMarginForDimensionArrows + dim.arrowLabelMargin;
				arrowLabelPosition = 'above';
				break;
			case WallAlignment.SOUTH:
				elementXPosition += elXCoord - elementWidth / 2;
				elementYPosition += elYCoord + dim.moduleLengthInCM - elementHeight / 2;
				if (!setPositionInsideOfWalls) {
					elementYPosition += dim.wallNorthDepth + dim.wallSouthDepth;
					elementXPosition += dim.wallWestDepth;
				}
				elementDimArrowBaseXPosition += dim.bathRoomModuleXZeroPoint;
				elementDimArrowBaseYPosition +=
					dim.roomYZeroPoint +
					dim.dimensionLength +
					dim.roomWallSouthDepth +
					dim.roomWallNorthDepth +
					dim.marginDimensionArrowsSouth -
					dim.arrowLabelMargin;
				arrowLabelPosition = 'below';
				break;
		}

		alignment =
			wallAlignment === WallAlignment.EAST || wallAlignment === WallAlignment.WEST ? 'vertical' : 'horizontal';
	}

	return {
		elementXPosition,
		elementYPosition,
		elementDimArrowBaseXPosition,
		elementDimArrowBaseYPosition,
		alignment,
		arrowLabelPosition,
	};
}

export function calculateMovementAreaPosition(
	elementXCoord: number,
	elementYCoord: number,
	elementHeight: number,
	movementAreaWidth: number,
	movementAreaHeight: number,
	wallAlignment: WallAlignment
) {
	let xPosition = elementXCoord;
	let yPosition = elementYCoord;

	switch (wallAlignment) {
		case WallAlignment.WEST:
			xPosition += movementAreaHeight / 2 + elementHeight / 2;

			break;
		case WallAlignment.EAST:
			xPosition -= movementAreaHeight / 2 + elementHeight / 2;
			break;
		case WallAlignment.NORTH:
			yPosition += movementAreaHeight / 2 + elementHeight / 2;
			break;
		case WallAlignment.SOUTH:
			yPosition -= movementAreaHeight / 2 + elementHeight / 2;
			break;
	}

	return {
		xPosition,
		yPosition,
		movementAreaHeight,
		movementAreaWidth,
	};
}

export function calculateRoomElementCoordinates(
	featureElementCat: string,
	elementWidth: LengthUnit,
	elementHeight: LengthUnit,
	movementAreaWidth: LengthUnit,
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	featureElements: { [featureElCategory: string]: FeatureElement },
	isSinkAndWCPositionSwapped: boolean,
	showerPosition: 'left' | 'right',
	productFamiliyId: string,
	productFamilyVariantId: string
) {
	let xCoordinate = 0;
	let yCoordinate = 0;

	let elementHeightInCM = convertLengthUnitToCentimeter(elementHeight);
	let elementWidthInCM = convertLengthUnitToCentimeter(elementWidth);
	const movementAreaWidthInCM = convertLengthUnitToCentimeter(movementAreaWidth);

	switch (featureElementCat) {
		case 'SH':
			let showerCoordinates = getElementCoordinatesForPositionAtWall(
				wallAlignment,
				dim,
				elementWidthInCM,
				elementHeightInCM,
				movementAreaWidthInCM,
				showerPosition,
				0,
				0
			);

			xCoordinate = showerCoordinates.xCoordinate;
			yCoordinate = showerCoordinates.yCoordinate;

			break;
		case 'WC':
			let wcCoordinates = calculateWCElementCoordinates(
				elementHeightInCM,
				elementWidthInCM,
				movementAreaWidthInCM,
				wallAlignment,
				dim,
				featureElements,
				isSinkAndWCPositionSwapped,
				showerPosition,
				productFamiliyId,
				productFamilyVariantId
			);
			xCoordinate = wcCoordinates.xCoordinate;
			yCoordinate = wcCoordinates.yCoordinate;

			break;
		case 'SI':
			let wbCoordinates = calculateSinkElementCoordinates(
				elementHeightInCM,
				elementWidthInCM,
				movementAreaWidthInCM,
				wallAlignment,
				dim,
				featureElements,
				isSinkAndWCPositionSwapped,
				showerPosition
			);
			xCoordinate = wbCoordinates.xCoordinate;
			yCoordinate = wbCoordinates.yCoordinate;
			break;
	}

	return {
		xCoordinate: { value: xCoordinate, unit: Unit.CENTI_METER },
		yCoordinate: { value: yCoordinate, unit: Unit.CENTI_METER },
	};
}

/**
 * updates wall alignment of WC and Sink for Sinoki product family, since it is depended on the shower position and wall alignment
 */
export function getUpdatedWallAlignment(
	featureElementCat: string,
	isSinkAndWCPositionSwapped: boolean,
	secondInstallationWallDirection: 'left' | 'right',
	wallAlignmentReferencedElement: WallAlignment
) {
	let secondInstallationWallForSinkOrWC = getSecondInstallationWall(
		wallAlignmentReferencedElement,
		secondInstallationWallDirection
	);

	let wall = wallAlignmentReferencedElement;

	switch (featureElementCat) {
		case 'WC':
			wall = isSinkAndWCPositionSwapped ? secondInstallationWallForSinkOrWC : wallAlignmentReferencedElement;

			break;
		case 'SI':
			wall = isSinkAndWCPositionSwapped ? wallAlignmentReferencedElement : secondInstallationWallForSinkOrWC;

			break;
	}

	return wall;
}

export function getMovementAreaDimension(
	roomElementConf: FeatureElement,
	elementXPosition: number,
	elementYPosition: number,
	elementHeight: number,
	elementWidth: number,
	wallAlignment: WallAlignment
) {
	let orgMovementAreaHeight = roomElementConf.movementAreaHeight
		? convertLengthUnitToCentimeter(roomElementConf.movementAreaHeight)
		: 0;
	let orgMovementAreaWidth = roomElementConf.movementAreaWidth
		? convertLengthUnitToCentimeter(roomElementConf.movementAreaWidth)
		: 0;

	let movementAreaXPosition = 0;
	let movementAreaYPosition = 0;

	let { xPosition, yPosition, movementAreaHeight, movementAreaWidth } = calculateMovementAreaPosition(
		elementXPosition,
		elementYPosition,
		elementHeight,
		orgMovementAreaWidth,
		orgMovementAreaHeight,
		wallAlignment
	);

	movementAreaXPosition = xPosition;
	movementAreaYPosition = yPosition;
	return {
		movementAreaXPosition,
		movementAreaYPosition,
		movementAreaHeight,
		movementAreaWidth,
	};
}

export function calculateWCElementCoordinates(
	elementHeight: number,
	elementWidth: number,
	movementAreaWidthInCM: number,
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	featureElements: { [featureElCategory: string]: FeatureElement },
	isSinkAndWCPositionSwapped: boolean,
	showerPosition: 'left' | 'right',
	productFamiliyId: string,
	productFamilyVariantId: string
) {
	let wcPositionAtWall:
		| 'center'
		| 'left'
		| 'right'
		| 'centerBetweenShowerAndMovementAreaOfElementOnAdjacentWall'
		| 'centerBetweenShowerAndOtherElement' = getElementPositionAtWall(
		'WC',
		wallAlignment,
		featureElements,
		!isSinkAndWCPositionSwapped,
		showerPosition
	);

	let coordinates = getCoordinatesForWCOrSink(
		'WC',
		elementHeight,
		elementWidth,
		movementAreaWidthInCM,
		wcPositionAtWall,
		wallAlignment,
		dim,
		featureElements,
		showerPosition
	);

	// keep space for radiator if hinoki common
	if (productFamiliyId === 'hinoki' && productFamilyVariantId === 'common') {
		coordinates.xCoordinate = coordinates.xCoordinate - 10;
	}

	return {
		xCoordinate: coordinates.xCoordinate,
		yCoordinate: coordinates.yCoordinate,
	};
}

//calculates the position of sink or wc (depending on the parameter 'elementCat') on a wall
//param 'isElementCatOnTheRightSideOfOhterFeatureElement' is used to define if the element (e.g. WC ) should be on the left or right side of the other element (in this example SI).

export function getElementPositionAtWall(
	elementCat: string,
	wallAlignment: WallAlignment,
	featureElements: { [featureElCategory: string]: FeatureElement },
	isElementCatOnTheRightSideOfOhterFeatureElement: boolean,
	showerPosition: 'left' | 'right'
) {
	let roomElementsOnSameWall: FeatureElement[] = Object.values(featureElements).filter(
		(el) => el.wallAlignment === wallAlignment && el.featureElementCategory !== elementCat
	);

	let shower = featureElements['SH'];

	let elementCatOfOtherElement = elementCat === 'WC' ? 'SI' : 'WC';

	let positionAtWall:
		| 'center'
		| 'left'
		| 'right'
		| 'centerBetweenShowerAndMovementAreaOfElementOnAdjacentWall'
		| 'centerBetweenShowerAndOtherElement' = 'center';
	//calculate positions for elements of shower position is left (default) or shower was selected
	if (roomElementsOnSameWall.length > 1) {
		positionAtWall = isElementCatOnTheRightSideOfOhterFeatureElement
			? 'right'
			: 'centerBetweenShowerAndOtherElement';
	} else if (roomElementsOnSameWall.length === 1) {
		positionAtWall =
			roomElementsOnSameWall[0].featureElementCategory === 'SH'
				? featureElements[elementCatOfOtherElement]
					? 'centerBetweenShowerAndMovementAreaOfElementOnAdjacentWall'
					: 'center'
				: isElementCatOnTheRightSideOfOhterFeatureElement
				? 'right'
				: 'left';
	} else {
		//check if shower is set, if yes element is in center (sinoki common),
		//else element is at the opposite end of the wall, depending on sink wallalignment (suki guest)
		if (shower && shower.wallAlignment) {
			positionAtWall = 'center';
		} else {
			let sinkWallAlignment = featureElements[elementCatOfOtherElement]
				? featureElements[elementCatOfOtherElement].wallAlignment
				: undefined;
			if (sinkWallAlignment) {
				positionAtWall = getOppositePositionAtWallBasedOnWallAlignments(wallAlignment, sinkWallAlignment);
			}
		}
	}

	//todo check if still needed
	if (shower && showerPosition === 'right') {
		positionAtWall = positionAtWall === 'right' ? 'left' : positionAtWall;
	}

	return positionAtWall;
}

function getCoordinatesForWCOrSink(
	elementCat: string,
	elementHeight: number,
	elementWidth: number,
	movementAreaWidthInCM: number,
	positionAtWall:
		| 'center'
		| 'left'
		| 'right'
		| 'centerBetweenShowerAndMovementAreaOfElementOnAdjacentWall'
		| 'centerBetweenShowerAndOtherElement',
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	featureElements: { [featureElCategory: string]: FeatureElement },
	showerPosition: 'left' | 'right'
) {
	let shower = featureElements['SH'];

	let otherElementWhichIsNotShower = elementCat === 'WC' ? 'SI' : 'WC';

	let coordinates = undefined;
	if (positionAtWall === 'centerBetweenShowerAndMovementAreaOfElementOnAdjacentWall') {
		let adjacentWallDirection: 'left' | 'right' = showerPosition === 'left' ? 'right' : 'left';
		let {
			firstElementPosition,
			secondElementPosition,
		} = getFirstAndSecondElementPositionForShowerAndElementOnAdjacentWall(
			wallAlignment,
			dim,
			shower,
			featureElements[otherElementWhichIsNotShower]!,
			showerPosition,
			adjacentWallDirection,
			true
		);
		coordinates = getElementCoordinatesForPositionAtWall(
			wallAlignment,
			dim,
			elementWidth,
			elementHeight,
			movementAreaWidthInCM,
			'centerBetweenTwoPositions',
			0,
			0,
			firstElementPosition,
			secondElementPosition
		);
	} else if (positionAtWall === 'centerBetweenShowerAndOtherElement') {
		let showerWidth = convertLengthUnitToCentimeter(shower.elementWidth);
		let secondElement = featureElements[otherElementWhichIsNotShower];

		let secondElementStart = getInnerPositionOfElementInOppositeCornerOfShowerOnSameWall(
			secondElement,
			wallAlignment,
			showerPosition
		);

		let { firstElementPosition, secondElementPosition } = adaptShowerWidthAndSeconElementPositionToWallAlignment(
			wallAlignment,
			dim,
			showerWidth,
			secondElementStart,
			showerPosition
		);

		coordinates = getElementCoordinatesForPositionAtWall(
			wallAlignment,
			dim,
			elementWidth,
			elementHeight,
			movementAreaWidthInCM,
			'centerBetweenTwoPositions',
			0,
			0,
			firstElementPosition,
			secondElementPosition
		);
	} else {
		coordinates = getElementCoordinatesForPositionAtWall(
			wallAlignment,
			dim,
			elementWidth,
			elementHeight,
			movementAreaWidthInCM,
			positionAtWall,
			0,
			0
		);
	}

	return coordinates;
}

//gets inner position of element (the coordinates of the corner of the element, that is close to the shower) that is on the same wall as the shower, but on the opposite side
//used for calculating the second element position for hinoki common
//shower position is not considered, since this will be done outside this function (we assume shower position is left like it is in the default)
function getInnerPositionOfElementInOppositeCornerOfShowerOnSameWall(
	element: FeatureElement,
	wallAlignment: WallAlignment,
	showerPosition: 'left' | 'right'
) {
	let innerElementPosition = 0;

	switch (wallAlignment) {
		case WallAlignment.EAST:
			innerElementPosition =
				showerPosition === 'left'
					? convertLengthUnitToCentimeter(element!.yCoordinate) -
					  convertLengthUnitToCentimeter(element.elementWidth) / 2
					: convertLengthUnitToCentimeter(element!.yCoordinate) +
					  convertLengthUnitToCentimeter(element.elementWidth) / 2;
			break;
		case WallAlignment.WEST:
			innerElementPosition =
				showerPosition === 'left'
					? convertLengthUnitToCentimeter(element!.yCoordinate) +
					  convertLengthUnitToCentimeter(element.elementWidth) / 2
					: convertLengthUnitToCentimeter(element!.yCoordinate) -
					  convertLengthUnitToCentimeter(element.elementWidth) / 2;
			break;
		case WallAlignment.NORTH:
			innerElementPosition =
				showerPosition === 'left'
					? convertLengthUnitToCentimeter(element!.xCoordinate) -
					  convertLengthUnitToCentimeter(element.elementWidth) / 2
					: convertLengthUnitToCentimeter(element!.xCoordinate) +
					  convertLengthUnitToCentimeter(element.elementWidth) / 2;
			break;
		case WallAlignment.SOUTH:
			innerElementPosition =
				showerPosition === 'left'
					? convertLengthUnitToCentimeter(element!.xCoordinate) +
					  convertLengthUnitToCentimeter(element.elementWidth) / 2
					: convertLengthUnitToCentimeter(element!.xCoordinate) -
					  convertLengthUnitToCentimeter(element.elementWidth) / 2;
			break;
	}

	return innerElementPosition;
}

//gets frist and second element position for caclulating the element position at wall in the center between the shower and the element on the adjacent wall
function getFirstAndSecondElementPositionForShowerAndElementOnAdjacentWall(
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	shower: FeatureElement,
	elementOnAdjacentWall: FeatureElement,
	showerWallPositionLeftRight: 'left' | 'right',
	adjacentWallDirection: 'left' | 'right',
	includeMovementAreaOfElement: boolean
) {
	let moveAreaStartPositionOFElementOnAdjacentWall = getStartPositionOFElementOnAdjacentWall(
		elementOnAdjacentWall,
		wallAlignment,
		dim,
		adjacentWallDirection,
		includeMovementAreaOfElement
	);

	let showerWidth = convertLengthUnitToCentimeter(shower.elementWidth);

	let { firstElementPosition, secondElementPosition } = adaptShowerWidthAndSeconElementPositionToWallAlignment(
		wallAlignment,
		dim,
		showerWidth,
		moveAreaStartPositionOFElementOnAdjacentWall,
		showerWallPositionLeftRight
	);

	return { firstElementPosition, secondElementPosition };
}

function adaptShowerWidthAndSeconElementPositionToWallAlignment(
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	showerWidth: number,
	secondElementPosition: number,
	showerWallPositionLeftRight: 'left' | 'right'
) {
	switch (wallAlignment) {
		case WallAlignment.EAST:
			[showerWidth, secondElementPosition] =
				showerWallPositionLeftRight === 'left'
					? [showerWidth, secondElementPosition]
					: [secondElementPosition, dim.moduleLengthInCM - showerWidth];
			break;
		case WallAlignment.WEST:
			[showerWidth, secondElementPosition] =
				showerWallPositionLeftRight === 'left'
					? [secondElementPosition, dim.moduleLengthInCM - showerWidth]
					: [showerWidth, secondElementPosition];
			break;
		case WallAlignment.NORTH:
			[showerWidth, secondElementPosition] =
				showerWallPositionLeftRight === 'left'
					? [showerWidth, secondElementPosition]
					: [secondElementPosition, dim.moduleWidthInCM - showerWidth];
			break;
		case WallAlignment.SOUTH:
			[showerWidth, secondElementPosition] =
				showerWallPositionLeftRight === 'left'
					? [secondElementPosition, dim.moduleWidthInCM - showerWidth]
					: [showerWidth, secondElementPosition];
			break;
	}

	return { firstElementPosition: showerWidth, secondElementPosition };
}

export function getStartPositionOFElementOnAdjacentWall(
	element: FeatureElement,
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	adjacentWallDirection: 'left' | 'right',
	includeMovementAreaOfElement: boolean
) {
	let moveAreaStartPositionOFElementOnAdjacentWall = 0;

	if (!element) {
		return moveAreaStartPositionOFElementOnAdjacentWall;
	}

	let adjacentWallAlignemnt =
		adjacentWallDirection === 'left'
			? getLeftAdjacentWallAlignment(wallAlignment)
			: getRightAdjacentWallAlignment(wallAlignment);
	let elementHeightInCM = convertLengthUnitToCentimeter(element.elementHeight);
	let movementAreaHeightInCM = convertLengthUnitToCentimeter(element.movementAreaHeight);

	switch (adjacentWallAlignemnt) {
		case WallAlignment.WEST:
			moveAreaStartPositionOFElementOnAdjacentWall = includeMovementAreaOfElement
				? elementHeightInCM + movementAreaHeightInCM
				: elementHeightInCM;
			break;
		case WallAlignment.EAST:
			moveAreaStartPositionOFElementOnAdjacentWall = includeMovementAreaOfElement
				? dim.moduleWidthInCM - (elementHeightInCM + movementAreaHeightInCM)
				: dim.moduleWidthInCM - elementHeightInCM;
			break;
		case WallAlignment.SOUTH:
			moveAreaStartPositionOFElementOnAdjacentWall = includeMovementAreaOfElement
				? dim.moduleLengthInCM - (elementHeightInCM + movementAreaHeightInCM)
				: dim.moduleLengthInCM - elementHeightInCM;
			break;
		case WallAlignment.NORTH:
			moveAreaStartPositionOFElementOnAdjacentWall = includeMovementAreaOfElement
				? elementHeightInCM + movementAreaHeightInCM
				: elementHeightInCM;
			break;
	}

	return moveAreaStartPositionOFElementOnAdjacentWall;
}

export function calculateRoomElementCoordinatesWithoutMovementArea(
	roomElement: FeatureElement,
	dimension: RoomDimensions,
	roomConfig: RoomConfiguration,
	roomSpec: RoomSpec,
	featureElements: { [featureElCategory: string]: FeatureElement }
) {
	let { xCoordinate, yCoordinate } = calculateRoomElementCoordinates(
		roomElement.featureElementCategory,
		roomElement.elementWidth,
		roomElement.elementHeight,
		roomElement.movementAreaWidth,
		roomElement.wallAlignment,
		dimension,
		featureElements,
		roomConfig.isSinkAndWCPositionSwapped,
		roomConfig.showerWallPositionLeftRight,
		roomSpec.productFamilyId,
		roomSpec.productFamilyVariantId
	);

	return {
		xCoordinate,
		yCoordinate,
	};
}

export function calculateSinkElementCoordinates(
	elementHeight: number,
	elementWidth: number,
	movementAreaWidthInCM: number,
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	featureElements: { [featureElCategory: string]: FeatureElement },
	isSinkAndWCPositionSwapped: boolean,
	showerPosition: 'left' | 'right'
) {
	let sinkPositionAtWall:
		| 'center'
		| 'left'
		| 'right'
		| 'centerBetweenShowerAndMovementAreaOfElementOnAdjacentWall'
		| 'centerBetweenShowerAndOtherElement' = getElementPositionAtWall(
		'SI',
		wallAlignment,
		featureElements,
		isSinkAndWCPositionSwapped,
		showerPosition
	);

	let coordinates = getCoordinatesForWCOrSink(
		'SI',
		elementHeight,
		elementWidth,
		movementAreaWidthInCM,
		sinkPositionAtWall,
		wallAlignment,
		dim,
		featureElements,
		showerPosition
	);

	return {
		xCoordinate: coordinates.xCoordinate,
		yCoordinate: coordinates.yCoordinate,
	};
}

//return the height of the last (most right) positioned of the left adjacent wall of the given wall
export function getHeightOfLastOrFirstWallElementOnAdjacentWall(
	wallAlignemnt: WallAlignment,
	featureElements: {
		[featureElCategory: string]: FeatureElement;
	},
	leftOrRightAdjacentWall: 'left' | 'right',
	heightOfFirstOrLastElementOnWall: 'first' | 'last'
) {
	let adjacentWallAlignemnt =
		leftOrRightAdjacentWall === 'left'
			? getLeftAdjacentWallAlignment(wallAlignemnt)
			: getRightAdjacentWallAlignment(wallAlignemnt);

	return getHeightOfElementOnWall(
		adjacentWallAlignemnt,
		Object.values(featureElements),
		heightOfFirstOrLastElementOnWall
	);
}

//return the height of the last (most right) positioned
export function getHeightOfElementOnWall(
	wallAlignemnt: WallAlignment,
	featureElements: FeatureElement[],
	heightOfFirstOrLastElementOnWall: 'first' | 'last'
) {
	let elementOnWall =
		heightOfFirstOrLastElementOnWall === 'last'
			? getMostRightPositionedElementAtWall(wallAlignemnt, featureElements)
			: getMostLeftPositionedElementAtWall(wallAlignemnt, featureElements);

	return elementOnWall ? elementOnWall.elementHeight : { value: 0, unit: Unit.CENTI_METER };
}

export function getMostRightPositionedElementAtWall(wallAlignment: WallAlignment, elements: FeatureElement[]) {
	let elementsOnWall = elements.filter((el) => el.wallAlignment === wallAlignment);

	let mostRightElementOnWall = undefined;

	if (elementsOnWall.length === 0) {
		return mostRightElementOnWall;
	}

	switch (wallAlignment) {
		case WallAlignment.WEST:
			mostRightElementOnWall = elementsOnWall.reduce((prev, current) =>
				convertLengthUnitToCentimeter(prev.yCoordinate) < convertLengthUnitToCentimeter(current.yCoordinate)
					? prev
					: current
			);
			break;
		case WallAlignment.EAST:
			mostRightElementOnWall = elementsOnWall.reduce((prev, current) =>
				convertLengthUnitToCentimeter(prev.yCoordinate) > convertLengthUnitToCentimeter(current.yCoordinate)
					? prev
					: current
			);
			break;
		case WallAlignment.SOUTH:
			mostRightElementOnWall = elementsOnWall.reduce((prev, current) =>
				convertLengthUnitToCentimeter(prev.xCoordinate) < convertLengthUnitToCentimeter(current.xCoordinate)
					? prev
					: current
			);
			break;
		case WallAlignment.NORTH:
			mostRightElementOnWall = elementsOnWall.reduce((prev, current) =>
				convertLengthUnitToCentimeter(prev.xCoordinate) > convertLengthUnitToCentimeter(current.xCoordinate)
					? prev
					: current
			);
			break;
	}

	return mostRightElementOnWall;
}

// most left -> view from standing in front of wall
export function getMostLeftPositionedElementAtWall(wallAlignment: WallAlignment, elements: FeatureElement[]) {
	let elementsOnWall = elements.filter((el) => el.wallAlignment === wallAlignment);

	let mostLeftElementOnWall = undefined;

	if (elementsOnWall.length === 0) {
		return mostLeftElementOnWall;
	}

	switch (wallAlignment) {
		case WallAlignment.WEST:
			mostLeftElementOnWall = elementsOnWall.reduce((prev, current) =>
				convertLengthUnitToCentimeter(prev.yCoordinate) > convertLengthUnitToCentimeter(current.yCoordinate)
					? prev
					: current
			);
			break;
		case WallAlignment.EAST:
			mostLeftElementOnWall = elementsOnWall.reduce((prev, current) =>
				convertLengthUnitToCentimeter(prev.yCoordinate) < convertLengthUnitToCentimeter(current.yCoordinate)
					? prev
					: current
			);
			break;
		case WallAlignment.SOUTH:
			mostLeftElementOnWall = elementsOnWall.reduce((prev, current) =>
				convertLengthUnitToCentimeter(prev.xCoordinate) > convertLengthUnitToCentimeter(current.xCoordinate)
					? prev
					: current
			);
			break;
		case WallAlignment.NORTH:
			mostLeftElementOnWall = elementsOnWall.reduce((prev, current) =>
				convertLengthUnitToCentimeter(prev.xCoordinate) < convertLengthUnitToCentimeter(current.xCoordinate)
					? prev
					: current
			);
			break;
	}

	return mostLeftElementOnWall;
}

/*calcualtes the coordinates for an element on the given wall 
@param wallAlignment: all where the element should be positioned
@param dim: room dimensions
@param elementHeight / elementWidth: element height and with in cm
@param positionAtWall: position of element at wall (always seen from the view in front of wall), margin to wall can be set by the parameter 'marginForOtherElementOrWallInCM'
	position 'centerBetweenTwoPositions': calculates the coordinates for the element positioned in the middle between two elements, set by the parameters 'firstPositionForCenterBetweenTwoPosition' and 'secondPositionForCenterBetweenTwoPosition'
	e.g. middle between end of shower and movement area of room element at adjacent wall (e.g. for suki common)
*/
export function getElementCoordinatesForPositionAtWall(
	wallAlignment: WallAlignment,
	dim: RoomDimensions,
	elementWidthInCM: number,
	elementHeightInCM: number,
	elementMovementAreaWidth: number,
	positionAtWall: 'left' | 'right' | 'center' | 'centerBetweenTwoPositions',
	marginToStartElementOrWallInCM: number,
	marginToSecondElementOrWallInCM: number,
	firstPositionForCenterBetweenTwoPosition?: number,
	secondPositionForCenterBetweenTwoPosition?: number
) {
	let yCoordinate = 0;
	let xCoordinate = 0;

	let movementAreaOverlap = (elementMovementAreaWidth - elementWidthInCM) / 2;

	if (movementAreaOverlap > 0) {
		marginToStartElementOrWallInCM += movementAreaOverlap;
		marginToSecondElementOrWallInCM += movementAreaOverlap;
	}

	if (
		positionAtWall === 'centerBetweenTwoPositions' &&
		(!firstPositionForCenterBetweenTwoPosition || !secondPositionForCenterBetweenTwoPosition)
	) {
		console.error(
			"If position at wall is set to 'centerBetweenTwoPositions', the paramters 'elementStartPositionForcenterBetweenTwoPositions' and 'elementEndPositionForcenterBetweenTwoPositions' are required"
		);
		return {
			xCoordinate,
			yCoordinate,
		};
	} else if (positionAtWall === 'centerBetweenTwoPositions') {
		secondPositionForCenterBetweenTwoPosition! -= marginToSecondElementOrWallInCM;
		firstPositionForCenterBetweenTwoPosition! += marginToStartElementOrWallInCM;
	}

	switch (wallAlignment) {
		case WallAlignment.WEST:
			yCoordinate =
				positionAtWall === 'centerBetweenTwoPositions'
					? (secondPositionForCenterBetweenTwoPosition! + firstPositionForCenterBetweenTwoPosition!) / 2
					: positionAtWall === 'center'
					? dim.moduleLengthInCM / 2
					: positionAtWall === 'left'
					? dim.moduleLengthInCM - elementWidthInCM / 2 - marginToStartElementOrWallInCM
					: elementWidthInCM / 2 + marginToSecondElementOrWallInCM;
			xCoordinate = elementHeightInCM / 2;
			break;
		case WallAlignment.EAST:
			yCoordinate =
				positionAtWall === 'centerBetweenTwoPositions'
					? (secondPositionForCenterBetweenTwoPosition! + firstPositionForCenterBetweenTwoPosition!) / 2
					: positionAtWall === 'center'
					? dim.moduleLengthInCM / 2
					: positionAtWall === 'left'
					? elementWidthInCM / 2 + marginToStartElementOrWallInCM
					: dim.moduleLengthInCM - elementWidthInCM / 2 - marginToSecondElementOrWallInCM;
			xCoordinate = -elementHeightInCM / 2;
			break;
		case WallAlignment.NORTH:
			xCoordinate =
				positionAtWall === 'centerBetweenTwoPositions'
					? (secondPositionForCenterBetweenTwoPosition! + firstPositionForCenterBetweenTwoPosition!) / 2
					: positionAtWall === 'center'
					? dim.moduleWidthInCM / 2
					: positionAtWall === 'left'
					? elementWidthInCM / 2 + marginToStartElementOrWallInCM
					: dim.moduleWidthInCM - elementWidthInCM / 2 - marginToSecondElementOrWallInCM;
			yCoordinate = elementHeightInCM / 2;
			break;
		case WallAlignment.SOUTH:
			xCoordinate =
				positionAtWall === 'centerBetweenTwoPositions'
					? (secondPositionForCenterBetweenTwoPosition! + firstPositionForCenterBetweenTwoPosition!) / 2
					: positionAtWall === 'center'
					? dim.moduleWidthInCM / 2
					: positionAtWall === 'left'
					? dim.moduleWidthInCM - elementWidthInCM / 2 - marginToStartElementOrWallInCM
					: elementWidthInCM / 2 + marginToSecondElementOrWallInCM;
			yCoordinate = -elementHeightInCM / 2;
			break;
	}

	return {
		xCoordinate: Math.round(xCoordinate * 1000) / 1000,
		yCoordinate: Math.round(yCoordinate * 1000) / 1000,
	};
}
