// prettier-ignore
import { Card, CircularProgress, Collapse, makeStyles, Theme, Typography } from '@material-ui/core';
import download from 'downloadjs';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

interface Props {
	children?: React.ReactElement | React.ReactElement[];
	onButtonClick?: () => void;
	buttonText?: string;
	foldableLinks?: Array<{
		label: string;
		link: string;
		isPDFwithToken?: boolean;
	}>;
	loading?: boolean;
}

function ButtonCard(props: Props) {
	const { children, onButtonClick, buttonText, foldableLinks, loading } = props;
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const onClick = () => {
		if (onButtonClick) {
			onButtonClick();
		}
		setExpanded(!expanded);
	};

	return (
		<Card className={classes.root}>
			<div className={classes.childrenContainer}>{children}</div>
			<div className={classes.button} onClick={onClick}>
				{!loading && <Typography variant="button">{buttonText}</Typography>}
				{loading && <CircularProgress className={classes.progress} />}
			</div>
			<Collapse in={expanded}>
				<LinkPane foldableLinks={foldableLinks} />
			</Collapse>
		</Card>
	);
}

function LinkPane(props: {
	foldableLinks?: Array<{
		label: string;
		link: string;
		isPDFwithToken?: boolean;
	}>;
}) {
	const classes = useStyles();
	const token: string = useSelector((state: RootState) => state.token);

	const onClick = (label: string, link: string, isPDFwithToken?: boolean) => () => {
		if (isPDFwithToken) {
			downloadFile(label.replace('PDF', '').trim() + '.pdf', link, token);
		} else {
			window.open(link);
		}
	};

	if (props.foldableLinks) {
		return (
			<div className={classes.linkListContainer}>
				{props.foldableLinks.map((l) => (
					<Typography
						key={l.label}
						variant="body2"
						className={classes.link}
						onClick={onClick(l.label, l.link, l.isPDFwithToken)}
					>
						{'• ' + l.label}
					</Typography>
				))}
			</div>
		);
	} else {
		return null;
	}
}

async function downloadFile(filename: string, url: string, token: string) {
	const response = await fetch(url, {
		method: 'GET',
		headers: new Headers({
			Authorization: 'Token ' + token,
		}),
	});

	const blob = await response.blob();
	download(blob, filename);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		minWidth: 300,
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
	},

	childrenContainer: {
		padding: 20,
	},

	button: {
		height: 60,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		color: 'white',
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.primary.dark,
		},
	},

	linkListContainer: {
		padding: 20,
		backgroundColor: theme.palette.primary.main,
	},

	link: {
		paddingLeft: 20,
		marginBottom: 20,
		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'underline',
		},
		color: 'white',
		fontWeight: 'bold',
	},

	progress: {
		color: 'white',
	},
}));

export default ButtonCard;
