// prettier-ignore
import { FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
	units?: string[];
	selectedUnit?: string;
	onChange: (unit: string) => void;
}

function UnitSwitch(props: Props) {
	const { units, onChange, selectedUnit } = props;
	const classes = useStyles();

	const onUnitChange = (event: any) => {
		const unit = event.target.value;
		onChange(unit);
	};

	if (units && units.length > 1) {
		return (
			<RadioGroup className={classes.unitSwitch} value={selectedUnit} onChange={onUnitChange}>
				{units.map((unit) => {
					return (
						<FormControlLabel
							key={unit}
							value={unit}
							label={<Typography variant="subtitle1">{unit}</Typography>}
							control={<Radio data-cy={'unitSwitch' + unit} className={classes.radio} />}
						/>
					);
				})}
			</RadioGroup>
		);
	} else {
		return null;
	}
}

const useStyles = makeStyles({
	unitSwitch: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop: -15,
	},

	radio: {
		transform: 'scale(0.75, 0.75)',
	},
});

export default UnitSwitch;
