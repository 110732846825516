// prettier-ignore
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import * as _ from 'lodash';
import * as React from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/de';
import { useSelector } from 'react-redux';
import { fetchAppConfig } from '../../actions/appConfig';
import { RoomSpec } from '../../model/model';
import { RootState } from '../../reducers';

export function ReplaceDefaultRoomSpecInAppConfigPanel() {
	const token: string = useSelector((state: RootState) => state.token);
	const [currentAppConfig, setCurrentAppConfig] = React.useState();
	const [modifiedAppConfig, setModifiedAppConfig] = React.useState();
	const [loading, setLoading] = React.useState(true);
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const [copySuccess, setCopySuccess] = React.useState(false);
	const textAreaRef = React.useRef(null);
	const classes = useStyles();

	React.useEffect(() => {
		fetchAppConfig(token).then((appConfig) => {
			setCurrentAppConfig(appConfig);
			setLoading(false);
		});
	}, []);

	React.useEffect(() => {
		// replace defaultRommData with the current roomSpec
		let ac = _.cloneDeep(currentAppConfig) as any;
		if (ac) {
			const indexOfFamily = _.findLastIndex(ac.productCategories[0].productFamilies, [
				'id',
				roomSpec.productFamilyId,
			]);
			const indexOfVariant = _.findLastIndex(
				ac.productCategories[0].productFamilies[indexOfFamily].productFamilyVariants,
				['id', roomSpec.productFamilyVariantId]
			);

			// replace defaultRoomSpec
			ac.productCategories[0].productFamilies[indexOfFamily].productFamilyVariants[
				indexOfVariant
			].defaultRoomData = roomSpec;

			setModifiedAppConfig(ac);
		}
	}, [currentAppConfig]);

	const copyToClipboard = (e: any) => {
		if (textAreaRef && textAreaRef.current) {
			(textAreaRef?.current as any).select();
			document.execCommand('copy');
			// This is just personal preference.
			// I prefer to not show the the whole text area selected.
			e.target.focus();
			setCopySuccess(true);
		}
	};

	if (loading) {
		return <CircularProgress />;
	}
	return (
		<div>
			<div className={classes.clipboardContainer}>
				<Button variant="contained" color="secondary" onClick={copyToClipboard}>
					{copySuccess ? 'Data is in Clipboard' : 'Copy to Clipboard'}
				</Button>
				<form>
					<textarea ref={textAreaRef} value={JSON.stringify(modifiedAppConfig)} />
				</form>
			</div>
			<JSONInput
				id="a_unique_id"
				placeholder={modifiedAppConfig}
				locale={locale}
				height="550px"
				width="100%"
				viewOnly
			/>
		</div>
	);
}

const useStyles = makeStyles({
	clipboardContainer: {
		display: 'flex',
	},
});
