import * as _ from 'lodash';
import {
	FeatureElement,
	LengthUnit,
	RoomConfiguration,
	RoomDimensions,
	RoomSpec,
	Unit,
	WallAlignment,
} from '../../../model/model';
import {
	convertCentiMeterInMeter,
	convertLengthUnitToCentimeter,
	convertLengthUnitToMeter,
} from '../LengthUnitConverter';
import { getMovementAreaDimension } from './calculateElementPositionAndCoordinatesFunctions';
import {
	getLeftAdjacentWallAlignment,
	getModuleWallThickness,
	getRightAdjacentWallAlignment,
	getRoomWallThickness,
	getWallAlignmentOfOppositeWall,
} from './wallCalculationFunctions';

export function getCalculatedRoomDimensions(roomSpec: RoomSpec, roomConfig: RoomConfiguration): RoomDimensions {
	const marginForRoomWalls = 12;
	const generalMarginForDimensionArrows = 32;

	const arrowLabelMargin = 16;

	//the dimension length represenets the room height in konva context
	//dimension with and height contains heigt and width of walls, depending on wall type
	let dimensionWithInCM = convertLengthUnitToCentimeter(roomSpec.dimensionWidth);
	let dimensionLengthInCM = convertLengthUnitToCentimeter(roomSpec.dimensionLength);
	const wallEastDepth = getModuleWallThickness(roomSpec, roomConfig.wallConfig, WallAlignment.EAST);
	const wallNorthDepth = getModuleWallThickness(roomSpec, roomConfig.wallConfig, WallAlignment.NORTH);
	const wallSouthDepth = getModuleWallThickness(roomSpec, roomConfig.wallConfig, WallAlignment.SOUTH);
	const wallWestDepth = getModuleWallThickness(roomSpec, roomConfig.wallConfig, WallAlignment.WEST);

	let moduleWidthInCM = dimensionWithInCM - wallEastDepth - wallWestDepth;
	let moduleLengthInCM = dimensionLengthInCM - wallNorthDepth - wallSouthDepth;

	let roomInsideMeasure = Math.round(moduleWidthInCM * moduleLengthInCM);
	const wallEastWidth = dimensionLengthInCM;
	const wallSouthWidth = dimensionWithInCM;
	const wallNorthWidth = dimensionWithInCM;
	const wallWestWidth = dimensionLengthInCM;

	//calculate wallDepths for room walls (walls of room where the module is placed)

	let roomWallNorthDepth = getRoomWallThickness(
		roomSpec.wallNorth.wallType,
		roomConfig.wallConfig,
		marginForRoomWalls
	);

	let roomWallSouthDepth = getRoomWallThickness(
		roomSpec.wallSouth.wallType,
		roomConfig.wallConfig,
		marginForRoomWalls
	);
	let roomWallWestDepth = getRoomWallThickness(roomSpec.wallWest.wallType, roomConfig.wallConfig, marginForRoomWalls);
	let roomWallEastDepth = getRoomWallThickness(roomSpec.wallEast.wallType, roomConfig.wallConfig, marginForRoomWalls);

	const {
		marginDimensionArrowsEast,
		marginDimensionArrowsNorth,
		marginDimensionArrowsSouth,
		marginDimensionArrowsWest,
	} = calcMarginsForDimensionArrows(roomSpec, generalMarginForDimensionArrows);

	//x  and y Coordinate of Zero Point of room plan without walls and arrow margin (Zero Point is start of element positioning, e.g. shower)
	const bathRoomModuleXZeroPoint = wallWestDepth + roomWallWestDepth + marginDimensionArrowsWest;
	const bathRoomModuleYZeroPoint = wallNorthDepth + marginDimensionArrowsNorth + roomWallNorthDepth;

	//x  and y Coordinate of Zero Point of room plan without room walls and arrow margin (roomXZeroPoint is the start of the module wall)
	const roomXZeroPoint = marginDimensionArrowsWest + roomWallWestDepth;
	const roomYZeroPoint = marginDimensionArrowsNorth + roomWallNorthDepth;

	return {
		dimensionLength: dimensionLengthInCM,
		dimensionWidth: dimensionWithInCM,
		moduleLengthInCM,
		moduleWidthInCM,
		roomInsideMeasure,
		wallEastWidth,
		wallEastDepth,
		wallNorthWidth,
		marginDimensionArrowsEast,
		marginDimensionArrowsNorth,
		marginDimensionArrowsSouth,
		marginDimensionArrowsWest,
		wallNorthDepth,
		wallSouthWidth,
		wallSouthDepth,
		wallWestWidth,
		wallWestDepth,
		generalMarginForDimensionArrows,
		marginForRoomWalls,
		bathRoomModuleXZeroPoint,
		bathRoomModuleYZeroPoint,
		roomXZeroPoint,
		roomYZeroPoint,
		roomWallNorthDepth,
		roomWallSouthDepth,
		roomWallWestDepth,
		roomWallEastDepth,
		roomWallNorthWidth: wallNorthWidth,
		roomWallSouthWidht: wallSouthWidth,
		roomWallWestWidth: wallWestWidth,
		roomWallEastWidth: wallEastWidth,
		arrowLabelMargin,
	};
}

function calcMarginsForDimensionArrows(spec: RoomSpec, marginForDimensionArrows: number) {
	let marginDimensionArrowsEast = 0,
		marginDimensionArrowsNorth = 0,
		marginDimensionArrowsSouth = 0,
		marginDimensionArrowsWest = 0;

	if (spec.doorSpecification && spec.doorSpecification.wall) {
		switch (spec.doorSpecification.wall.wallAlignment) {
			case WallAlignment.EAST:
				marginDimensionArrowsEast +=
					marginForDimensionArrows + convertLengthUnitToCentimeter(spec.doorSpecification.width);
				break;
			case WallAlignment.WEST:
				marginDimensionArrowsWest +=
					marginForDimensionArrows + convertLengthUnitToCentimeter(spec.doorSpecification.width);
				break;
			case WallAlignment.NORTH:
				marginDimensionArrowsNorth +=
					marginForDimensionArrows + convertLengthUnitToCentimeter(spec.doorSpecification.width);
				break;
			case WallAlignment.SOUTH:
				marginDimensionArrowsSouth +=
					marginForDimensionArrows + convertLengthUnitToCentimeter(spec.doorSpecification.width);
				break;
		}
	}

	//add margin for default room arrows
	marginDimensionArrowsNorth += marginForDimensionArrows;
	marginDimensionArrowsEast += marginForDimensionArrows;

	return {
		marginDimensionArrowsEast,
		marginDimensionArrowsNorth,
		marginDimensionArrowsSouth,
		marginDimensionArrowsWest,
	};
}

//get positions in room drawing and check if element position and movementarea is inside room or coordinates have to be adapted
//this  is currently not needed in the main cases, since the min room size ensures a correct position with enough space. But the function prevents the overlapping in some edge cases
export function adjustRoomElementCoordinatesToPreventOverlapping(
	xCoordinate: LengthUnit,
	yCoordinate: LengthUnit,
	roomElement: FeatureElement,
	featureElements: { [featureElCategory: string]: FeatureElement },
	dim: RoomDimensions
) {
	const wallAlignment = roomElement.wallAlignment;

	let xCoordinateInCM = convertLengthUnitToCentimeter(xCoordinate);
	let yCoordinateInCM = convertLengthUnitToCentimeter(yCoordinate);

	let elementHeight = convertLengthUnitToCentimeter(roomElement.elementHeight);
	let elementWidth = convertLengthUnitToCentimeter(roomElement.elementWidth);

	// get movement area coordinates for room drawing to check if it fits inside room or has to be adjusted
	let {
		movementAreaHeight,
		movementAreaWidth,
		movementAreaXPosition,
		movementAreaYPosition,
	} = getMovementAreaDimension(
		roomElement,
		xCoordinateInCM,
		yCoordinateInCM,
		elementHeight,
		elementWidth,
		wallAlignment
	);

	if (movementAreaHeight !== 0 || movementAreaWidth !== 0) {
		return {
			adjustedElementXPosition: convertLengthUnitToCentimeter(xCoordinate),
			adjustedElementYPosition: convertLengthUnitToCentimeter(yCoordinate),
			adjustedMovementAreaXPosition: movementAreaXPosition,
			adjustedMovementAreaYPosition: movementAreaYPosition,
			movementAreaHeight,
			movementAreaWidth,
		};
	}

	//check overlap of movement area with room dimension and in case adjust coordinates
	let diffToRoomWalls = adjustRooomElementCoordsToRoomDimensions(
		wallAlignment,
		movementAreaWidth,
		movementAreaXPosition,
		movementAreaYPosition,
		dim
	);

	//check overlap of movement area with other room elements and in case adjust coordinates
	let diffToOtherElements = adjustRooomElementCoordsWithMovementAreaToOtherRoomElements(
		roomElement.elementId,
		wallAlignment,
		movementAreaWidth,
		movementAreaXPosition,
		movementAreaYPosition,
		featureElements
	);

	diffToOtherElements.xDiff = 0;
	diffToOtherElements.yDiff = 0;

	let adjustedElementXPosition =
		convertLengthUnitToCentimeter(xCoordinate) + diffToRoomWalls.xDiff + diffToOtherElements.xDiff;

	let adjustedElementYPosition =
		convertLengthUnitToCentimeter(yCoordinate) + diffToRoomWalls.yDiff + diffToOtherElements.yDiff;

	let adjustedMovementAreaXPosition = movementAreaXPosition + diffToRoomWalls.xDiff + diffToOtherElements.xDiff;

	let adjustedMovementAreaYPosition = movementAreaYPosition + diffToRoomWalls.yDiff + diffToOtherElements.yDiff;

	return {
		adjustedElementXPosition,
		adjustedElementYPosition,
		adjustedMovementAreaXPosition,
		adjustedMovementAreaYPosition,
		movementAreaHeight,
		movementAreaWidth,
	};
}

// adjusting coords in case that movement area overlaps with other element
const adjustRooomElementCoordsWithMovementAreaToOtherRoomElements = (
	elementId: string,
	wallAlignment: WallAlignment,
	movementAreaWidth: number,
	movementAreaXPosition: number,
	movementAreaYPosition: number,

	featureElements: { [featureElCategory: string]: FeatureElement }
) => {
	let xDiff = 0;
	let yDiff = 0;

	let movementAreaXEndPoint = movementAreaXPosition + movementAreaWidth / 2;
	let movementAreaYEndPoint = movementAreaYPosition - movementAreaWidth / 2;

	let movementAreaXStartPoint = movementAreaXPosition + movementAreaWidth / 2;
	let movementAreaYStartPoint = movementAreaYPosition - movementAreaWidth / 2;

	let featureElementsToCompare = Object.values(featureElements).filter(
		(el) => el.wallAlignment === wallAlignment && el.elementId !== elementId
	);

	featureElementsToCompare.forEach((comparedEl) => {
		let comparedElementXCoord = convertLengthUnitToCentimeter(comparedEl.xCoordinate);

		let comparedElementYCoord = convertLengthUnitToCentimeter(comparedEl.yCoordinate);

		let comparedElementXStartCoord =
			comparedElementXCoord - convertLengthUnitToCentimeter(comparedEl.elementWidth) / 2;
		let comparedElementYStartCoord =
			comparedElementYCoord - convertLengthUnitToCentimeter(comparedEl.elementHeight) / 2;

		let comparedElementXEndCoord =
			comparedElementXCoord + convertLengthUnitToCentimeter(comparedEl.elementWidth) / 2;
		let comparedElementYEndCoord =
			comparedElementYCoord + convertLengthUnitToCentimeter(comparedEl.elementHeight) / 2;

		if (wallAlignment === WallAlignment.NORTH || wallAlignment === WallAlignment.SOUTH) {
			//if element is not high enough to overlap with movmeent area continue with next element
			if (convertLengthUnitToCentimeter(comparedEl.elementHeight) < Math.abs(movementAreaYStartPoint)) {
				return;
			}
			if (
				movementAreaXStartPoint < comparedElementXEndCoord &&
				comparedElementXEndCoord < movementAreaXEndPoint
			) {
				xDiff = comparedElementXEndCoord - movementAreaXStartPoint;
			} else if (
				movementAreaXEndPoint > comparedElementXStartCoord &&
				comparedElementXEndCoord > movementAreaXStartPoint
			) {
				xDiff = comparedElementXStartCoord - movementAreaXEndPoint;
			}
		} else {
			//if element is not high enough to overlap with movmeent area continue with next element
			if (convertLengthUnitToCentimeter(comparedEl.elementHeight) < Math.abs(movementAreaXStartPoint)) {
				return;
			}
			if (
				movementAreaYStartPoint < comparedElementYEndCoord &&
				comparedElementYEndCoord < movementAreaYEndPoint
			) {
				yDiff = comparedElementYEndCoord - movementAreaYStartPoint;
			} else if (
				movementAreaYEndPoint > comparedElementYStartCoord &&
				comparedElementYEndCoord > movementAreaYStartPoint
			) {
				yDiff = comparedElementYStartCoord - movementAreaYEndPoint;
			}
		}
	});

	return { xDiff, yDiff };
};

// adjusting coords in case that movement area overlaps with room wall
const adjustRooomElementCoordsToRoomDimensions = (
	wallAlignment: WallAlignment,
	movementAreaWidth: number,
	movementAreaXPosition: number,
	movementAreaYPosition: number,
	dim: RoomDimensions
) => {
	let xDiff = 0;
	let yDiff = 0;

	if (wallAlignment === WallAlignment.NORTH || wallAlignment === WallAlignment.SOUTH) {
		movementAreaXPosition -= movementAreaWidth / 2;
		if (movementAreaXPosition < 0) {
			xDiff = -movementAreaXPosition;
		} else if (movementAreaXPosition + movementAreaWidth > dim.moduleWidthInCM) {
			xDiff = dim.moduleWidthInCM - (movementAreaXPosition + movementAreaWidth);
		}
	} else {
		movementAreaYPosition -= movementAreaWidth / 2;
		if (movementAreaYPosition < 0) {
			yDiff = -movementAreaYPosition;
		} else if (movementAreaYPosition + movementAreaWidth > dim.moduleLengthInCM) {
			yDiff = dim.moduleLengthInCM - (movementAreaYPosition + movementAreaWidth);
		}
	}

	return { xDiff, yDiff };
};

export function getReferenceElementWallAlignment(featureElements: { [featureElCategory: string]: FeatureElement }) {
	//get reference room element wall for calculating e.g. door position or radiator position
	//Hinoku-> all room elements are on same wall -> getting first set room element (logic of suki can be used, so there is no case distinction between prod families )
	//suki -> if shower is set, use shower. Otherwise use the  element on the left wall of the two elements
	// if "isSinkAndWCPositionSwapped" left elemet is sink, else left element is toilet

	let shower = featureElements['SH'];
	let toilet = featureElements['WC'];
	let sink = featureElements['SI'];

	//North as default value if no featureelement has been selected yet
	let referenceRoomElWallAlignment = WallAlignment.NORTH;

	if (shower && shower.wallAlignment) {
		referenceRoomElWallAlignment = shower.wallAlignment;
	} else if (toilet && toilet.wallAlignment && sink && sink.wallAlignment) {
		let leftAdjacentWallOfToilet = getLeftAdjacentWallAlignment(toilet.wallAlignment);

		let leftAdjacentWallOfSink = getLeftAdjacentWallAlignment(sink.wallAlignment);

		if (leftAdjacentWallOfToilet === sink.wallAlignment) {
			return sink.wallAlignment;
		} else if (leftAdjacentWallOfSink === toilet.wallAlignment) {
			return toilet.wallAlignment;
		} else if (toilet.wallAlignment === sink.wallAlignment) {
			return toilet.wallAlignment;
		}
		return toilet.wallAlignment;
	} else {
		referenceRoomElWallAlignment =
			sink && sink.wallAlignment
				? sink.wallAlignment
				: toilet && toilet.wallAlignment
				? toilet.wallAlignment
				: WallAlignment.NORTH;
	}

	return referenceRoomElWallAlignment;
}

export function getEndOfShowerOnOppositeWall(
	wallAlignment: WallAlignment,
	featureElements: {
		[featureElCategory: string]: FeatureElement;
	}
) {
	let oppositeWallAlignment = getWallAlignmentOfOppositeWall(wallAlignment);

	let shower = featureElements['SH'];

	let elementEndPosition = 0;

	if (!shower || !shower.wallAlignment || shower.wallAlignment !== oppositeWallAlignment) {
		return { value: elementEndPosition, unit: Unit.CENTI_METER };
	}

	let showerWidth = convertLengthUnitToCentimeter(shower.elementWidth);

	return { value: showerWidth, unit: Unit.CENTI_METER };
}

export function getElementPositionOfElementNextToShower(
	wallAlignment: WallAlignment,
	element: FeatureElement,
	showerWallPositionLeftRight: 'left' | 'right'
) {
	let elementWidth = convertLengthUnitToCentimeter(element.elementWidth);
	let elementXCoord = convertLengthUnitToCentimeter(element.xCoordinate);
	let elementYCoord = convertLengthUnitToCentimeter(element.yCoordinate);

	let elementPosition = 0;

	switch (wallAlignment) {
		case WallAlignment.WEST:
			elementPosition =
				showerWallPositionLeftRight === 'left'
					? elementYCoord - elementWidth / 2
					: elementYCoord + elementWidth / 2;

			break;
		case WallAlignment.EAST:
			elementPosition =
				showerWallPositionLeftRight === 'left'
					? elementYCoord + elementWidth / 2
					: elementYCoord - elementWidth / 2;

			break;
		case WallAlignment.NORTH:
			elementPosition =
				showerWallPositionLeftRight === 'left'
					? elementXCoord + elementWidth / 2
					: elementXCoord - elementWidth / 2;

			break;
		case WallAlignment.SOUTH:
			elementPosition =
				showerWallPositionLeftRight === 'left'
					? elementXCoord - elementWidth / 2
					: elementXCoord + elementWidth / 2;

			break;
	}

	return elementPosition;
}

//calcualtes the position of an element on an adjacent wall. The element is positioned on the opposite wall side than the given wall alignment (considerung the given maring in CM)
// e.g. given wall is north wall and the element should be on left adjacent wall (= west wall), it will be positioned on the west wall in the corner to the south wall with the given margin
// used for calulating radiator position
export function getCoordinatesAtAdjacentWallWithMarginToOppositeWall(
	referenceWallAlignment: WallAlignment,
	adjacentWallAlignemnt: WallAlignment,
	dim: RoomDimensions,
	elementWidthInCM: number,
	elementHeightInCM: number,
	marginInCM: number
) {
	let yCoordinate = 0;
	let xCoordinate = 0;

	switch (referenceWallAlignment) {
		case WallAlignment.NORTH:
			yCoordinate = dim.moduleLengthInCM - elementWidthInCM / 2 - marginInCM;
			//adjacent wall can only be West or East, set coordinate two align radiator with wall
			xCoordinate = adjacentWallAlignemnt === WallAlignment.WEST ? elementHeightInCM / 2 : -elementHeightInCM / 2;
			break;
		case WallAlignment.WEST:
			xCoordinate = dim.moduleWidthInCM - elementWidthInCM / 2 - marginInCM;
			//adjacent wall can only be North or South, set coordinate two align radiator with wall
			yCoordinate =
				adjacentWallAlignemnt === WallAlignment.NORTH ? elementHeightInCM / 2 : -elementHeightInCM / 2;

			break;
		case WallAlignment.EAST:
			xCoordinate = elementWidthInCM / 2 + marginInCM;
			//adjacent wall can only be North or South, set coordinate two align radiator with wall
			yCoordinate =
				adjacentWallAlignemnt === WallAlignment.NORTH ? elementHeightInCM / 2 : -elementHeightInCM / 2;

			break;
		case WallAlignment.SOUTH:
			yCoordinate = elementWidthInCM / 2 + marginInCM;
			//adjacent wall can only be west or east, set coordinate two align radiator with wall
			xCoordinate = adjacentWallAlignemnt === WallAlignment.WEST ? elementHeightInCM / 2 : -elementHeightInCM / 2;
			break;
	}

	return { xCoordinate, yCoordinate };
}

export function getMinMaxDimensionLengthAndWidth(roomSpec: RoomSpec, roomConfig: RoomConfiguration) {
	const minRoomHeight = roomConfig.roomHeights.min;
	const maxRoomHeight = roomConfig.roomHeights.max;
	const minSubFloorHeight = roomConfig.subFloorHeights.min;
	const maxSubFloorHeight = roomConfig.subFloorHeights.max;

	let maxLength = roomConfig.maxLongerDimensionLength;
	let maxWidth = roomConfig.maxShorterDimensionLength;
	let minLength = roomConfig.minLongerDimensionLength;
	let minWidth = roomConfig.minShorterDimensionLength;
	let longerWall = WallAlignment.NORTH;

	//"guest" variant is always without shower, variant with shower is called "common"
	if (!roomSpec.featureElements['SH'] || !roomSpec.featureElements['SH'].wallAlignment) {
		let toilet = roomSpec.featureElements['WC'];
		let sink = roomSpec.featureElements['SI'];

		if (
			toilet &&
			toilet.wallAlignment &&
			sink &&
			sink.wallAlignment &&
			toilet.wallAlignment === sink.wallAlignment
		) {
			longerWall = sink.wallAlignment;
		}
	} else {
		// common variant longer wall depends on shower position
		longerWall = roomSpec.featureElements['SH'].wallAlignment;
	}

	switch (longerWall) {
		case WallAlignment.SOUTH:
		case WallAlignment.NORTH:
			maxWidth = roomConfig.maxLongerDimensionLength;
			minWidth = roomConfig.minLongerDimensionLength;
			maxLength = roomConfig.maxShorterDimensionLength;
			minLength = roomConfig.minShorterDimensionLength;
			break;
		case WallAlignment.WEST:
		case WallAlignment.EAST:
			maxLength = roomConfig.maxLongerDimensionLength;
			minLength = roomConfig.minLongerDimensionLength;
			maxWidth = roomConfig.maxShorterDimensionLength;
			minWidth = roomConfig.minShorterDimensionLength;
			break;
	}

	let minWidthInCM = checkIfMinDimensionWidthIsValidAndAdaptIfInvalid(roomSpec, roomConfig, minWidth);

	let minLengthInCM = checkIfMinDimensionLengthhIsValidAndAdaptIfInvalid(roomSpec, roomConfig, minLength);

	let maxLengthInM = convertLengthUnitToMeter(maxLength);
	let minLengthInM = convertCentiMeterInMeter(minLengthInCM);
	let maxWidthInM = convertLengthUnitToMeter(maxWidth);
	let minWidthInM = convertCentiMeterInMeter(minWidthInCM);

	//worst case check, should not happen, but can happen with invalid configuration (bigger feature elements than in max dimensions length possible)
	//0.01 meter just an default value, can be changed but should not be zero, otherwise the slider control looks ugly
	if (minWidthInM > maxWidthInM) {
		maxWidthInM = minWidthInM + 0.01;
	}

	if (minLengthInM > maxLengthInM) {
		maxLengthInM = minLengthInM + 0.01;
	}

	return {
		maxLength: maxLengthInM,
		maxWidth: maxWidthInM,
		minLength: minLengthInM,
		minWidth: minWidthInM,
		minRoomHeightInMeter: convertLengthUnitToMeter(minRoomHeight),
		maxRoomHeightInMeter: convertLengthUnitToMeter(maxRoomHeight),
		minSubFloorHeightInCentimeter: convertLengthUnitToCentimeter(minSubFloorHeight),
		maxSubFloorHeightInCentimeter: convertLengthUnitToCentimeter(maxSubFloorHeight),
	};
}

//checks if the min width is valid (all selected room elements have enough space)
//if more space is needed the bigger
function checkIfMinDimensionWidthIsValidAndAdaptIfInvalid(
	roomSpec: RoomSpec,
	roomConfig: RoomConfiguration,
	minWidth: LengthUnit
) {
	let minWidthInCM = convertLengthUnitToCentimeter(minWidth);

	let minWidthForNorthWall = getMinWidthForWall(WallAlignment.NORTH, roomSpec, roomConfig);

	let minWidthForSouthWall = getMinWidthForWall(WallAlignment.SOUTH, roomSpec, roomConfig);

	let biggerMinWidth = minWidthForNorthWall > minWidthForSouthWall ? minWidthForNorthWall : minWidthForSouthWall;

	return minWidthInCM > biggerMinWidth ? minWidthInCM : biggerMinWidth;
}

//checks if the min length is valid (all selected room elements have enough space)
function checkIfMinDimensionLengthhIsValidAndAdaptIfInvalid(
	roomSpec: RoomSpec,
	roomConfig: RoomConfiguration,
	minLength: LengthUnit
) {
	let minLengthInCM = convertLengthUnitToCentimeter(minLength);

	let minWidthForEastWall = getMinWidthForWall(WallAlignment.EAST, roomSpec, roomConfig);

	let minWidthForWestWall = getMinWidthForWall(WallAlignment.WEST, roomSpec, roomConfig);

	let minLengthOfElements = getMinLength(roomSpec, roomConfig);

	let biggerMinLength = _.max([minLengthInCM, minWidthForEastWall, minWidthForWestWall, minLengthOfElements]);

	return biggerMinLength ? biggerMinLength : minLengthInCM;
}

function getMinLength(roomSpec: RoomSpec, roomConfig: RoomConfiguration) {
	let featureElements = Object.values(roomSpec.featureElements);
	let elementsOnSouth = featureElements.filter((el) => el.wallAlignment === WallAlignment.SOUTH);

	let elementsOnNorth = featureElements.filter((el) => el.wallAlignment === WallAlignment.NORTH);

	let elements = [...elementsOnSouth, ...elementsOnNorth];

	let biggestHeight = 0;
	elements.forEach((e) => {
		let h = convertLengthUnitToCentimeter(e.elementHeight) + convertLengthUnitToCentimeter(e.movementAreaHeight);

		if (biggestHeight < h) {
			biggestHeight = h;
		}
	});

	let additionalWidhtForSouthAdjacentWallThickness = getModuleWallThickness(
		roomSpec,
		roomConfig.wallConfig,
		WallAlignment.NORTH
	);
	let additionalWidhtForNorthAdjacentWallThickness = getModuleWallThickness(
		roomSpec,
		roomConfig.wallConfig,
		WallAlignment.SOUTH
	);

	let minWallWidth =
		biggestHeight + additionalWidhtForSouthAdjacentWallThickness + additionalWidhtForNorthAdjacentWallThickness;

	return minWallWidth;
}

function getMinWidthForWall(wallAlignemnt: WallAlignment, roomSpec: RoomSpec, roomConfig: RoomConfiguration) {
	let featureElements = Object.values(roomSpec.featureElements);
	let elementsOnWall = featureElements.filter((el) => el.wallAlignment === wallAlignemnt);
	let elementsOnLeftAdjacentWall = featureElements.filter(
		(el) => el.wallAlignment === getLeftAdjacentWallAlignment(wallAlignemnt)
	);
	let elementsOnRightAdjacentWall = featureElements.filter(
		(el) => el.wallAlignment === getRightAdjacentWallAlignment(wallAlignemnt)
	);

	let sumOfMaxlementHeightsOnAdjacentWalls = 0;

	//only needed if current wall is wall with shower or WC
	if (elementsOnWall.find((el) => el.featureElementCategory === 'SH')) {
		//getting the sum of the highest element height on the left and the right adjacent wall
		//wall width has to ensure no overlapping between elements in the corner to the adjacent wall
		sumOfMaxlementHeightsOnAdjacentWalls = getSumOfMaxElementHeightsOnAdjacentWalls(
			elementsOnLeftAdjacentWall,
			elementsOnRightAdjacentWall
		);
	}

	let minWallWidthForElementsOnWall = getMinWidthForElementsOnWall(wallAlignemnt, elementsOnWall);

	//the wall thickness of adjacent walls have to be added because the min dimension lenght inlcudes the module walls
	let additionalWidhtForLeftAdjacentWallThickness = getModuleWallThickness(
		roomSpec,
		roomConfig.wallConfig,
		getLeftAdjacentWallAlignment(wallAlignemnt)
	);
	let additionalWidhtForRightAdjacentWallThickness = getModuleWallThickness(
		roomSpec,
		roomConfig.wallConfig,
		getRightAdjacentWallAlignment(wallAlignemnt)
	);

	let minWallWidth =
		minWallWidthForElementsOnWall +
		additionalWidhtForLeftAdjacentWallThickness +
		additionalWidhtForRightAdjacentWallThickness +
		sumOfMaxlementHeightsOnAdjacentWalls;

	return minWallWidth;
}

function getSumOfMaxElementHeightsOnAdjacentWalls(
	elementsOnLeftAdjacentWall: FeatureElement[],
	elementsOnRightAdjacentWall: FeatureElement[]
) {
	//getting element width biggest height (including movement area) for each adjacent wall
	let maxHeightOfElementOnLeftAdjacentWall =
		elementsOnLeftAdjacentWall.length > 0
			? Math.max(
					...elementsOnLeftAdjacentWall.map(
						(el) =>
							convertLengthUnitToCentimeter(el.elementHeight) +
							convertLengthUnitToCentimeter(el.movementAreaHeight)
					)
			  )
			: 0;
	let maxHeightOfElementOnRightAdjacentWall =
		elementsOnRightAdjacentWall.length > 0
			? Math.max(
					...elementsOnRightAdjacentWall.map(
						(el) =>
							convertLengthUnitToCentimeter(el.elementHeight) +
							convertLengthUnitToCentimeter(el.movementAreaHeight)
					)
			  )
			: 0;

	// create sum of highest elements of each adjacent wall to get min widht for these elements (e.g. needed in suki)
	let minWidthForHeightOfAdjacentWallElements =
		maxHeightOfElementOnRightAdjacentWall + maxHeightOfElementOnLeftAdjacentWall;

	return minWidthForHeightOfAdjacentWallElements;
}

function getMinWidthForElementsOnWall(wallAlignment: WallAlignment, elements: FeatureElement[]) {
	let minWidhtOfWallForElementsOnWall = 0;
	//sort elements by coordinates to have the right order of elements in array (needed to calculate needed off set between elements)
	let sortedElementsOnWall = getSortedElementsOnWall(wallAlignment, elements);

	sortedElementsOnWall.forEach((element, idx) => {
		let elementWidthInCM = convertLengthUnitToCentimeter(element.elementWidth);

		let nextElement = sortedElementsOnWall[idx + 1];
		let previousElement = sortedElementsOnWall[idx - 1];

		let minMarginForMovementArea = getMinMarginForMovementArea(element, previousElement, nextElement);

		let minOffSetToNextElement = 0;

		//check if a min offset between this element and next element is defined and is higher than already applied margin for movement area
		if (nextElement && element.offsets && element.offsets[nextElement.sanitaryType]) {
			let minOffset = convertLengthUnitToCentimeter(element.offsets[nextElement.sanitaryType]);
			minOffSetToNextElement = minOffset > minMarginForMovementArea ? minOffset - minMarginForMovementArea : 0;
		}

		minWidhtOfWallForElementsOnWall += minMarginForMovementArea;
		minWidhtOfWallForElementsOnWall += minOffSetToNextElement;
		minWidhtOfWallForElementsOnWall += elementWidthInCM;
	});

	return minWidhtOfWallForElementsOnWall;
}

function getMinMarginForMovementArea(
	currentElement: FeatureElement,
	previousElement: FeatureElement,
	nextElement: FeatureElement
) {
	let marginForMovementArea = 0;

	let currentElementHeightInCM = convertLengthUnitToCentimeter(currentElement.elementHeight);
	let currentElementWidthInCM = convertLengthUnitToCentimeter(currentElement.elementWidth);

	let currentElementMovementAreaWidth = convertLengthUnitToCentimeter(currentElement.movementAreaWidth);

	let movementAreaOverlap = (currentElementMovementAreaWidth - currentElementWidthInCM) / 2;
	movementAreaOverlap = movementAreaOverlap > 0 ? movementAreaOverlap : 0;

	let movementAreaMarginToNextElement = getMinMarginOfMovementAreaToOhterElement(
		currentElementHeightInCM,
		nextElement,
		movementAreaOverlap
	);
	let movementAreaMarginToPreviousElement = getMinMarginOfMovementAreaToOhterElement(
		currentElementHeightInCM,
		previousElement,
		movementAreaOverlap
	);

	marginForMovementArea += movementAreaMarginToNextElement;
	marginForMovementArea += movementAreaMarginToPreviousElement;

	return marginForMovementArea;
}

function getMinMarginOfMovementAreaToOhterElement(
	currentElementHeightInCM: number,
	otherElement: FeatureElement,
	movementAreaOverlapWidth: number
) {
	let marginToOtherElementForMovementArea = 0;

	if (otherElement) {
		let otherElementHeightInCM = convertLengthUnitToCentimeter(otherElement.elementHeight);
		//if movement area cannot overlap with other element, because current element is higher or equal no margin for movement area between elements is needed
		if (currentElementHeightInCM < otherElementHeightInCM) {
			marginToOtherElementForMovementArea = movementAreaOverlapWidth;
		}
	} else {
		//if there is no other (previous or next) element, the movement area overlap is needed as margin to wall
		marginToOtherElementForMovementArea = movementAreaOverlapWidth;
	}

	return marginToOtherElementForMovementArea;
}

function getSortedElementsOnWall(wallAlignment: WallAlignment, elements: FeatureElement[]) {
	switch (wallAlignment) {
		case WallAlignment.EAST:
		case WallAlignment.WEST:
			return elements.sort(
				(a, b) => convertLengthUnitToCentimeter(a.yCoordinate) - convertLengthUnitToCentimeter(b.yCoordinate)
			);
		case WallAlignment.NORTH:
		case WallAlignment.SOUTH:
			return elements.sort(
				(a, b) => convertLengthUnitToCentimeter(a.xCoordinate) - convertLengthUnitToCentimeter(b.xCoordinate)
			);
	}
}
