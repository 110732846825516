import { appConfigURL } from '../endpoints';
import {
	AccessoryElement,
	ActionType,
	AppConfig,
	DoorSpecification,
	DoorType,
	EquipmentLine,
	FeatureElementCategoryConfig,
	FeatureElementConfig,
	LengthUnit,
	ProductCategory,
	ProductFamily,
	ProductFamilyVariant,
	RoomConfiguration,
	RoomSpec,
	SelectableImage,
	SocketConfig,
	Unit,
	WallAlignment,
	WallConfig,
	WallType,
} from '../model/model';

export function getAppConfig(authToken: string, onComplete: Function) {
	return async (dispatch: Function, getState: Function) => {
		let appConfig = await fetchAppConfig(authToken);
		if (appConfig) {
			dispatch({ type: ActionType.GET_APP_CONFIG, payload: appConfig });
			onComplete();
		}
	};
}

export async function fetchAppConfig(token: string) {
	try {
		const response = await fetch(appConfigURL, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Token ' + token,
			},
		});

		const json = await response.json();

		let appConfig = json[0];

		return appConfig;
	} catch (e) {
		console.log(e);
	}
}

//helper function until backend provides data
export function getDefaultAppConfig() {
	const length: LengthUnit = { value: 2, unit: Unit.METER };
	const width: LengthUnit = { value: 2, unit: Unit.METER };
	const height: LengthUnit = { value: 2.5, unit: Unit.METER };
	const subFloorHeight: LengthUnit = { value: 15, unit: Unit.CENTI_METER };
	const roomHeight: LengthUnit = { value: 2.3, unit: Unit.METER };
	const ceilingHeight: LengthUnit = { value: 11.25, unit: Unit.CENTI_METER };

	const frameOpeningHeight: LengthUnit = { value: 0, unit: Unit.CENTI_METER };
	const frameOpeningWidth: LengthUnit = { value: 0, unit: Unit.CENTI_METER };

	const frameOpeningHeight2: LengthUnit = {
		value: 0,
		unit: Unit.CENTI_METER,
	};
	const frameOpeningWidth2: LengthUnit = { value: 0, unit: Unit.CENTI_METER };

	const defaultSelectableImage1: SelectableImage = {
		id: '1',
		title: 'Type A',
		src: '/assets/demoSliderControl_1.png',
	};

	const defaultSelectableImage2: SelectableImage = {
		id: '2',
		title: 'Type B',
		src: '/assets/demoSliderControl_1.png',
	};

	const selectableDoorHeights: LengthUnit[] = [
		{ value: 210, unit: Unit.CENTI_METER },
		{ value: 213.5, unit: Unit.CENTI_METER },
		{ value: 226, unit: Unit.CENTI_METER },
	];

	const selectableDoorWidths: LengthUnit[] = [
		{ value: 63.5, unit: Unit.CENTI_METER },
		{ value: 76, unit: Unit.CENTI_METER },
		{ value: 88.5, unit: Unit.CENTI_METER },
		{ value: 101, unit: Unit.CENTI_METER },
		{ value: 113.5, unit: Unit.CENTI_METER },
	];

	const selectableDoors: DoorSpecification[] = [
		{
			id: '1',
			name: 'Regular',
			frameHeight: frameOpeningHeight,
			frameWidth: frameOpeningWidth,
			frameType: 'surroundingFrame',
			xCoordinate: length,
			yCoordinate: width,
			wall: undefined,
			selectedImageId: '1',
			doorType: DoorType.NORMAL,
			swingingDirectionInsideOutside: 'inside',
			openingDirectionLeftRight: 'left',
			width: selectableDoorWidths[0],
			depth: { value: 30, unit: Unit.MILLI_METER },
			height: selectableDoorHeights[0],
		},
		{
			id: '2',
			name: 'Luxury',
			frameHeight: frameOpeningHeight2,
			frameWidth: frameOpeningWidth2,
			frameType: 'surroundingFrame',
			xCoordinate: length,
			yCoordinate: width,
			wall: undefined,
			selectedImageId: '1',
			doorType: DoorType.NORMAL,
			swingingDirectionInsideOutside: 'inside',
			openingDirectionLeftRight: 'left',
			width: selectableDoorWidths[1],
			depth: { value: 30, unit: Unit.MILLI_METER },
			height: selectableDoorHeights[1],
		},
		{
			id: '3',
			name: 'Sliding',
			frameHeight: frameOpeningHeight,
			frameWidth: frameOpeningWidth,
			frameType: 'surroundingFrame',
			xCoordinate: length,
			yCoordinate: width,
			wall: undefined,
			selectedImageId: '1',
			doorType: DoorType.SLIDING,
			openingDirectionLeftRight: 'left',
			width: selectableDoorWidths[1],
			depth: { value: 30, unit: Unit.MILLI_METER },
			height: selectableDoorHeights[1],
		},
		{
			id: '4',
			name: 'Luxury Sliding',
			frameHeight: frameOpeningHeight2,
			frameWidth: frameOpeningWidth2,
			frameType: 'surroundingFrame',
			xCoordinate: length,
			yCoordinate: width,
			wall: undefined,
			selectedImageId: '1',
			doorType: DoorType.SLIDING,
			openingDirectionLeftRight: 'left',
			width: { value: 885, unit: Unit.MILLI_METER },
			depth: { value: 30, unit: Unit.MILLI_METER },
			height: selectableDoorHeights[2],
		},
	];

	const wallWest = {
		id: '1',
		name: 'steps.structuralElements.wallWest',
		wallType: WallType.IN_FRONT_OF_INTERIOR,
		wallAlignment: WallAlignment.WEST,
	};
	const wallNorth = {
		id: '2',
		name: 'steps.structuralElements.wallNorth',
		wallType: WallType.IN_FRONT_OF_EXTERIOR,
		wallAlignment: WallAlignment.NORTH,
	};
	const wallEast = {
		id: '3',
		name: 'steps.structuralElements.wallEast',
		wallType: WallType.FREE_STANDING,
		wallAlignment: WallAlignment.EAST,
	};
	const wallSouth = {
		id: '4',
		name: 'steps.structuralElements.wallSouth',
		wallType: WallType.FREE_STANDING,
		wallAlignment: WallAlignment.SOUTH,
	};

	const accessoryElements: AccessoryElement[] = [
		{
			id: '1',
			name: 'steps.structuralElements.raditor',
			isSelected: true,
		},
		{
			id: '2',
			name: 'steps.structuralElements.heatedFlooring',
			isSelected: false,
		},
	];

	const defaultRoomSpec1: RoomSpec = {
		dimensionLength: length,
		dimensionWidth: width,
		storeyHeight: height,
		subFloorHeight: subFloorHeight,
		roomHeight: roomHeight,
		ceilingHeight: ceilingHeight,
		featureElements: {},
		accessoryElements: ['1'],
		wallNorth: wallNorth,
		wallEast: wallEast,
		wallSouth: wallSouth,
		wallWest: wallWest,
		numberOfOutletsDoor: 1,
		numberOfOutletsSink: 2,
		lightingType: 'lightingArea',
		lightingMirror: false,
		lightingCount: 0,
		radiatorType: null,
		underfloorHeating: false,
		doorSpecification: { ...selectableDoors[0], wall: wallSouth },
		equipmentLineId: '',
		productCategoryId: '',
		productFamilyId: '',
		wallSwitches: { doorSwitches: [], sinkSwitches: [] },
		productFamilyVariantId: '',
		radiatorConfig: null,
		ventilationType: 'singleVentilationSystem',
		wallNorthTiles: {
			tileSquareMetersInShowerArea: 0,
			tileSquareMetersNotInShowerArea: 0,
			tileSquareMetersBaseTiles: 0,
		},
		wallSouthTiles: {
			tileSquareMetersInShowerArea: 0,
			tileSquareMetersNotInShowerArea: 0,
			tileSquareMetersBaseTiles: 0,
		},
		wallWestTiles: {
			tileSquareMetersInShowerArea: 0,
			tileSquareMetersNotInShowerArea: 0,
			tileSquareMetersBaseTiles: 0,
		},
		wallEastTiles: {
			tileSquareMetersInShowerArea: 0,
			tileSquareMetersNotInShowerArea: 0,
			tileSquareMetersBaseTiles: 0,
		},
		shaftConfig: null,
		noShaftConfig: {
			cablesLeft: true,
			cablesRight: false,
		},
		elements: [],
		wallTiles: undefined,
		showerTiles: undefined,
		floorTiles: undefined,
	};

	const defaultRoomSpec2: RoomSpec = {
		...defaultRoomSpec1,
	};
	defaultRoomSpec2.storeyHeight = { ...height, value: 3 };
	defaultRoomSpec2.dimensionLength = { ...length, value: 3.5 };
	defaultRoomSpec2.dimensionWidth = { ...width, value: 3 };

	const defaultRoomSpec3: RoomSpec = {
		...defaultRoomSpec1,
	};

	defaultRoomSpec3.storeyHeight = { ...height, value: 4 };
	defaultRoomSpec3.dimensionLength = { ...length, value: 4 };
	defaultRoomSpec3.dimensionWidth = { ...width, value: 4 };

	const toiletConfigs: FeatureElementConfig[] = [
		{
			id: '6',
			name: 'WC',
			sanitaryType: 'WC',
			length: { value: 75, unit: Unit.CENTI_METER },
			width: { value: 40, unit: Unit.CENTI_METER },
			movementAreaLength: { value: 60, unit: Unit.CENTI_METER },
			movementAreaWidth: { value: 80, unit: Unit.CENTI_METER },
			offsets: {
				SI: { value: 20, unit: Unit.CENTI_METER },
				DWB: { value: 20, unit: Unit.CENTI_METER },
				BWU: { value: 20, unit: Unit.CENTI_METER },
				BWU2: { value: 20, unit: Unit.CENTI_METER },
				HWB: { value: 20, unit: Unit.CENTI_METER },
				BID: { value: 25, unit: Unit.CENTI_METER },
				UR: { value: 20, unit: Unit.CENTI_METER },
				SH: { value: 20, unit: Unit.CENTI_METER },
				BTH: { value: 20, unit: Unit.CENTI_METER },
				WMDR: { value: 20, unit: Unit.CENTI_METER },
			},
		},
	];

	const toiletConfigsBF: FeatureElementConfig[] = [
		{
			id: '6',
			name: 'WC',
			sanitaryType: 'WC',
			length: { value: 75, unit: Unit.CENTI_METER },
			width: { value: 40, unit: Unit.CENTI_METER },
			movementAreaLength: { value: 80, unit: Unit.CENTI_METER },
			movementAreaWidth: { value: 100, unit: Unit.CENTI_METER },
			offsets: {
				SI: { value: 20, unit: Unit.CENTI_METER },
				DWB: { value: 20, unit: Unit.CENTI_METER },
				BWU: { value: 20, unit: Unit.CENTI_METER },
				BWU2: { value: 20, unit: Unit.CENTI_METER },
				HWB: { value: 20, unit: Unit.CENTI_METER },
				BID: { value: 25, unit: Unit.CENTI_METER },
				UR: { value: 20, unit: Unit.CENTI_METER },
				SH: { value: 20, unit: Unit.CENTI_METER },
				BTH: { value: 20, unit: Unit.CENTI_METER },
				WMDR: { value: 20, unit: Unit.CENTI_METER },
			},
		},
	];

	const sinkConfigs: FeatureElementConfig[] = [
		{
			id: '2',
			name: 'SI',
			sanitaryType: 'SI',
			length: { value: 55, unit: Unit.CENTI_METER },
			width: { value: 60, unit: Unit.CENTI_METER },
			movementAreaLength: { value: 55, unit: Unit.CENTI_METER },
			movementAreaWidth: { value: 90, unit: Unit.CENTI_METER },
			offsets: {
				BID: { value: 25, unit: Unit.CENTI_METER },
				WC: { value: 20, unit: Unit.CENTI_METER },
				WCi: { value: 20, unit: Unit.CENTI_METER },
				UR: { value: 20, unit: Unit.CENTI_METER },
				SH: { value: 20, unit: Unit.CENTI_METER },
				BTH: { value: 20, unit: Unit.CENTI_METER },
				WMDR: { value: 20, unit: Unit.CENTI_METER },
				SKB: { value: 20, unit: Unit.CENTI_METER },
				SNK: { value: 20, unit: Unit.CENTI_METER },
			},
		},
		{
			id: '3',
			name: 'D_SI',
			sanitaryType: 'SI',
			length: { value: 55, unit: Unit.CENTI_METER },
			width: { value: 120, unit: Unit.CENTI_METER },
			movementAreaLength: { value: 55, unit: Unit.CENTI_METER },
			movementAreaWidth: { value: 150, unit: Unit.CENTI_METER },
			offsets: {
				BID: { value: 25, unit: Unit.CENTI_METER },
				WC: { value: 20, unit: Unit.CENTI_METER },
				WCi: { value: 20, unit: Unit.CENTI_METER },
				UR: { value: 20, unit: Unit.CENTI_METER },
				SH: { value: 20, unit: Unit.CENTI_METER },
				BTH: { value: 20, unit: Unit.CENTI_METER },
				WMDR: { value: 20, unit: Unit.CENTI_METER },
				SKB: { value: 20, unit: Unit.CENTI_METER },
				SNK: { value: 20, unit: Unit.CENTI_METER },
			},
		},
	];

	const sinkConfigsBF: FeatureElementConfig[] = [
		{
			id: '2',
			name: 'SI',
			sanitaryType: 'SI',
			length: { value: 55, unit: Unit.CENTI_METER },
			width: { value: 60, unit: Unit.CENTI_METER },
			movementAreaLength: { value: 100, unit: Unit.CENTI_METER },
			movementAreaWidth: { value: 75, unit: Unit.CENTI_METER },
			offsets: {
				BID: { value: 25, unit: Unit.CENTI_METER },
				WC: { value: 20, unit: Unit.CENTI_METER },
				WCi: { value: 20, unit: Unit.CENTI_METER },
				UR: { value: 20, unit: Unit.CENTI_METER },
				SH: { value: 20, unit: Unit.CENTI_METER },
				BTH: { value: 20, unit: Unit.CENTI_METER },
				WMDR: { value: 20, unit: Unit.CENTI_METER },
				SKB: { value: 20, unit: Unit.CENTI_METER },
				SNK: { value: 20, unit: Unit.CENTI_METER },
			},
		},
	];

	const sinkConfigsNoDoubleSink: FeatureElementConfig[] = [
		{
			id: '2',
			name: 'SI',
			sanitaryType: 'SI',
			length: { value: 55, unit: Unit.CENTI_METER },
			width: { value: 60, unit: Unit.CENTI_METER },
			movementAreaLength: { value: 55, unit: Unit.CENTI_METER },
			movementAreaWidth: { value: 90, unit: Unit.CENTI_METER },
			offsets: {
				BID: { value: 25, unit: Unit.CENTI_METER },
				WC: { value: 20, unit: Unit.CENTI_METER },
				WCi: { value: 20, unit: Unit.CENTI_METER },
				UR: { value: 20, unit: Unit.CENTI_METER },
				SH: { value: 20, unit: Unit.CENTI_METER },
				BTH: { value: 20, unit: Unit.CENTI_METER },
				WMDR: { value: 20, unit: Unit.CENTI_METER },
				SKB: { value: 20, unit: Unit.CENTI_METER },
				SNK: { value: 20, unit: Unit.CENTI_METER },
			},
		},
	];

	const generateShowerSlot = (length: number, width: number) => {
		return {
			id: 'shower-' + length + 'x' + width,
			name: length + 'x' + width,
			sanitaryType: 'SH',
			length: { value: length / 10, unit: Unit.CENTI_METER },
			width: { value: width / 10, unit: Unit.CENTI_METER },
			offsets: {
				WB: { value: 20, unit: Unit.CENTI_METER },
				DWB: { value: 20, unit: Unit.CENTI_METER },
				BWU: { value: 15, unit: Unit.CENTI_METER },
				BWU2: { value: 15, unit: Unit.CENTI_METER },
				HWB: { value: 20, unit: Unit.CENTI_METER },
				BID: { value: 25, unit: Unit.CENTI_METER },
				WC: { value: 20, unit: Unit.CENTI_METER },
				WCi: { value: 20, unit: Unit.CENTI_METER },
				UR: { value: 20, unit: Unit.CENTI_METER },
				WMDR: { value: 3, unit: Unit.CENTI_METER },
			},
			movementAreaLength: { value: 0, unit: Unit.CENTI_METER },
			movementAreaWidth: { value: 0, unit: Unit.CENTI_METER },
		};
	};

	const showerConfigs: FeatureElementConfig[] = [
		generateShowerSlot(900, 900),
		generateShowerSlot(1000, 1000),
		generateShowerSlot(1200, 900),
		generateShowerSlot(1400, 900),
		generateShowerSlot(1500, 1500),
		generateShowerSlot(1600, 900),
		generateShowerSlot(1600, 1000),
	];

	const showerConfigsBF: FeatureElementConfig[] = [
		generateShowerSlot(1500, 1500),
		generateShowerSlot(1600, 900),
		generateShowerSlot(1600, 1000),
	];

	const featureElementsConfig: FeatureElementCategoryConfig[] = [
		{
			categoryId: 'SH',
			categoryName: 'Shower',
			elements: showerConfigs,
		},
		{
			categoryId: 'WC',
			categoryName: 'Toilet',
			elements: toiletConfigs,
		},
		{
			categoryId: 'SI',
			categoryName: 'Sink',
			elements: sinkConfigs,
		},
	];

	const featureElementsConfigBF: FeatureElementCategoryConfig[] = [
		{
			categoryId: 'SH',
			categoryName: 'Shower',
			elements: showerConfigsBF,
		},
		{
			categoryId: 'WC',
			categoryName: 'Toilet',
			elements: toiletConfigsBF,
		},
		{
			categoryId: 'SI',
			categoryName: 'Sink',
			elements: sinkConfigsBF,
		},
	];

	const featureElementsConfigGuestVariant: FeatureElementCategoryConfig[] = [
		{
			categoryId: 'WC',
			categoryName: 'Toilet',
			elements: toiletConfigs,
		},
		{
			categoryId: 'SI',
			categoryName: 'Sink',
			elements: sinkConfigsNoDoubleSink,
		},
	];

	const equipmentLines: EquipmentLine[] = [
		{
			id: 'S',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			mainImageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
			name: 'S',
			exampleImages: [
				{
					imageTitle: 'S Toilet',
					imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
				},
				{
					imageTitle: 'S Shower',
					imageUrl: '/assets/icons/tjiko_icons_190115_Kaeufer.png',
				},
			],
		},
		{
			id: 'M',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			mainImageUrl: '/assets/icons/tjiko_icons_190115_Planung.png',
			name: 'M',
			exampleImages: [
				{
					imageTitle: 'M Toilet',
					imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
				},
				{
					imageTitle: 'M Shower',
					imageUrl: '/assets/icons/tjiko_icons_190115_Kaeufer.png',
				},
				{
					imageTitle: 'M Shower',
					imageUrl: '/assets/icons/tjiko_icons_190115_Planung.png',
				},
			],
		},
		{
			id: 'L',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			mainImageUrl: '/assets/icons/tjiko_icons_190115_Planung.png',
			name: 'L',
			exampleImages: [
				{
					imageTitle: 'L Toilet',
					imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
				},
				{
					imageTitle: 'L Shower',
					imageUrl: '/assets/icons/tjiko_icons_190115_Kaeufer.png',
				},
				{
					imageTitle: 'L Shower',
					imageUrl: '/assets/icons/tjiko_icons_190115_Planung.png',
				},
			],
		},
	];

	const wallConfig: WallConfig = {
		wallThicknessWallDefault: { value: 11.2, unit: Unit.CENTI_METER },
		wallThicknessWallWithBasinOrShower: {
			value: 29.2,
			unit: Unit.CENTI_METER,
		},
		wallThicknessWallWithToilet: { value: 29.2, unit: Unit.CENTI_METER },
		roomWallThicknessExterior: { value: 30, unit: Unit.CENTI_METER },
		roomWallThicknessInterior: { value: 12, unit: Unit.CENTI_METER },
	};

	const socketConfig: SocketConfig = {
		doorSwitchHeight: { value: 1.05, unit: Unit.METER },
		switchWidth: { value: 10, unit: Unit.CENTI_METER },
		doorSocketHeight: { value: 0.3, unit: Unit.METER },
		singleSocketWidth: { value: 10, unit: Unit.CENTI_METER },
		marginSocketSwitchToDoor: { value: 10, unit: Unit.CENTI_METER },
		marginSocketSwitchToWall: { value: 10, unit: Unit.CENTI_METER },
		doubleSocketWidth: { value: 15, unit: Unit.CENTI_METER },
		doubleSocketSinkHeight: { value: 1.1, unit: Unit.METER },
	};
	const defaultRoomconfigurationSukiGuest: RoomConfiguration = {
		minLongerDimensionLength: { value: 1.834, unit: Unit.METER },
		minShorterDimensionLength: { value: 1.754, unit: Unit.METER },
		maxLongerDimensionLength: { value: 2.5, unit: Unit.METER },
		maxShorterDimensionLength: { value: 2.0, unit: Unit.METER },
		roomHeights: {
			min: { value: 2.3, unit: Unit.METER },
			max: { value: 2.3, unit: Unit.METER },
		},
		subFloorHeights: {
			min: { value: 15, unit: Unit.CENTI_METER },
			max: { value: 25, unit: Unit.CENTI_METER },
		},
		ceilingHeight: { value: 11.25, unit: Unit.CENTI_METER },
		doorImageSelection: [defaultSelectableImage1, defaultSelectableImage2],
		frameTypes: ['surroundingFrame', 'blockFrame'],
		featureElements: featureElementsConfigGuestVariant,
		acccessoryElements: accessoryElements,
		doors: selectableDoors,
		doorHeights: selectableDoorHeights,
		doorWidths: selectableDoorWidths,
		equipmentLines: equipmentLines,
		lightingTypes: ['lightingArea', 'lightingObject'],
		radiatorTypes: ['electricalRadiator'],
		ventilationTypes: ['singleVentilationSystem', 'centralVentilationSystem'],
		isSinkAndWCPositionSwapped: false,
		showerWallPositionLeftRight: 'left',
		socketConfig,
		wallConfig,
		doorSpaceToWcOrSink: {
			value: -10 - 25, // -10 - lightSwitch
			unit: Unit.CENTI_METER,
		},
		marginRadiatorToWall: { value: 10, unit: Unit.CENTI_METER },
		radiatorHeight: { value: 10, unit: Unit.CENTI_METER },
		radiatorWidth: { value: 60, unit: Unit.CENTI_METER },
		tileHeightBaseTiles: { value: 70, unit: Unit.MILLI_METER },
		tileHeightHalfRoomHeight: { value: 120, unit: Unit.CENTI_METER },
		marginRadiatorToSinkForSukiCommon: {
			value: 200,
			unit: Unit.MILLI_METER,
		},
	};

	const defaultRoomconfigurationSukiCommon: RoomConfiguration = {
		minLongerDimensionLength: { value: 3.299, unit: Unit.METER },
		minShorterDimensionLength: { value: 1.604, unit: Unit.METER },
		maxLongerDimensionLength: { value: 4.0, unit: Unit.METER },
		maxShorterDimensionLength: { value: 2.5, unit: Unit.METER },
		roomHeights: {
			min: { value: 2.3, unit: Unit.METER },
			max: { value: 2.3, unit: Unit.METER },
		},
		subFloorHeights: {
			min: { value: 15, unit: Unit.CENTI_METER },
			max: { value: 25, unit: Unit.CENTI_METER },
		},
		ceilingHeight: { value: 11.25, unit: Unit.CENTI_METER },
		doorImageSelection: [defaultSelectableImage1, defaultSelectableImage2],
		frameTypes: ['surroundingFrame', 'blockFrame'],
		featureElements: featureElementsConfig,
		acccessoryElements: accessoryElements,
		doors: selectableDoors,
		doorHeights: selectableDoorHeights,
		doorWidths: selectableDoorWidths,
		equipmentLines: equipmentLines,
		lightingTypes: ['lightingArea', 'lightingObject'],
		radiatorTypes: ['electricalRadiator'],
		ventilationTypes: ['singleVentilationSystem', 'centralVentilationSystem'],
		isSinkAndWCPositionSwapped: false,
		showerWallPositionLeftRight: 'left',
		socketConfig,
		wallConfig,
		doorSpaceToWcOrSink: {
			value: -10 - 25, // -10 - lightSwitch
			unit: Unit.CENTI_METER,
		},
		marginRadiatorToWall: { value: 10, unit: Unit.CENTI_METER },
		radiatorHeight: { value: 10, unit: Unit.CENTI_METER },
		radiatorWidth: { value: 60, unit: Unit.CENTI_METER },
		tileHeightBaseTiles: { value: 70, unit: Unit.MILLI_METER },
		tileHeightHalfRoomHeight: { value: 120, unit: Unit.CENTI_METER },
		marginRadiatorToSinkForSukiCommon: {
			value: 200,
			unit: Unit.MILLI_METER,
		},
	};

	const defaultRoomconfigurationSukiBF: RoomConfiguration = {
		minLongerDimensionLength: { value: 4.299, unit: Unit.METER },
		minShorterDimensionLength: { value: 4.604, unit: Unit.METER },
		maxLongerDimensionLength: { value: 4.0, unit: Unit.METER },
		maxShorterDimensionLength: { value: 2.5, unit: Unit.METER },
		doorImageSelection: [defaultSelectableImage1, defaultSelectableImage2],
		frameTypes: ['surroundingFrame', 'blockFrame'],
		featureElements: featureElementsConfigBF,
		acccessoryElements: accessoryElements,
		doors: selectableDoors,
		doorHeights: selectableDoorHeights,
		doorWidths: selectableDoorWidths,
		equipmentLines: equipmentLines,
		isSinkAndWCPositionSwapped: false,
		showerWallPositionLeftRight: 'left',
		socketConfig,
		wallConfig,
		doorSpaceToWcOrSink: {
			value: -10 - 25, // -10 - lightSwitch
			unit: Unit.CENTI_METER,
		},
		marginRadiatorToWall: { value: 10, unit: Unit.CENTI_METER },
		radiatorHeight: { value: 10, unit: Unit.CENTI_METER },
		radiatorWidth: { value: 60, unit: Unit.CENTI_METER },
		tileHeightBaseTiles: { value: 70, unit: Unit.MILLI_METER },
		tileHeightHalfRoomHeight: { value: 120, unit: Unit.CENTI_METER },
		roomHeights: {
			min: { value: 2.3, unit: Unit.METER },
			max: { value: 2.3, unit: Unit.METER },
		},
		subFloorHeights: {
			min: { value: 15, unit: Unit.CENTI_METER },
			max: { value: 25, unit: Unit.CENTI_METER },
		},
		ceilingHeight: { value: 11.25, unit: Unit.CENTI_METER },
		marginRadiatorToSinkForSukiCommon: {
			value: 200,
			unit: Unit.MILLI_METER,
		},
		lightingTypes: ['lightingArea', 'lightingObject'],
		radiatorTypes: ['electricalRadiator'],
		ventilationTypes: ['singleVentilationSystem', 'centralVentilationSystem'],
	};

	const defaultRoomconfigurationHinokiBF: RoomConfiguration = {
		minLongerDimensionLength: { value: 4.299, unit: Unit.METER },
		minShorterDimensionLength: { value: 4.604, unit: Unit.METER },
		maxLongerDimensionLength: { value: 4.0, unit: Unit.METER },
		maxShorterDimensionLength: { value: 2.5, unit: Unit.METER },
		doorImageSelection: [defaultSelectableImage1, defaultSelectableImage2],
		frameTypes: ['surroundingFrame', 'blockFrame'],
		featureElements: featureElementsConfigBF,
		acccessoryElements: accessoryElements,
		doors: selectableDoors,
		doorHeights: selectableDoorHeights,
		doorWidths: selectableDoorWidths,
		equipmentLines: equipmentLines,
		isSinkAndWCPositionSwapped: false,
		showerWallPositionLeftRight: 'left',
		socketConfig,
		wallConfig,
		doorSpaceToWcOrSink: {
			value: -10 - 25, // -10 - lightSwitch
			unit: Unit.CENTI_METER,
		},
		marginRadiatorToWall: { value: 10, unit: Unit.CENTI_METER },
		radiatorHeight: { value: 10, unit: Unit.CENTI_METER },
		radiatorWidth: { value: 60, unit: Unit.CENTI_METER },
		tileHeightBaseTiles: { value: 70, unit: Unit.MILLI_METER },
		tileHeightHalfRoomHeight: { value: 120, unit: Unit.CENTI_METER },
		roomHeights: {
			min: { value: 2.3, unit: Unit.METER },
			max: { value: 2.3, unit: Unit.METER },
		},
		subFloorHeights: {
			min: { value: 15, unit: Unit.CENTI_METER },
			max: { value: 25, unit: Unit.CENTI_METER },
		},
		ceilingHeight: { value: 11.25, unit: Unit.CENTI_METER },

		marginRadiatorToSinkForSukiCommon: {
			value: 200,
			unit: Unit.MILLI_METER,
		},
		lightingTypes: ['lightingArea', 'lightingObject'],
		radiatorTypes: ['electricalRadiator'],
		ventilationTypes: ['singleVentilationSystem', 'centralVentilationSystem'],
	};

	const defaultRoomconfigurationHinokiGuest: RoomConfiguration = {
		minLongerDimensionLength: { value: 1.874, unit: Unit.METER },
		minShorterDimensionLength: { value: 1.604, unit: Unit.METER },
		maxLongerDimensionLength: { value: 2.5, unit: Unit.METER },
		maxShorterDimensionLength: { value: 2.0, unit: Unit.METER },
		roomHeights: {
			min: { value: 2.3, unit: Unit.METER },
			max: { value: 2.3, unit: Unit.METER },
		},
		subFloorHeights: {
			min: { value: 15, unit: Unit.CENTI_METER },
			max: { value: 25, unit: Unit.CENTI_METER },
		},
		ceilingHeight: { value: 11.25, unit: Unit.CENTI_METER },
		doorImageSelection: [defaultSelectableImage1, defaultSelectableImage2],
		frameTypes: ['surroundingFrame', 'blockFrame'],
		featureElements: featureElementsConfigGuestVariant,
		acccessoryElements: accessoryElements,
		doors: selectableDoors,
		doorHeights: selectableDoorHeights,
		doorWidths: selectableDoorWidths,
		equipmentLines: equipmentLines,
		isSinkAndWCPositionSwapped: false,
		lightingTypes: ['lightingArea', 'lightingObject'],
		radiatorTypes: ['electricalRadiator'],
		ventilationTypes: ['singleVentilationSystem', 'centralVentilationSystem'],
		showerWallPositionLeftRight: 'left',
		socketConfig,
		wallConfig,
		doorSpaceToWcOrSink: {
			value: -10 - 25, // -10 - lightSwitch
			unit: Unit.CENTI_METER,
		},
		marginRadiatorToWall: { value: 10, unit: Unit.CENTI_METER },
		radiatorHeight: { value: 10, unit: Unit.CENTI_METER },
		radiatorWidth: { value: 60, unit: Unit.CENTI_METER },
		tileHeightBaseTiles: { value: 70, unit: Unit.MILLI_METER },
		tileHeightHalfRoomHeight: { value: 120, unit: Unit.CENTI_METER },
		marginRadiatorToSinkForSukiCommon: {
			value: 200,
			unit: Unit.MILLI_METER,
		},
	};

	const defaultRoomconfigurationHinokiCommon: RoomConfiguration = {
		minLongerDimensionLength: { value: 2.799, unit: Unit.METER },
		minShorterDimensionLength: { value: 1.704, unit: Unit.METER },
		maxLongerDimensionLength: { value: 4.0, unit: Unit.METER },
		maxShorterDimensionLength: { value: 2.5, unit: Unit.METER },
		roomHeights: {
			min: { value: 2.3, unit: Unit.METER },
			max: { value: 2.3, unit: Unit.METER },
		},
		subFloorHeights: {
			min: { value: 15, unit: Unit.CENTI_METER },
			max: { value: 25, unit: Unit.CENTI_METER },
		},
		ceilingHeight: { value: 11.25, unit: Unit.CENTI_METER },
		doorImageSelection: [defaultSelectableImage1, defaultSelectableImage2],
		frameTypes: ['surroundingFrame', 'blockFrame'],
		featureElements: featureElementsConfig,
		acccessoryElements: accessoryElements,
		doors: selectableDoors,
		doorHeights: selectableDoorHeights,
		doorWidths: selectableDoorWidths,
		equipmentLines: equipmentLines,
		isSinkAndWCPositionSwapped: false,
		lightingTypes: ['lightingArea', 'lightingObject'],
		radiatorTypes: ['electricalRadiator'],
		ventilationTypes: ['singleVentilationSystem', 'centralVentilationSystem'],
		showerWallPositionLeftRight: 'left',
		socketConfig,
		wallConfig,
		doorSpaceToWcOrSink: {
			value: -10 - 25, // -10 - lightSwitch
			unit: Unit.CENTI_METER,
		},
		marginRadiatorToWall: { value: 10, unit: Unit.CENTI_METER },
		radiatorHeight: { value: 10, unit: Unit.CENTI_METER },
		radiatorWidth: { value: 60, unit: Unit.CENTI_METER },
		tileHeightBaseTiles: { value: 70, unit: Unit.MILLI_METER },
		tileHeightHalfRoomHeight: { value: 120, unit: Unit.CENTI_METER },
		marginRadiatorToSinkForSukiCommon: {
			value: 200,
			unit: Unit.MILLI_METER,
		},
	};

	const productFamVariantsHinoki: ProductFamilyVariant[] = [
		{
			id: 'guest',
			name: 'steps.productSelection.hinokiGuest',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			defaultRoomData: defaultRoomSpec1,
			imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
			roomConfiguration: defaultRoomconfigurationHinokiGuest,
		},
		{
			id: 'common',
			name: 'steps.productSelection.hinokiCommon',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			defaultRoomData: defaultRoomSpec1,
			imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
			roomConfiguration: defaultRoomconfigurationHinokiCommon,
		},
		{
			id: 'barrierfree',
			name: 'steps.productSelection.hinokiBarrierFree',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			defaultRoomData: defaultRoomSpec1,
			imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
			roomConfiguration: defaultRoomconfigurationHinokiBF,
		},
	];

	const productFamVariantsSuki: ProductFamilyVariant[] = [
		{
			id: 'guest',
			name: 'steps.productSelection.sukiGuest',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			defaultRoomData: defaultRoomSpec1,
			imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
			roomConfiguration: defaultRoomconfigurationSukiGuest,
		},
		{
			id: 'common',
			name: 'steps.productSelection.sukiCommon',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			defaultRoomData: defaultRoomSpec1,
			imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
			roomConfiguration: defaultRoomconfigurationSukiCommon,
		},
		{
			id: 'barrierfree',
			name: 'steps.productSelection.sukiBarrierFree',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			defaultRoomData: defaultRoomSpec1,
			imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
			roomConfiguration: defaultRoomconfigurationSukiBF,
		},
	];

	const productFamilies: ProductFamily[] = [
		{
			id: 'hinoki',
			name: 'steps.productSelection.hinokiSeries',
			imageUrl: '/assets/icons/tjiko_icons_190115_Kaeufer.png',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			productFamilyVariants: productFamVariantsHinoki,
		},
		{
			id: 'suki',
			name: 'steps.productSelection.sukiSeries',
			imageUrl: '/assets/icons/tjiko_icons_190115_Planung.png',
			descriptionText:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum leo a felis feugiat tristique. In hac habitasse platea dictumst. Ut ac lobortis leo.',
			productFamilyVariants: productFamVariantsSuki,
		},
	];

	const productCategories: ProductCategory[] = [
		{
			id: 'functional',
			name: 'steps.productSelection.functionalName',
			imageUrl: '/assets/icons/tjiko_icons_180306_Zeichenfläche 1.png',
			descriptionText:
				'BlaBlub functional products provide essential requirements with a focus on functionality. These washrooms are the smallest of the BlaBlub product family.',
			productFamilies: productFamilies,
		},
	];

	const defaultAppConfig: AppConfig = {
		productCategories: productCategories,
	};

	return defaultAppConfig;
}
