import { Middleware } from 'redux';
import { ActionType } from '../../model/model';
import { RootState } from '../../reducers';
import { calculateFeatureElementCoordinates } from './roomElementPositionCalculator';

/**
 * Middleware for field validation and step progress:
 * Every dispatched redux action will pass this middleware before it gets handeled.
 * Fields that are newly registered will be validated by this middleware and every update action of the roomSpec state will also trigger the validation
 * All other ActionTypes are passed through.
 *
 */

const RoomElementPositionCalculatorMiddleware: Middleware = (store) => (next) => (action) => {
	let state: RootState = store.getState();

	switch (action.type) {
		case ActionType.UPDATE_ROOMSPEC:
			/*check if room config was already defined by selecting the product family,
		 room drawing is only needed after selection of the product family/room config */
			if (Object.keys(state.roomConfigurationValues).length > 0) {
				action.payload = calculateFeatureElementCoordinates(state, action.payload);
			}

			break;
	}

	return next(action);
};

export default RoomElementPositionCalculatorMiddleware;
