import { CssBaseline } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import * as React from 'react';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
	typography: { fontFamily: "'Open Sans'" },
	palette: {
		primary: {
			light: '#7a7878',
			main: '#4e4c4c',
			dark: '#262424',
			contrastText: '#fff',
		},
		secondary: {
			light: '#80B3FF',
			main: '#80B3FF',
			dark: '#80B3FF',
			contrastText: '#fff',
		},
	},
});

function withRoot(Component: any) {
	function WithRoot(props: object) {
		// MuiThemeProvider makes the theme available down the React tree
		// thanks to React context.
		return (
			<ThemeProvider theme={theme}>
				{/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />
				<Component {...props} />
			</ThemeProvider>
		);
	}

	return WithRoot;
}

export default withRoot;
