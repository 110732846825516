// prettier-ignore
import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import * as React from 'react';
import { UnityFunctions } from './UnityFunctions';

interface Props {
	unityFunctions: UnityFunctions;
}

function ActionPanel(props: Props) {
	const classes = useStyles(props);
	const unityF = props.unityFunctions;

	return (
		<Paper className={classes.root}>
			<Grid container spacing={4}>
				<Grid item sm={12}>
					<Typography gutterBottom variant="h5" component="h2">
						Actions
					</Typography>
				</Grid>
				<Grid item sm={6}>
					<Button variant="contained" color="secondary" onClick={() => unityF.setTilesToRoomHeight()}>
						Tiles to Room Height
					</Button>
				</Grid>
				<Grid item sm={6}>
					<Button variant="contained" color="secondary" onClick={() => unityF.setTilesTo120()}>
						Tiles to 120cm
					</Button>
				</Grid>
				<Grid item sm={6}>
					<Button variant="contained" color="secondary" onClick={() => unityF.setTilesToSock()}>
						Tiles to sock height
					</Button>
				</Grid>
				<Grid item sm={6}>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => unityF.setShowerTilesOrShowerWedi(true)}
					>
						Shower tiles to wedi
					</Button>
				</Grid>
				<Grid item sm={6}>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => unityF.setShowerTilesOrShowerWedi(false)}
					>
						Wedi to shower tiles
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		marginTop: 30,
		maxWidth: 500,
		minHeight: 200,
		padding: 10,
	},
});

export default ActionPanel;
