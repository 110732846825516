import { RoomSpec, Wall, WallAlignment, WallConfig, WallType } from '../../../model/model';
import { convertLengthUnitToCentimeter } from '../LengthUnitConverter';

export function getModuleWallThickness(roomSpec: RoomSpec, wallConfig: WallConfig, wallAlignment: WallAlignment) {
	try {
		const wallThickness = convertLengthUnitToCentimeter(wallConfig.wallThicknessWallDefault);
		const wallThicknessShowerOrSink = convertLengthUnitToCentimeter(wallConfig.wallThicknessWallWithBasinOrShower);
		const wallThicknessWC = convertLengthUnitToCentimeter(wallConfig.wallThicknessWallWithToilet);

		let shower = roomSpec.featureElements['SH'];
		let toilet = roomSpec.featureElements['WC'];

		let sink = roomSpec.featureElements['SI'];

		if (toilet && toilet.wallAlignment === wallAlignment) {
			return wallThicknessWC;
		}
		if ((shower && shower.wallAlignment === wallAlignment) || (sink && sink.wallAlignment === wallAlignment)) {
			return wallThicknessShowerOrSink;
		}

		return wallThickness;
	} catch (e) {
		console.error('Can not calculate wallThickness');
		return 1;
	}
}

export function getRoomWallThickness(wallType: WallType, wallConfig: WallConfig, marginForRoomWalls: number) {
	try {
		const roomWallThicknessExterior = convertLengthUnitToCentimeter(wallConfig.roomWallThicknessExterior);
		const roomWallThicknessInterior = convertLengthUnitToCentimeter(wallConfig.roomWallThicknessInterior);
		//marginForRoomWalls is the margin between room walls and the module walls
		//(the drawed thickness of the wall is without the margin, but the maring is included in the thickness for an easier calculation)
		switch (wallType) {
			case WallType.IN_FRONT_OF_EXTERIOR:
				return roomWallThicknessExterior + marginForRoomWalls;
			case WallType.IN_FRONT_OF_INTERIOR:
				return roomWallThicknessInterior + marginForRoomWalls;
			default:
				return 0;
		}
	} catch (e) {
		console.error('Can not calculate roomWallThickness');
		return 1;
	}
}

export function getSecondInstallationWall(
	wallAlignmentReferencedElement: WallAlignment,
	secondInstallationWallDirection: 'left' | 'right'
) {
	let secondInstallationWall = WallAlignment.EAST;
	switch (wallAlignmentReferencedElement) {
		case WallAlignment.WEST:
			secondInstallationWall =
				secondInstallationWallDirection === 'right' ? WallAlignment.NORTH : WallAlignment.SOUTH;
			break;
		case WallAlignment.EAST:
			secondInstallationWall =
				secondInstallationWallDirection === 'right' ? WallAlignment.SOUTH : WallAlignment.NORTH;
			break;
		case WallAlignment.NORTH:
			secondInstallationWall =
				secondInstallationWallDirection === 'right' ? WallAlignment.EAST : WallAlignment.WEST;
			break;
		case WallAlignment.SOUTH:
			secondInstallationWall =
				secondInstallationWallDirection === 'right' ? WallAlignment.WEST : WallAlignment.EAST;
			break;
	}

	return secondInstallationWall;
}

export function getWallAlignmentOfWallAndAdjacentWalls(
	wallAlignmentShowerInstallation: WallAlignment,
	positionOfWallNextToIt: 'left' | 'right' | 'both'
) {
	let walls: WallAlignment[] = [];
	walls.push(wallAlignmentShowerInstallation);

	switch (wallAlignmentShowerInstallation) {
		case WallAlignment.WEST:
			positionOfWallNextToIt === 'left'
				? walls.push(WallAlignment.SOUTH)
				: positionOfWallNextToIt === 'right'
				? walls.push(WallAlignment.NORTH)
				: walls.push(WallAlignment.SOUTH) && walls.push(WallAlignment.NORTH);

			break;
		case WallAlignment.EAST:
			positionOfWallNextToIt === 'left'
				? walls.push(WallAlignment.NORTH)
				: positionOfWallNextToIt === 'right'
				? walls.push(WallAlignment.SOUTH)
				: walls.push(WallAlignment.SOUTH) && walls.push(WallAlignment.NORTH);
			break;
		case WallAlignment.NORTH:
			positionOfWallNextToIt === 'left'
				? walls.push(WallAlignment.WEST)
				: positionOfWallNextToIt === 'right'
				? walls.push(WallAlignment.EAST)
				: walls.push(WallAlignment.WEST) && walls.push(WallAlignment.EAST);
			break;
		case WallAlignment.SOUTH:
			positionOfWallNextToIt === 'left'
				? walls.push(WallAlignment.EAST)
				: positionOfWallNextToIt === 'right'
				? walls.push(WallAlignment.WEST)
				: walls.push(WallAlignment.WEST) && walls.push(WallAlignment.EAST);
			break;
	}

	return walls;
}

export function getOppositeWallAlignment(wallAlignment: WallAlignment) {
	switch (wallAlignment) {
		case WallAlignment.WEST:
			return WallAlignment.EAST;
		case WallAlignment.EAST:
			return WallAlignment.WEST;
		case WallAlignment.NORTH:
			return WallAlignment.SOUTH;
		case WallAlignment.SOUTH:
			return WallAlignment.NORTH;
	}

	return WallAlignment.SOUTH;
}

export function getLeftAdjacentWallAlignment(wallAlignment: WallAlignment) {
	switch (wallAlignment) {
		case WallAlignment.WEST:
			return WallAlignment.SOUTH;
		case WallAlignment.EAST:
			return WallAlignment.NORTH;
		case WallAlignment.NORTH:
			return WallAlignment.WEST;
		case WallAlignment.SOUTH:
			return WallAlignment.EAST;
	}
}

export function getRightAdjacentWallAlignment(wallAlignment: WallAlignment) {
	switch (wallAlignment) {
		case WallAlignment.WEST:
			return WallAlignment.NORTH;
		case WallAlignment.EAST:
			return WallAlignment.SOUTH;
		case WallAlignment.NORTH:
			return WallAlignment.EAST;
		case WallAlignment.SOUTH:
			return WallAlignment.WEST;
	}

	return WallAlignment.SOUTH;
}

export function getWallAlignmentOfOppositeWall(wallAlignment: WallAlignment) {
	switch (wallAlignment) {
		case WallAlignment.WEST:
			return WallAlignment.EAST;
		case WallAlignment.EAST:
			return WallAlignment.WEST;
		case WallAlignment.NORTH:
			return WallAlignment.SOUTH;
		case WallAlignment.SOUTH:
			return WallAlignment.NORTH;
	}
}

//returns position "left" or "right" based on two wall alignments, the position is the opposite position of the first wall alignment compared with the second wall alignment
//if the compared wall alignment is the opposite wall, the position "center" will be returned
//used for suki guest
//example: WC is on North wall, sink is on east wall-> WC has to be on the left side of the north wall
export function getOppositePositionAtWallBasedOnWallAlignments(
	wallAlignment: WallAlignment,
	wallAlignmentToCompare: WallAlignment
) {
	let positionAtWall: 'left' | 'right' | 'center' = 'center';
	switch (wallAlignment) {
		case WallAlignment.WEST:
			positionAtWall =
				wallAlignmentToCompare === WallAlignment.NORTH
					? 'left'
					: wallAlignmentToCompare === WallAlignment.SOUTH
					? 'right'
					: 'center';
			break;
		case WallAlignment.EAST:
			positionAtWall =
				wallAlignmentToCompare === WallAlignment.SOUTH
					? 'left'
					: wallAlignmentToCompare === WallAlignment.NORTH
					? 'right'
					: 'center';
			break;
		case WallAlignment.NORTH:
			positionAtWall =
				wallAlignmentToCompare === WallAlignment.EAST
					? 'left'
					: wallAlignmentToCompare === WallAlignment.WEST
					? 'right'
					: 'center';
			break;
		case WallAlignment.SOUTH:
			positionAtWall =
				wallAlignmentToCompare === WallAlignment.WEST
					? 'left'
					: wallAlignmentToCompare === WallAlignment.EAST
					? 'right'
					: 'center';
			break;
	}

	return positionAtWall;
}

export function isWallOnOppositeSideOrSameSide(wall: Wall, wallAlignmentToCompare: WallAlignment) {
	if (wall.wallAlignment === wallAlignmentToCompare) {
		return true;
	}
	switch (wallAlignmentToCompare) {
		case WallAlignment.NORTH:
			return wall.wallAlignment === WallAlignment.SOUTH;
		case WallAlignment.SOUTH:
			return wall.wallAlignment === WallAlignment.NORTH;
		case WallAlignment.WEST:
			return wall.wallAlignment === WallAlignment.EAST;
		case WallAlignment.EAST:
			return wall.wallAlignment === WallAlignment.WEST;
	}
}
