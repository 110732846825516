// prettier-ignore
import { Theme } from "@material-ui/core/";
import { makeStyles, useTheme } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useActions } from '../../actions';
import * as RoomSpecActions from '../../actions/roomSpec';
import * as ShapediverActions from '../../actions/shapediver';
import { useValidator } from '../../middleware/fieldValidation/fieldValidatorHook';
// prettier-ignore
import { RoomConfiguration, RoomSpec, Route, Unit } from "../../model/model";
import { RootState } from '../../reducers';
import CaclulationRow from '../calculationRow/CaclulationRow';
import ConfigFieldList from '../configuratorView/ConfigFieldList';
import ConfiguratorView from '../configuratorView/ConfiguratorView';
import ConfigDivider from '../configuratorView/fields/ConfigDivider';
import ConfigFieldContainer from '../configuratorView/fields/ConfigFieldContainer';
import ConfigNextButton from '../configuratorView/fields/ConfigNextButton';
import { convertCentiMeterInMeter, convertLengthUnitToMeter } from '../roomPlan/LengthUnitConverter';
import {
	getCalculatedRoomDimensions,
	getMinMaxDimensionLengthAndWidth,
} from '../roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';
import StepSlider from '../sliderWithInput/StepSlider';
import FeatureElements from './FeatureElementsView';
import WallConfig from './WallConfig';

interface Props extends RouteComponentProps<void> {}

function DimensionsView(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);

	React.useEffect(() => {
		roomSpecActions.updateRoomSpec(roomSpec);
	}, []);

	return (
		<ConfiguratorView
			configFieldList={
				<ConfigFieldList>
					<ConfigDivider title={t('steps.structuralElements.dimension')} />
					<DimensionConfig {...props} />
					<ConfigDivider />
					<WallConfig {...props} />
					{/*<ConfigDivider title={t('steps.structuralElements.featureElements')} />*/}
					{/*<FeatureElements />*/}
					<ConfigDivider />
					<ConfigNextButton nextRoute={Route.STRUCTURAL_ELEMENTS_DOOR_SPECIFICATION} />
				</ConfigFieldList>
			}
		/>
	);
}

function DimensionConfig(props: Props) {
	const { getErrorText, setFieldDirty } = useValidator();
	const { t } = useTranslation();
	const theme: Theme = useTheme();
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);

	const selectableValues: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);

	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);
  const shapediverActions: typeof ShapediverActions = useActions(ShapediverActions);

	const numberBackgroundColor = {
		notEditable: theme.palette.background.paper,
		editable: theme.palette.background.default,
		editing: theme.palette.background.default,
	};

	const {
		dimensionLength,
		dimensionWidth,
		storeyHeight: dimensionHeight,
		subFloorHeight,
		roomHeight,
		ceilingHeight,
	} = roomSpec;

	const {
		minRoomHeightInMeter,
		maxRoomHeightInMeter,
		minSubFloorHeightInCentimeter,
		maxSubFloorHeightInCentimeter,
	} = getMinMaxDimensionLengthAndWidth(roomSpec, selectableValues);

	const minWidth = 2.8;
	const maxWidth = 4;
  const minLength = 1.5;
  const maxLength = 2.5;

	const updateLength = (value: number) => {
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			dimensionLength: { ...dimensionLength, value: value },
			lightingCount: Math.ceil(
				getCalculatedRoomDimensions(
					{
						...roomSpec,
						dimensionLength: { ...dimensionLength, value: value },
					},
					selectableValues
				).roomInsideMeasure /
					(100 * 100)
			),
		});
	};

	const updateWidth = (value: number) => {
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			dimensionWidth: { ...dimensionWidth, value: value },
			lightingCount: Math.ceil(
				getCalculatedRoomDimensions(
					{
						...roomSpec,
						dimensionWidth: { ...dimensionWidth, value: value },
					},
					selectableValues
				).roomInsideMeasure /
					(100 * 100)
			),
		});
	};

	const updateSubfloorHeight = (value: number) => {
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			subFloorHeight: { ...subFloorHeight, value: value },
			storeyHeight: {
				...dimensionHeight,
				value:
					convertCentiMeterInMeter(value) +
					convertLengthUnitToMeter(ceilingHeight) +
					convertLengthUnitToMeter(roomHeight),
			},
		});
	};

	const updateRoomHeight = (value: number) => {
		setFieldDirty('roomHeight');
		roomSpecActions.updateRoomSpec({
			...roomSpec,
			roomHeight: { ...roomHeight, value: value },
			storeyHeight: {
				...dimensionHeight,
				value: convertLengthUnitToMeter(subFloorHeight) + convertLengthUnitToMeter(ceilingHeight) + value,
			},
		});
	};

	if (convertLengthUnitToMeter(roomSpec.dimensionLength) < minLength) {
		updateLength(minLength);
	}
	if (convertLengthUnitToMeter(roomSpec.dimensionLength) > maxLength) {
		updateLength(maxLength);
	}

	if (convertLengthUnitToMeter(roomSpec.dimensionWidth) < minWidth) {
		updateWidth(minWidth);
	}
	if (convertLengthUnitToMeter(roomSpec.dimensionWidth) > maxWidth) {
		updateWidth(maxWidth);
	}
	return (
		<ConfigFieldContainer>
      <StepSlider
        label={"Außenlänge"}
        value={dimensionWidth.value}
        onChange={updateWidth}
        minValue={minWidth}
        maxValue={maxWidth}
        unit={Unit.METER}
        step={0.001}
      />
			<StepSlider
				label={"Außenbreite"}
				value={dimensionLength.value}
				onChange={updateLength}
				minValue={minLength}
				maxValue={maxLength}
				unit={Unit.METER}
				step={0.001}
			/>

			<StepSlider
				label={t('steps.structuralElements.roomHeight')}
				value={roomHeight.value}
				onChange={updateRoomHeight}
				minValue={minRoomHeightInMeter}
				maxValue={maxRoomHeightInMeter}
				unit={Unit.METER}
				step={0.1}
			/>

			<StepSlider
				label={t('steps.structuralElements.subFloorHeight')}
				value={subFloorHeight.value}
				onChange={updateSubfloorHeight}
				minValue={minSubFloorHeightInCentimeter}
				maxValue={maxSubFloorHeightInCentimeter}
				unit={subFloorHeight.unit}
				step={1}
			/>
			<CaclulationRow
				label={t('steps.structuralElements.ceilingHeight')}
				editable={false}
				value={ceilingHeight.value}
				numberBackgroundColor={numberBackgroundColor}
				units={[ceilingHeight.unit]}
				decimalDigits={2}
			/>
			<CaclulationRow
				label={t('steps.structuralElements.storeyHeight')}
				value={dimensionHeight.value}
				editable={false}
				numberBackgroundColor={numberBackgroundColor}
				units={[dimensionHeight.unit]}
				decimalDigits={2}
			/>
		</ConfigFieldContainer>
	);
}

const useStyles = makeStyles((theme: Theme) => ({}));

export default DimensionsView;
