// prettier-ignore
import { makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
	id: number;
	name: string;
	price: number;
	imgSrc: string;
	onSelect: (id: number) => void;
}

function SelectionListItem(props: Props) {
	const { id, name, price, imgSrc, onSelect } = props;
	const classes = useStyles();

	return (
		<div className={classes.root} onClick={() => onSelect(id)} key={id + name}>
			<img alt="" src={imgSrc} height={50} width={50} className={classes.img} />
			<div className={classes.textContainer}>
				<Typography className={classes.name}>{name}</Typography>
				<Typography className={classes.price}>{price + ',- €'}</Typography>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 10,
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			cursor: 'pointer',
		},

		borderBottomWidth: 1,
		borderBottomColor: 'lightgrey',
		borderBottomStyle: 'solid',
	},

	textContainer: {
		marginLeft: 5,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},

	name: {},

	price: {
		fontWeight: 'bold',
		fontSize: 12,
	},

	img: {},
}));

export default SelectionListItem;
