// prettier-ignore
import { Action, ActionType } from "../model/model";
import createReducer from './createReducer';

export const token = createReducer<string>('', {
	[ActionType.GET_USER_TOKEN](state: string, action: Action<string>) {
		return action.payload;
	},
	[ActionType.SET_TOKEN](state: string, action: Action<string>) {
		return action.payload;
	},
});

export const authError = createReducer<number>(0, {
	[ActionType.SET_AUTH_ERROR](state: number, action: Action<number>) {
		return action.payload;
	},
});

export const serverStatus = createReducer<number>(0, {
	[ActionType.GET_PASSWORD_RESET_LINK](state: number, action: Action<number>) {
		return action.payload;
	},
});
