// prettier-ignore
import {makeStyles} from '@material-ui/core';
import * as React from 'react';
import SDViewer from "../shapediver/SDViewer";

interface Props {
  configFieldList: React.ReactNode;
}

function ConfiguratorView(props: Props) {
  const {configFieldList} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {configFieldList}
      <div className={classes.planContainer}>
        <SDViewer
          ticket="7021fd8db7f91fe8803e32e0da2e0221213dce060767c3ffaa39735b4b220b9d165bd30c592ed55a96fbd666d644bf2beff71250a9930b26f114d59ab25ec6779150e91f903dffa1c37481713ac42e2561c7275c22e9153508a3a6c5a6e01eb9f1a6a5f1730804f1af34d4d5e51f72dcc8c0814a7f78-16e5eee2b0b46559ceb6e4bdfdab8a3c"
          modelViewUrl="eu-central-1"
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },

  planContainer: {
    height: window.innerHeight - 112,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ConfiguratorView;
