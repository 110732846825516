import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

export function scale(size?: number, min?: number, max?: number) {
	const w = window.innerWidth;
	const h = window.innerHeight;
	const zw = w / 1200;
	const zh = h / 900;

	const scaleFactor = zw <= zh ? zw : zh;

	if (!size) {
		return scaleFactor;
	}

	const s = size * scaleFactor;

	if (min && min > s) {
		return min;
	}
	if (max && max < s) {
		return max;
	}

	return s;
}

export const drawerWidth = scale(350, 300, 350);

const allowMobile = true;
export function isSmartphone(width: Breakpoint): boolean {
	return allowMobile && isWidthDown('xs', width);
}

export function isTablet(width: Breakpoint): boolean {
	return allowMobile && isWidthDown('sm', width);
}

export function isMobile(width: Breakpoint): boolean {
	return allowMobile && isTablet(width);
}

export function isDesktop(width: Breakpoint): boolean {
	return !allowMobile || isWidthUp('md', width);
}
