import {
	DoorSpecification,
	LengthUnit,
	SocketConfig,
	Unit,
	WallAlignment,
	WallSwitch,
	WallSwitchType,
} from '../../../model/model';
import { convertLengthUnitToCentimeter } from '../LengthUnitConverter';

export function getUpdatedDoorSwitches(door: DoorSpecification, socketConfig: SocketConfig) {
	let wallSwitches: WallSwitch[] = [];

	const marginForSwitchesToDoorInCM = convertLengthUnitToCentimeter(socketConfig.marginSocketSwitchToDoor);
	const doorWidth = convertLengthUnitToCentimeter(door.width);
	const doorDepth = convertLengthUnitToCentimeter(door.depth);

	const doorXPosition = convertLengthUnitToCentimeter(door.xCoordinate);
	const doorYPosition = convertLengthUnitToCentimeter(door.yCoordinate);

	let socketXPosition = { value: 0, unit: Unit.CENTI_METER };
	let socketYPosition = { value: 0, unit: Unit.CENTI_METER };
	let wallAlignment = door.wall!.wallAlignment;

	let switchPositionY = { value: 0, unit: Unit.CENTI_METER };
	let switchPositionX = { value: 0, unit: Unit.CENTI_METER };

	let marginSwitchSocketForDrawing = 20;

	if (door.openingDirectionLeftRight === 'left') {
		switch (wallAlignment) {
			//doorDepth included in calculation since door position is rotated and the position was adapted to ensure a correct display
			//That adaption in in the door drawing has to be applied on the switches position
			case WallAlignment.WEST:
				socketYPosition.value += doorYPosition - doorDepth + doorWidth / 2 + marginForSwitchesToDoorInCM;
				switchPositionY = socketYPosition;
				switchPositionX.value = marginSwitchSocketForDrawing;
				break;
			case WallAlignment.EAST:
				socketYPosition.value += doorYPosition - doorDepth - marginForSwitchesToDoorInCM - doorWidth / 2;
				switchPositionY = socketYPosition;
				switchPositionX.value -= marginSwitchSocketForDrawing;
				break;
			case WallAlignment.NORTH:
				socketXPosition.value += doorXPosition - doorDepth - marginForSwitchesToDoorInCM - doorWidth / 2;
				switchPositionX = socketXPosition;
				switchPositionY.value = +marginSwitchSocketForDrawing;
				break;
			case WallAlignment.SOUTH:
				socketXPosition.value += doorXPosition + doorDepth + doorWidth / 2 + marginForSwitchesToDoorInCM;
				switchPositionX = socketXPosition;
				switchPositionY.value = -marginSwitchSocketForDrawing;
				break;
		}
	} else if (door.openingDirectionLeftRight === 'right') {
		switch (wallAlignment) {
			//doorDepth included in calculation since door position is rotated and the position was adapted to ensure a correct display
			//That adaption in in the door drawing has to be applied on the switches position
			case WallAlignment.WEST:
				socketYPosition.value += doorYPosition - doorDepth - marginForSwitchesToDoorInCM - doorWidth / 2;
				switchPositionY = socketYPosition;
				switchPositionX.value = marginSwitchSocketForDrawing;
				break;
			case WallAlignment.EAST:
				socketYPosition.value += doorYPosition - doorDepth + doorWidth / 2 + marginForSwitchesToDoorInCM;
				switchPositionY = socketYPosition;
				switchPositionX.value = -marginSwitchSocketForDrawing;
				break;
			case WallAlignment.NORTH:
				socketXPosition.value += doorXPosition + doorDepth + doorWidth / 2 + marginForSwitchesToDoorInCM;
				switchPositionX = socketXPosition;
				switchPositionY.value = +marginSwitchSocketForDrawing;
				break;
			case WallAlignment.SOUTH:
				socketXPosition.value = doorXPosition - doorDepth - marginForSwitchesToDoorInCM - doorWidth / 2;
				switchPositionX = socketXPosition;
				switchPositionY.value = -marginSwitchSocketForDrawing;
				break;
		}
	}

	switchPositionX.value = switchPositionX.value;
	switchPositionY.value = switchPositionY.value;

	socketXPosition.value = socketXPosition.value;
	socketYPosition.value = socketYPosition.value;

	let lightSwitch: WallSwitch = {
		id: 'LightSwitch_Door_1',
		height: socketConfig.doorSwitchHeight,
		type: WallSwitchType.SINGLE_SWITCH,
		wallAlignment: wallAlignment,
		width: socketConfig.switchWidth,
		xCoordinate: switchPositionX,
		yCoordinate: switchPositionY,
	};

	let socket: WallSwitch = {
		id: 'Socket_Door_1',
		height: socketConfig.doorSocketHeight,
		type: WallSwitchType.SINGLE_SOCKET,
		wallAlignment: wallAlignment,
		width: socketConfig.singleSocketWidth,
		xCoordinate: socketXPosition,
		yCoordinate: socketYPosition,
	};

	wallSwitches.push(lightSwitch);
	wallSwitches.push(socket);
	return wallSwitches;
}

export function getSinkWallSocket(
	sinkPositionX: LengthUnit,
	sinkPositionY: LengthUnit,
	sinkHeight: LengthUnit,
	sinkWidth: LengthUnit,
	sinkWallAlignment: WallAlignment,
	showerWallAlignment: WallAlignment | undefined,
	socketConfig: SocketConfig,
	showerWallPositionLeftRight: 'left' | 'right'
) {
	let socketPositionX = { value: 0, unit: Unit.CENTI_METER };
	let socketPositionY = { value: 0, unit: Unit.CENTI_METER };

	let socketWithInCM = convertLengthUnitToCentimeter(socketConfig.doubleSocketWidth);

	let sinkPositionXInCM = convertLengthUnitToCentimeter(sinkPositionX);
	let sinkPositionYInCM = convertLengthUnitToCentimeter(sinkPositionY);
	let sinkHeightInCM = convertLengthUnitToCentimeter(sinkHeight);
	let sinkWidthInCM = convertLengthUnitToCentimeter(sinkWidth);

	let positionFromSink: 'left' | 'right' =
		showerWallAlignment === undefined
			? 'right'
			: getSocketPositionFromSink(sinkWallAlignment, showerWallAlignment, showerWallPositionLeftRight);

	switch (sinkWallAlignment) {
		case WallAlignment.WEST:
			socketPositionY.value =
				positionFromSink === 'right'
					? sinkPositionYInCM - sinkWidthInCM / 2 + socketWithInCM / 2
					: sinkPositionYInCM + sinkWidthInCM / 2 - socketWithInCM / 2;

			break;
		case WallAlignment.EAST:
			socketPositionY.value =
				positionFromSink === 'right'
					? sinkPositionYInCM + sinkWidthInCM / 2 - socketWithInCM / 2
					: sinkPositionYInCM - sinkWidthInCM / 2 + socketWithInCM / 2;
			break;
		case WallAlignment.NORTH:
			socketPositionX.value =
				positionFromSink === 'right'
					? sinkPositionXInCM + sinkWidthInCM / 2 - socketWithInCM / 2
					: sinkPositionXInCM - sinkWidthInCM / 2 + socketWithInCM / 2;
			break;
		case WallAlignment.SOUTH:
			socketPositionX.value =
				positionFromSink === 'right'
					? sinkPositionXInCM - sinkWidthInCM / 2 + socketWithInCM / 2
					: sinkPositionXInCM + sinkWidthInCM / 2 - socketWithInCM / 2;
			break;
	}

	socketPositionX.value = socketPositionX.value;
	socketPositionY.value = socketPositionY.value;

	let doubleSocket: WallSwitch = {
		id: 'LightSwitch_Door_1',
		height: socketConfig.doubleSocketSinkHeight,
		type: WallSwitchType.SAFETY_DOUBLE_SOCKET,
		wallAlignment: sinkWallAlignment,
		width: { value: socketWithInCM, unit: Unit.CENTI_METER },
		xCoordinate: socketPositionX,
		yCoordinate: socketPositionY,
	};

	return doubleSocket;
}

//socket at Sink should be on as far away from the shower as possible: shower on the left of Sink => socket on the right, etc.
export function getSocketPositionFromSink(
	sinkWallAlignment: WallAlignment,
	showerWallAlignment: WallAlignment,
	showerPositionLeftRight: 'left' | 'right'
) {
	let socketPositionFromSink: 'left' | 'right' = 'right';

	//shower is always in the  corner of a wall, so socket position has to be on the opposite side if wall alignments are equal
	if (sinkWallAlignment === showerWallAlignment) {
		socketPositionFromSink = showerPositionLeftRight === 'right' ? 'left' : 'right';
		return socketPositionFromSink;
	}
	switch (sinkWallAlignment) {
		case WallAlignment.WEST:
			socketPositionFromSink =
				showerWallAlignment === WallAlignment.NORTH || showerWallAlignment === WallAlignment.EAST
					? 'left'
					: 'right';
			break;
		case WallAlignment.EAST:
			socketPositionFromSink =
				showerWallAlignment === WallAlignment.WEST || showerWallAlignment === WallAlignment.SOUTH
					? 'left'
					: 'right';
			break;
		case WallAlignment.NORTH:
			socketPositionFromSink =
				showerWallAlignment === WallAlignment.EAST || showerWallAlignment === WallAlignment.SOUTH
					? 'left'
					: 'right';
			break;
		case WallAlignment.SOUTH:
			socketPositionFromSink =
				showerWallAlignment === WallAlignment.WEST || showerWallAlignment === WallAlignment.NORTH
					? 'left'
					: 'right';
			break;
	}

	return socketPositionFromSink;
}
