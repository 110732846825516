// prettier-ignore
import {CircularProgress, Drawer as DrawerMui, makeStyles, Typography} from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RoomSpecPrice } from '../../model/model';
import { RootState } from '../../reducers';

interface Props {}

function Price(props: Props) {
	const {} = props;
	const classes = useStyles();

	const roomSpecPrice: RoomSpecPrice = useSelector((state: RootState) => state.roomSpecPrice);

	if (roomSpecPrice.loading) {
		return (
			<div className={classes.root}>
				<Typography className={classes.netto}>Preis (netto):</Typography>
				<CircularProgress size={30} />
        <Typography className={classes.netto} variant="caption">Rabattierung bzgl. Projekt- und Stückzahl möglich</Typography>
			</div>
		);
	} else {
		return (
			<div className={classes.root}>
				<Typography className={classes.priceHint}>Preis (netto):</Typography>
				<Typography variant="h5" className={classes.price}>
					{roomSpecPrice && roomSpecPrice.value
						? roomSpecPrice.value.toFixed(2) + ' €'
						: roomSpecPrice.error
						? roomSpecPrice.error
						: ' - €'}
				</Typography>
        <Typography className={classes.priceHint} variant="caption">Rabattierung bzgl. Projekt- und Stückzahl möglich</Typography>
			</div>
		);
	}
}

const useStyles = makeStyles({
	root: {
		height: 124,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		padding: 10,
	},

	price: {
		margin: 0,
	},

	netto: {
		marginTop: 0,
		marginBottom: 8,
	},

  priceHint: {
	  marginTop: 8
  }
});

export default Price;
