/**
 * This file holds all validation functions and exposes them in an exported constant.
 * Those functions have to be used for validating fields by the validation middleware.
 * To add a new registration function, implement a new function with (value:any)=> ValidationResult and add it to the exported constant.
 * The key will be used to set the validation function in the validation field registration.
 * The error message of the validation result should not contain the readable error message,
 * but a translation string for the "react-i18next" package like result.errorMessage = "validation.errorTexts.numberNotValid"
 *
 */

import { isArray } from 'util';
import { convertLengthUnitToMeter } from '../../components/roomPlan/LengthUnitConverter';
import { LengthUnit, ValidationResult } from '../../model/model';

export const validationFunctions: {
	[key: string]: (value: any, combinedFields?: boolean, param?: any) => ValidationResult;
} = {
	numeric: validateNumericField,
	required: validateRequiredField,
	greaterThanZero: validateGreaterThanZeroField,
	requiredNoFalse: validateRequiredFieldNoFalse,
	greaterOrEqualMinValue: (value: any, combinedFields?: boolean, param?: any) =>
		validateGreaterOrEqualThanMinValue(value, param.greaterOrEqualMinValue),
};

function validateNumericField(value: any): ValidationResult {
	let result: ValidationResult = {
		isValid: true,
		message: '',
	};
	//combinedValidtionFields are not supported for this validation Funnction
	if (isArray(value)) {
		throw Error('Field validation excpected number or string value, not an array');
	}
	if (typeof value === 'number') {
		return result;
	} else if (typeof value === 'string') {
		if (isNaN(parseFloat(value))) {
			result.isValid = false;
			result.message = 'validation.errorTexts.numberNotValid';
		}
	} else {
		result.isValid = false;
		result.message = 'validation.errorTexts.numberNotValid';
	}

	return result;
}

function validateGreaterThanZeroField(value: any): ValidationResult {
	let result: ValidationResult = validateNumericField(value);
	if (!result.isValid) {
		return result;
	}

	let number = parseFloat(value);

	if (number <= 0) {
		result.isValid = false;
		result.message = 'validation.errorTexts.valueGreaterThanZero';
	}

	return result;
}

function validateRequiredField(value: any): ValidationResult {
	let result: ValidationResult = {
		isValid: true,
		message: '',
	};
	//at least one elemt has to be not null or undefined in the array
	if (isArray(value)) {
		//remove empty array elements
		value = value.filter((el) => el !== undefined && el !== null && el.length !== 0);
		if (value.length < 1) {
			result.isValid = false;
			result.message = 'validation.errorTexts.fieldIsRequired';
		}
	} else {
		if (value === undefined || value === null || value.length === 0) {
			result.isValid = false;
			result.message = 'validation.errorTexts.fieldIsRequired';
		}
	}

	return result;
}

function validateRequiredFieldNoFalse(value: any): ValidationResult {
	if (isArray(value)) {
		const newVal = value.map((el) => (el === false ? null : el));
		return validateRequiredField(newVal);
	} else {
		if (value === false) {
			return validateRequiredField(null);
		} else {
			return validateRequiredField(value);
		}
	}
}

function validateGreaterOrEqualThanMinValue(value: LengthUnit, param: LengthUnit): ValidationResult {
	if (value && param) {
		if (convertLengthUnitToMeter(value) >= convertLengthUnitToMeter(param)) {
			return {
				isValid: true,
				message: '',
			};
		}
	}
	return {
		isValid: false,
		message: 'validation.errorTexts.greaterOrEqualMinValue',
	};
}
