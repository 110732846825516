import { Action, ActionType, AppConfig } from '../model/model';
import createReducer from './createReducer';

const defaultAppConfig: AppConfig = {
	productCategories: [],
};

export const appConfig = createReducer<AppConfig>(defaultAppConfig, {
	[ActionType.GET_APP_CONFIG](state: AppConfig, action: Action<AppConfig>) {
		return { ...state, ...action.payload };
	},
});
