import {Box, Grid, makeStyles} from '@material-ui/core';
import download from 'downloadjs';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
//@ts-ignore
import {useSelector} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import useReactRouter from 'use-react-router';
import ButtonCard from '../components/overview/ButtonCard';
import Checklist from '../components/overview/Checklist';
import Price from '../components/overview/Price';
import FloorPlan from '../components/roomPlan/floorPlan/FloorPlan';
import {getCalculatedRoomDimensions} from '../components/roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';
import {contactMail, roomListPdfURL} from '../endpoints';
import {RoomConfiguration, RoomDimensions, RoomSpec} from '../model/model';
import {RootState} from '../reducers';
import SDViewer from "../components/shapediver/SDViewer";

interface Props extends RouteComponentProps<void> {}

async function getBase64ImageFromUrl(imageUrl: string) {
  const res = await fetch(imageUrl);
  const blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject('');
    };
    reader.readAsDataURL(blob);
  })
}

function OverviewPage(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);

	const stageRef = React.useRef<any>();
	const roomConfig: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);

	// if user is not authenticated -> redirect to login page
	const { history } = useReactRouter();

	const token: string = useSelector((state: RootState) => state.token);

	const roomSpecId: number | null = useSelector((state: RootState) => state.roomSpecId);
	const [png, setPNG] = React.useState<string | undefined>(undefined);

	const dim = getCalculatedRoomDimensions(roomSpec, roomConfig);

	React.useEffect(() => {
		if (!token) {
			history.push('/');
		}
	}, []);

	const downloadURI = (uri: string, name: string) => {
    window.api.exports.requestAsync({idOrName: "Download plan as .png"}).then(function (x: any) {
      const url: string = x.data.content[0].href;
      console.log(url);
    });
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={4} alignItems={'stretch'} direction={'row'} >
				<Grid item sm={12}>
					<div className={classes.planContainer}>
            <SDViewer
              ticket="7021fd8db7f91fe8803e32e0da2e0221213dce060767c3ffaa39735b4b220b9d165bd30c592ed55a96fbd666d644bf2beff71250a9930b26f114d59ab25ec6779150e91f903dffa1c37481713ac42e2561c7275c22e9153508a3a6c5a6e01eb9f1a6a5f1730804f1af34d4d5e51f72dcc8c0814a7f78-16e5eee2b0b46559ceb6e4bdfdab8a3c"
              modelViewUrl="eu-central-1"
            />
					</div>
				</Grid>
				<Grid item sm={6}>
					<SpecCard roomSpec={roomSpec} dim={dim} roomSpecId={roomSpecId} png={png} />
				</Grid>
				<Grid item sm={6}>
					<PriceCard
						onClick={() => {
							const subject = encodeURI(
								t('mail.contact.subject').replace('PPPPJJJJMMDD_Angebot', offerName())
							);
							const body = encodeURI(t('mail.contact.body').replace('Angebot .......', offerName()));

							window.open('mailto:' + contactMail + '?subject=' + subject + '&body=' + body);
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

function offerName(): string {
	const now = new Date();
	const today = now.getDate() + '-' + (now.getMonth() + 1) + '-' + now.getFullYear();

	return 'Angebot BlaBlub-Badmodul vom ' + today;
}

function SpecCard(props: { roomSpec: RoomSpec; dim: RoomDimensions; roomSpecId: number | null; png?: string }) {
	const { dim, roomSpec, roomSpecId, png } = props;
	const token: string = useSelector((state: RootState) => state.token);
	const [loading, setLoading] = React.useState(false);

	return (
		<ButtonCard
			buttonText="Konfiguration speichern und Angebot öffnen"
			onButtonClick={() => {
				setLoading(true);
				downloadFile(offerName() + '.pdf', roomListPdfURL + roomSpecId + '/', token, png, () =>
					setLoading(false)
				);
			}}
			loading={loading}
		>
			<Checklist
				headline="Charakteristika"
				bullets={[
					'Produktfamilie: ' + roomSpec.productFamilyId.toUpperCase(),
					'Länge: ' + (dim.dimensionLength / 100).toFixed(2) + ' m',
					'Breite: ' + (dim.dimensionWidth / 100).toFixed(2) + ' m',
					'Höhe: ' + roomSpec.storeyHeight.value.toFixed(2) + ' ' + roomSpec.storeyHeight.unit,
					'Nettogrundfläche (NGF): ' +
						((dim.moduleWidthInCM * dim.moduleLengthInCM) / 10000).toFixed(2) +
						' m²',
					'Bruttogrundfläche (BFG): ' +
						((dim.dimensionLength * dim.dimensionWidth) / 10000).toFixed(2) +
						' m²',
				]}
				footer="Weitere Informationen zum herunterladen:"
			/>
		</ButtonCard>
	);
}

function PriceCard(props: { onClick: () => void}) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<ButtonCard buttonText={t('steps.overview.contact')} onButtonClick={props.onClick}>
			<Checklist
				headline="Preis pro Bad"
				bullets={[
					'Höchste Ausführungsqualität durch industrielle Vorfertigung',
					'individuelle Gestaltung im klaren Kostenrahmen',
					'Sicherheit und Effizienz im Bauprozess',
				]}
			/>
			<Box className={classes.hackz} />
			<Price />
		</ButtonCard>
	);
}

async function downloadFile(
	filename: string,
	url: string,
	token: string,
	png: string | undefined,
	callback: (error?: boolean) => void
) {
	try {
	  const x = await window.api.exports.requestAsync({idOrName: "Download plan as .png"});
    const img_url: string = x.data.content[0].href;
    console.log(img_url);

    const png2: any = await getBase64ImageFromUrl(img_url);

		let formData = new FormData();
		formData.append('canvas', png2 ? png2 : png);

		const response = await fetch(url, {
			method: 'PUT',
			headers: new Headers({
				Accept: 'application/octet-stream',
				Authorization: 'Token ' + token,
			}),
			body: new URLSearchParams([...(formData as any)]),
		});

		if (response.status === 200) {
			response.headers.forEach((element) => {
				console.log(element);
			});

			const blob = await response.blob();
			download(blob, filename);
			callback(false);
		} else {
			callback(true);
		}
	} catch (e) {
		callback(true);
	}
}

const useStyles = makeStyles({
	root: { padding: '30px', maxWidth: 1000 },
	container: {
		margin: 50,
	},
	headingContainer: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		padding: 20,
	},
	addToOrderButton: {
		margin: '10px',
	},
	planContainer: {
		display: 'flex',
		width: '100%',
    height: window.innerHeight * 0.4,
		alignItems: 'center',
		justifyContent: 'center',
	},
	addOrderBtnContainer: {
		textAlign: 'right',
	},
    // set some height so both cards appear to have the same height
	// the right way: make paper/card use 100% of container height
	hackz: {
		height: '29px'
	}
});

export default OverviewPage;
