// prettier-ignore
import { makeStyles } from '@material-ui/core';
import * as React from 'react';

interface Props {
	children?: React.ReactNode | React.ReactNode[];
}

function ConfigFieldList(props: Props) {
	const { children } = props;
	const classes = useStyles();

	return <div className={classes.root}>{children}</div>;
}

const useStyles = makeStyles({
	root: {
		backgroundColor: 'white',
		width: 500,
		minWidth: 500,
		minHeight: '100%',
		maxHeight: '100%',
		borderRightColor: 'lightgrey',
		borderRightStyle: 'solid',
		borderRightWidth: 1,
		overflow: 'scroll',
	},
});

export default ConfigFieldList;
