// prettier-ignore
import { Collapse, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
	text?: string;
	open: boolean;
}

function InfoContainer(props: Props) {
	const { text, open } = props;
	const classes = useStyles();

	return (
		<Collapse in={open} className={classes.infoContainer}>
			<Typography className={classes.collapseText}>{text}</Typography>
		</Collapse>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	infoContainer: {
		backgroundColor: theme.palette.primary.light,
	},

	collapseText: {
		padding: 2,
		color: 'white',
	},
}));

export default InfoContainer;
