// prettier-ignore
import { useMediaQuery, withWidth } from "@material-ui/core";
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import AppBar, { appBarHeight } from './components/AppBar';
import Drawer from './components/drawer/Drawer';
import MobileOverlay from './components/MobileOverlay';
import Validator from './components/validation/Validator';
import { history } from './configureStore';
import i18n from './i18n'; // import i18n (needs to be bundled ;))
import { Route as R, Step } from './model/model';
import ExpertPage from './pages/ExpertPage';
import LoadRoomSpecPage from './pages/LoadRoomSpecPage';
import LoginPage from './pages/LoginPage';
import OverviewPage from './pages/OverviewPage';
import ProductSelectionPage from './pages/ProductSelectionPage';
import ReceiveUnitPage from './pages/ReceiveUnitPage';
import StructuralElementsPage from './pages/StructuralElementsPage';
import TechnicalDetailsPage from './pages/TechnicalDetailsPage';
import UnityDemoPage from './pages/UnityDemoPage';
import { RootState } from './reducers/index';
import withRoot from './withRoot';

function Routes() {
	const classes = useStyles();
	return (
		<div className={classes.routes}>
			<div className={classes.contentPages}>
				<Route exact={false} path={R.PRODUCT_SELECTION} component={ProductSelectionPage} />
				<Route exact={false} path={R.STRUCTURAL_ELEMENTS} component={StructuralElementsPage} />
				<Route exact={false} path={R.TECHNICAL_DETAILS} component={TechnicalDetailsPage} />
				{/* <Route
					exact={false}
					path={R.FEATURE_ELEMENTS}
					component={FeatureElementsPage}
				/>
				<Route
					exact={true}
					path={R.ACCESSORY_ELEMENTS}
					component={AccessoryElementsPage}
				/> */}
				<Route exact={true} path={R.OVERVIEW} component={OverviewPage} />
				<Route exact={false} path={R.EXPERT} component={ExpertPage} />

				{/* for component testing */}
				<Route exact={true} path="/unityDemo" component={UnityDemoPage} />
				<Route exact={true} path={R.RECEIVE_UNITS} component={ReceiveUnitPage} />
				<Route exact={true} path={R.LOAD_ROOMSPEC} component={LoadRoomSpecPage} />
			</div>
		</div>
	);
}

function App() {
	const classes = useStyles();
	const isMobileWidth = useMediaQuery('(max-width:1000px)');

	const [isFullscreenPage, setIsFullscreenPage] = React.useState(false);

	const fullscreenRoutes: string[] = ['/', R.RECEIVE_UNITS, R.EXPERT, R.LOAD_ROOMSPEC];

	React.useEffect(() => {
		setIsFullscreenPage(fullscreenRoutes.indexOf(window.location.pathname) > -1);

		history.listen(function (location: any) {
			setIsFullscreenPage(fullscreenRoutes.indexOf(location.pathname) > -1);
		});
	}, [fullscreenRoutes]);

	const stepList: Step[] = useSelector((state: RootState) => state.stepList);

	if (!i18n) {
		return null;
	}

	const path = window.location.pathname;

	if (isMobileBrowser() || isMobileWidth) {
		return <MobileOverlay />;
	}

	if (isFullscreenPage) {
		return (
			<Router history={history}>
				<Route exact={true} path="/" component={LoginPage} />
				{path !== '/' && (
					<div>
						<Validator />
						<div className={classes.pagesContainerFullscreen}>
							<div className={classes.contentMain}>
								<Routes />
							</div>
						</div>
					</div>
				)}
			</Router>
		);
	} else {
		return (
			<Router history={history}>
				<Route exact={true} path="/" component={LoginPage} />
				{path !== '/' && (
					<div>
						{' '}
						<Validator />
						<div className={classes.root}>
							<div className={classes.appFrame}>
								<AppBar stepList={stepList} />
								<Drawer stepList={stepList} />
								<div className={classes.contentMain}>
									<Routes />
								</div>
							</div>
						</div>
					</div>
				)}
			</Router>
		);
	}
}

function isMobileBrowser() {
	var check = false;
	// tslint:disable-next-line:max-line-length
	(function (a: any) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		) {
			check = true;
		}
	})(navigator.userAgent || navigator.vendor);
	return check;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		height: '100%',
		zIndex: 1,
		overflow: 'hidden',
	},
	appFrame: {
		position: 'relative',
		display: 'flex',
		width: '100%',
		height: '100%',
	},
	routes: {
		width: '100%',
		height: '100%',
	},
	navIconHide: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	contentMain: {
		backgroundColor: theme.palette.background.default,
		width: '100%',
		height: 'calc(100% - ' + appBarHeight + 'px)',
		[theme.breakpoints.up('sm')]: {
			height: 'calc(100% - ' + appBarHeight + 'px)',
		},
	},
	contentPages: {
		width: '100%',
		height: '100%',
		//height: "calc(100% - " + appBarHeight + "px)",
		marginTop: appBarHeight,
		[theme.breakpoints.up('sm')]: {
			//height: "calc(100% - " + appBarHeight + "px)",
			height: '100%',
			marginTop: appBarHeight,
		},
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	pagesContainerFullscreen: {
		width: '100%',
		height: '100%',
		// paddingTop: 1,
		// [theme.breakpoints.up("sm")]: {
		// 	paddingTop: 1,
		// },
	},
}));

export default withRoot(withWidth()(App));
