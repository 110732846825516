// prettier-ignore
import { makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {}

function MobileOverlay(props: Props) {
	const {} = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography color="primary" variant="h2" style={{ paddingBottom: '20%', marginLeft: 10 }}>
				Mobile wird derzeit nicht unterstützt!
			</Typography>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: window.innerHeight,
		backgroundColor: theme.palette.secondary.main,
	},
}));

export default MobileOverlay;
