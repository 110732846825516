import { Button, CircularProgress, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useActions } from '../../actions';
import * as AppConfigActions from '../../actions/appConfig';
import * as UserActions from '../../actions/user';
import { Route } from '../../model/model';
import { RootState } from '../../reducers';

interface Props {}

function LoginForm(props: Props) {
	const classes = useStyles();

	const { history } = useReactRouter();
	const { t } = useTranslation();

	const [password, setPassword] = React.useState('');
	const [username, setUsername] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [showError, setShowError] = React.useState(false);
	const [showNotification, setShowNotification] = React.useState(false);
	const [notificationType, setNotificationType] = React.useState(''); // 'loginError' / 'emailSuccess' / 'emailError' possible

	const token: string = useSelector((state: RootState) => state.token);
	const authError: number = useSelector((state: RootState) => state.authError);
	const serverStatus: number = useSelector((state: RootState) => state.serverStatus);

	const appConfigActions: typeof AppConfigActions = useActions(AppConfigActions);

	const userActions = useActions(UserActions);

	// when token is updated => redirect to next page
	React.useEffect(() => {
		if (token) {
			//if login was successfull get app config from server

			appConfigActions.getAppConfig(token, () => history.push(Route.PRODUCT_SELECTION_PRODUCT_FAMILIES));
			// if authError was caused by token fetch => display error view
		} else if (authError === 400 || authError === 401) {
			setNotificationType('loginError');
			setShowError(true);
			//reset authError + error view + clear text-fields
			userActions.setAuthError(0);
			setUsername('');
			setPassword('');
		} else {
			userActions.setTokenFromCookie();
		}
	});

	// when notice types are updated => re-render component with notices
	React.useEffect(() => {
		if (notificationType) {
			setShowNotification(true);
		}
	});

	const onLoginButton = () => {
		if (username && password) {
			// updates state of token via API call
			userActions.getUserToken(username, password);
		}
	};

	const onForgotPasswordButton = () => {
		setOpen(true);
		// reset auth error + error notice if dialog is openend => prettier
		setShowError(false);
		setNotificationType('');
		setShowNotification(false);
		userActions.setAuthError(0);
	};

	// pressing Enter Key will fire login too
	const keypress = (e: any) => {
		if (e.key === 'Enter') {
			onLoginButton();
		}
	};

	// change style of forgot-password button if user enters wrong password
	const forgotButtonSwitchStyle = (showError: boolean) => {
		if (showError) {
			return classes.forgotButtonAlert;
		} else {
			return classes.forgotButtonDefault;
		}
	};

	// handler for closing of request password reset dialog
	const handleClose = (email?: string) => {
		setOpen(false);
		// if user typed in email => trigger action for sending reset link, else user cancelled dialog
		if (email) {
			userActions.getPasswordResetLink(email);
			// check if server could find mail address
			if (serverStatus === 202) {
				setNotificationType('emailSuccess');
			} else if (authError === 404) {
				setNotificationType('emailError');
			}
		}
		//reset authError
		userActions.setAuthError(0);
	};

	const handleUsernameChange = (e: any) => setUsername(e.target.value);

	const handlePasswordChange = (e: any) => setPassword(e.target.value);

	// notification texts, based on type of notification:
	const notification_tooltips = (noticeType: string) => {
		switch (noticeType) {
			case 'loginError': {
				return (
					<Typography variant={'body1'} align={'center'}>
						{t('login.loginError')}
					</Typography>
				);
			}
			case 'emailSuccess': {
				return (
					<Typography variant={'body1'} align={'center'}>
						{t('login.emailSuccess')}
					</Typography>
				);
			}
			case 'emailError': {
				return (
					<Typography variant={'body1'} align={'center'}>
						{t('login.emailError')}
					</Typography>
				);
			}
			default: {
				// break if nothing has to be communicated to user
				break;
			}
		}
	};

	if (token) {
		return <CircularProgress />;
	} else {
		return (
			<form className={classes.container} noValidate autoComplete="off" onKeyDown={keypress}>
				<Tooltip
					open={showNotification}
					title={<React.Fragment>{notification_tooltips(notificationType)}</React.Fragment>}
					placement="top"
				>
					<div>
						<TextField
							error={showError}
							id="username"
							label={t('login.username')}
							className={classes.textField}
							value={username}
							onChange={handleUsernameChange}
							margin="normal"
							variant="filled"
						/>
						<TextField
							error={showError}
							id="password"
							label={t('login.password')}
							className={classes.textField}
							value={password}
							onChange={handlePasswordChange}
							margin="normal"
							variant="filled"
							type="password"
						/>
						<Button variant="contained" color="primary" className={classes.button} onClick={onLoginButton}>
							{t('login.loginButton')}
						</Button>
					</div>
				</Tooltip>
			</form>
		);
	}
}

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
	},
	textField: {
		width: '80%',
		margin: 10,
		backgroundColor: 'white',
	},
	button: {
		margin: 10,
		width: 200,
		marginLeft: '40%',
		marginRight: '40%',
	},
	forgotButtonDefault: {
		color: 'white',
		width: 200,
		float: 'right',
		textAlign: 'right',
	},
	forgotButtonAlert: {
		color: 'red',
		backgroundColor: 'white',
		width: 200,
		float: 'right',
		textAlign: 'right',
		marginRight: '10%',
		opacity: 0.7,
		'&:hover': {
			backgroundColor: 'white',
			opacity: 1,
		},
	},
	rightIcon: {
		marginRight: theme.spacing(0.5),
	},
	buttonText: {
		whiteSpace: 'nowrap',
		color: 'white',
	},
	buttonTextAlert: {
		whiteSpace: 'nowrap',
		color: 'black',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		marginRight: '10%',
	},
}));

export default LoginForm;
