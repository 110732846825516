import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core/';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useActions } from '../../actions';
import * as RoomSpecActions from '../../actions/roomSpec';
import { useValidator } from '../../middleware/fieldValidation/fieldValidatorHook';
//prettier-ignore
import { DoorSpecification, LengthUnit, RoomConfiguration, RoomDimensions, RoomSpec, Route } from "../../model/model";
import { RootState } from '../../reducers';
import ConfigFieldList from '../configuratorView/ConfigFieldList';
import ConfiguratorView from '../configuratorView/ConfiguratorView';
import ConfigDivider from '../configuratorView/fields/ConfigDivider';
import ConfigFieldContainer from '../configuratorView/fields/ConfigFieldContainer';
import ConfigNextButton from '../configuratorView/fields/ConfigNextButton';
import ImageSelectionCarousel from '../imageSelectionCarousel/ImageSelectionCarousel';
import { convertLengthUnitToMeter } from '../roomPlan/LengthUnitConverter';
import { getCalculatedRoomDimensions } from '../roomPlan/roomPlanLogicFunctions/dimensionCalculatorFunctions';
import { calculateDoorPositionAtWallMaxAndMinValue } from '../roomPlan/roomPlanLogicFunctions/doorFunctions';
import { getUpdatedDoorSwitches } from '../roomPlan/roomPlanLogicFunctions/socketFunctions';
import SelectionInput from '../selectionInput/SelectionInput';
import DoorWallPositionConfig from './DoorWallPositionConfig';
import FrameTypeConfig from './FrameOpeningConfig';

interface Props extends RouteComponentProps<void> {}

function DoorSpecificationView(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);
	const roomConfigurationValues: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);

	const roomSpecActions: typeof RoomSpecActions = useActions(RoomSpecActions);

	const { getErrorText, setFieldDirty } = useValidator();

	const { doorSpecification } = roomSpec;
	const { doors } = roomConfigurationValues;
	const frameType = roomSpec.doorSpecification ? roomSpec.doorSpecification.frameType : '';
	const frameTypes = roomConfigurationValues.frameTypes;

	const walls = [roomSpec.wallEast, roomSpec.wallNorth, roomSpec.wallSouth, roomSpec.wallWest];

	const handleDoorTypeSelection = (id: string) => {
		setFieldDirty('doorSpecification');
		let selectedDoorType = doors.filter((el: DoorSpecification) => el.id === id)[0];
		updateDoor(selectedDoorType);
	};

	const handleDoorTypeImageSelection = (selectedImageId: string) => {
		let updatedDoorSpec: DoorSpecification = {
			...roomSpec.doorSpecification!,
			selectedImageId,
		};

		updateDoor(updatedDoorSpec);
	};

	const updateDoor = (door: DoorSpecification) => {
		let wallSwitches = roomSpec.wallSwitches;
		if (door.wall) {
			wallSwitches.doorSwitches = getUpdatedDoorSwitches(door, roomConfigurationValues.socketConfig);
		}

		roomSpecActions.updateRoomSpec({
			...roomSpec,
			doorSpecification: door,
			wallSwitches: wallSwitches,
		});
	};

	const updateFrameType = (frameType: string) => {
		let updatedDoorSpec: DoorSpecification = {
			...roomSpec.doorSpecification!,
			frameType,
		};

		roomSpecActions.updateRoomSpec({
			...roomSpec,
			doorSpecification: updatedDoorSpec,
		});
	};

	return (
		<ConfiguratorView
			configFieldList={
				<ConfigFieldList>
					{/*<ConfigDivider title={t('steps.structuralElements.doorType')} />*/}
					{/*<ConfigFieldContainer>*/}
					{/*	<SelectionInput*/}
					{/*		{...props}*/}
					{/*		selectedElementId={doorSpecification ? doorSpecification.id : '-1'}*/}
					{/*		selectableElements={doors}*/}
					{/*		handleElementSelection={handleDoorTypeSelection}*/}
					{/*		errorText={t(getErrorText('doorSpecification'))}*/}
					{/*	/>*/}
					{/*</ConfigFieldContainer>*/}
					{doorSpecification && doorSpecification.id && (
						<div>
							<OpeningDirectionConfig {...props} updateDoor={updateDoor} />
							<ConfigDivider />
							<DoorHeightAndWidthConfig {...props} updateDoor={updateDoor} />
							{/*<FrameTypeConfig*/}
							{/*	door={doorSpecification}*/}
							{/*	updateAction={updateDoor}*/}
							{/*	validatorProp="doorSpecification"*/}
							{/*	frameType={frameType}*/}
							{/*	frameTypes={frameTypes}*/}
							{/*	updateFrameType={updateFrameType}*/}
							{/*/>*/}
						</div>
					)}
					{/*{doorSpecification && doorSpecification.id && (*/}
					{/*	<div className={classes.sliderContainer}>*/}
					{/*		<ImageSelectionCarousel*/}
					{/*			{...props}*/}
					{/*			selectableImages={roomConfigurationValues.doorImageSelection}*/}
					{/*			selectedImageId={doorSpecification.selectedImageId}*/}
					{/*			handleImageSelection={handleDoorTypeImageSelection}*/}
					{/*		/>*/}
					{/*	</div>*/}
					{/*)}*/}
					{doorSpecification && doorSpecification.id && (
						<DoorWallPositionConfig
							label={t('steps.structuralElements.doorLocation')}
							walls={walls}
							updateAction={updateDoor}
						/>
					)}
					<ConfigDivider />
					<ConfigNextButton nextRoute={Route.STRUCTURAL_ELEMENTS_INTERIOR_DETAILS} />
				</ConfigFieldList>
			}
		/>
	);
}

interface DoorConfigProps extends RouteComponentProps<void> {
	updateDoor(door: DoorSpecification): void;
}

interface SelectableElement {
	id: string;
	name: string;
	value: LengthUnit;
}

function DoorHeightAndWidthConfig(props: DoorConfigProps) {
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);

	const roomConfig: RoomConfiguration = useSelector((state: RootState) => state.roomConfigurationValues);

	const dim = getCalculatedRoomDimensions(roomSpec, roomConfig);
	const { doorSpecification } = roomSpec;

	const classes = useStyles();

	const { t } = useTranslation();

	const { updateDoor } = props;

	const handleDoorHeightSelection = (id: string) => {
		let selectedHeight = selectableDoorHeights.find((el) => el.id === id);
		updateDoor({
			...roomSpec.doorSpecification!,
			height: selectedHeight!.value,
		});
	};

	const handleDoorWidthSelection = (id: string, selectableDoorWidths: SelectableElement[]) => {
		let selectedWidth = selectableDoorWidths.find((el) => el.id === id);
		updateDoor({
			...roomSpec.doorSpecification!,
			width: selectedWidth!.value,
		});
	};

	const selectableDoorHeights = roomConfig.doorHeights
		.map((el) => {
			return {
				id: lengthUnitToStringId(el),
				name: el.value + ' ' + el.unit,
				value: el,
			};
		})
		.sort((a, b) => {
			return a.value.value - b.value.value;
		});

	const selectableDoorWidths = checkAndGetSelectableDoorWidth(
		doorSpecification,
		dim,
		roomSpec,
		roomConfig,
		handleDoorWidthSelection
	);

	return (
		<div className={classes.fontWeightNormal}>
			<SelectionInput
				{...props}
				label={t('steps.structuralElements.doorHeight')}
				selectedElementId={
					doorSpecification && doorSpecification.height
						? lengthUnitToStringId(doorSpecification.height)
						: '-1'
				}
				sortFunction={(a: SelectableElement, b: SelectableElement) => {
					return a.value.value - b.value.value;
				}}
				selectableElements={selectableDoorHeights}
				handleElementSelection={handleDoorHeightSelection}
			/>

			<SelectionInput
				{...props}
				label={t('steps.structuralElements.doorWidth')}
				selectedElementId={
					doorSpecification && doorSpecification.width ? lengthUnitToStringId(doorSpecification.width) : '-1'
				}
				selectableElements={selectableDoorWidths}
				sortFunction={(a: SelectableElement, b: SelectableElement) => {
					return a.value.value - b.value.value;
				}}
				handleElementSelection={(id: string) => {
					handleDoorWidthSelection(id, selectableDoorWidths);
				}}
			/>
		</div>
	);
}

const lengthUnitToStringId = (el: LengthUnit) => {
	return el.value + '_' + el.unit;
};

const checkAndGetSelectableDoorWidth = (
	doorSpecification: DoorSpecification | null,
	dim: RoomDimensions,
	roomSpec: RoomSpec,
	roomConfig: RoomConfiguration,
	handleDoorWidthSelection: (id: string, selectableDoorWidths: SelectableElement[]) => void
) => {
	let { doorMaxPositionValue, doorMinPositionValue } = calculateDoorPositionAtWallMaxAndMinValue(
		dim,
		roomSpec.doorSpecification,
		roomSpec.featureElements,
		roomConfig,
		true
	);

	let doorMinPositionInM = convertLengthUnitToMeter(doorMinPositionValue);
	let doorMaxPositionInM = convertLengthUnitToMeter(doorMaxPositionValue);

	let availableDoorWidthInM = doorMaxPositionInM - doorMinPositionInM;

	const validDoorWidths = roomConfig.doorWidths.filter((el) => convertLengthUnitToMeter(el) <= availableDoorWidthInM);

	const selectableDoorWidths = validDoorWidths.map((el) => {
		return {
			id: lengthUnitToStringId(el),
			name: el.value + ' ' + el.unit,
			value: el,
		};
	});

	//check if currently selected door width is still valid (if one is selected)
	//update to the next widest door width, if the current selected wall width is not valid
	if (
		doorSpecification &&
		doorSpecification.width &&
		!selectableDoorWidths.find((el) => el.id === lengthUnitToStringId(doorSpecification.width))
	) {
		let widestSelectableDoorWidth = selectableDoorWidths.sort((a: SelectableElement, b: SelectableElement) => {
			return b.value.value - a.value.value;
		})[0];

		if (widestSelectableDoorWidth) {
			handleDoorWidthSelection(widestSelectableDoorWidth.id, selectableDoorWidths);
		}
	}

	return selectableDoorWidths;
};

function OpeningDirectionConfig(props: DoorConfigProps) {
	const roomSpec: RoomSpec = useSelector((state: RootState) => state.roomSpec);

	const { doorSpecification } = roomSpec;

	const classes = useStyles();

	const { t } = useTranslation();

	const { updateDoor } = props;

	const handleOpeningDirectionChanged = (event: any) => {
		const selectedOpeningDir = event.target.value;
		updateDoor({
			...doorSpecification!,
			openingDirectionLeftRight: selectedOpeningDir,
		});
	};

	return (
		<div>
			<ConfigDivider title={t('steps.structuralElements.openingDirection')} />
			<ConfigFieldContainer>
				<div className={classes.horizontalContainer}>
					<RadioGroup
						row={true}
						name="openingDirection"
						value={doorSpecification!.openingDirectionLeftRight}
						onChange={handleOpeningDirectionChanged}
					>
						<FormControlLabel
							classes={{
								label: classes.radioButtonLabel,
							}}
							value="left"
							control={<Radio color="primary" />}
							label={t('steps.structuralElements.swingingDirectionLeft')}
							labelPlacement="end"
						/>
						<FormControlLabel
							classes={{
								label: classes.radioButtonLabel,
							}}
							value="right"
							control={<Radio color="primary" />}
							label={t('steps.structuralElements.swingingDirectionRight')}
							labelPlacement="end"
						/>
					</RadioGroup>
				</div>
			</ConfigFieldContainer>
		</div>
	);
}

const useStyles = makeStyles({
	root: {
		maWidth: 1000,
		width: 900,
	},
	radioButtonLabel: {
		fontSize: '0.8rem',
	},
	fontWeightNormal: {
		'& h6': { fontWeight: 'normal' },
		paddingRight: 60,
		paddingLeft: 30,
	},
	horizontalContainer: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#ffffff',
		padding: '5px',
		alignItems: 'center',
	},
	labelContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	label: {
		marginTop: 10,
		marginLeft: 10,
		marginBottom: 10,
		marginRight: 10,
	},
	gridContainer: {
		padding: '5px',
	},

	text: {
		marginTop: 10,
		marginLeft: 10,
		marginBottom: 10,
		marginRight: 10,
	},
	planContainer: {
		padding: '10px',
		marginLeft: '30px',
	},
	carouselSlide: {
		backgroundColor: 'white',
	},
	sliderContainer: {
		maxWidth: '380px',
		paddingTop: '10px',
		margin: '0 auto',
	},
});

export default DoorSpecificationView;
